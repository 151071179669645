import isEmpty from "../../reusableComponents/is-empty";
import {SCHEDULE_LOADING, GET_JOB_POSTS, SET_JOB_TITLE, CLEAR_JOB_POSTS_DATA, NO_JOB_POSTS_DATA } from "../actions/types";

const initialState = {
  userEmail: "",
  jobPostsTitle: "",
  jobPostsData: "",
  noJobPostsData: false,
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SCHEDULE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_JOB_TITLE:
      return{
        ...state,
        jobPostsTitle: action.payload.title,
        userEmail: action.payload.email
      }
    case NO_JOB_POSTS_DATA:
      return {
        ...state,
        noJobPostsData: true
      }
    case GET_JOB_POSTS:
      return {
        ...state,
        jobPostsData: action.payload,
        loading: false,
        noJobPostsData: false
      };
    case CLEAR_JOB_POSTS_DATA:
      return {
        ...state,
        userEmail: "",
        jobPostsTitle: "",
        jobPostsData: "",
        noJobPostsData: false,
        loading: false
      };
    default:
      return state;
  }
}

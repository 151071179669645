import { ADMIN_ADD_NEW_COACH_LOADING, ADMIN_GET_INDUSTRY_LIST, ADMIN_GET_SKILLS_LIST, ADMIN_GET_DAYS_LIST, ADMIN_GET_TIMINGS_LIST, ADMIN_GET_COACH_IMAGE, ADMIN_GET_TIMEZONE_LIST } from '../../actions/types';

const initialState = {
    loading: false,
    industryList: [],
    skillsList: [],
    days: [],
    timingSlots: [],
    coachImage: null,
    timeZoneList: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case ADMIN_ADD_NEW_COACH_LOADING:
            return {
                ...state,
                loading: true
            }
        case ADMIN_GET_INDUSTRY_LIST:
            return {
                ...state,
                industryList: action.payload,
                loading: false
            }
        case ADMIN_GET_SKILLS_LIST:
            return {
                ...state,
                skillsList: action.payload,
                loading: false
            }
        case ADMIN_GET_DAYS_LIST:
            return {
                ...state,
                days: action.payload,
                loading: false,
            }
        case ADMIN_GET_TIMINGS_LIST:
            return {
                ...state,
                timingSlots: action.payload,
                loading: false
            }
        case ADMIN_GET_COACH_IMAGE:
            return {
                ...state,
                coachImage: action.payload,
                loading: false
            }
        case ADMIN_GET_TIMEZONE_LIST:
            return {
                ...state,
                timeZoneList: action.payload,
                loading: false
            }
        default:
            return state;
    }
}
import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Container, Grid } from "@material-ui/core";
import MUITable from "../../MaterialTable/MaterialTable";
import {
  getLearnersList,
  clearLearnerProgressInfo,
  clearLearnerInfo,
} from "../../../redux/actions/admin_view/mvkActions";
import { getDateTime } from "../../../reusableComponents/GetDate";

const Mkv = ({
  getLearnersList,
  auth,
  adminMvk,
  clearLearnerProgressInfo,
  clearLearnerInfo,
  history,
}) => {
  const { isAuthenticated } = auth;
  const { learnersList } = adminMvk;

  useEffect(() => {
    clearLearnerProgressInfo();
    clearLearnerInfo();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getLearnersList();
    }
  }, [isAuthenticated]);

  const columns = [
    {
      title: "Learner Name",
      field: "name",
    },
    {
      title: "Email ID",
      field: "email",
    },
    {
      title: "Education",
      field: "education",
    },
    {
      title: "Phone Number",
      field: "phoneNumber",
    },
    {
      title: "Registered On",
      field: "createdAt",
    },
  ];

  const data = learnersList?.map((list) => {
    const {
      _id,
      firstName,
      lastName,
      email,
      role,
      phoneNumber,
      education,
      createdAt,
    } = list;
    return {
      _id,
      name: `${firstName} ${lastName}`,
      email,
      role,
      phoneNumber,
      education,
      createdAt: getDateTime(createdAt),
    };
  });

  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUITable
            title="Learners List"
            columns={columns}
            data={data}
            pageSize={15}
            // selectedRow={row => history.push(`/admin/learner/${row._id}`)}
            selectedRow={(row) =>
              history.push(`/admin/mentorLearnerInfo/${row._id}`)
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
};

Mkv.propTypes = {
  auth: PropTypes.object.isRequired,
  getLearnersList: PropTypes.func.isRequired,
  clearLearnerProgressInfo: PropTypes.func.isRequired,
  clearLearnerInfo: PropTypes.func.isRequired,
  adminMvk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  adminMvk: state.adminMvk,
});

const mapDispatchToProps = {
  getLearnersList,
  clearLearnerProgressInfo,
  clearLearnerInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Mkv);

import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar } from "@material-ui/core";
import MUITable from "../../MaterialTable/MaterialTable";
import { getProgramBatchList } from "../../../redux/actions/programBatchActions";
import { Search as SearchIcon, Add as AddIcon } from "@material-ui/icons";
import ButtonField from "../../../reusableComponents/ButtonField";
import { getMentorBatchList } from "../../../redux/actions/mentorActions";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

const ProgramBatch = ({
  getProgramBatchList,
  getMentorBatchList,
  programBatch,
  mentor,
  auth,
  history,
}) => {
  const classes = useStyles();
  const { isAuthenticated, user } = auth;
  const { programBatchList } = programBatch;
  const { mentorBatchList } = mentor;
  const currentUser = user?.role?.r_id === 1 ? "admin" : "mentor";

  useEffect(() => {
    if (isAuthenticated) {
      if (currentUser === "admin") getProgramBatchList();
      else {
        getMentorBatchList(user?._id);
      }
    }
  }, [isAuthenticated]);

  const columns = [
    {
      title: "Batch Title",
      field: "name",
    },
    {
      title: "Program Name",
      field: "programName",
    },
    {
      title: "Start Date",
      field: "batchStartDate",
    },
    {
      title: "End Date",
      field: "batchEndDate",
    },
  ];

  const adminData = programBatchList?.map((program) => {
    const {
      _id,
      name,
      description,
      programName,
      batchStartDate,
      batchEndDate,
    } = program;
    return {
      _id,
      name,
      description,
      programName: programName?.name,
      batchStartDate: new Date(batchStartDate).toDateString(),
      batchEndDate: new Date(batchEndDate).toDateString(),
    };
  });

  const mentorData = mentorBatchList?.map((program) => {
    const {
      _id,
      name,
      description,
      programName,
      batchStartDate,
      batchEndDate,
    } = program;
    return {
      _id,
      name,
      description,
      programName: programName[0]?.name,
      batchStartDate: new Date(batchStartDate).toDateString(),
      batchEndDate: new Date(batchEndDate).toDateString(),
    };
  });

  const data = currentUser === "admin" ? adminData : mentorData;

  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MUITable
            title={
              <ButtonField
                variant="outlined"
                name="Create Batch"
                icon={<AddIcon />}
                onClick={() => history.push("createBatch")}
              />
            }
            columns={columns}
            data={data}
            pageSize={10}
            // selectedRow={(row) => console.log("clicked")}
            selectedRow={(row) =>
              // history.push(`/admin/addBatchUsers/${row._id}`)
              history.push(`/admin/batchUsersList/${row._id}/${row.name}`)
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
};

ProgramBatch.propTypes = {
  auth: PropTypes.object.isRequired,
  getProgramBatchList: PropTypes.func.isRequired,
  getMentorBatchList: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  programBatch: state.programBatch,
  mentor: state.mentor,
});

const mapDispatchToProps = { getProgramBatchList, getMentorBatchList };

export default connect(mapStateToProps, mapDispatchToProps)(ProgramBatch);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCoachInfo } from "../../../redux/actions/admin_view/coachList";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Card,
  Grid,
  Paper,
  Typography,
  Avatar,
} from "@material-ui/core";
import { PeopleOutline as PeopleOutlineIcon } from "@material-ui/icons";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import { getDateTime } from "../../../reusableComponents/GetDate";
// import SelectMultipleValues from '../../../reusableComponents/SelectMultipleValues';
import ButtonField from "../../../reusableComponents/ButtonField";

import isEmpty from "../../../reusableComponents/is-empty";
import PhotoUpload from "../../../reusableComponents/PhotoUpload";
import SelectMultipleValues from "../../../reusableComponents/SelectMultipleValues";
import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";
import MultiSelect from "../../../reusableComponents/MultiSelect";

import {
  registerCoach,
  getIndustryList,
  getSkillsList,
  getDaysList,
  getTimingsList,
  getCoachImage,
  getTimeZoneList,
} from "../../../redux/actions/admin_view/addNewCoachActions";

import { editCoachInfo } from "../../../redux/actions/admin_view/coachList";

import { useFormik } from "formik";
import * as Yup from "yup";
import { formatRelativeWithOptions } from "date-fns/esm/fp";

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
    marginBottom: theme.spacing(4),
  },
  paperRoot: {
    backgroundColor: "#fdfdff",
    padding: theme.spacing(3, 4),
  },
  pageAssignHeader: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
  },
  pageAssignIcon: {
    display: "inline-block",
    padding: theme.spacing(1),
    color: "#3d4977",
  },
  pageAssignTitle: {
    paddingLeft: theme.spacing(2),
    "& .MuiTypography-h6": {
      fontWeight: 600,
    },
  },
  Image: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  pageEditButton: {
    display: "flex",
    alignItems: "right",
    justifyContent: "right",
    marginLeft: "660px",
  },
}));

const CoachInfo = ({
  getCoachInfo,
  auth,
  adminCoach,
  getIndustryList,
  adminAddNewCoach,
  getSkillsList,
  getDaysList,
  getTimingsList,
  getCoachImage,
  getTimeZoneList,
  editCoachInfo,
  match,
}) => {
  const {
    industryList,
    skillsList,
    days,
    timingSlots,
    coachImage,
    timeZoneList,
  } = adminAddNewCoach;

  // console.log("checking for TimingSlot", timingSlots);
  const classes = useStyles();
  const { isAuthenticated } = auth;
  const { userId } = match.params;
  const { coachInfo } = adminCoach;
  const [textFieldProp, setTextFieldProp] = useState(true);
  const [callOnce, setCallOnce] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      getCoachInfo(userId);
      getIndustryList();
      getSkillsList();
      getDaysList();
      getTimingsList();
      getTimeZoneList();
      if (!isEmpty(coachImage)) {
        formik.setFieldValue("userImageName", coachImage.name);
        formik.setFieldValue("userImage", coachImage.link);
      }
    }
  }, [isAuthenticated, coachImage]);

  useEffect(() => {
    if (callOnce && isAuthenticated && coachInfo) {
      formik.setFieldValue(
        "userImage",
        coachInfo && coachInfo?.userImage && coachInfo?.userImage.link
          ? coachInfo.userImage.link
          : ""
      );
      formik.setFieldValue(
        "userImageName",
        coachInfo && coachInfo?.userImage && coachInfo?.userImage.name
          ? coachInfo.userImage.name
          : ""
      );
      formik.setFieldValue(
        "firstName",
        coachInfo && coachInfo?.firstName ? coachInfo?.firstName : ""
      );
      formik.setFieldValue(
        "lastName",
        coachInfo && coachInfo?.lastName ? coachInfo?.lastName : ""
      );
      formik.setFieldValue(
        "email",
        coachInfo && coachInfo?.email ? coachInfo?.email : ""
      );
      formik.setFieldValue(
        "expertIn",
        coachInfo && coachInfo?.expertIn ? coachInfo?.expertIn : ""
      );
      formik.setFieldValue(
        "industry",
        coachInfo && coachInfo?.industry ? coachInfo?.industry : []
      );
      formik.setFieldValue(
        "skills",
        coachInfo && coachInfo?.skills ? coachInfo?.skills : []
      );
      formik.setFieldValue(
        "experience",
        coachInfo && coachInfo?.experience ? coachInfo?.experience : ""
      );
      formik.setFieldValue(
        "location",
        coachInfo && coachInfo?.location ? coachInfo?.location : ""
      );
      formik.setFieldValue(
        "weekelyAvailableHours",
        coachInfo && coachInfo?.weekelyAvailableHours
          ? coachInfo?.weekelyAvailableHours
          : ""
      );
      const alterDays = coachInfo?.preferredDays?.map((list) => {
        const { _id, day } = list;
        return {
          _id,
          name: day,
        };
      });
      formik.setFieldValue(
        "preferredDays",
        coachInfo && coachInfo?.preferredDays && alterDays?.length > 0
          ? alterDays
          : []
      );
      const alterTimingSlots = coachInfo?.prefferedTime?.map((list) => {
        const { _id, slot } = list;
        return {
          _id,
          name: slot,
        };
      });
      formik.setFieldValue(
        "prefferedTime",
        // coachInfo && coachInfo?.prefferedTime && alterTimingSlots?.length ? alterTimingSlots : []
        coachInfo && coachInfo?.prefferedTime && alterTimingSlots?.length > 0
          ? alterTimingSlots
          : []
      );
      formik.setFieldValue(
        "timezone",
        coachInfo && coachInfo?.timezone ? { name: coachInfo?.timezone } : {}
      );
      formik.setFieldValue(
        "description",
        coachInfo && coachInfo?.description ? coachInfo?.description : ""
      );
      setCallOnce(false);
    }
  }, [coachInfo]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      userImage: "",
      userImageName: "",
      expertIn: "",
      industry: [],
      skills: [],
      experience: "",
      location: "",
      weekelyAvailableHours: "",
      preferredDays: [],
      prefferedTime: [],
      description: "",
      timezone: {},
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .trim()
        .required("First Name is a required field")
        .min(3, "Name must be at least 3 characters")
        .matches(/^[A-Za-z\s]+$/, "First Name should contain only alphabets"),
      lastName: Yup.string()
        .trim()
        .required("Last Name is a required field")
        .min(3, "Name must be at least 3 characters")
        .matches(/^[A-Za-z\s]+$/, "First Name should contain only alphabets"),
      email: Yup.string().email().required("Email is required field"),
      expertIn: Yup.string()
        .trim()
        .matches(/^[A-Za-z\s]+$/, "Expert In should contain only alphabets")
        .required("Expert In is required field"),
      industry: Yup.array()
        .of(
          Yup.object({
            _id: Yup.string(),
            name: Yup.string(),
          })
        )
        .min(1, "Industry is required field")
        .required("Industry is required field"),
      skills: Yup.array()
        .of(
          Yup.object({
            _id: Yup.string(),
            name: Yup.string(),
          })
        )
        .min(1, "Skills is required field")
        .required("Skills is required field"),
      experience: Yup.string()
        .trim()
        .matches(/^[0-9]*$/, "Experience should be in numbers")
        .required("Experience is reqired field"),
      location: Yup.string()
        .trim()
        .matches(/^[A-Za-z\s]+$/, "location should contain only alphabets")
        .required("Location is reqired field"),
      weekelyAvailableHours: Yup.string()
        .trim()
        .matches(/^[0-9]*$/, "Weekly Available should be in numbers")
        .required("Weekly Available Time is reqired field"),
      preferredDays: Yup.array()
        .of(
          Yup.object({
            _id: Yup.string(),
            name: Yup.string(),
          })
        )
        .min(1, "Preffered Days is required field")
        .required("Preffered Days is reqired field"),
      prefferedTime: Yup.array(
        Yup.object({
          _id: Yup.string().required("Preffered Time is required field"),
          name: Yup.string().required("Preffered Time is required field"),
        })
      )
        .nullable()
        .min(1, "Preffered Time is required field")
        .required("Preffered Time is reqired field"),
      timezone: Yup.object({
        name: Yup.string().required("Timezone is reqired field"),
      })
        .nullable()
        .required("Timezone is reqired field"),
      description: Yup.string()
        .trim()
        .required("Description is a required field"),
    }),

    onSubmit: async (values) => {
      const userData = await values;
      userData.industry = await userData?.industry?.map((list) => list._id);
      userData.skills = await userData?.skills?.map((list) => list._id);
      userData.weekelyAvailableHours = await parseInt(
        userData.weekelyAvailableHours
      );
      userData.preferredDays = await userData?.preferredDays?.map(
        (list) => list._id
      );
      userData.prefferedTime = await userData?.prefferedTime?.map(
        (list) => list._id
      );
      userData.timezone = await userData?.timezone?.name;
      delete userData.userImageName;

      if (isEmpty(userData.description)) {
        delete userData.description;
      }

      if (isEmpty(userData.userImage)) {
        delete userData.userImage;
      } else {
        userData.userImage = coachImage;
      }
      userData.userId = userId;

      // console.log("in coachInfo1", userData);
      await editCoachInfo(userData);
    },
  });

  const alterDays = days?.map((list) => {
    const { _id, day } = list;
    return {
      _id,
      name: day,
    };
  });

  const alterTimingSlots = timingSlots?.map((list) => {
    const { _id, slot } = list;
    return {
      _id,
      name: slot,
    };
  });

  const alterTimeZoneList = timeZoneList?.map((list) => {
    const { timeZone } = list;
    return {
      name: timeZone,
    };
  });

  const uploadCoachImage = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    await formData.append("photo", e.target.files[0]);
    getCoachImage(formData);
  };

  useEffect(() => {
    console.log(formik.errors);
  }, [formik.errors]);

  const userData = () => {
    return (
      <>
        <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
          <Grid container spacing={5}>
            <Grid item xs={12} className="inputfieldCenter" align="center">
              <PhotoUpload
                disabled={textFieldProp}
                imageName={formik.values.userImageName}
                imageUrl={formik.values.userImage}
                onChange={(e) => uploadCoachImage(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                disabled={textFieldProp}
                label="FirstName"
                type="text"
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                errors={
                  formik.touched.firstName && formik.errors.firstName
                    ? formik.errors.firstName
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                disabled={textFieldProp}
                label="LastName"
                type="text"
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                errors={
                  formik.touched.lastName && formik.errors.lastName
                    ? formik.errors.lastName
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                disabled={textFieldProp}
                label="Email"
                type="text"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                errors={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : null
                }
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="Password"
            type={showPassword ? "text" : "password"}
            name="password"
            passwordControl={() => setShowPassword(!showPassword)}
            showPassword={showPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            errors={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : null
            }
          />
        </Grid> */}
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                disabled={textFieldProp}
                label="Expert In"
                type="text"
                name="expertIn"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.expertIn}
                errors={
                  formik.touched.expertIn && formik.errors.expertIn
                    ? formik.errors.expertIn
                    : null
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="inputFieldContainer inputFieldMultiContainer"
            >
              <SelectMultipleValues
                disabled={textFieldProp}
                // placeholder="Industry *"
                name="industry"
                dataList={industryList}
                onSelectData={(industry) =>
                  formik.setFieldValue("industry", industry)
                }
                selectedData={formik.values.industry}
                errors={
                  formik.touched.industry && formik.errors.industry
                    ? formik.errors.industry
                    : null
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="inputFieldContainer inputFieldMultiContainer"
            >
              <SelectMultipleValues
                disabled={textFieldProp}
                placeholder="Skills *"
                name="skills"
                dataList={skillsList}
                onSelectData={(skills) =>
                  formik.setFieldValue("skills", skills)
                }
                selectedData={formik.values.skills}
                errors={
                  formik.touched.skills && formik.errors.skills
                    ? formik.errors.skills
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                disabled={textFieldProp}
                label="Experience"
                type="text"
                name="experience"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.experience}
                errors={
                  formik.touched.experience && formik.errors.experience
                    ? formik.errors.experience
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                disabled={textFieldProp}
                label="Location"
                type="text"
                name="location"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.location}
                errors={
                  formik.touched.location && formik.errors.location
                    ? formik.errors.location
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                disabled={textFieldProp}
                label="Weekly Available Hours"
                type="text"
                name="weekelyAvailableHours"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.weekelyAvailableHours}
                errors={
                  formik.touched.weekelyAvailableHours &&
                  formik.errors.weekelyAvailableHours
                    ? formik.errors.weekelyAvailableHours
                    : null
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="inputFieldContainer inputFieldMultiContainer"
            >
              {/* <SelectMultipleValues
                disabled={textFieldProp}
                placeholder="Preffered Days *"
                name="preferredDays"
                dataList={alterDays}
                onSelectData={(preferredDays) =>
                  formik.setFieldValue("preferredDays", preferredDays)
                }
                selectedData={formik.values.preferredDays}
                errors={
                  formik.touched.preferredDays && formik.errors.preferredDays
                    ? formik.errors.preferredDays
                    : null
                }
              /> */}

              <MultiSelect
                disabled={textFieldProp}
                placeholder="Preffered Days *"
                label="Preffered Days *"
                name="preferredDays"
                dataList={alterDays}
                onSelectData={(days) => {
                  console.log(days);
                  formik.setFieldValue("preferredDays", days);
                }}
                selectedData={formik.values.preferredDays}
                errors={
                  formik.touched.preferredDays && formik.errors.preferredDays
                    ? formik.errors.preferredDays
                    : null
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="inputFieldContainer inputFieldMultiContainer"
            >
              {/* {console.log("preferredDays", formik.values.preferredDays)} */}
              {/* <SelectMultipleValues
                disabled={textFieldProp}
                label="Preffered Time *"
                placeholder="Preffered Time *"
                name="prefferedTime"
                dataList={alterTimingSlots}
                onSelectData={(prefferedTime) =>
                  formik.setFieldValue("prefferedTime", prefferedTime)
                }
                selectedData={formik.values.prefferedTime}
                errors={
                  formik.touched.prefferedTime && formik.errors.prefferedTime
                    ? formik.errors.prefferedTime
                    : null
                }
              /> */}

              <MultiSelect
                disabled={textFieldProp}
                label="Preffered Time *"
                placeholder="Preffered Time *"
                name="prefferedTime"
                dataList={alterTimingSlots}
                onSelectData={(prefferedTime) =>
                  formik.setFieldValue("prefferedTime", prefferedTime)
                }
                selectedData={formik.values.prefferedTime}
                errors={
                  formik.touched.prefferedTime && formik.errors.prefferedTime
                    ? formik.errors.prefferedTime
                    : null
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className="inputFieldContainer inputFieldMultiContainer"
            >
              <SelectSingleValues
                disabled={textFieldProp}
                placeholder="TimeZone *"
                name="timezone"
                values={alterTimeZoneList}
                onSelect={(timezone) =>
                  formik.setFieldValue("timezone", timezone)
                }
                selected={formik.values.timezone}
                errors={
                  formik.touched.timezone && formik.errors.timezone
                    ? formik.errors.timezone
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} className="inputFieldContainer">
              <TextFieldGroup
                disabled={textFieldProp}
                label="Description"
                type="text"
                name="description"
                multiline
                rows={4}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                errors={
                  formik.touched.description && formik.errors.description
                    ? formik.errors.description
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} className="inputFieldContainer">
              <div className={classes.submitButton}>
                <ButtonField
                  disabled={textFieldProp}
                  buttonStyle="formSubmit"
                  type="submit"
                  name="Submit"
                  className={classes.mentorForm}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  return (
    <Container maxWidth="lg">
      <div className={classes.gridRoot}>
        <Paper className={classes.paperRoot}>
          <div className={classes.pageAssignHeader}>
            <Card className={classes.pageAssignIcon}>
              <PeopleOutlineIcon fontSize="large" />
            </Card>
            <div className={classes.pageAssignTitle}>
              <Typography variant="h6" component="div">
                Coach Details
              </Typography>
            </div>
            <ButtonField
              buttonStyle={classes.pageEditButton}
              name={"Edit"}
              onClick={() => setTextFieldProp(false)}
            />
          </div>
          <Grid container spacing={3}>
            {userData()}
          </Grid>
        </Paper>
      </div>
    </Container>
  );
};

CoachInfo.propTypes = {
  auth: PropTypes.object.isRequired,
  getCoachInfo: PropTypes.func.isRequired,
  adminCoach: PropTypes.object.isRequired,
  registerCoach: PropTypes.func.isRequired,
  getIndustryList: PropTypes.func.isRequired,
  getSkillsList: PropTypes.func.isRequired,
  getDaysList: PropTypes.func.isRequired,
  getTimingsList: PropTypes.func.isRequired,
  getCoachImage: PropTypes.func.isRequired,
  getTimeZoneList: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  adminAddNewCoach: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  adminCoach: state.adminCoach,
  adminAddNewCoach: state.adminAddNewCoach,
});

const mapDispatchToProps = {
  getCoachInfo,
  registerCoach,
  getIndustryList,
  getSkillsList,
  getDaysList,
  getTimingsList,
  getCoachImage,
  getTimeZoneList,
  editCoachInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoachInfo);

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Paper, Radio } from "@material-ui/core";
import ButtonField from "../../reusableComponents/ButtonField";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: 5,
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
}));

const PersonalityAssessment = ({
  userId,
  psychometricQuestions,
  postPsychometricQuestions,
}) => {
  const classes = useStyles();

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const nextQuestion = currentQuestion + 1;

  const handleNextQuestions = () => {
    setAnsweredQuestions([...answeredQuestions, selectedAnswer]);
    setCurrentQuestion(nextQuestion);
    setSelectedAnswer({});
    setDisabled(true);
  };

  const handleSubmitAnswers = () => {
    const finalAnswer = [...answeredQuestions, selectedAnswer];
    const submitAnswers = {
      _id: userId,
      answers: {
        questionAnswer: finalAnswer,
      },
    };
    postPsychometricQuestions(submitAnswers);
  };

  return (
    <Container maxWidth="lg" className="PersonalityAssessmentContainer">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography className="headingText" variant="h5">
            Personality Assessment Quesions
          </Typography>
          <Typography className="headingTagLine" variant="h5">
            {nextQuestion} / {psychometricQuestions.length}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <div className="PersonalityAssessmentTest">
              <Typography className="assessmentQuestion" variant="h5">
                {nextQuestion}.{" "}
                {psychometricQuestions[currentQuestion]?.question}
              </Typography>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <div className="PersonalityAssessmentTest">
              <div
                className="assessmentAnswer"
                onClick={() => {
                  setDisabled(false);
                  setSelectedAnswer({
                    questionId: psychometricQuestions[currentQuestion]?._id,
                    pickedOption:
                      psychometricQuestions[currentQuestion]?.option1,
                  });
                }}
              >
                <Radio
                  className="assessmentCheck"
                  checked={
                    psychometricQuestions[currentQuestion]?.option1 ===
                    selectedAnswer.pickedOption
                      ? true
                      : false
                  }
                  name={psychometricQuestions[currentQuestion]?.option1}
                />
                <div className="assessmentAnswer">
                  {psychometricQuestions[currentQuestion]?.option1}
                </div>
              </div>
              <div
                className="assessmentAnswer"
                onClick={() => {
                  setDisabled(false);
                  setSelectedAnswer({
                    questionId: psychometricQuestions[currentQuestion]?._id,
                    pickedOption:
                      psychometricQuestions[currentQuestion]?.option2,
                  });
                }}
              >
                <Radio
                  className="assessmentCheck"
                  checked={
                    psychometricQuestions[currentQuestion]?.option2 ===
                    selectedAnswer.pickedOption
                      ? true
                      : false
                  }
                  name={psychometricQuestions[currentQuestion]?.option2}
                />
                <div className="assessmentAnswer">
                  {psychometricQuestions[currentQuestion]?.option2}
                </div>
              </div>
              <div
                className="assessmentAnswer"
                onClick={() => {
                  setDisabled(false);
                  setSelectedAnswer({
                    questionId: psychometricQuestions[currentQuestion]?._id,
                    pickedOption:
                      psychometricQuestions[currentQuestion]?.option3,
                  });
                }}
              >
                <Radio
                  className="assessmentCheck"
                  checked={
                    psychometricQuestions[currentQuestion]?.option3 ===
                    selectedAnswer.pickedOption
                      ? true
                      : false
                  }
                  name={psychometricQuestions[currentQuestion]?.option3}
                />
                <div className="assessmentAnswer">
                  {psychometricQuestions[currentQuestion]?.option3}
                </div>
              </div>
              <div
                className="assessmentAnswer"
                onClick={() => {
                  setDisabled(false);
                  setSelectedAnswer({
                    questionId: psychometricQuestions[currentQuestion]?._id,
                    pickedOption:
                      psychometricQuestions[currentQuestion]?.option4,
                  });
                }}
              >
                <Radio
                  className="assessmentCheck"
                  checked={
                    psychometricQuestions[currentQuestion]?.option4 ===
                    selectedAnswer.pickedOption
                      ? true
                      : false
                  }
                  name={psychometricQuestions[currentQuestion]?.option4}
                />
                <div className="assessmentAnswer">
                  {psychometricQuestions[currentQuestion]?.option4}
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {psychometricQuestions.length - 1 === answeredQuestions.length ? (
            <div className="submitContainer">
              <ButtonField name="Submit" onClick={handleSubmitAnswers} />
            </div>
          ) : (
            <div className="submitContainer">
              <ButtonField
                name="Next"
                onClick={handleNextQuestions}
                disabled={disabled}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default PersonalityAssessment;

import axios from "axios";
import {
  ADMIN_ADD_NEW_SERVICEPROVIDER_LOADING,
  ADMIN_GET_SERVICEPROVIDER_IMAGE,
  ADMIN_GET_sERVICEPROVIDER_TIMEZONE_LIST,
} from "../types";
import { SnackBar } from "../../../reusableComponents/SnackbarConfig";
import history from "../../../reusableComponents/history";

// register Coach
export const registerServiceProvider = (userData) => (dispatch) => {
  console.log(userData);
  axios
    .post("/api/serviceProviders/serviceProviderSignUp", userData)
    .then((res) => {
      const { message } = res?.data;
      history.push("/admin/serviceProviderList");
      SnackBar.success(message);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Edit Service Provider
export const editServiceProvider = (userData) => (dispatch) => {
  axios
    .put(
      `/api/serviceProviders/editServiceProvider/${userData.userId}`,
      userData
    )
    .then((res) => {
      console.log(res.data.message);
      // const message = res?.data?.message;
      history.push("/admin/serviceProviderList");
      SnackBar.success(res.data.message);
    })
    .catch((err) => {
      console.log(err);
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Coach Image
export const getServiceProviderImage = (userImage) => (dispatch) => {
  axios
    .post("/api/adminUser/coachImage", userImage)
    .then((res) =>
      dispatch({
        type: ADMIN_GET_SERVICEPROVIDER_IMAGE,
        payload: res?.data?.message,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Add New Service Provider Loading
export const addNewServiceProviderLoading = () => {
  return {
    type: ADMIN_ADD_NEW_SERVICEPROVIDER_LOADING,
  };
};

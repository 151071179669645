import React, { useEffect, useState } from "react";

// import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Link as Link1 } from "react-scroll";
import { Link } from "react-router-dom";
import axios from "axios";

import { getPrograms } from "../../redux/actions/programActions";
// import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import isEmpty from "../../reusableComponents/is-empty"

// --------------Material Card------------------
import { Container, Grid, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
// import CardActions from '@material-ui/core/CardActions';
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

//-------------images-----------------------------

import includeImg1 from "../../images/programs/includeImg1.jpg";
import includeImg2 from "../../images/programs/includeImg2.jpg";
import includeImg3 from "../../images/programs/includeImg3.jpg";
import includeImg4 from "../../images/programs/includeImg4.jpg";

//------------Reusable Components------------------------

import ButtonField from "../../reusableComponents/ButtonField";
import ProgramCards from "../../reusableComponents/programCards";

//---------------------Components-------------------------
import DataEngineering from "./dataEngProgramDetails";
import QualityAssuDetails from "./qualityAssuDetails";
import BusinessAnalyst from "./businessAnaDetails";
import MicrosoftBI from "./microsoftBI";

import ProgramDetails from "./ProgramDetails";
import { postUserAccessLog } from "../../redux/actions/authActions";

import { USER_TOKEN } from "../../redux/actions/types";

const useStyles = makeStyles((theme) => ({
  newButton: {
    width: "100%",
  },
}));

const Programs = ({ getPrograms, programs, history, auth, postUserAccessLog, }) => {
  const classes = useStyles();

  const { programList, filteredProgramsList } = programs;

  const [programId, setProgramId] = useState({});
  const [shadow, setShadow] = useState(0);

  const onMouseOver = () => setShadow(1);
  const onMouseOut = () => setShadow(0);

  const { user, isAuthenticated } = auth;

  var tabVisibility = document.visibilityState

  const [timeInterval, setTimeInterval] = useState(0);

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 60000);

  useEffect(() => {
    if(isAuthenticated && tabVisibility === "visible"){
      const data = {
        pageName:"Explore Programs",
        recordedDate: new Date(),
      }
      postUserAccessLog(data)
    }
    // console.log("Individual Course Useage , Date", new Date(),"UserId :", user._id,"Location :Explore Program");
  }, [timeInterval,tabVisibility]);

  useEffect(() => {
    getPrograms();
  }, []);

  useEffect(() => {
    if (isEmpty(filteredProgramsList)) {
      getPrograms();
    }
  }, [programList]);

  useEffect(() => {
    setProgramId(programList?.find((item) => item.name === "Data Engineering"));
  }, [programList]);

  const handleIlearn = () => {
    if (user && user.role && user.role.name === "learner") {
      window.open(
        `${process.env.REACT_APP_ILEARN_URL}/ibridge/${USER_TOKEN()}/program`,
        "_blank"
      );
    } else {
      window.open(
        `${process.env.REACT_APP_ILEARN_URL}/explore-courses`,
        "_blank"
      );
    }
  };

  const programsData = filteredProgramsList?.map((program, idx) => (
    <Grid key={idx} item xs={12} md={4}>
      <ProgramCards
        history={history}
        programs={program}
        setProgramId={setProgramId}
        userPrograms={user?.programsEnrolled}
        handleIlearn={handleIlearn}
      />
    </Grid>
  ));

  return (
    <Container maxWidth="lg" className="mainViewContainer">
      <div className="mainViewHeading">
        <Typography className="headingTagLine" varient="h5">
          current program offerings
        </Typography>
      </div>
      <br />
      <Grid container spacing={5}>
        {programsData}
      </Grid>

      <br />
      <br />
      <div className="Prgdetails" id="prgDetails">
        <ProgramDetails 
          programInfo = {programId}
          history ={history}
        />

        {/* {programId?.name === "Data Engineering" ? (
          <DataEngineering history={history} />
        ) : programId?.name === "Quality Assurance" ? (
          <QualityAssuDetails history={history} />
        ) : programId?.name === "Business Analyst" ? (
          <BusinessAnalyst history={history} />
        ) : programId?.name === "Microsoft Business Intelligence" ? (
          <MicrosoftBI history={history} />
        ) : null} */}
      </div>

      <br />
      <br />
      <br />
      <br />

      {/* ------------------------------Program Includes----------------------------------------------- */}

      <div className="prgIncludes">
        <div className="programDetails">
          <div className="prgDetails">
            <Typography className="headingTagLine" varient="h5">
              Programs Include
            </Typography>
          </div>
        </div>

        <br />
        <br />

        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            {/* <div className="imgInclude">
              <Card>
                <CardContent>
                  <img src={includeImg1} />
                </CardContent>
              </Card>
            </div> */}

            <Card
              className="articalCard"
              style={{ width: "90%", height: "80%" }}
            >
              <Card
                container
                className="articalCard1"
                style={{ height: "100%" }}
              >
                <img src={includeImg1} style={{ width: "100%" }} />
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className="textInclude">
              <Typography
                className="headingTagLine includeHead"
                varient="h3"
                style={{ fontWeight: 600 }}
              >
                Projects From
              </Typography>
              <div className="underLine"></div>
              <Typography
                className="headingTagLine"
                varient="h3"
                style={{ fontWeight: 600 }}
              >
                Indsutry Experts
              </Typography>
              <br />
              <Typography className="headingTagLine" varient="subtitle4">
                With relevant projects, bond on the degree and the industry you
                choose, you will master the skills required to add values.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            {/* <div className="imgInclude">
              <Card>
                <CardContent>
                  <img src={includeImg2} />
                </CardContent>
              </Card>
            </div> */}

            <Card
              className="articalCard"
              style={{ width: "90%", height: "80%" }}
            >
              <Card
                container
                className="articalCard1"
                style={{ height: "100%" }}
              >
                <img src={includeImg2} style={{ width: "100%" }} />
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className="textInclude">
              <Typography
                className="headingTagLine includeHead"
                varient="h3"
                style={{ fontWeight: 600 }}
              >
                Mentor Support
              </Typography>
              <div className="underLine"></div>
              <br />
              <Typography className="headingTagLine" varient="subtitle4">
                Our knowledgeable mentors guide your learning and are focused on
                answering your questions, motivating you and keeping you on
                track.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} md={3}>
            {/* <Card>
                <CardContent>
                  <img src={includeImg3} />
                </CardContent>
              </Card> */}
            <Card
              className="articalCard"
              style={{ width: "90%", height: "80%" }}
            >
              <Card
                container
                className="articalCard1"
                style={{ height: "100%" }}
              >
                <img src={includeImg3} style={{ width: "100%" }} />
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className="textInclude">
              <Typography
                className="headingTagLine includeHead"
                varient="h3"
                style={{ fontWeight: 600 }}
              >
                Career Service
              </Typography>
              <div className="underLine" style={{ marginTop: "0px" }}></div>
              <br />
              <Typography className="headingTagLine" varient="subtitle4">
                As we are in mentoring business from 15+ years, we provide
                guidance based on the industry needs and your passion.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            {/* <div className="imgInclude">
              <Card>
                <CardContent>
                  <img src={includeImg4} />
                </CardContent>
              </Card>
            </div> */}
            <Card
              className="articalCard"
              style={{ width: "90%", height: "80%" }}
            >
              <Card
                container
                className="articalCard1"
                style={{ height: "100%" }}
              >
                <img src={includeImg4} style={{ width: "100%" }} />
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className="textInclude">
              <Typography
                className="headingTagLine includeHead"
                varient="h2"
                style={{ fontWeight: 600 }}
              >
                Active Learning
              </Typography>
              <div className="underLine"></div>
              <br />
              <Typography className="headingTagLine" varient="subtitle4">
                Engaged on the daily basis in the platform, to solve the
                problems by doing [Hands-On].
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  programs: state.programs,
  auth: state.auth,
});

const mapDispatchToProps = { getPrograms,postUserAccessLog };

export default connect(mapStateToProps, mapDispatchToProps)(Programs);
// export default Programs;

// import React, { useEffect, useState } from "react";
// import Pagination from "@material-ui/lab/Pagination";
// import { Box, Grid } from "@material-ui/core";
// import ButtonField from "../../reusableComponents/ButtonField";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getRIASECTestQuestions,
//   saveRIASECTestResult,
// } from "../../redux/actions/RIASECTestAction";

// const RIASECTestScreen = ({ history }) => {
//   const { auth, RIASECTestReducer } = useSelector((state) => state);

//   const dispatch = useDispatch();

//   const [quizzes, setQuizzes] = useState([]); // Initialize as an empty array
//   const [selectedOptions, setSelectedOptions] = useState({});
//   const [currentPage, setCurrentPage] = useState(1); // Track the current page
//   const [categoryResults, setCategoryResults] = useState({}); // Track results by category
//   const [totalScore, setTotalScore] = useState(0);
//   const [loading, setLoading] = useState(true); // Loading state
//   const questionsPerPage = 15; // Set the number of questions per page

//   // Handle option change
//   const handleOptionChange = (questionIndex, category, optionPoints) => {
//     setSelectedOptions({
//       ...selectedOptions,
//       [questionIndex]: { points: optionPoints, category },
//     });
//   };

//   // Handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();

//     const categoryScores = {}; // To track scores per category
//     let calculatedTotalScore = 0; // To calculate the total score

//     // Calculate category scores and total score
//     Object.values(selectedOptions).forEach(({ points, category }) => {
//       if (!categoryScores[category]) {
//         categoryScores[category] = 0;
//       }
//       categoryScores[category] += points;
//       calculatedTotalScore += points; // Add to total score
//     });

//     // Set category scores and total score to state
//     setCategoryResults(categoryScores);
//     setTotalScore(calculatedTotalScore);

//     console.log("categoryScores", categoryScores);
//     console.log("totalScore", calculatedTotalScore);

//     const resultObj = {
//       userId: auth.user._id,
//       totalScore: calculatedTotalScore,
//       categoryScores: categoryScores,
//     };
//     console.log("recultObj", resultObj);

//     dispatch(saveRIASECTestResult(resultObj));

//     // alert("Quiz submitted!");
//   };

//   // Get current quiz and slice questions for pagination
//   const indexOfLastQuestion = currentPage * questionsPerPage;
//   const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
//   const currentQuestions =
//     quizzes.length > 0
//       ? quizzes.slice(indexOfFirstQuestion, indexOfLastQuestion)
//       : []; // Fallback to an empty array

//   useEffect(() => {
//     const fetchQuizzes = async () => {
//       setLoading(true); // Set loading to true
//       await dispatch(getRIASECTestQuestions()); // Set loading to false once data is fetched
//     };
//     if (auth?.isAuthenticated) {
//       fetchQuizzes();
//     }
//   }, [auth?.isAuthenticated, dispatch]);

//   useEffect(() => {
//     if (RIASECTestReducer?.riasecTestQuestionsList) {
//       setQuizzes(RIASECTestReducer?.riasecTestQuestionsList);
//       setLoading(false);
//     }
//   }, [RIASECTestReducer?.riasecTestQuestionsList]);

//   // console.log("quizzes", quizzes);

//   // Handle page change using Material UI Pagination component
//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   // Check if all questions on the page have been answered
//   const areAllQuestionsAnswered = quizzes.every((question, questionIndex) =>
//     selectedOptions.hasOwnProperty(questionIndex)
//   );

//   // Return loading state if data is being fetched
//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <>
//       {quizzes && quizzes.length > 0 ? (
//         <>
//           <form onSubmit={handleSubmit}>
//             <h3>RIASEC Test</h3>
//             <div className="wrapper">
//               {currentQuestions.map((question, questionIndex) => (
//                 <div className="question" key={question._id}>
//                   <div className="question-headline">
//                     {`${indexOfFirstQuestion + questionIndex + 1}) ${
//                       question.questionText
//                     }`}
//                   </div>
//                   <div className="question-answers">
//                     {question.options.map((option, optionIndex) => (
//                       <div className="answer" key={optionIndex}>
//                         <input
//                           type="radio"
//                           name={`question-${
//                             indexOfFirstQuestion + questionIndex
//                           }`}
//                           value={option.points}
//                           checked={
//                             selectedOptions[
//                               indexOfFirstQuestion + questionIndex
//                             ]?.points === option.points
//                           }
//                           onChange={() =>
//                             handleOptionChange(
//                               indexOfFirstQuestion + questionIndex,
//                               question.category,
//                               option.points
//                             )
//                           }
//                         />
//                         <label>{`${
//                           optionIndex === 0
//                             ? "😡 "
//                             : optionIndex === 1
//                             ? "😠"
//                             : optionIndex === 2
//                             ? "😑"
//                             : optionIndex === 3
//                             ? "🙂"
//                             : "😁"
//                         } ${option.text}`}</label>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               ))}

//               {/* Display Submit Button Only on the Last Page */}
//               {currentPage === Math.ceil(quizzes.length / questionsPerPage) && (
//                 <ButtonField
//                   type="submit"
//                   className="btn-check"
//                   disabled={!areAllQuestionsAnswered} // Now checking all questions
//                   name={"Submit Quiz"}
//                 />
//               )}
//             </div>

//             {/* Pagination Component */}
//             <Box mt={3} display="flex" justifyContent="center">
//               <Pagination
//                 count={Math.ceil(quizzes.length / questionsPerPage)} // Total number of pages
//                 page={currentPage} // Current active page
//                 onChange={handlePageChange} // Function to handle page changes
//                 color="primary"
//               />
//             </Box>
//           </form>
//         </>
//       ) : (
//         <div>No Quizzes Available</div>
//       )}
//     </>
//   );
// };

// export default RIASECTestScreen;

import React, { useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { Box } from "@material-ui/core";
import ButtonField from "../../reusableComponents/ButtonField";
import { useDispatch, useSelector } from "react-redux";
import {
  getRIASECTestQuestions,
  saveRIASECTestResult,
} from "../../redux/actions/RIASECTestAction";
import Spinner from "../../reusableComponents/Spinner";

const RIASECTestScreen = ({ history }) => {
  const { auth, RIASECTestReducer, loading } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [quizzes, setQuizzes] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [categoryResults, setCategoryResults] = useState({});
  const [totalScore, setTotalScore] = useState(0);
  // const [loading, setLoading] = useState(true);
  const questionsPerPage = 15;

  // Function to shuffle questions
  const shuffleQuestions = (questions) => {
    for (let i = questions.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [questions[i], questions[j]] = [questions[j], questions[i]];
    }
    return questions;
  };

  // Handle option change
  const handleOptionChange = (questionIndex, category, optionPoints) => {
    setSelectedOptions({
      ...selectedOptions,
      [questionIndex]: { points: optionPoints, category },
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const categoryScores = {};
    let calculatedTotalScore = 0;

    Object.values(selectedOptions).forEach(({ points, category }) => {
      if (!categoryScores[category]) {
        categoryScores[category] = 0;
      }
      categoryScores[category] += points;
      calculatedTotalScore += points;
    });

    setCategoryResults(categoryScores);
    setTotalScore(calculatedTotalScore);

    const resultObj = {
      userId: auth.user._id,
      totalScore: calculatedTotalScore,
      categoryScores,
    };

    dispatch(saveRIASECTestResult(resultObj));
  };

  // Get current quiz and slice questions for pagination
  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = quizzes.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  useEffect(() => {
    const fetchQuizzes = async () => {
      await dispatch(getRIASECTestQuestions());
    };
    if (auth?.isAuthenticated) {
      fetchQuizzes();
    }
  }, [auth?.isAuthenticated, dispatch]);

  useEffect(() => {
    if (RIASECTestReducer?.riasecTestQuestionsList) {
      const shuffledQuestions = shuffleQuestions([
        ...RIASECTestReducer.riasecTestQuestionsList,
      ]);
      setQuizzes(shuffledQuestions);
    }
  }, [RIASECTestReducer?.riasecTestQuestionsList]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Move to the next page when the "Next" button is clicked
  const handleNextPage = () => {
    if (currentPage < Math.ceil(quizzes.length / questionsPerPage)) {
      setCurrentPage(currentPage + 1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const areAllQuestionsAnswered = quizzes.every((_, questionIndex) =>
    selectedOptions.hasOwnProperty(questionIndex)
  );

  const areAllQuestionsAnsweredOnPage = currentQuestions.every(
    (_, questionIndex) =>
      selectedOptions.hasOwnProperty(indexOfFirstQuestion + questionIndex)
  );

  return (
    <>
      {quizzes && quizzes.length > 0 ? (
        <>
          <form onSubmit={handleSubmit}>
            <h3>RIASEC Test</h3>
            <div className="wrapper">
              {currentQuestions.map((question, questionIndex) => (
                <div className="question" key={question._id}>
                  <div className="question-headline">
                    {`${indexOfFirstQuestion + questionIndex + 1}) ${
                      question.questionText
                    }`}
                  </div>
                  <div className="question-answers">
                    {question.options.map((option, optionIndex) => {
                      const inputId = `question-${
                        indexOfFirstQuestion + questionIndex
                      }-option-${optionIndex}`;
                      return (
                        <div className="answer" key={optionIndex}>
                          <input
                            type="radio"
                            id={inputId} // Set unique ID
                            style={{ cursor: "pointer" }}
                            name={`question-${
                              indexOfFirstQuestion + questionIndex
                            }`}
                            value={option.points}
                            checked={
                              selectedOptions[
                                indexOfFirstQuestion + questionIndex
                              ]?.points === option.points
                            }
                            onChange={() =>
                              handleOptionChange(
                                indexOfFirstQuestion + questionIndex,
                                question.category,
                                option.points
                              )
                            }
                          />
                          <label htmlFor={inputId}>
                            {`${
                              optionIndex === 0
                                ? "😨"
                                : optionIndex === 1
                                ? "😒"
                                : optionIndex === 2
                                ? "😑"
                                : optionIndex === 3
                                ? "🙂"
                                : "😁"
                            } ${option.text}`}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}

              {/* {currentPage === Math.ceil(quizzes.length / questionsPerPage) && (
                <ButtonField
                  type="submit"
                  className="btn-check"
                  disabled={!areAllQuestionsAnswered}
                  name={"Submit Quiz"}
                />
              )} */}

              {/* Display "Next" button until the last page, then show submit button */}
              {currentPage === Math.ceil(quizzes.length / questionsPerPage) ? (
                <Box mt={2} display="flex" justifyContent="center">
                  <ButtonField
                    type="submit"
                    className="btn-check"
                    // disabled={!areAllQuestionsAnswered}
                    name="Submit"
                    disabled={
                      !(
                        areAllQuestionsAnsweredOnPage && areAllQuestionsAnswered
                      )
                    }
                  />
                </Box>
              ) : (
                <Box mt={2} display="flex" justifyContent="right">
                  <ButtonField
                    type="button"
                    onClick={handleNextPage}
                    className="btn-next"
                    name="Next"
                    disabled={!areAllQuestionsAnsweredOnPage}
                  />
                </Box>
              )}
            </div>

            <Box mt={3} display="flex" justifyContent="center">
              <Pagination
                count={Math.ceil(quizzes.length / questionsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                disabled={!areAllQuestionsAnsweredOnPage}
              />
            </Box>
          </form>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default RIASECTestScreen;

import axios from "axios";
import { SCHEDULE_LOADING } from "./types";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import history from "../../reusableComponents/history";

// Get Recommended Job Lists
export const checkOdooAccess = (data) => (dispatch) => {
  dispatch(scheduleLoading());
  axios
    .post(`/api/odoo/checkOdooAccess`)
    .then((res) => {
      const { message } = res.data;
      SnackBar.success(message);
      setTimeout(() => {
        window.open(`http://35.165.94.197:8069/web/login`, "_blank");
      }, 3000);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Schedule Loading
export const scheduleLoading = () => {
  return {
    type: SCHEDULE_LOADING,
  };
};

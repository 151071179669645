// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getRIASECTestResultByUserId } from "../../redux/actions/RIASECTestAction";
// import {
//   Grid,
//   Container,
//   Typography,
//   Box,
//   CssBaseline,
// } from "@material-ui/core";
// import { Bar } from "react-chartjs-2";

// const RIASECResultgraph = ({ userID }) => {
//   const { auth, RIASECTestReducer } = useSelector((state) => state);
//   const dispatch = useDispatch();
//   const [loading, setLoading] = useState(true);
//   const [RIASECTestResult, setRIASECTestResult] = useState(null);
//   const { pathname } = window.location;
//   // console.log("pathname", pathname);

//   // Define different colors for each category
//   const categoryColors = {
//     Artistic: "rgba(255, 99, 132, 0.6)", // Red
//     Social: "rgba(54, 162, 235, 0.6)", // Blue
//     Investigative: "rgba(255, 206, 86, 0.6)", // Yellow
//     Realistic: "rgba(75, 192, 192, 0.6)", // Green
//     Enterprising: "rgba(153, 102, 255, 0.6)", // Purple
//     Conventional: "rgba(255, 159, 64, 0.6)", // Orange
//   };

//   useEffect(() => {
//     const fetchResult = async () => {
//       setLoading(true);
//       await dispatch(getRIASECTestResultByUserId(userID));
//     };
//     if (auth?.isAuthenticated) {
//       fetchResult();
//     }
//   }, [auth?.isAuthenticated, dispatch]);

//   useEffect(() => {
//     if (RIASECTestReducer?.riasecResultByUserId?.[0]) {
//       setRIASECTestResult(RIASECTestReducer.riasecResultByUserId[0]);
//       setLoading(false);
//     }
//   }, [RIASECTestReducer?.riasecResultByUserId]);

//   if (loading) return <div>Loading...</div>;

//   // Extract category names and scores from the result
//   const categories = Object.keys(RIASECTestResult?.categoryScores || {});
//   const scores = Object.values(RIASECTestResult?.categoryScores || {});

//   // Get colors dynamically based on category names
//   const colors = categories.map(
//     (category) => categoryColors[category] || "rgba(0, 0, 0, 0.6)"
//   ); // Default to black if no color is defined

//   const data = {
//     labels: categories,
//     datasets: [
//       {
//         label: "",
//         data: scores,
//         backgroundColor: colors, // Use the dynamically mapped colors
//         borderColor: colors.map((color) => color.replace("0.6", "1")), // Set border color to fully opaque version of the background color
//         borderWidth: 1,
//       },
//     ],
//   };

//   const options = {
//     scales: {
//       y: {
//         beginAtZero: true, // Start Y-axis at 0
//         max: 50, // Set the upper limit to 60
//         ticks: {
//           stepSize: 5, // Optional: Define step size between ticks (10 in this case)
//         },
//       },
//     },
//   };

//   return (
//     <div style={{ marginTop: "60px" }}>
//       <h3>Your RIASEC Test Results</h3>
//       <Grid container>
//         <Grid item xs={12} md={6}>
//           <Bar data={data} options={options} />
//         </Grid>
//         <Grid item xs={12} md={6}></Grid>
//         <Grid item xs={12} md={12}>
//           <ul style={{ fontSize: "18px", color: "#0a71b9", padding: "20px" }}>
//             <li>
//               <Typography style={{ padding: "7px" }}>
//                 <span style={{ fontWeight: "bold" }}>Realistic Attributes</span>{" "}
//                 – Realistic people are aggressive, prefer concrete versus
//                 abstract tasks, and are less sociable and less skilled at
//                 interpersonal interactions. They have athletic or mechanical
//                 ability and often prefer to work outdoors with objects,
//                 machines, tools, plants, or animals.
//               </Typography>
//               <Typography style={{ padding: "7px" }}>
//                 Health Assistant, Computers, Construction, Mechanic/Machinist,
//                 Engineering, Food and Hospitality
//               </Typography>
//             </li>

//             <li>
//               <Typography style={{ padding: "7px" }}>
//                 <span style={{ fontWeight: "bold" }}>
//                   Investigative Attributes
//                 </span>
//                 – Investigative people are intellectual, abstract, analytical,
//                 independent, and task oriented. They like to observe, learn,
//                 investigate, analyze, evaluate, and solve problems.
//               </Typography>
//             </li>

//             <li>
//               <Typography style={{ padding: "7px" }}>
//                 <span style={{ fontWeight: "bold" }}>Artistic Attributes</span>–
//                 Artistic people are creative, independent, expressive,
//                 imaginative, original, and intuitive. They like to compose,
//                 write, appreciate the arts, create, imagine, and generate new
//                 ideas.
//               </Typography>
//             </li>

//             <li>
//               <Typography style={{ padding: "7px" }}>
//                 <span style={{ fontWeight: "bold" }}>Social Attributes</span> –
//                 Social people are community-service oriented, interested in
//                 educational activities, prefer social interaction, and are
//                 concerned with social and religious issues. They like to inform,
//                 enlighten, train, develop, and cure others. They may also be
//                 skilled with words.
//               </Typography>
//             </li>
//             <li>
//               <Typography style={{ padding: "7px" }}>
//                 <span style={{ fontWeight: "bold" }}>
//                   Enterprising Attributes
//                 </span>
//                 – Enterprising people like to work with people to influence,
//                 persuade, and lead them and to achieve organizational or
//                 financial goals. They tend to be assertive, ambitious, and
//                 energetic.
//               </Typography>
//             </li>
//             <li>
//               <Typography style={{ padding: "7px" }}>
//                 <span style={{ fontWeight: "bold" }}>
//                   Conventional Attributes
//                 </span>{" "}
//                 – Conventional people are practical, well controlled, sociable,
//                 and conservative, prefer structured tasks, and prefer
//                 conformity. They like to work with data, have clerical or
//                 numerical ability, and are very attentive to detail.
//               </Typography>
//             </li>
//           </ul>
//           {pathname.startsWith("/admin/mentorLearnerInfo/") ? null : (
//             <Typography
//               style={{
//                 fontSize: "22px",
//                 color: "#0a71b9",
//                 padding: "7px",
//               }}
//             >
//               TO UNDERSTAND YOUR SCORE BETTER AND CHOOSE YOUR CAREER PATH, BOOK
//               FOR A COUNSELLING
//             </Typography>
//           )}
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// export default RIASECResultgraph;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRIASECTestResultByUserId } from "../../redux/actions/RIASECTestAction";
import { Grid, Typography, Box } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartDataLabels);

const RIASECResultgraph = ({ userID }) => {
  const { auth, RIASECTestReducer } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [RIASECTestResult, setRIASECTestResult] = useState(null);
  const { pathname } = window.location;

  // Preferred category order
  const preferredOrder = [
    "Realistic",
    "Investigative",
    "Artistic",
    "Social",
    "Enterprising",
    "Conventional",
  ];

  // Define different colors for each category
  const categoryColors = {
    Realistic: "rgba(75, 192, 192, 0.6)", // Green
    Investigative: "rgba(255, 206, 86, 0.6)", // Yellow
    Artistic: "rgba(255, 99, 132, 0.6)", // Red
    Social: "rgba(54, 162, 235, 0.6)", // Blue
    Enterprising: "rgba(153, 102, 255, 0.6)", // Purple
    Conventional: "rgba(255, 159, 64, 0.6)", // Orange
  };

  useEffect(() => {
    const fetchResult = async () => {
      setLoading(true);
      await dispatch(getRIASECTestResultByUserId(userID));
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    if (auth?.isAuthenticated) {
      fetchResult();
    }
  }, [auth?.isAuthenticated, dispatch]);

  useEffect(() => {
    if (RIASECTestReducer?.riasecResultByUserId?.[0]) {
      setRIASECTestResult(RIASECTestReducer.riasecResultByUserId[0]);
      setLoading(false);
    }
  }, [RIASECTestReducer?.riasecResultByUserId]);

  if (loading) return <div>Loading...</div>;

  // Sort categories and scores based on the preferred order
  const sortedCategories = preferredOrder.filter((category) =>
    RIASECTestResult?.categoryScores.hasOwnProperty(category)
  );
  const sortedScores = sortedCategories.map(
    (category) => RIASECTestResult.categoryScores[category]
  );
  const colors = sortedCategories.map((category) => categoryColors[category]);

  const data = {
    labels: sortedCategories,
    datasets: [
      {
        label: "KYC Scores",
        data: sortedScores,
        backgroundColor: colors,
        borderColor: colors.map((color) => color.replace("0.6", "1")),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: 50,
        ticks: {
          stepSize: 5,
        },
      },
      x: {
        ticks: {
          font: {
            weight: "bold", // Make x-axis labels bold
          },
        },
      },
    },
    plugins: {
      datalabels: {
        display: true, // Show labels on each bar
        color: "#000", // Label color
        anchor: "end", // Position label at the end of the bar
        align: "top", // Align label at the top
        formatter: (value) => value, // Format to show the value directly
        font: {
          // weight: "bold", // Make the label bold
          size: 12, // Font size
        },
      },
    },
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Typography align="center" style={{ color: "#0a71b9", fontSize: "24px" }}>
        KYC Assessment Results
      </Typography>
      <Grid container style={{ marginTop: "20px" }}>
        <Grid item xs={12} md={7}>
          <Bar data={data} options={options} />
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={12}>
          <ul style={{ fontSize: "18px", color: "#0a71b9", padding: "20px" }}>
            <li>
              <Typography style={{ padding: "7px" }}>
                <span style={{ fontWeight: "bold" }}>Realistic Attributes</span>{" "}
                – Realistic people are aggressive, prefer concrete versus
                abstract tasks, and detail oriented. They are systematic
                planners who like to turn ideas into actions.
              </Typography>
              {/* <Typography style={{ padding: "7px" }}>
                Health Assistant, Computers, Construction, Mechanic/Machinist,
                Engineering, Food and Hospitality
              </Typography> */}
            </li>
            <li>
              <Typography style={{ padding: "7px" }}>
                <span style={{ fontWeight: "bold" }}>
                  Investigative Attributes
                </span>{" "}
                – Investigative people are intellectual, abstract, analytical,
                independent, and always try to find solutions. They like to
                observe, learn, investigate, analyze, evaluate, and solve
                problems.
              </Typography>
              {/* <Typography style={{ padding: "7px" }}>
                Marine Biology, Engineering, Chemistry, Zoology,
                Medicine/Surgery, Consumer Economics, Psychology
              </Typography> */}
            </li>
            <li>
              <Typography style={{ padding: "7px" }}>
                <span style={{ fontWeight: "bold" }}>Artistic Attributes</span>{" "}
                – Artistic people are creative, independent, expressive,
                imaginative, original, and intuitive. They like to write,
                create, imagine, generate new ideas and are appreciative.
              </Typography>
              {/* <Typography style={{ padding: "7px" }}>
                Communications, Cosmetology, Fine and Performing Arts,
                Photography, Radio and TV, Interior Design, Architecture, Public
                and Human Services, Arts and Communication
              </Typography> */}
            </li>
            <li>
              <Typography style={{ padding: "7px" }}>
                <span style={{ fontWeight: "bold" }}>Social Attributes</span> –{" "}
                Social people are service oriented, interested in educational
                activities, prefer social interaction. They like to inform,
                enlighten, train & develop others. They may also be skilled with
                words.
              </Typography>
              {/* <Typography style={{ padding: "7px" }}>
                Counseling, Nursing, Physical Therapy, Travel, Advertising,
                Public Relations, Education,
              </Typography> */}
            </li>
            <li>
              <Typography style={{ padding: "7px" }}>
                <span style={{ fontWeight: "bold" }}>
                  Enterprising Attributes
                </span>{" "}
                – Enterprising people like to work with people to influence,
                persuade, and lead them and to achieve organizational or
                financial goals. They tend to be assertive, ambitious, and
                energetic.
              </Typography>
              {/* <Typography style={{ padding: "7px" }}>
                Fashion Merchandising, Real Estate, Marketing/Sales, Law,
                Political Science, International Trade, Banking/Finance
              </Typography> */}
            </li>
            <li>
              <Typography style={{ padding: "7px" }}>
                <span style={{ fontWeight: "bold" }}>
                  Conventional Attributes
                </span>{" "}
                – Conventional people are practical, well controlled, sociable,
                systematic individuals who prefer structured tasks, and are
                disciplined. They like to work with data, and are very attentive
                to detail.
              </Typography>
              {/* <Typography style={{ padding: "7px" }}>
                Accounting, Court Reporting, Insurance, Administration, Medical
                Records, Banking, Data Processing
              </Typography> */}
            </li>
          </ul>
          {pathname.startsWith("/admin/mentorLearnerInfo/") ? null : (
            <Typography
              style={{
                fontSize: "22px",
                color: "#0a71b9",
                padding: "7px",
                fontWeight: "bold",
              }}
            >
              TO UNDERSTAND YOUR SCORE BETTER AND CHOOSE YOUR CAREER PATH, BOOK
              FOR A COUNSELLING
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default RIASECResultgraph;

// import React, { useEffect } from 'react';
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
// import { Container, Grid, Typography } from '@material-ui/core';
// import ib1 from '../../images/ibridge/ib1.jpg';
// import ib2 from '../../images/ibridge/ib2.jpg';
// import ib3 from '../../images/ibridge/ib3.jpg';
// import ib4 from '../../images/ibridge/ib4.jpg';
// import ib5 from '../../images/ibridge/ib5.jpg';
// import howwedoit from '../../images/ibridge/howwedoit.jpeg';
// import Why from '../../images/ibridge/why.jpeg';
// import Do from '../../images/ibridge/whatwedo.jpg';
// import Happening from '../../images/ibridge/Whatshappening.jpg';

// const useStyles = makeStyles((theme)=>({
//     item1: {
//         [theme.breakpoints.down('sm')]:{
//             order:2
//         }
//     },
//     item2: {
//         [theme.breakpoints.down('sm')]: {
//             order:1
//         }
//     }
// }));

// const Welcome = ({
//     auth,
//     history
// }) => {

//     const classes = useStyles();
//     const { isAuthenticated, user } = auth;

//     useEffect(() => {
//         if(isAuthenticated) {
//             if(user?.role?.r_id === 1) {
//                 history.push('/admin/dashboard');
//             } else if(user?.role?.r_id === 2) {
//                 history.push('/dashboard');
//             }
//         }
//     }, [auth])

//     return (
//         <Container maxWidth="lg" className="welcomeContainer">
//             <Grid container>
//                 <Grid item xs={12} className="welcomBrandContainer">
//                     <div className="welcomeHeading">
//                         <Typography className="headingText" varient="h5">
//                             iBridge360
//                         </Typography>
//                         <Typography className="headingTagLine" varient="h5">
//                             Making Every Graduate Employable
//                         </Typography>
//                     </div>
//                 </Grid>
//                 <Grid item xs={12} className="welcomeContentContainer">
//                     <Grid container>
//                         <Grid item xs={12} md={6}>
//                             <div className="welcomeContentTextContainer">
//                                 <div className="welcomeContentInnerTextContainer">
//                                     <Typography className="headingText" variant="h5">
//                                         What’s Happening?
//                                     </Typography>
//                                     <Typography className="headingTagLine" variant="h3">
//                                         Out of the 34 million Indians currently enrolled in colleges,1 out of 4 struggles to find employment after they graduate. Many young Indians with large aspirations and college degrees will not get jobs or have the opportunity to make an impact
//                                     </Typography>
//                                 </div>
//                             </div>
//                         </Grid>
//                         <Grid item xs={12} md={6}>
//                             <img className="welcomeImages" src={Happening}/>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//                 <Grid item xs={12} className="welcomeContentContainer">
//                     <Grid container>
//                         <Grid item xs={12} md={6} className={classes.item1}>
//                             <img className="welcomeImages" src={Why}/>
//                         </Grid>
//                         <Grid item xs={12} md={6} className={classes.item2}>
//                             <div className="welcomeContentTextContainer">
//                                 <div className="welcomeContentInnerTextContainer">
//                                     <Typography className="headingText" variant="h5">
//                                         Why?
//                                     </Typography>
//                                     <Typography className="headingTagLine" variant="h3">
//                                         This is because there is a wide gap between what graduates learn in colleges and what employees need to know before joining an organization
//                                     </Typography>
//                                 </div>
//                             </div>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//                 <Grid item xs={12} className="welcomeContentContainer">
//                     <Grid container>
//                         <Grid item xs={12} md={6}>
//                             <div className="welcomeContentTextContainer">
//                                 <div className="welcomeContentInnerTextContainer">
//                                     <Typography className="headingText" variant="h5">
//                                         What We Do
//                                     </Typography>
//                                     <Typography className="headingTagLine" variant="h3">
//                                         We bridge that gap, ensuring that every Indian graduate acquires the specific skills and knowledge that are most valued by employers, and every employer welcomes job-ready graduates who don’t require additional training.
//                                     </Typography>
//                                 </div>
//                             </div>
//                         </Grid>
//                         <Grid item xs={12} md={6}>
//                             <img className="welcomeImages" src={Do}/>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//                 <Grid item xs={12} className="welcomeContentContainer">
//                     <Grid container>
//                         <Grid item xs={12} md={6} className={classes.item1}>
//                             <img className="welcomeImages" src={howwedoit}/>
//                         </Grid>
//                         <Grid item xs={12} md={6}  className={classes.item2}>
//                             <div className="welcomeContentTextContainer">
//                                 <div className="welcomeContentInnerTextContainer">
//                                     <Typography className="headingText" variant="h5">
//                                         How We Do It
//                                     </Typography>
//                                     <Typography className="headingTagLine" variant="h3">
//                                         We offer courses that make Indian graduates employable. We take inventory of the fundamental skill sets that are required by most industries and condense them into efficient and effective bridge programs to support those who seek immediate employment.
//                                     </Typography>
//                                 </div>
//                             </div>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//                 <Grid item xs={12} className="welcomBrandContentContainer">
//                     <div className="welcomeHeading">
//                         <Typography className="headingText" varient="h5">
//                             Who We Serve
//                         </Typography>
//                         <Typography className="headingTagLine" varient="h5">
//                             Solving India's employability Problem
//                         </Typography>
//                     </div>
//                 </Grid>
//                 <Grid item xs={12} className="welcomeContentContainer">
//                     <Grid container>
//                         <Grid item xs={12} md={6}  className={classes.item1}>
//                             <img className="welcomeImages" src={ib1}/>
//                         </Grid>
//                         <Grid item xs={12} md={6}  className={classes.item2}>
//                             <div className="welcomeContentTextContainer">
//                                 <Typography className="headingTagLine" variant="h3">
//                                     You are a student in college / taking a gap year / looking for a job / starting your first job.
//                                 </Typography>
//                             </div>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//                 <Grid item xs={12} className="welcomeContentContainer">
//                     <Grid container>
//                         <Grid item xs={12} md={6}>
//                             <div className="welcomeContentTextContainer">
//                                 <Typography className="headingTagLine" variant="h3">
//                                     You are a thought leader / university professor / mentor / trainer / subject matter specialist.
//                                 </Typography>
//                             </div>
//                         </Grid>
//                         <Grid item xs={12} md={6}>
//                             <img className="welcomeImages" src={ib2}/>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//                 <Grid item xs={12} className="welcomeContentContainer">
//                     <Grid container>
//                         <Grid item xs={12} md={6}  className={classes.item1}>
//                             <img className="welcomeImages" src={ib3}/>
//                         </Grid>
//                         <Grid item xs={12} md={6}  className={classes.item2}>
//                             <div className="welcomeContentTextContainer">
//                                 <Typography className="headingTagLine" variant="h3">
//                                     You are a fresh talent acquirer for a company, challenged with increasing the productivity of entry level staff.
//                                 </Typography>
//                             </div>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//                 <Grid item xs={12} className="welcomeContentContainer">
//                     <Grid container>
//                         <Grid item xs={12} md={6}>
//                             <div className="welcomeContentTextContainer">
//                                 <Typography className="headingTagLine" variant="h3">
//                                     You are a university that want  your students gain some additional practical skills and knowledge for specific industries.
//                                 </Typography>
//                             </div>
//                         </Grid>
//                         <Grid item xs={12} md={6}>
//                             <img className="welcomeImages" src={ib4}/>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//                 <Grid item xs={12} className="welcomeContentContainer">
//                     <Grid container>
//                         <Grid item xs={12} md={6}  className={classes.item1}>
//                             <img className="welcomeImages" src={ib5}/>
//                         </Grid>
//                         <Grid item xs={12} md={6}  className={classes.item2}>
//                             <div className="welcomeContentTextContainer">
//                                 <Typography className="headingTagLine" variant="h3">
//                                     You are an SME , Association or Government, wanting to develop the best entry level talent for your constituents.
//                                 </Typography>
//                             </div>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//             </Grid>
//         </Container>
//     );
// };

// Welcome.propTypes = {
//     auth: PropTypes.object.isRequired
// }

// const mapStateToProps = state => ({
//     auth: state.auth
// });

// export default connect(mapStateToProps, null)(Welcome);

// -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import ScrollAnimation from "react-animate-on-scroll";

import { withRouter, Link } from "react-router-dom";

// import ib1 from '../../images/ibridge/ib1.jpg';
// import ib2 from '../../images/ibridge/ib2.jpg';
// import ib3 from '../../images/ibridge/ib3.jpg';
// import ib4 from '../../images/ibridge/ib4.jpg';
// import ib5 from '../../images/ibridge/ib5.jpg';
// import howwedoit from '../../images/ibridge/howwedoit.jpeg';
// import Why from '../../images/ibridge/why.jpeg';
// import Do from '../../images/ibridge/whatwedo.jpg';
// import Happening from '../../images/ibridge/Whatshappening.jpg';

import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

//------------Carousel reusable component-------------------------
import Carousel from "../../reusableComponents/Carousel";

//---------------------images-----------------------------------------

const useStyles = makeStyles((theme) => ({
  item1: {
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
  item2: {
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
}));

// const bull = <span className={classes.bullet}>•</span>;
const Welcome = ({ auth, history }) => {
  const classes = useStyles();
  const { isAuthenticated, user } = auth;
  const bull = <span className={classes.bullet}>•</span>;

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.role?.r_id === 1) {
        history.push("/admin/dashboard");
      } else if (user?.role?.r_id === 3) {
        history.push("/admin/mentorDashboard");
      } else if (user?.role?.r_id === 2) {
        if (user?.programsEnrolled?.length > 0) {
          history.push("/myPrograms");
        } else {
          history.push("/dashboard");
        }
      }
    }
  }, [auth]);

  const items = [
    {
      name: "About_1",
      image: "https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/s1.png",
      // image:s1,
      title: "Artificial Intelligence ",
      discription:
        "We are at the cusp of a revolution where robots could replace 800 million jobs by 2030, while 60% of today's Grad School kids will end up at jobs that haven't been invented yet!",
    },
    {
      name: "About_2",
      image: "https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/s22.png",
      // image:s22,
      title: "Learning In The Future",
      discription:
        "In an environment of such uncertainty, we are left with questions of how students will use their bodies and minds to learn what will be important in the 21st Century! ",
    },
    {
      name: "About_3",
      image:
        "https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/slider+3+removed+content.png",
      // image:slider3,
      title: "Skills Of The 21st Century",
      discription: "",
      cardTitle1: "Mathematical Reasoning & Computational Thinking",
      cardDiscription1: "Learn To Break Down Complex Problems",
      cardTitle2: "Collaboration & Creative Expression",
      cardDiscription2: "Innovative Solutions",
      cardTitle3: "Scientific Argumentation & Design Thinking",
      cardDiscription3: "Evidence - Based Argumentation",
    },
    // {
    //     name: 'About_4',
    //     image:'https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/About-4+slider.png'
    // }
  ];

  return (
    <Container className="welcomeContainer">
      {/* <Grid container> */}
      {/* <Grid item xs={12} className="welcomBrandContainer">
                     <div className="welcomeHeading">
                         <Typography className="headingText1" varient="h5">
                             iBridge360
                         </Typography>
                         <Typography className="headingTagLine1" varient="h5">
                             Making Every Graduate Employable
                         </Typography>
                     </div>
                </Grid> */}
      {/* <Grid item xs={12} md={12} className="">
          <div className="landingVideoContainer" style={{paddingTop:'10px'}}>
            <video
              height="695px"
              className="welcomeVideoClass"
              src="https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/Videos/Briter.mp4"
              autoPlay="true"
              muted
              loop
            />
          </div>
        </Grid> */}
      {/* </Grid> */}
      <Grid container>
        <Grid item xs={12} md={12}>
          <Carousel items={items} />
        </Grid>
      </Grid>
      {/* ---------------------------------------------------------------------------------------------------------------------------------------- */}

      <Grid container className="contentGrid">
        <Grid item xs={12} md={6}>
          {/* <div className='landingTextContainer'> */}
          <ScrollAnimation
            animateIn="fadeInLeft"
            animateOut="fadeOutLeft"
            delay={0.3}
            duration={1}
          >
            <Typography className="contentHeadingText" varient="h2">
              What's Happening
            </Typography>
            <Typography className="contentTagLine" varient="h3">
              Of the 34 million students graduating out of colleges in India,
              25% struggle to find employment - that is over 7 million qualified
              aspiring graduates not finding suitable jobs and missing out on
              the opportunity to start a career!
            </Typography>
            {/* </div> */}
          </ScrollAnimation>
        </Grid>

        <Grid item xs={12} md={6}>
          <ScrollAnimation
            animateIn="fadeInRight"
            animateOut="fadeOutRight"
            delay={0.3}
            duration={1}
          >
            {/* <div className="landingImageContainer rightImg"> */}

            <img
              src="https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/Work+time-pana.png"
              className="mediaClassImg"
            />

            {/* </div> */}
          </ScrollAnimation>
        </Grid>

        {/* ---------------------------------------------------------------------------------------- */}

        <Grid item xs={12} md={6}>
          <ScrollAnimation
            animateIn="fadeInLeft"
            animateOut="fadeOutLeft"
            delay={0.3}
            duration={1}
          >
            {/* <div className="landingImageContainer"> */}
            {/* <img src={welcomeImage2} className=''/> */}
            <img
              src="https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/Job+hunt-bro.png"
              className="mediaClassImg"
            />
            {/* </div> */}
          </ScrollAnimation>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className="landingTextContainer">
            <ScrollAnimation
              animateIn="fadeInRight"
              animateOut="fadeOutRight"
              delay={0.3}
              duration={1}
            >
              <Typography className="contentHeadingText" varient="h5">
                Why
              </Typography>
              <Typography className="contentTagLine" varient="h5">
                This is because there is a wide gap between what graduates learn
                in college and the basic skills employers expect them to have -
                oftentimes tested in the interview process.
              </Typography>
            </ScrollAnimation>
          </div>
        </Grid>
        {/* ------------------------------------------------------------------------------------------ */}

        <Grid item xs={12} md={6}>
          <div className="landingTextContainer">
            <ScrollAnimation
              animateIn="fadeInLeft"
              animateOut="fadeOutLeft"
              delay={0.3}
              duration={1}
            >
              <Typography className="contentHeadingText" varient="h5">
                What do we do
              </Typography>
              <Typography className="contentTagLine" varient="h5">
                We help bridge the gap, ensuring that every graduate acquires
                the skill and knowledge that Organizations value most. We see to
                it that the graduates are ready for employment and that they
                will be effective in their jobs from day one.
              </Typography>
            </ScrollAnimation>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          {/* <div className="landingImageContainer rightImg"> */}
          {/* <img src={welcomeImage3} className=''/> */}
          <ScrollAnimation
            animateIn="fadeInRight"
            animateOut="fadeOutRight"
            delay={0.3}
            duration={1}
          >
            <img
              src="https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/Resume-amico.png"
              className="mediaClassImg"
            />
            {/* </div> */}
          </ScrollAnimation>
        </Grid>
        {/* ------------------------------------------------------------------------------------------ */}

        <Grid item xs={12} md={6}>
          <ScrollAnimation
            animateIn="fadeInLeft"
            animateOut="fadeOutLeft"
            delay={0.3}
            duration={1}
          >
            {/* <div className="landingImageContainer"> */}
            {/* <img src={welcomeImage4} className=''/> */}
            <img
              src="https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/Group+114.png"
              className="mediaClassImg"
            />
            {/* </div> */}
          </ScrollAnimation>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className="landingTextContainer">
            <ScrollAnimation
              animateIn="fadeInRight"
              animateOut="fadeOutRight"
              delay={0.3}
              duration={1}
            >
              <Typography className="contentHeadingText" varient="h5">
                How do we bridge the gap
              </Typography>
              <Typography className="contentTagLine" varient="h5">
                We study the specific skills required by the Industries, and
                design industry-specific boot camp programs. We also study the
                basic knowledge gained by the graduates in their college
                curriculum to assess their skills gap, and customize bridge
                programs to upskill the candidates to fit the needs of the
                employer.
              </Typography>
            </ScrollAnimation>
          </div>
        </Grid>
      </Grid>

      {/* ----------------------------------------------------------------------------------------------------------------------------------- */}
      <Grid container>
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeOut"
          delay={0.3}
          duration={1}
        >
          <Grid
            item
            xs={12}
            md={12}
            className="welcomBrandContainer"
            style={{ marginTop: "50px" }}
          >
            <div className="welcomeHeading">
              <Typography className="headingText" varient="h5">
                Who do we Serve
              </Typography>
              <Typography className="headingTagLine" varient="h5">
                OUR OBJECTIVE IS TO ENSURE THAT EVERY GRADUATE FIND A JOB, AND
                EVERY EMPLOYER FINDS A JOB-READY CANDIDATE!
              </Typography>
            </div>
          </Grid>
        </ScrollAnimation>
      </Grid>
      {/*------------------------------------------------------------------------------------------------------------------------------- */}
      <Grid container className="contentGrid">
        <Grid item xs={12} md={6}>
          <ScrollAnimation
            animateIn="fadeInLeft"
            animateOut="fadeOutLeft"
            delay={0.3}
            duration={1}
          >
            {/* <div className="landingImageContainer"> */}
            {/* <img src={welcomeImage5} className=''/> */}
            <img
              src="https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/Nerd-bro.png"
              className="mediaClassImg"
            />
            {/* </div> */}
          </ScrollAnimation>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className="landingTextContainer">
            <ScrollAnimation
              animateIn="fadeInRight"
              animateOut="fadeOutRight"
              delay={0.3}
              duration={1}
            >
              <Typography className="contentTagLine1" varient="h5">
                You are a student in college, or you just graduated out of
                college.
              </Typography>
            </ScrollAnimation>
          </div>
        </Grid>
        {/* ------------------------------------------------------------------------------------------ */}

        <Grid item xs={12} md={6}>
          <div className="landingTextContainer">
            <ScrollAnimation
              animateIn="fadeInLeft"
              animateOut="fadeOutLeft"
              delay={0.3}
              duration={1}
            >
              <Typography className="contentTagLine1" varient="h5">
                You are proficient in teaching, and can coach, mentor or train
                people.
              </Typography>
            </ScrollAnimation>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <ScrollAnimation
            animateIn="fadeInRight"
            animateOut="fadeOutRight"
            delay={0.3}
            duration={1}
          >
            {/* <div className="landingImageContainer rightImg"> */}
            {/* <img src={welcomeImage6} className=''/> */}
            <img
              src="https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/Webinar-pana.png"
              className="mediaClassImg"
            />
            {/* </div> */}
          </ScrollAnimation>
        </Grid>
        {/* ------------------------------------------------------------------------------------------ */}

        <Grid item xs={12} md={6}>
          <ScrollAnimation
            animateIn="fadeInLeft"
            animateOut="fadeOutLeft"
            delay={0.3}
            duration={1}
          >
            {/* <div className="landingImageContainer"> */}
            {/* <img src={welcomeImage7} className=''/> */}
            <img
              src="https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/Collaboration-rafiki.png"
              className="mediaClassImg"
            />
            {/* </div> */}
          </ScrollAnimation>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className="landingTextContainer">
            <ScrollAnimation
              animateIn="fadeInRight"
              animateOut="fadeOutRight"
              delay={0.3}
              duration={1}
            >
              <Typography className="contentTagLine1" varient="h5">
                You are responsible for building your company's capability with
                fresh talent in record ramp up time.
              </Typography>
            </ScrollAnimation>
          </div>
        </Grid>

        {/* ------------------------------------------------------------------------------------------ */}

        <Grid item xs={12} md={6}>
          <div className="landingTextContainer">
            <ScrollAnimation
              animateIn="fadeInLeft"
              animateOut="fadeOutLeft"
              delay={0.3}
              duration={1}
            >
              <Typography className="contentTagLine1" varient="h5">
                you are an educational institution helping your students to find
                better placements as they graduate.
              </Typography>
            </ScrollAnimation>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <ScrollAnimation
            animateIn="fadeInRight"
            animateOut="fadeOutRight"
            delay={0.3}
            duration={1}
          >
            {/* <div className="landingImageContainer rightImg"> */}
            {/* <img src={welcomeImage8} className=''/> */}
            <img
              src="https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/Marriage+counseling-bro.png"
              className="mediaClassImg"
            />
            {/* </div> */}
          </ScrollAnimation>
        </Grid>
        {/* ------------------------------------------------------------------------------------------ */}

        <Grid item xs={12} md={6}>
          {/* <div className="landingImageContainer"> */}
          {/* <img src={welcomeImage9} className=''/> */}
          <ScrollAnimation
            animateIn="fadeInLeft"
            animateOut="fadeOutLeft"
            delay={0.3}
            duration={1}
          >
            <img
              src="https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/Company-amico.png"
              className="mediaClassImg"
            />
            {/* </div> */}
          </ScrollAnimation>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className="landingTextContainer">
            <ScrollAnimation
              animateIn="fadeInRight"
              animateOut="fadeOutRight"
              delay={0.3}
              duration={1}
            >
              <Typography className="contentTagLine1" varient="h5">
                You are a small or medium level enterprise or a government
                organization who needs to work with partners to custom train
                entry level staff.
              </Typography>
            </ScrollAnimation>
          </div>
        </Grid>
        {/* ------------------------------------------------------------------------------------------------------------------------------------ */}
      </Grid>

      {/* -------------------------------Terms and Conditions-----------------------------------------------*/}

      <Grid
        container
        className="tremsConditions"
        style={{ paddingLeft: "10%", paddingRight: "10%" }}
      >
        <Grid item xs={12} md={2} align="left">
          <Typography>Follow Us !</Typography>

          <Grid container className="tremsConditionsSubContent">
            <Grid item xs={12} md={6} align="left">
              <a
                href="https://www.facebook.com/iBridge360"
                style={{ color: "white" }}
              >
                <FacebookIcon fontSize="large" />
              </a>
              <a
                href="https://instagram.com/ibridge360?utm_medium=copy_link"
                style={{ color: "white" }}
              >
                <InstagramIcon fontSize="large" />
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} align="left">
          <Typography>Company</Typography>

          <Grid container className="tremsConditionsSubContent">
            <Grid item xs={12} md={12}>
              <Typography>
                <Link
                  to="/about-us"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  About Us{" "}
                </Link>
              </Typography>
              <Typography>
                <Link
                  to="/programs"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Programs{" "}
                </Link>
              </Typography>
              <Typography>
                <Link
                  to="/explore"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Explore{" "}
                </Link>
              </Typography>
              <Typography>
                <Link
                  to="/sign-in"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  Sign in{" "}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} align="left">
          <Typography>Work with us</Typography>

          <Grid container className="tremsConditionsSubContent">
            <Grid item xs={12} md={12} align="left">
              <Typography>Become a Mentor</Typography>
              <Typography>Become a Service Provider</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} align="left">
          <Typography>Contact Us</Typography>

          <Grid container className="tremsConditionsSubContent">
            <Grid item xs={12} md={12} align="left">
              <Typography>iBridge.360</Typography>
              <Typography>Bangalore 560041</Typography>
              <Typography>support@ibridge360.com</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container align="center">
          <Grid item xs={12} md={12}>
            <Typography>
              <Link className="tremsConditionsLink" to="/termsConditions">
                Terms and Conditions {bull} Privacy Policy {bull} Refund Policy
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

Welcome.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Welcome);

import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar } from "@material-ui/core";
// import Table from '../../MaterialTable/Table';
import MUITable from "../../MaterialTable/MaterialTable";
import PageHeader from "../../../reusableComponents/PageHeader";
import { ListAlt as ListAltIcon } from "@material-ui/icons";
import { getCoachList, clearCoachInfo } from "../../../redux/actions/admin_view/coachList";
import { getDateTime } from "../../../reusableComponents/GetDate";
import { Search as SearchIcon, Add as AddIcon } from "@material-ui/icons";
import ButtonField from "../../../reusableComponents/ButtonField";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

const MentorList = ({ getCoachList, clearCoachInfo, auth, adminCoach, history }) => {
  const classes = useStyles();
  const { isAuthenticated } = auth;
  const { coachList } = adminCoach;

  useEffect(() => {
    if (isAuthenticated) {
      getCoachList();
      clearCoachInfo();
    }
  }, [isAuthenticated]);

  const columns = [
    {
      title: "Photo",
      field: "userImage",
      render: (rowData) => (
        <Avatar
          alt=""
          src={rowData?.userImage}
          className={classes.tableImage}
        />
      ),
    },
    {
      title: "Coach Name",
      field: "name",
    },
    {
      title: "Email ID",
      field: "email",
    },
    {
      title: "Expert In",
      field: "expertIn",
    },
    {
      title: "Industry",
      field: "industry",
      render: (rowData) => (
        <div>
          <ul className={classes.industryList}>
            {rowData?.industry?.map((list) => (
              <li key={list._id}>{list.name}</li>
            ))}
          </ul>
        </div>
      ),
    },
    {
      title: "Generated On",
      field: "createdAt",
    },
  ];

  const data = coachList?.map((list) => {
    const {
      _id,
      firstName,
      lastName,
      email,
      industry,
      expertIn,
      userImage,
      createdAt,
    } = list;
    return {
      _id,
      userImage: userImage?.link,
      name: `${firstName} ${lastName}`,
      email,
      industry,
      expertIn,
      createdAt: getDateTime(createdAt),
    };
  });
  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MUITable
            title={
              <ButtonField
                variant="outlined"
                name="Add New Coach"
                icon={<AddIcon />}
                onClick={() => history.push("addNewcoach")}
              />
            }
            columns={columns}
            data={data}
            pageSize={10}
            selectedRow={(row) => history.push(`/admin/coach/${row._id}`)}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

MentorList.propTypes = {
  auth: PropTypes.object.isRequired,
  getCoachList: PropTypes.func.isRequired,
  clearCoachInfo: PropTypes.func.isRequired,
  adminCoach: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  adminCoach: state.adminCoach,
});

const mapDispatchToProps = { getCoachList, clearCoachInfo };

export default connect(mapStateToProps, mapDispatchToProps)(MentorList);

import axios from "axios";
import { GET_CHATGPT_JOB_TYPES, SCHEDULE_LOADING, SET_USER_SKILLS_LOCATION, CLEAR_JOB_TYPES_DATA } from "./types";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import history from "../../reusableComponents/history";

// Get Job description List
export const getJobTypes = (data) => (dispatch) => {
  dispatch(scheduleLoading());
  dispatch({type:SET_USER_SKILLS_LOCATION, payload:data});
  axios
    .post(`/api/openAI/getJobTypes`, data)
    .then((res) =>
      dispatch({
        type: GET_CHATGPT_JOB_TYPES,
        payload: res?.data?.messages,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Schedule Loading
export const scheduleLoading = () => {
  return {
    type: SCHEDULE_LOADING,
  };
};

export const clearOpenAIJobTypesData = () => {
  return {
    type: CLEAR_JOB_TYPES_DATA
  }
}
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  CssBaseline,
  useScrollTrigger,
  Slide,
  Menu,
  MenuItem,
  Button,
  IconButton,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  ExitToApp as ExitToAppIcon,
  AccountCircle,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  MoreVert as MoreIcon,
} from "@material-ui/icons";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { logoutUser } from "./redux/actions/authActions";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "./images/logo/ibridge.png";
import Logo1 from "./images/logo/ibi.png";
import clsx from "clsx";
import { USER_TOKEN } from "./redux/actions/types";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";

import CustomizedDialogs from "./reusableComponents/DialogModal";
import EditLearnerProfile from "./components/Learner_Module/EditLearnerProfile";
import LearnerResetPassword from "./components/Learner_Module/LearnerResetPassword";
import SearchComponent from "./reusableComponents/SearchComponent";

// import { Menu as MenuIcon } from "@material-ui/icons";

// --------------------------------------Component-----------------------------------------

import Footer from "./components/Header_Footer/Footer";
import Header from "./components/Header_Footer/Header";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    marginLeft: theme.spacing(2),
  },
  menuCollapseContaier: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    textDecoration: "none",
    color: "#666666",
    cursor: "pointer",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    "&:hover": {
      color: "#003399",
    },
  },
  titleActive: {
    color: "#003399",
  },
  mobileTitle: {
    color: "#666666",
    textDecoration: "none",
    fontSize: 16,
    fontWeight: 600,
  },
  profileIconButton: {
    marginLeft: "10px",
    background: "#F8F9F9",
    border: "1px solid #005a86",
    borderRadius: 25,
    color: "#005a86",
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: 16,
    "&:hover": {
      border: "1px solid #006b9f",
      background: "#005a86",
      color: "#F8F9F9",
    },
  },
  profileIcon: {
    marginRight: theme.spacing(1),
  },
  userDetails: {
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
    color: "#2B2B2B",
    cursor: "pointer",
    fontWeight: 600,
  },
  profileDrop: {
    marginTop: "45px",
    "& .MuiList-root": {
      // width: 130
      // background: '#233044',
    },
    "& .MuiButtonBase-root": {
      // color: '#F8F9F9',
      // '&:hover': {
      //   background: '#1e293a'
      // }
    },
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
    },
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

const Layout = (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [comps, setComps] = useState(<EditLearnerProfile />);

  // const location = useLocation();
  // // console.log("location",location)

  const { isAuthenticated, user } = props?.auth;

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);

  const [mobileMenuIcon, setMobileMenuIcon] = useState("closed");
  // const [activeTitle, setActiveTitle] = useState(user?.programsEnrolled?.length > 0 ? "MyPrograms" :"mvk");
  const [activeTitle, setActiveTitle] = useState("");
  // user?.programsEnrolled?.length > 0 ? "MyPrograms" : "mvk"

  const { pathname } = props.location;

  useEffect(() => {
    if (isAuthenticated) {
      // props?.location?.pathname === "/myPrograms"
      //   ? setActiveTitle("MyPrograms")
      //   : props?.location?.pathname === "/dashboard"
      //   ? setActiveTitle("mvk")
      //   : setActiveTitle("Recommended");

      setActiveTitle(user?.programsEnrolled?.length > 0 ? "MyPrograms" : "mvk");
    }
  }, [isAuthenticated]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    props.history.push("/sign-in");
  };

  const handleMobileMenu = (event) => {
    console.log(event.currentTarget);
    setMobileAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileAnchorEl(null);
  };

  const handleIlearn = () => {
    window.open(
      `${process.env.REACT_APP_ILEARN_URL}/explore-courses`,
      "_blank"
    );
  };

  const handleICapability = () => {
    window.open(
      `${
        process.env.REACT_APP_CAPABILITY_URL
      }/menu/ibridge/${USER_TOKEN()}/user`,
      "_blank"
    );
  };

  return (
    <>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar className="headerContainer">
          <Toolbar>
            <Container maxWidth="lg" className="headerContent">
              <img
                style={{
                  width: "205px",
                  height: "60px",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                // width="250"
                // height="60"
                src={Logo1}
                onClick={() =>
                  (window.location.href = `${process.env.REACT_APP_WORDPRESS_URL}`)
                }
              />
              <div className={classes.grow} />
              {!isAuthenticated ? (
                <>
                  <div className={classes.menuContainer}>
                    <Typography
                      variant="h5"
                      noWrap
                      onClick={() =>
                        (window.location.href = `${process.env.REACT_APP_WORDPRESS_URL}`)
                      }
                    >
                      <Link
                        // to="/"
                        className={
                          pathname === "/"
                            ? clsx(classes.title, classes.titleActive)
                            : classes.title
                        }
                      >
                        Home
                      </Link>
                    </Typography>
                  </div>

                  <div className={classes.menuCollapseContaier}>
                    <IconButton onClick={handleMobileMenu}>
                      <MoreIcon />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={mobileAnchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(mobileAnchorEl)}
                      onClose={handleMobileMenuClose}
                    ></Menu>
                  </div>
                </>
              ) : (
                <div className={classes.userDetails}>
                  <Button
                    disableRipple
                    className={classes.profileIconButton}
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    variant="outlined"
                    onClick={handleMenu}
                  >
                    <AccountCircle className={classes.profileIcon} />{" "}
                    {user?.firstName}{" "}
                    {Boolean(anchorEl) ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </Button>

                  <Menu
                    className={classes.profileDrop}
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        setOpen(true);
                        setComps(<EditLearnerProfile />);
                      }}
                    >
                      <EditIcon /> Edit Profile
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        setOpen(true);
                        setComps(<LearnerResetPassword setOpen={setOpen} />);
                      }}
                    >
                      <VpnKeyIcon /> Reset Password
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        props.logoutUser(props.history);
                        handleClose();
                        handleLogout();
                      }}
                    >
                      <ExitToAppIcon /> Logout
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </Container>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
      {isAuthenticated && props?.location?.pathname !== "/join-meeting" ? (
        <nav className="navBar">
          <h2 className="navBarTitle">My Learning</h2>
          <div
            className="menu-icon"
            onClick={() => {
              mobileMenuIcon === "closed"
                ? setMobileMenuIcon("expand")
                : setMobileMenuIcon("closed");
            }}
          >
            {mobileMenuIcon === "closed" ? (
              <MenuIcon style={{ color: "white" }} />
            ) : (
              <CloseIcon style={{ color: "white" }} />
            )}
          </div>
          <div className="navLinksSearchContainer">
            <ul
              className={
                mobileMenuIcon === "closed" ? "navMenu" : "navMenu active "
              }
            >
              {user?.programsEnrolled?.length > 0 ? (
                <>
                  <li
                    className={
                      activeTitle === "MyPrograms" ? "menuItems1" : "menuItems"
                    }
                  >
                    <Link
                      to="/myPrograms"
                      onClick={() => {
                        setActiveTitle("MyPrograms");
                        setMobileMenuIcon("closed");
                      }}
                      className="nav-links"
                    >
                      My Programs
                    </Link>
                  </li>{" "}
                  <li
                    className={
                      activeTitle === "mvk" ? "menuItems1" : "menuItems"
                    }
                  >
                    <Link
                      to="/dashboard"
                      onClick={() => {
                        setActiveTitle("mvk");
                        setMobileMenuIcon("closed");
                      }}
                      className="nav-links"
                    >
                      KYC
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li
                    className={
                      activeTitle === "mvk" ? "menuItems1" : "menuItems"
                    }
                  >
                    <Link
                      to="/dashboard"
                      onClick={() => {
                        setActiveTitle("mvk");
                        setMobileMenuIcon("closed");
                      }}
                      className="nav-links"
                    >
                      KYC
                    </Link>
                  </li>

                  <li
                    className={
                      activeTitle === "MyPrograms" ? "menuItems1" : "menuItems"
                    }
                  >
                    <Link
                      to="/myPrograms"
                      onClick={() => {
                        setActiveTitle("MyPrograms");
                        setMobileMenuIcon("closed");
                      }}
                      className="nav-links"
                    >
                      My Programs
                    </Link>
                  </li>
                </>
              )}
              {/* <li
                className={
                  activeTitle === "Recommended" ? "menuItems1" : "menuItems"
                }
              >
                <Link
                  to="/explorePrograms"
                  onClick={() => {
                    setActiveTitle("Recommended");
                    setMobileMenuIcon("closed");
                  }}
                  className="nav-links"
                >
                  Explore Programs
                </Link>
              </li> */}
            </ul>
            {props?.location?.pathname === "/myPrograms" ||
            props?.location?.pathname === "/programs" ? (
              <div className="searchBoxDiv">
                <SearchComponent userId={user._id} />
              </div>
            ) : null}
          </div>
        </nav>
      ) : null}

      {props.children}
      {/* <Footer /> */}

      <CustomizedDialogs
        title={
          comps.type.displayName === "Connect(EditLearnerProfile)"
            ? "Edit Profile"
            : "Reset Password"
        }
        // children={<EditLearnerProfile />}
        children={comps}
        openPopup={open}
        setOpenPopup={setOpen}
      />
    </>
  );
};

Layout.propTypes = {
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { logoutUser };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));

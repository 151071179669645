import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Button,
  Paper,
  Container,
  Select,
  MenuItem,
} from "@material-ui/core";
import { GroupAdd as GroupAddIcon, YouTube } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import ButtonField from "../../../reusableComponents/ButtonField";
import PageHeader from "../../../reusableComponents/PageHeader";
import FileUpload from "../../../reusableComponents/FileUpload";
import PhotoUpload from "../../../reusableComponents/PhotoUpload";
import isEmpty from "../../../reusableComponents/is-empty";
import DateTimePick from "../../../reusableComponents/DateTimePick";
import { getCoachList } from "../../../redux/actions/admin_view/coachList";
import {
  getCourses,
  uploadContentImage,
  uploadProfileImage,
  addNewProgram,
  getCapabilityTopics,
} from "../../../redux/actions/programActions";

import { getServiceProviderList } from "../../../redux/actions/admin_view/serviceProviderList";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import SelectMultipleValues from "../../../reusableComponents/SelectMultipleValues";
import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";
import DynamicTextField from "../../../reusableComponents/DynamicTextField";

const useStyles = makeStyles((theme) => ({
  inputFields: {
    margin: "1%",
    // width: "60%",
  },
  inputButtonFields: {
    margin: "1%",
    width: "60%",
  },
  LableText: {
    paddingLeft: "2%",
    paddingTop: "2%",
    fontSize: "18px",
  },
  inputButton: {
    marginTop: "3%",
    marginLeft: "2%",
    width: "60%",
  },
  inputPhoto: {
    marginTop: "10%",
    borderRadius: "0",
    height: "2000px",
  },
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const AddPrograms = ({
  history,
  getCoachList,
  auth,
  adminCoach,
  getCourses,
  programs,
  uploadContentImage,
  uploadProfileImage,
  getCapabilityTopics,
  addNewProgram,
  getServiceProviderList,
  adminServiceProvider,
}) => {
  const {
    iLearnCourses,
    programProfileImage,
    programContentImage,
    iCapabilityTopics,
  } = programs;

  const { coachList } = adminCoach;
  const { serviceProviderList } = adminServiceProvider;
  // console.log("serviceProviderList",serviceProviderList)
  // const serviceProviderName = serviceProviderList.map((item => {
  //   return
  //   {name:item.providerName}
  // }))

  const serviceProviderName = serviceProviderList?.map((item) => {
    return {
      name: item.providerName,
    };
  });

  console.log("serviceProviderName", serviceProviderName);
  const programDurationData = [
    { name: "1 Month" },
    { name: "1.5 Months" },
    { name: "2 Months" },
    { name: "2.5 Months" },
    { name: "3 Months" },
    { name: "3.5 Months" },
    { name: "4 Months" },
    { name: "4.5 Months" },
    { name: "5 Months" },
    { name: "5.5 Months" },
    { name: "6 Months" },
    { name: "6.5 Months" },
    { name: "7 Months" },
    { name: "7.5 Months" },
    { name: "8 Months" },
    { name: "8.5 Months" },
    { name: "9 Months" },
    { name: "9.5 Months" },
    { name: "10 Months" },
    { name: "10.5 Months" },
    { name: "11 Months" },
    { name: "11.5 Months" },
    { name: "12 Months" },
    { name: "12.5 Months" },
  ];

  const classes = useStyles();

  const today = new Date();

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedDate1, setSelectedDate1] = React.useState(new Date());

  const handleDateChange = (programStartDate) => {
    formik.setFieldValue("programStartDate", programStartDate);
    console.log(selectedDate);
    setSelectedDate(programStartDate);
  };

  const handleDateChange1 = (programEndDate) => {
    formik.setFieldValue("programEndDate", programEndDate);
    console.log(selectedDate1);
    setSelectedDate1(programEndDate);
  };

  const mentorData = coachList?.map((list) => {
    const { _id, firstName, lastName } = list;
    return {
      name: `${firstName} ${lastName}`,
      _id,
    };
  });

  const { isAuthenticated, user } = auth;
  useEffect(() => {
    if (isAuthenticated) {
      getCoachList();
      getCourses();
      getCapabilityTopics();
      getServiceProviderList();
    }
  }, [isAuthenticated]);

  const formik = useFormik({
    initialValues: {
      programName: "",
      addProgramPath: [],
      addProgramLearningPath: [],
      selectCourses: [],
      programDuration: "",
      programStartDate: new Date(),
      programEndDate: new Date(),
      programMentor: [],
      programDescription: "",
      programPrice: "",
      programImage: "",
      programImageName: "",
      cardImage: "",
      cardImageName: "",
      totalHours: "",
      capabilityTopics: [],
      serviceProvider: "",
      odooAccess: { name: "Yes", value: true },
    },
    validationSchema: Yup.object({
      programName: Yup.string()
        .trim()
        .required("Program Name is required field"),
      totalHours: Yup.number()
        .typeError("Total Hours must be in Numbers")
        .required("Total Hours is required field"),
      addProgramPath: Yup.array(Yup.string())
        .min(1, "Program Path is required field")
        .required("Program Path is required field"),
      addProgramLearningPath: Yup.array(Yup.string())
        .min(1, "Learning Path is required field")
        .required("Learning Path is reqired field"),

      selectCourses: Yup.array(
        Yup.object({
          _id: Yup.string(),
          name: Yup.string(),
        })
      )
        .min(1, "Please Select Atleast One iLearn Course")
        .required("Please Select Atleast One iLearn Course"),
      capabilityTopics: Yup.array(
        Yup.object({
          _id: Yup.string(),
          name: Yup.string(),
        })
      )
        .min(1, "Please Select Atleast One Capability Topic")
        .required("Please Select Atleast One Capability Topic"),

      programDuration: Yup.object()
        .nullable()
        .required("Program Duration is reqired field"),

      programMentor: Yup.array(
        Yup.object({
          _id: Yup.string(),
          name: Yup.string(),
        })
      )
        .min(1, "Please Select Atleast a Mentor")
        .required("Please Select Atleast a Mentor"),

      serviceProvider: Yup.object()
        .nullable()
        .required("Service Provider is reqired field"),

      programStartDate: Yup.string()
        .trim()
        .required("Program Start Date required field"),
      programEndDate: Yup.string()
        .trim()
        .required("Program End Date required field"),
      // programMentor: Yup.string()
      // // .trim()
      // .required("Program Mentor is required field"),
      programDescription: Yup.string()
        .trim()
        .required("Program Description is required field"),

      programPrice: Yup.number()
        .typeError("Price must be in Numbers")
        .required("Price is a required field"),
    }),

    // onSubmit: async (values) => {
    //   console.log(values,"Test123")
    //   alert("test123 : ",values)
    // }

    onSubmit: async (values) => {
      const programData = {
        name: values.programName,
        description: values.programDescription,
        price: parseFloat(values.programPrice),
        estimatedTime: values.programDuration.name,
        programPath: values.addProgramPath,
        learningPath: values.addProgramLearningPath,
        programMentor: values.programMentor.map((mentor) => mentor._id),
        courses: values.selectCourses.map((selectCourse) => selectCourse._id),
        programStartDate: values.programStartDate.toString().slice(0, 15),
        programEndDate: values.programEndDate.toString().slice(0, 15),
        capabilityTopics: values.capabilityTopics.map((topic) => topic.id),
        addedBy: user._id,
        totalHours: parseInt(values.totalHours),
        odooAccess: values.odooAccess.value,
        programImage: values.programImage,
        cardImage: values.cardImage,
      };
      addNewProgram(programData);
    },
  });

  useEffect(() => {
    if (isAuthenticated) {
      if (!isEmpty(programContentImage)) {
        formik.setFieldValue("programImageName", programContentImage.name);
        formik.setFieldValue("programImage", programContentImage.link);
      }
    }
  }, [isAuthenticated, programContentImage]);
  useEffect(() => {
    if (isAuthenticated) {
      if (!isEmpty(programProfileImage)) {
        formik.setFieldValue("cardImageName", programProfileImage.name);
        formik.setFieldValue("cardImage", programProfileImage.link);
      }
    }
  }, [isAuthenticated, programProfileImage]);

  const uploadProgramProfileImage = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("photo", e.target.files[0]);

    uploadProfileImage(formData);
  };

  const uploadProgramContentImage = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("photo", e.target.files[0]);

    uploadContentImage(formData);
  };

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography className={classes.LableText}>
                Add Program Profile Image
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} align="center">
              <PhotoUpload
                name={"profileImage"}
                className={classes.inputPhoto}
                imageName={formik.values.cardImageName}
                imageUrl={formik.values.cardImage}
                htmlFor={"profiileImage"}
                onChange={(e) => uploadProgramProfileImage(e)}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextFieldGroup
                label=" Program Name"
                type="text"
                className={classes.inputFields}
                name="programName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.programName}
                errors={
                  formik.touched.programName && formik.errors.programName
                    ? formik.errors.programName
                    : null
                }
              />
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <TextFieldGroup
                label="Program Path"
                type="text"
                rows={7}
                multiline
                className={classes.inputFields}
                name="addProgramPath"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.addProgramPath}
                errors={
                  formik.touched.addProgramPath && formik.errors.addProgramPath
                    ? formik.errors.addProgramPath
                    : null
                }
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextFieldGroup
                label="Learning Path"
                type="text"
                className={classes.inputFields}
                multiline
                rows={7}
                name="addProgramLearningPath"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.addProgramLearningPath}
                errors={
                  formik.touched.addProgramLearningPath &&
                  formik.errors.addProgramLearningPath
                    ? formik.errors.addProgramLearningPath
                    : null
                }
              />
            </Grid> */}

            <Grid item xs={12} md={12}>
              <TextFieldGroup
                label="Total Hours"
                type="text"
                multiline
                className={classes.inputFields}
                name="totalHours"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.totalHours}
                errors={
                  formik.touched.totalHours && formik.errors.totalHours
                    ? formik.errors.totalHours
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {console.log("selectCourses", iLearnCourses)}
              <SelectMultipleValues
                label="Select Courses"
                className={classes.inputFields}
                name="selectCourses"
                style={{ marginTop: "5%", paddingTop: "5%" }}
                dataList={iLearnCourses} //?iLearnCourses:[]
                value="Select Courses"
                onSelectData={(course) =>
                  formik.setFieldValue("selectCourses", course)
                }
                selectedData={formik.values.selectCourses}
                // selectedData={[]}
                errors={
                  formik.touched.selectCourses && formik.errors.selectCourses
                    ? formik.errors.selectCourses
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ marginTop: "5%" }}>
              <SelectMultipleValues
                label="Select Capability Topics"
                className={classes.inputFields}
                name="capabilityTopics"
                style={{ marginTop: "5%", paddingTop: "5%" }}
                dataList={iCapabilityTopics} //?iLearnCourses:[]
                value="Select Topics"
                onSelectData={(topic) =>
                  formik.setFieldValue("capabilityTopics", topic)
                }
                selectedData={formik.values.capabilityTopics}
                // selectedData={[]}
                errors={
                  formik.touched.capabilityTopics &&
                  formik.errors.capabilityTopics
                    ? formik.errors.capabilityTopics
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ marginTop: "5%" }}>
              <SelectSingleValues
                label="Program Duration"
                name="programDuration"
                className={classes.inputFields}
                values={programDurationData}
                style={{ marginTop: "5%", paddingTop: "5%" }}
                onSelect={(duration) =>
                  formik.setFieldValue("programDuration", duration)
                }
                // onSelect={(programDuration) => console.log(programDuration)}
                selected={formik.values.programDuration}
                errors={
                  formik.touched.programDuration &&
                  formik.errors.programDuration
                    ? formik.errors.programDuration
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  // margin="normal"
                  disablePast={true}
                  name="programStartDate"
                  label="Program Start Date"
                  format="MM/dd/yyyy"
                  inputVariant="outlined"
                  value={selectedDate}
                  style={{ marginTop: "5%" }}
                  className={classes.inputFields}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  // margin="normal"
                  disablePast={true}
                  name="programEndDate"
                  label="Program End Date"
                  format="MM/dd/yyyy"
                  inputVariant="outlined"
                  style={{ marginTop: "5%" }}
                  value={selectedDate1}
                  className={classes.inputFields}
                  onChange={handleDateChange1}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={12}>
              {/*  <SelectSingleValues
                label="Select Mentor"
                name="programMentor"
                className={classes.inputFields}
                style={{ marginTop: "5%", paddingTop: "5%" }}
                values={mentorData}
                // onSelect={(timezone) => formik.setFieldValue("timezone", timezone)}
                onSelect={(programMentor) =>
                  formik.setFieldValue("programMentor", programMentor)
                }
                selected={formik.values.programMentor}
                errors={
                  formik.touched.programMentor && formik.errors.programMentor
                    ? formik.errors.programMentor
                    : null
                }
              />*/}

              <SelectMultipleValues
                label="Select Mentor"
                className={classes.inputFields}
                name="programMentor"
                style={{ marginTop: "5%", paddingTop: "5%" }}
                dataList={mentorData}
                // dataList={[{name:"vishwas",_id:"123"}]}

                value="Select mentors"
                onSelectData={(mentor) =>
                  formik.setFieldValue("programMentor", mentor)
                }
                selectedData={formik.values.programMentor}
                // selectedData={[]}
                errors={
                  formik.touched.programMentor && formik.errors.programMentor
                    ? formik.errors.programMentor
                    : null
                }
              />
            </Grid>

            {/* <SelectMultipleValues
                label="Select Mentor"
                className={classes.inputFields}
                name="programMentor"
                style={{ marginTop: "5%", paddingTop: "5%" }}
                dataList={mentorData} //?iLearnCourses:[]
                // values={mentorData}
                value="Select Topics"
                onSelectData={(topic) =>
                  formik.setFieldValue("programMentor", programMentor)
                }
                selectedData={formik.values.programMentor}
                // selectedData={[]}
                errors={
                  formik.touched.programMentor &&
                  formik.errors.programMentor
                    ? formik.errors.programMentor
                    : null
                }
              /> */}

            <Grid item xs={12} md={12}>
              <TextFieldGroup
                label="Price"
                type="text"
                className={classes.inputFields}
                name="programPrice"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.programPrice}
                errors={
                  formik.touched.programPrice && formik.errors.programPrice
                    ? formik.errors.programPrice
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <SelectSingleValues
                label="Odoo Access"
                name="odooAccess"
                className={classes.inputFields}
                values={[
                  { name: "Yes", value: true },
                  { name: "No", value: false },
                ]}
                onSelect={(access) =>
                  formik.setFieldValue("odooAccess", access)
                }
                // onSelect={(programDuration) => console.log(programDuration)}
                selected={formik.values.odooAccess}
                errors={
                  formik.touched.odooAccess && formik.errors.odooAccess
                    ? formik.errors.odooAccess
                    : null
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} align="center">
          <Grid container>
            {/* <Grid item xs={12} md={12}>
              <Typography className={classes.LableText}>
                Add Program Profile Image
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <PhotoUpload
                name={"profileImage"}
                className={classes.inputPhoto}
                imageName={formik.values.cardImageName}
                imageUrl={formik.values.cardImage}
                htmlFor={"profiileImage"}
                onChange={(e) => uploadProgramProfileImage(e)}
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <Typography className={classes.LableText}>
                Add Program Content Image
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <PhotoUpload
                name={"contentImage"}
                className={classes.inputPhoto}
                imageName={formik.values.programImageName}
                imageUrl={formik.values.programImage}
                htmlFor={"contentImage"}
                onChange={(e) => uploadProgramContentImage(e)}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              {/* <TextFieldGroup
                label="Program Path"
                type="text"
                rows={7}
                multiline
                className={classes.inputFields}
                name="addProgramPath"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.addProgramPath}
                errors={
                  formik.touched.addProgramPath && formik.errors.addProgramPath
                    ? formik.errors.addProgramPath
                    : null
                }
              /> */}

              <DynamicTextField
                label="Program Path"
                type="text"
                multiline
                className={classes.inputFields}
                name="addProgramPath"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onChangeData={(programPathValue) => {
                  console.log(programPathValue);
                  formik.setFieldValue("addProgramPath", programPathValue);
                }}
                value={formik.values.addProgramPath}
                errors={
                  formik.touched.addProgramPath && formik.errors.addProgramPath
                    ? formik.errors.addProgramPath
                    : null
                }
              ></DynamicTextField>
            </Grid>

            <Grid item xs={12} md={12}>
              <DynamicTextField
                label="Learning Path"
                type="text"
                multiline
                className={classes.inputFields}
                name="addProgramLearningPath"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onChangeData={(learningPathValue) => {
                  // console.log(programPathValue);
                  formik.setFieldValue(
                    "addProgramLearningPath",
                    learningPathValue
                  );
                }}
                value={formik.values.addProgramLearningPath}
                errors={
                  formik.touched.addProgramLearningPath &&
                  formik.errors.addProgramLearningPath
                    ? formik.errors.addProgramLearningPath
                    : null
                }
              ></DynamicTextField>
            </Grid>
            {user?.role?.r_id === 1 ? (
              <Grid item xs={12} md={12} style={{ marginTop: "2%" }}>
                <SelectSingleValues
                  label="Service Provider"
                  name="serviceProvider"
                  className={classes.inputFields}
                  values={serviceProviderName}
                  style={{ marginTop: "5%", paddingTop: "5%" }}
                  onSelect={(providerName) =>
                    formik.setFieldValue("serviceProvider", providerName)
                  }
                  // onSelect={(programDuration) => console.log(programDuration)}
                  selected={formik.values.serviceProvider}
                  errors={
                    formik.touched.serviceProvider &&
                    formik.errors.serviceProvider
                      ? formik.errors.serviceProvider
                      : null
                  }
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} align="center">
          <TextFieldGroup
            label="Program Description"
            type="text"
            name="programDescription"
            multiline
            rows={4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.programDescription}
            errors={
              formik.touched.programDescription &&
              formik.errors.programDescription
                ? formik.errors.programDescription
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={12} align="center">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="submit"
              name="submit"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              // onClick={submitForm}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      <PageHeader
        title="Add New Programs"
        icon={<GroupAddIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>{renderForm}</Paper>
    </Container>
  );
};

AddPrograms.propTypes = {
  auth: PropTypes.object.isRequired,
  getCoachList: PropTypes.func.isRequired,
  programs: PropTypes.object.isRequired,
  getCourses: PropTypes.func.isRequired,
  uploadContentImage: PropTypes.func.isRequired,
  uploadProfileImage: PropTypes.func.isRequired,
  addNewProgram: PropTypes.func.isRequired,
  adminServiceProvider: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  adminCoach: state.adminCoach,
  programs: state.programs,
  adminServiceProvider: state.adminServiceProvider,
});

const mapDispatchToProps = {
  getCoachList,
  getCourses,
  uploadContentImage,
  uploadProfileImage,
  addNewProgram,
  getCapabilityTopics,
  getServiceProviderList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPrograms);

// import React, { useState, useEffect } from "react";
// // import { connect } from "react-redux";
// import PropTypes from "prop-types";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { Grid, makeStyles, CircularProgress } from "@material-ui/core";
// import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
// import ButtonField from "../../../reusableComponents/ButtonField";
// import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";
// // import {
// //   getAllCoursesList,
// //   addNewVideo,
// //   uploadCourseDocuments,
// // } from "../../../redux/actions/CoursesActions";
// import isEmpty from "../../../reusableComponents/is-empty";
// import SelectMultipleValues from "../../../reusableComponents/SelectMultipleValues";
// import TextField from "@material-ui/core/TextField";

// const useStyles = makeStyles((theme) => ({
//   singleSelect: {
//     margin: theme.spacing(2, 0),
//   },
//   prerequisite: {
//     margin: theme.spacing(2, 0),
//   },
//   btnContailer: {
//     margin: theme.spacing(2, 0),
//   },
//   formGridContainer:{
//     padding:"2%",
//   },
//   btnSubmit: {
//     borderRadius:"16px",
//     float: "right",
//     backgroundColor:  "#008dd2",
//     color: "#F8F9F9",
//     //opacity: "0.8",
//     textTransform: "capitalize",
//     fontWeight: 600,
//     letterSpacing: 1,
//     "&:hover": {
//       backgroundColor: "#008dd2",
//       //opacity: "1",
//     },
//   },
// }));

// const AddPrograms = ({
//   // auth,
//   // courses,
//   // getAllCoursesList,
//   // addNewProgram,
//   // //uploadCourseDocuments,
// }) => {
//   const classes = useStyles();

//   const formik = useFormik({
//   //   initialValues: {
//   //     name: "",
//   //     path: "",
//   //     learningPath:"",
//   //     duration: "",
//   //     description: "",
//   //     selectedCourse: {},
//   //     price:"",

//     // },
//   //   validationSchema: Yup.object({
//   //    programName: Yup.string().trim().required("Program Name is required field"),
//   //    programPath: Yup.string().trim().required("Program Path is required field"),
//   //    programLearningPath: Yup.string().trim().required("Program Learning Path is required field"),
//   //    programDuration: Yup.string().trim().required("Program Duration is required field"),
//   //    programDescription: Yup.string().trim().required("Program Description is required field"),
//   //    price: Yup.string().trim().required("Pris required field"),

//   //     // fileUpload: Yup.string()
//   //     //     .required('file upload is required field'),

//   //     relatedCourse: Yup.object().required(
//   //       "Video Related Course is required field"
//   //     ),
//   //   }),
//   //   onSubmit: async (values) => {
//   //     let videoData = JSON.parse(JSON.stringify(values));
//   //     videoData.prerequisiteVideos = values.prerequisiteVideos.map(
//   //       (video) => video._id
//   //     );
//   //     videoData.orderId = parseInt(values.orderId);
//   //     // videoData.courseDocuments = {
//   //     //   key: courseDocuments.key,
//   //     //   link: courseDocuments.link,
//   //     //   name: courseDocuments.name,
//   //     // };

//   //     // console.log(videoData);

//   //     await addNewProgram(videoData);
//   //   },
//   });

//   // const { isAuthenticated } = auth;
//   // const { allCoursesList, uploadSurveyFileProcess, courseDocuments } = courses;
//   // const [videoOrderList, setVideoOrderList] = useState([]);

//   // useEffect(() => {
//   //   if (isEmpty(allCoursesList) && isAuthenticated) {
//   //     getAllCoursesList();
//   //   }
//   // }, [allCoursesList, isAuthenticated]);

//   // useEffect(() => {
//   //   console.log(formik.values.relatedCourse);
//   //   if (formik.values?.relatedCourse?.videos) {
//   //     setVideoOrderList(formik.values.relatedCourse?.videos);
//   //   } else {
//   //     setVideoOrderList([]);
//   //   }
//   // }, [formik.values.relatedCourse]);

//   // const uploadDocuments = (e) => {
//   //   formik.setFieldValue("courseDocument", e.target.files[0]);
//   //   formik.setFieldValue("courseDocumentName", e.target.files[0].name);
//   //   // e.preventDefault();

//   //   const formData = new FormData();
//   //   formData.append("courseDocument", e.target.files[0]);
//   //   uploadCourseDocuments(formData);
//   // };

//   return (
//     <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
//       <Grid container spacing={5} className={classes.formGridContainer}>
//         <Grid item xs={6}>
//           <div className={classes.singleSelect}>
//             <SelectSingleValues
//               placeholder="Select Course"
//               name="selectedCourse"
//               // favourite={allCoursesList}
//               // onSelect={(course) =>
//               //   formik.setFieldValue("selectedCourse", course)
//               // }
//               // // selected={formik.values.relatedCourse}
//               // errors={
//               //   formik.touched.selectedCourse && formik.errors.selectedCourse
//               //     ? formik.errors.selectedCourse
//               //     : null
//               // }
//             />
//           </div>
//           <TextFieldGroup
//             label=" Program Name"
//             type="text"
//             name="name"
//             // onChange={formik.handleChange}
//             // onBlur={formik.handleBlur}
//             // value={formik.values.name}
//             // errors={
//             //   formik.touched.name&& formik.errors.name
//             //     ? formik.errors.name
//             //     : null
//             // }
//           />
//           <TextFieldGroup
//             label=" Program Path"
//             type="text"
//             name="description"
//             multiline
//             rows={7}
//             // onChange={formik.handleChange}
//             // onBlur={formik.handleBlur}
//             // value={formik.values.description}
//             // errors={
//             //   formik.touched.description && formik.errors.description
//             //     ? formik.errors.description
//             //     : null
//             // }
//           />
//            <TextFieldGroup
//             label="Program Learning Path"
//             type="text"
//             name="learningPath"
//              multiline
//             // // rows={7}
//             // onChange={formik.handleChange}
//             // onBlur={formik.handleBlur}
//             // value={formik.values.learningPath}
//             // errors={
//             //   formik.touched.learningPath && formik.errors.learningPath
//             //     ? formik.errors.learningPath
//             //     : null
//             // }
//           />
//           <TextFieldGroup
//             label=" Program Description"
//             type="text"
//             name="description"
//             multiline
//             rows={7}
//             // onChange={formik.handleChange}
//             // onBlur={formik.handleBlur}
//             // value={formik.values.description}
//             // errors={
//             //   formik.touched.description && formik.errors.description
//             //     ? formik.errors.description
//             //     : null
//             // }
//           />
//         </Grid>
//         <Grid item xs={6}>

//           <div className={classes.singleSelect}>

//              <TextFieldGroup
//             label="Program Duration"
//             type="text"
//             name="duration"
//             // onChange={formik.handleChange}
//             // onBlur={formik.handleBlur}
//             // value={formik.values.duration}
//             // errors={
//             //   formik.touched.duration && formik.errors.duration
//             //     ? formik.errors.duration
//             //     : null
//             // }
//           />
//           <TextField
//         id="datetime-local"
//         label="Start Date"
//         type="datetime-local"
//         //defaultValue="2017-05-24T10:30"
//         className={classes.textField}
//         InputLabelProps={{
//           shrink: true,
//         }}
//       />
//       <TextField
//         id="datetime-local"
//         label="End Date"
//         type="datetime-local"
//         //defaultValue="2017-05-24T10:30"
//         className={classes.textField}
//         InputLabelProps={{
//           shrink: true,
//         }}
//       />

// <TextFieldGroup
//             label="Price"
//             type="text"
//             name="price"
//           />
//       </div>

//           <div className={classes.btnContailer}>
//             <ButtonField
//               type="submit"
//               buttonStyle={classes.btnSubmit}
//               name="Submit "
//             />
//           </div>
//         </Grid>
//       </Grid>
//     </form>
//   );
// };

// export default AddPrograms;

import { ADMIN_ADD_NEW_SERVICEPROVIDER_LOADING, ADMIN_GET_SERVICEPROVIDER_IMAGE,ADMIN_GET_sERVICEPROVIDER_TIMEZONE_LIST  } from '../../actions/types';

const initialState = {
    loading: false,    
    serviceProviderImage: null,
    serviceProvidertimeZoneList: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case ADMIN_ADD_NEW_SERVICEPROVIDER_LOADING:
            return {
                ...state,
                loading: true
            }
       
        case ADMIN_GET_SERVICEPROVIDER_IMAGE:
            return {
                ...state,
                serviceProviderImage: action.payload,
                loading: false
            }
        case ADMIN_GET_sERVICEPROVIDER_TIMEZONE_LIST:
            return {
                ...state,
                serviceProvidertimeZoneList: action.payload,
                loading: false
            }
        default:
            return state;
    }
}
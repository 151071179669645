import React, { Component } from 'react';

import { Container, Grid, Typography } from '@material-ui/core';


const PrivacyPolicy = () => {
    return ( 
        <Grid container>
            <Grid item xs={12} md={12} >
                <Typography className="termsAndConditionContent1" align='Left' style={{fontSize:'24px',fontWeight:'600'}}>
                    Privacy Policy
                </Typography>
                <Typography className="termsAndConditionContent1" align='Left' style={{fontSize:'20px',fontWeight:'600'}}>
                    WHAT DO WE DO WITH YOUR INFORMATION?
                </Typography>
                <Typography className="termsAndConditionContent2" align='Left'>
                    When you purchase something from our iBridge360, as part of the buying process, 
                    we collect the personal 
                    information you give us such as your name, address and email address. 
                </Typography>
                <Typography className="termsAndConditionContent2" align='Left'>
                    Please read this Privacy Policy carefully, as it governs how you use 
                    iBridge360 or its affiliate products. 
                    If you do not agree to this Privacy Policy, please do not use iBridge360. 
                </Typography>

                <Typography className="termsAndConditionContent1" align='Left' style={{fontSize:'20px',fontWeight:'600'}}>
                    CONSENT
                </Typography>
                <Typography className="termsAndConditionContent1" align='Left' style={{fontSize:'20px',fontWeight:'600'}}>
                    How do you get my consent?
                </Typography>
                <Typography className="termsAndConditionContent2" align='Left'>
                    When you provide us with personal information to complete a transaction, 
                    verify your credit card, place an order, arrange for a delivery, we imply that 
                    you consent to our collecting it and using it for that specific reason only. 
                </Typography>
                <Typography className="termsAndConditionContent2" align='Left'>
                    If we ask for your personal information for a secondary reason, 
                    like marketing, we will either ask you directly for your expressed consent, 
                    or provide you with an opportunity to say no. 
                </Typography>

                <Typography className="termsAndConditionContent1" align='Left' style={{fontSize:'20px',fontWeight:'600'}}>
                    How do I withdraw my consent?
                </Typography>
                <Typography className="termsAndConditionContent2" align='Left'>
                    If after you opt-in, you change your mind, you may withdraw your consent for us to 
                    contact you, for the continued collection, use or disclosure of your information, 
                    at anytime, by contacting us at : support@ibridge360.com .
                </Typography>

                <Typography className="termsAndConditionContent1" align='Left' style={{fontSize:'20px',fontWeight:'600'}}>
                    PAYMENT
                </Typography>
                <Typography className="termsAndConditionContent2" align='Left'>
                    We use Razorpay for processing payments. We/Razorpay do not store your card data on their servers. 
                    The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) 
                    when processing payment. Your purchase transaction data is only used as long as is necessary 
                    to complete your purchase transaction. 
                    After that is complete, your purchase transaction information is not saved.
                </Typography>
                <Typography className="termsAndConditionContent2" align='Left'>
                    Our payment gateway adheres to the standards set by PCI-DSS as managed by the 
                    PCI Security Standards Council, 
                    which is a joint effort of brands like Visa, MasterCard, American Express and Discover.
                </Typography>
                <Typography className="termsAndConditionContent2" align='Left'>
                    PCI-DSS requirements help ensure the secure handling of credit card 
                    information by our store and its service providers.
                </Typography>
                <Typography className="termsAndConditionContent2" align='Left'>
                    For more insight, you may also want to read terms and conditions of 
                    razorpay on https://razorpay.com
                </Typography>

                <Typography className="termsAndConditionContent1" align='Left' style={{fontSize:'20px',fontWeight:'600'}}>
                    TYPES OF PERSONAL DATA COLLECTED
                </Typography>
                <Typography className="termsAndConditionContent2" align='Left'>
                    The Personal Data that we collect about you depends on the context of your 
                    interactions with us, the products, 
                    services and features that you use, your location, and the applicable laws.
                </Typography>
                <Typography className="termsAndConditionContent2" align='Left'>
                    Personal Data is stored in personnel files or within the 
                    electronic records (on servers in India or other countries) of iBridge360. 
                    The following types of Personal Data may be held by the Company, 
                    as appropriate, on relevant individuals:
                </Typography>

                <Typography className="termsAndConditionContent2" align='Left'>
                    A. Personal Identification Data
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    First Name, Last name
                </Typography>
                <Typography align='Left' style={{paddingLeft:'2%'}}>
                    Job title & Company
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Photographs
                </Typography>

                <Typography className="termsAndConditionContent2" align='Left'>
                    B. Identification Data
                </Typography>
                <Typography  align='Left' style={{paddingLeft:'2%'}}>
                    Social security or tax identification numbers
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Aadhar number and PAN
                </Typography>

                <Typography className="termsAndConditionContent2" align='Left'>
                    C. Financial Data
                </Typography>
                <Typography  align='Left' style={{paddingLeft:'2%'}}>
                    Bank Account information
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Salary Information
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Payment gateway account details
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    E-wallet account details
                </Typography>

                <Typography className="termsAndConditionContent2" align='Left'>
                    D. Personal Characteristics
                </Typography>
                <Typography  align='Left'  style={{paddingLeft:'2%'}}>
                    Age
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Gender
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Date of Birth
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Marital Status
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Nationality
                </Typography>

                <Typography className="termsAndConditionContent2" align='Left'>
                    E. Contact Data
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Postal address
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Email address
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Phone number
                </Typography>

                <Typography className="termsAndConditionContent2" align='Left'>
                    F. Education and Recruitment Data
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Educational qualification(s)
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Working goals
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Post-qualification experience
                </Typography>

                <Typography className="termsAndConditionContent2" align='Left'>
                    G. Electronic Identification Data
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Login credentials (If you are a registered user)
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Date and time of website visit
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Pages visited and navigation on the website
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Browser being used
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    County of accessing website
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Language of the browser being used
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}} >
                    Words searched for
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Pixel tags
                </Typography>

                <Typography className="termsAndConditionContent2" align='Left'>
                    H. Inquiries
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Personal Data stated in the form – for example: Name, address, phone number, country
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Subject of Inquiry
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Personal details (Name on the card, billing address)
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Payment details (card numbers, card type)   
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Recordings of calls with students and users showing interest in our Program.
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Information about your interactions with customer service and maintenance interactions with us.
                </Typography>

                <Typography className="termsAndConditionContent1" align='Left' style={{fontSize:'20px',fontWeight:'600'}}>
                    SECURITY
                </Typography>
                <Typography className="termsAndConditionContent2" align='Left'>
                    To protect your personal information, we take reasonable precautions and 
                    follow industry best practices to make sure it is not 
                    inappropriately lost, misused, accessed, disclosed, altered or destroyed.
                </Typography>

                <Typography className="termsAndConditionContent1" align='Left' style={{fontSize:'20px',fontWeight:'600'}}>
                    COOKIES
                </Typography>
                <Typography className="termsAndConditionContent2" align='Left'>
                    We use cookies to maintain session of your user. It is not used to 
                    personally identify you on other websites.
                </Typography>

                <Typography className="termsAndConditionContent1" align='Left' style={{fontSize:'20px',fontWeight:'600'}}>
                    CONTACTING iBridge360
                </Typography>
                <Typography className="termsAndConditionContent2" align='Left'>
                    If you have any questions about this privacy policy or your dealings with the 
                    iBridge360,you can reach out to us using the iBridge360 Help and Support page at : support@ibridge360.com
                </Typography>

                <Typography className="termsAndConditionContent2" align='Left'>
                    India
                </Typography>
                <Typography align='Left' style={{paddingLeft:'2%'}}>
                    iBridge360,
                </Typography>
                <Typography align='Left'  style={{paddingLeft:'2%'}}>
                    Bangalore.
                </Typography>


            </Grid>
        </Grid>
     );
}
 
export default PrivacyPolicy;
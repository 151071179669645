import React, { Component } from "react";

import { Container, Grid, Typography } from "@material-ui/core";

const TermsAndCondition = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "24px", fontWeight: "600" }}
          >
            Terms and conditions
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            Please read these Terms of Service carefully before accessing or
            using our website. By accessing or using any part of the site, you
            agree to be bound by these Terms of Service.
          </Typography>

          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            GENERAL CONDITIONS
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            We reserve the right to refuse service to anyone for any reason at
            any time. You understand that your content (not including credit
            card information), may be transferred unencrypted and involve (a)
            transmissions over various networks; and (b) changes to conform and
            adapt to technical requirements of connecting networks or devices.
            Credit card information is always encrypted during transfer over
            networks.
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            You agree not to reproduce, duplicate, copy, sell, resell or exploit
            any portion of the Service, use of the Service, or access to the
            Service or any contact on the website through which the service is
            provided, without express written permission by us.
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            The headings used in this agreement are included for convenience
            only and will not limit or otherwise affect these Terms.
          </Typography>

          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            ACCURACY OF BILLING AND ACCOUNT INFORMATION
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            Upon payment of the Program fees – in part or whole, depending on
            the Program – iBridge360 shall deliver the terms of use of the
            Program and grant you access to the Program. The invoice may be
            provided to you along with the email confirmation or within two (2)
            months thereafter. This shall be a proof of delivery against the
            successful payment of fees.
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            Owing to certain exceptional circumstances, if iBridge360 has
            granted you access to a Program pending payment, and iBridge360 does
            not receive payments within the stipulated time periods, iBridge360
            may, at its own discretion, withdraw your access to the Program
            without giving any prior notice.
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            You agree that you will never divulge or share access to your User
            Account with any third party for any reason. You also agree that you
            will create, use and/or access only one User Account, and that you
            will not use any User Account other than your own.
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            In setting up your User Account, you may be prompted or required to
            enter additional information, including but not limited to your name
            and email address. Additional information may be required to confirm
            your identity. You understand and agree that all information
            provided by you is accurate, current and complete and that you will
            maintain and update your information to keep it accurate, current
            and complete. You acknowledge that if any information provided by
            you is untrue, inaccurate, not current or incomplete, we reserve the
            right to terminate your use of the Platform and your enrolment into
            a Program, to the extent applicable.
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            In addition to the registration process, as part of your use of the
            Platform or participation in the Programs, we may obtain certain
            information about you and your performance in the Programs. Some of
            this information may be Personally Identifiable Information. We may
            use, maintain, and store this information to provide certain
            services to you now and in the future, and may share such
            information with our Educational Partners or third parties in
            conjunction with such services or for the purpose of marketing. For
            example, as further detailed in our Privacy Policy, we may share
            certain materials or information about you with third parties,
            including your grades/scores in our classes.
          </Typography>

          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            PERSONAL INFORMATION
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            Your submission of personal information through the store is
            governed by our Privacy Policy.
          </Typography>

          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            USE OF PROGRAMS
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            iBridge360 reserves the right to cancel or reschedule any Program or
            live lectures/webinars, or to alter, modify or rearrange the
            schedule of topics, as well as the point value or weight of
            assignments, tests, quizzes, exams, projects and other such
            evaluations of progress. You also understand that iBridge360 at its
            sole discretion, may limit, suspend, or terminate your use of the
            Platform or Programs and/or all iBridge360 provided services related
            to the Programs, such as access to iBridge360 coaches or support
            services, evaluation services, or certifications. You also
            understand that iBridge may modify or discontinue all services
            related to its Programs at its sole discretion. You agree that
            iBridge360 shall not be liable to you or to any third party for any
            such modification, suspension or discontinuance. Nothing in these
            Terms shall be construed to obligate iBridge360 to maintain and
            support the Platform or Programs or any part or portion thereof or
            any associated services.
          </Typography>

          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            MODIFICATIONS TO THE SERVICE AND PRICES
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            iBridge360 reserves the right to change prices for all our products,
            offers, or deals. These changes are done due to market conditions,
            course termination, providers, price changes, errors in
            advertisements and other extenuating circumstances. However, the
            price you paid at the time of purchase still holds for you.
          </Typography>

          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            CONTACT INFORMATION
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            Questions about the Terms of Service should be sent to us at :
            support@ibridge360.com .
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default TermsAndCondition;

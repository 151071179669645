import {
  GET_PROGRAM_LIST,
  GET_PROGRAM_INFO,
  GET_ILEARN_COURSES,
  PROGRAM_LOADING,
  UPLOAD_PROGRAM_PROFILE_IMAGE,
  UPLOAD_PROGRAM_CONTENT_IMAGE,
  GET_USER_ENROLLED_PROGRAMS,
  GET_PROGRAM_COURSES,
  GET_ICAPABILITY_TOPICS,
  GET_PROGRAM_TOPICS,
  GET_MENTOR_PROGRAMS,
  GET_PROGRAM_DISENROLLED_USER_LIST,
} from "../actions/types";

const initialState = {
  loading: false,
  programList: [],
  filteredProgramsList:[],
  filteredMyProgramsList:[],
  programInfo: null,
  programProfileImage: null,
  programContentImage: null,
  programCourses: null,
  userPrograms: [],
  iLearnCourses: [],
  iCapabilityTopics: [],
  programTopics: null,
  mentorPrograms: [],
  programDisEnrolledUserList:[],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROGRAM_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_PROGRAM_LIST:
      return {
        ...state,
        programList: action.payload.programList,
        filteredProgramsList: action.payload.filteredProgramsList,
        loading: false,
      };
    case GET_PROGRAM_INFO:
      return {
        ...state,
        programInfo: action.payload,
        loading: false,
      };
    case GET_USER_ENROLLED_PROGRAMS:
      return {
        ...state,
        userPrograms: action.payload.userPrograms,
        filteredMyProgramsList:action.payload.filteredMyProgramsList,
        loading: false,
      };
    case UPLOAD_PROGRAM_PROFILE_IMAGE:
      return {
        ...state,
        programProfileImage: action.payload,
        loading: false,
      };
    case GET_PROGRAM_COURSES:
      return {
        ...state,
        programCourses: action.payload,
        loading: false,
      };
    case UPLOAD_PROGRAM_CONTENT_IMAGE:
      return {
        ...state,
        programContentImage: action.payload,
        loading: false,
      };
    case GET_ILEARN_COURSES:
      return {
        ...state,
        iLearnCourses: action.payload,
        loading: false,
      };
    case GET_ICAPABILITY_TOPICS:
      return {
        ...state,
        iCapabilityTopics: action.payload,
        loading: false,
      };
    case GET_PROGRAM_TOPICS:
      return {
        ...state,
        programTopics: action.payload,
        loading: false,
      };
    case GET_MENTOR_PROGRAMS:
      return {
        ...state,
        mentorPrograms: action.payload,
        loading: false,
      };

    case GET_PROGRAM_DISENROLLED_USER_LIST:
      return {
        ...state,
        programDisEnrolledUserList: action.payload,
        loading: false,        
      };
      
    default:
      return state;
  }
}

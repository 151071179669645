import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Typography, Button, Paper, Container } from "@material-ui/core";
import { GroupAdd as GroupAddIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import ButtonField from "../../../reusableComponents/ButtonField";
import PageHeader from "../../../reusableComponents/PageHeader";
import FileUpload from "../../../reusableComponents/FileUpload";
import PhotoUpload from "../../../reusableComponents/PhotoUpload";
import isEmpty from "../../../reusableComponents/is-empty";
import {
  registerCoach,
  getIndustryList,
  getSkillsList,
  getDaysList,
  getTimingsList,
  getCoachImage,
  getTimeZoneList,
} from "../../../redux/actions/admin_view/addNewCoachActions";
import SelectMultipleValues from "../../../reusableComponents/SelectMultipleValues";
import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";
import MultiSelect from "../../../reusableComponents/MultiSelect";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const AddNewCoach = ({
  registerCoach,
  getIndustryList,
  getSkillsList,
  getDaysList,
  getTimingsList,
  getCoachImage,
  getTimeZoneList,
  auth,
  adminAddNewCoach,
  history,
}) => {
  const classes = useStyles();
  const { isAuthenticated } = auth;
  const {
    industryList,
    skillsList,
    days,
    timingSlots,
    coachImage,
    timeZoneList,
  } = adminAddNewCoach;
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      if (isEmpty(industryList)) {
        getIndustryList();
      }
      if (isEmpty(skillsList)) {
        getSkillsList();
      }
      if (isEmpty(days)) {
        getDaysList();
      }
      if (isEmpty(coachImage)) {
        getTimingsList();
      }
      if (isEmpty(timeZoneList)) {
        getTimeZoneList();
      }
      if (!isEmpty(coachImage)) {
        formik.setFieldValue("userImageName", coachImage.name);
        formik.setFieldValue("userImage", coachImage.link);
      }
    }
  }, [isAuthenticated, coachImage]);

  // const phoneRegExp = "(/^[a-zA-Z\s]+$/)"
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      userImage: "",
      userImageName: "",
      expertIn: "",
      industry: [],
      skills: [],
      experience: "",
      location: "",
      weekelyAvailableHours: "",
      preferredDays: [],
      prefferedTime: [],
      description: "",
      timezone: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .trim()
        .required("First Name is a required field")
        .min(3, "Name must be at least 3 characters")
        .matches(/^[A-Za-z\s]+$/, "First Name should contain only alphabets"),
      // firstName: Yup.string()
      //   // .matches("^[0-9]*", "name should not contain numbers")
      //   .trim()
      //   .required("First Name is a required field")
      //   .min(3, "Name must be at least 3 characters")
      //   .matches(phoneRegExp, "name should contain only alphabets"),
      lastName: Yup.string()
        .trim()
        .required("Last Name is a required field")
        .min(3, "Name must be at least 3 characters")
        .matches(/^[A-Za-z\s]+$/, "Last Name should contain only alphabets"),
      email: Yup.string().email().required("Email is required field"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(
          "(?=.*[a-z])",
          "Password must contain at least 1 Lower Case character"
        )
        .matches(
          "(?=.*[A-Z])",
          "Password must contain at least 1 Upper Case character"
        )
        .matches("(?=.*[0-9])", "Password must contain at least 1 Number")
        .matches(
          "(?=.*[!@#$%^&*])",
          "Password must contain at least 1 special character"
        )
        .required("Password is required field"),
      expertIn: Yup.string()
        .trim()
        .matches(/^[A-Za-z\s]+$/, "Expert In should contain only alphabets")
        .required("Expert In is required field"),
      industry: Yup.array()
        .of(
          Yup.object({
            _id: Yup.string(),
            name: Yup.string(),
          })
        )
        .min(1, "Industry is required field")
        .required("Industry is required field"),
      skills: Yup.array()
        .of(
          Yup.object({
            _id: Yup.string(),
            name: Yup.string(),
          })
        )
        .min(1, "Skills is required field")
        .required("Skills is required field"),
      experience: Yup.string()
        .trim()
        .matches(/^[0-9]*$/, "Experience should be in numbers")
        .required("Experience is reqired field"),
      location: Yup.string()
        .trim()
        .matches(/^[A-Za-z\s]+$/, "Last Name should contain only alphabets")
        .required("Location is reqired field"),
      weekelyAvailableHours: Yup.string()
        .trim()
        .matches(/^[0-9]*$/, "Weekly Available should be in numbers")
        .required("Weekly Available Time is reqired field"),
      preferredDays: Yup.array()
        .of(
          Yup.object({
            _id: Yup.string(),
            name: Yup.string(),
          })
        )
        .min(1, "Preffered Days is required field")
        .required("Preffered Days is reqired field"),
      prefferedTime: Yup.array(
        Yup.object({
          _id: Yup.string().required("Preffered Time is required field"),
          name: Yup.string().required("Preffered Time is required field"),
        })
      )
        .nullable()
        .min(1, "Preffered Time is required field")
        .required("Preffered Time is reqired field"),
      timezone: Yup.object({
        name: Yup.string().required("Timezone is reqired field"),
      })
        .nullable()
        .required("Timezone is reqired field"),
      description: Yup.string()
        .trim()
        .required("Description is required field"),
    }),
    onSubmit: async (values) => {
      const userData = await values;
      userData.industry = await userData?.industry?.map((list) => list._id);
      userData.skills = await userData?.skills?.map((list) => list._id);
      userData.weekelyAvailableHours = await parseInt(
        userData.weekelyAvailableHours
      );
      userData.preferredDays = await userData?.preferredDays?.map(
        (list) => list._id
      );
      userData.prefferedTime = await userData?.prefferedTime?.map(
        (list) => list._id
      );
      userData.timezone = await userData?.timezone?.name;
      delete userData.userImageName;

      if (isEmpty(userData.description)) {
        delete userData.description;
      }

      if (isEmpty(userData.userImage)) {
        delete userData.userImage;
      } else {
        userData.userImage = coachImage;
      }

      await registerCoach(userData);
      console.log("add new coach onSubmit values", userData);
    },
  });

  const uploadCoursePoster = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("photo", e.target.files[0]);

    getCoachImage(formData);
  };

  const alterDays = days?.map((list) => {
    const { _id, day } = list;
    return {
      _id,
      name: day,
    };
  });

  const alterTimingSlots = timingSlots?.map((list) => {
    const { _id, slot } = list;
    return {
      _id,
      name: slot,
    };
  });

  const alterTimeZoneList = timeZoneList?.map((list) => {
    const { timeZone } = list;
    return {
      name: timeZone,
    };
  });

  const renderForm = (
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={5}>
        <Grid item xs={12} className="inputfieldCenter">
          <PhotoUpload
            imageName={formik.values.userImageName}
            imageUrl={formik.values.userImage}
            onChange={(e) => uploadCoursePoster(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="FirstName"
            type="text"
            name="firstName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            errors={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="LastName"
            type="text"
            name="lastName"
            onChange={formik.handleChange}
            // onChange={(e) => {
            //   const regex = "[a-z A-Z]*";
            //   if (e.target.value === "" || regex.test(e.target.value)) {
            //     formik.setFieldValue("lastName", e.target.value);
            //   } else {
            //   }
            // }}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            errors={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="Email"
            type="text"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            errors={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="Password"
            type={showPassword ? "text" : "password"}
            name="password"
            passwordControl={() => setShowPassword(!showPassword)}
            showPassword={showPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            errors={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="Expert In"
            type="text"
            name="expertIn"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.expertIn}
            errors={
              formik.touched.expertIn && formik.errors.expertIn
                ? formik.errors.expertIn
                : null
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className="inputFieldContainer inputFieldMultiContainer"
        >
          <SelectMultipleValues
            placeholder="Industry *"
            name="industry"
            dataList={industryList}
            onSelectData={(industry) =>
              formik.setFieldValue("industry", industry)
            }
            selectedData={formik.values.industry}
            errors={
              formik.touched.industry && formik.errors.industry
                ? formik.errors.industry
                : null
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className="inputFieldContainer inputFieldMultiContainer"
        >
          <SelectMultipleValues
            placeholder="Skills *"
            name="skills"
            dataList={skillsList}
            onSelectData={(skills) => formik.setFieldValue("skills", skills)}
            selectedData={formik.values.skills}
            errors={
              formik.touched.skills && formik.errors.skills
                ? formik.errors.skills
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="Experience"
            type="text"
            name="experience"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.experience}
            errors={
              formik.touched.experience && formik.errors.experience
                ? formik.errors.experience
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="Location"
            type="text"
            name="location"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.location}
            errors={
              formik.touched.location && formik.errors.location
                ? formik.errors.location
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="Weekly Available Hours"
            type="text"
            name="weekelyAvailableHours"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.weekelyAvailableHours}
            errors={
              formik.touched.weekelyAvailableHours &&
              formik.errors.weekelyAvailableHours
                ? formik.errors.weekelyAvailableHours
                : null
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className="inputFieldContainer inputFieldMultiContainer"
        >
          {/* <SelectMultipleValues
            placeholder="Preffered Days *"
            name="preferredDays"
            dataList={alterDays}
            onSelectData={(preferredDays) =>
              formik.setFieldValue("preferredDays", preferredDays)
            }
            selectedData={formik.values.preferredDays}
            errors={
              formik.touched.preferredDays && formik.errors.preferredDays
                ? formik.errors.preferredDays
                : null
            }
          /> */}

          <MultiSelect
            placeholder="Preffered Days *"
            label="Preffered Days *"
            name="preferredDays"
            dataList={alterDays}
            onSelectData={(days) => {
              console.log(days);
              formik.setFieldValue("preferredDays", days);
            }}
            selectedData={formik.values.preferredDays}
            errors={
              formik.touched.preferredDays && formik.errors.preferredDays
                ? formik.errors.preferredDays
                : null
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className="inputFieldContainer inputFieldMultiContainer"
        >
          {/* <SelectMultipleValues
            placeholder="Preffered Time *"
            name="prefferedTime"
            dataList={alterTimingSlots}
            onSelectData={(prefferedTime) =>
              formik.setFieldValue("prefferedTime", prefferedTime)
            }
            selectedData={formik.values.prefferedTime}
            errors={
              formik.touched.prefferedTime && formik.errors.prefferedTime
                ? formik.errors.prefferedTime
                : null
            }
          /> */}

          <MultiSelect
            label="Preffered Time *"
            placeholder="Preffered Time *"
            name="prefferedTime"
            dataList={alterTimingSlots}
            onSelectData={(prefferedTime) =>
              formik.setFieldValue("prefferedTime", prefferedTime)
            }
            selectedData={formik.values.prefferedTime}
            errors={
              formik.touched.prefferedTime && formik.errors.prefferedTime
                ? formik.errors.prefferedTime
                : null
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className="inputFieldContainer inputFieldMultiContainer"
        >
          <SelectSingleValues
            placeholder="TimeZone *"
            name="timezone"
            values={alterTimeZoneList}
            onSelect={(timezone) => formik.setFieldValue("timezone", timezone)}
            selected={formik.values.timezone}
            errors={
              formik.touched.timezone && formik.errors.timezone
                ? formik.errors.timezone
                : null
            }
          />
        </Grid>
        <Grid item xs={12} className="inputFieldContainer">
          <TextFieldGroup
            label="Description"
            type="text"
            name="description"
            multiline
            rows={4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            errors={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : null
            }
          />
        </Grid>
        <Grid item xs={12} className="inputFieldContainer">
          <div className={classes.submitButton}>
            <ButtonField
              buttonStyle="formSubmit"
              type="submit"
              name="Submit"
              className={classes.mentorForm}
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      <PageHeader
        title="Add New Coach"
        subTitle="Add New coach for guiding the learner"
        icon={<GroupAddIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>{renderForm}</Paper>
    </Container>
  );
};

AddNewCoach.propTypes = {
  registerCoach: PropTypes.func.isRequired,
  getIndustryList: PropTypes.func.isRequired,
  getSkillsList: PropTypes.func.isRequired,
  getDaysList: PropTypes.func.isRequired,
  getTimingsList: PropTypes.func.isRequired,
  getCoachImage: PropTypes.func.isRequired,
  getTimeZoneList: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  adminAddNewCoach: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  adminAddNewCoach: state.adminAddNewCoach,
});

const mapDispatchToProps = {
  registerCoach,
  getIndustryList,
  getSkillsList,
  getDaysList,
  getTimingsList,
  getCoachImage,
  getTimeZoneList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCoach);

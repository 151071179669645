import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Card,
  CardContent,
  CssBaseline,
  Box,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import PersonalityAssessment from "./PersonalityAssessment";
import PersonalityAssessmentGraph from "./PersonalityAssessmentGraph";
import clsx from "clsx";
import ButtonField from "../../reusableComponents/ButtonField";
import { USER_TOKEN } from "../../redux/actions/types";
import { postUserAccessLog } from "../../redux/actions/authActions";
import {
  getAssessmentResult,
  getPsychometricQuestions,
  postPsychometricQuestions,
  getPsychometricResultAnswers,
  sendCounsellingRequest,
  getCounsellingRequest,
} from "../../redux/actions/dashboardActions";
import { getRIASECTestResultByUserId } from "../../redux/actions/RIASECTestAction";
import RIASECTestScreen from "./RIASECTestScreen";
import RIASECResultgraph from "./RIASECResultgraph";

import isEmpty from "../../reusableComponents/is-empty";
import Counselling from "./Counselling";
import { getDateTime } from "../../reusableComponents/GetDate";
// import MediaRecorder from "./MediaRecorder";
import IntroduceYourself from "./IntroduceYourself";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    width: "100%",
    content: {
      margin: "0",
    },
  },
  accordionSummary: {
    width: "100%",
    // padding: "0",
    // paddingTop:'0',
    margin: "0",
    content: {
      padding: "0",
      paddingTop: "0",
      margin: "0",
    },
  },
  gridItem: {
    // width: '100%',
    padding: "0",
    margin: "0",
    content: {
      margin: "0",
    },
  },
  heading: {
    paddingLeft: "10px",
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  headingBold: {
    // fontWeight: 600
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  newBusinessContainer: {
    textAlign: "center",
  },
  againNewBusinessTest: {
    textAlign: "right",
  },
  table: {
    minWidth: 650,
  },
  tableHeading: {
    fontWeight: 600,
  },
  tableBordered: {
    borderWidth: 1,
    borderColor: "#ddd",
    borderStyle: "solid",
  },
  cardRoot: {
    minWidth: 275,
  },
  title: {
    display: "flex",
    color: "#2B2B2B",
    margin: theme.spacing(1, 0),
  },
  titleBold: {
    fontWeight: 600,
    paddingRight: 4,
  },
  centerTitle: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
}));
// const AccordionSummary = withStyles({
//     content: {
//       margin: "0",
//       "&$expanded": {
//         margin: "0",
//         padding: 0,
//         backgroundColor: "#008dd2",
//       },
//     },
//     expanded: {},
//   })(MuiAccordionSummary);

const Dashboard = ({
  history,
  auth,
  dashboard,
  RIASECTestReducer,
  getAssessmentResult,
  getPsychometricQuestions,
  postPsychometricQuestions,
  getPsychometricResultAnswers,
  sendCounsellingRequest,
  getCounsellingRequest,
  postUserAccessLog,
  getRIASECTestResultByUserId,
}) => {
  const classes = useStyles();
  const { isAuthenticated, user } = auth;
  console.log("user ", user);
  const {
    assessmentResult,
    psychometricQuestions,
    psychometricResult,
    comunicationVideoURL,
    aiTextToSpeechUrl,
    // aiResponse,
    aiFeedbackResponse,
    aiCorrectedVersion,
    counsellingRequest,
  } = dashboard;

  console.log("RIASECTestReducer", RIASECTestReducer);

  const { riasecResultByUserId } = RIASECTestReducer;
  const [expanded, setExpanded] = useState(false);
  const [personaliyTest, setPersonalityTest] = useState(false);
  const [anotherCounselling, setAnotherCounselling] = useState(false);
  const [enrolledInProgram, setEnrolledInProgram] = useState(
    user?.programsEnrolled?.length > 0
  );

  var tabVisibility = document.visibilityState;

  const [timeInterval, setTimeInterval] = useState(0);

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 60000);

  useEffect(() => {
    if (isAuthenticated && tabVisibility === "visible") {
      const data = {
        pageName: "MVK",
        recordedDate: new Date(),
      };
      postUserAccessLog(data);
    }
    // console.log("Individual Course Useage , Date", new Date(),"UserId :", user._id,"Location : MVVK");
  }, [timeInterval, tabVisibility]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      getAssessmentResult(user.email);
      getRIASECTestResultByUserId(user._id);
      getCounsellingRequest();
      getPsychometricResultAnswers(user._id);
    }
  }, [isAuthenticated]);

  console.log(psychometricResult);
  console.log(psychometricResult?.traitOne?.suitJob?.length);
  const firstOne =
    psychometricResult?.traitOne?.suitJob?.length > 0
      ? psychometricResult?.traitOne
      : null;
  const secondOne =
    psychometricResult?.traitTwo?.suitJob?.length > 0
      ? psychometricResult?.traitTwo
      : null;
  const thirdOne =
    psychometricResult?.traitThree?.suitJob?.length > 0
      ? psychometricResult?.traitThree
      : null;
  const fourthOne =
    psychometricResult?.traitFour?.suitJob?.length > 0
      ? psychometricResult?.traitFour
      : null;

  console.log(firstOne);
  const psychometricGraphData = [
    {
      trait_name: "Introvert",
      scores: isEmpty(firstOne?.Introvert) ? 0 : firstOne?.Introvert,
    },
    {
      trait_name: "Extrovert",
      scores: isEmpty(firstOne?.Extrovert) ? 0 : firstOne?.Extrovert,
    },
    {
      trait_name: "Thinker",
      scores: isEmpty(secondOne?.Thinker) ? 0 : secondOne?.Thinker,
    },
    {
      trait_name: "Judgemental",
      scores: isEmpty(secondOne?.Judgemental) ? 0 : secondOne?.Judgemental,
    },
    {
      trait_name: "AttentionToDetails",
      scores: isEmpty(thirdOne?.AttentionToDetails)
        ? 0
        : thirdOne?.AttentionToDetails,
    },
    {
      trait_name: "HighFlying",
      scores: isEmpty(thirdOne?.HighFlying) ? 0 : thirdOne?.HighFlying,
    },
    {
      trait_name: "Composed",
      scores: isEmpty(fourthOne?.Composed) ? 0 : fourthOne?.Composed,
    },
    {
      trait_name: "Restless",
      scores: isEmpty(fourthOne?.Restless) ? 0 : fourthOne?.Restless,
    },
  ];

  return (
    <Container maxWidth="lg" className="dashboardContainer">
      <Grid container spacing={0}>
        {/* <Grid item xs={12}>
          <div className="mainViewHeading">
            <Typography className="headingText" variant="h5">
              Minimum Viable Knowledge (MVK)
            </Typography>
          </div>
        </Grid> */}
        <Grid item xs={12}>
          {/* <Accordion data={CheckKnowledgeList} /> */}
          <div className={classes.accordionRoot}>
            <MuiAccordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <MuiAccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                {/* <Typography className={expanded === 'panel2' ? clsx(classes.heading, classes.headingBold) :classes.heading}>Check your personality</Typography> */}
                {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
                <Grid container>
                  {isEmpty(riasecResultByUserId) ? (
                    <Grid item xs={2} md={2} className="colorSpace1">
                      <div className="iconCard">
                        <ClearIcon className="Icon" />
                      </div>
                      {/* <ClearIcon className='Icon'/> */}
                    </Grid>
                  ) : (
                    <Grid item xs={2} md={2} className="colorSpace2">
                      <div className="iconCard">
                        <DoneIcon className="Icon" />
                      </div>
                      {/* <DoneIcon className='Icon'/> */}
                    </Grid>
                  )}
                  <Grid item xs={7} md={7}>
                    <Typography
                      className={
                        expanded === "panel2"
                          ? clsx(classes.heading, classes.headingBold)
                          : classes.heading
                      }
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}
                      >
                        K
                      </span>
                      now{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}
                      >
                        Y
                      </span>
                      our{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}
                      >
                        C
                      </span>
                      areer-Path
                    </Typography>
                  </Grid>

                  <Grid item xs={3} md={3}>
                    <Typography
                      className={
                        expanded === "panel2"
                          ? clsx(classes.heading, classes.headingBold)
                          : classes.heading
                      }
                    >
                      {isEmpty(riasecResultByUserId)
                        ? "Incomplete"
                        : "Complete"}
                    </Typography>
                  </Grid>
                </Grid>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <Grid container>
                  <Grid item xs={12}>
                    {!personaliyTest && isEmpty(riasecResultByUserId) && (
                      <div className={classes.newBusinessContainer}>
                        <Container component="main" maxWidth="md">
                          <CssBaseline />
                          <Box
                            style={{
                              marginTop: "15",
                              padding: "10px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              background: "white",
                              margin: "20px",
                              border: "1px solid #0a71b9",
                              borderRadius: "25px",
                              // width: "100%",
                              // height: "100%",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "22px",
                                color: "#0a71b9",
                                fontWeight: "600",
                                textDecoration: "underline",
                              }}
                              align="left"
                            >
                              INSTRUCTIONS:
                            </Typography>

                            <ul style={{ fontSize: "18px", color: "#0a71b9" }}>
                              {/*  <li> */}
                              <Typography
                                align="left"
                                style={{ padding: "7px", fontWeight: "bold" }}
                              >
                                This is a One-time activity
                              </Typography>
                              {/* </li> */}

                              {/* <li> */}
                              <Typography
                                align="left"
                                style={{ padding: "7px" }}
                              >
                                Do not think about Qualifications, Money or
                                Trainings, but have patience while answering
                                these questions as this will help you in
                                deciding your Interests
                              </Typography>
                              {/* </li> */}

                              {/* <li> */}
                              <Typography
                                align="left"
                                style={{ padding: "7px" }}
                              >
                                There are no RIGHT or WRONG answers..!!!
                              </Typography>
                              {/* </li> */}

                              {/* <li> */}
                              <Typography
                                align="left"
                                style={{ padding: "7px" }}
                              >
                                You will have to answer 60 questions which
                                should not take more than 5 minutes. However,
                                there is no Time Limit.
                              </Typography>
                              {/* </li> */}
                            </ul>

                            {/* <Typography>
                * Patent Link – Click here to understand BREU better
              </Typography> */}
                          </Box>
                          {/* <Copyright style={{ mt: 8, mb: 4 }} /> */}
                        </Container>
                        <ButtonField
                          name="Click to start your Career Assessment"
                          onClick={() => setPersonalityTest(true)}
                          color="#003399"
                        />
                      </div>
                    )}
                    {personaliyTest && isEmpty(riasecResultByUserId) && (
                      // <PersonalityAssessment
                      //   userId={user._id}
                      //   psychometricQuestions={psychometricQuestions}
                      //   postPsychometricQuestions={postPsychometricQuestions}
                      // />
                      <RIASECTestScreen />
                    )}
                  </Grid>
                  {!isEmpty(riasecResultByUserId) && (
                    <Grid item xs={12}>
                      {/* <PersonalityAssessmentGraph
                        psychometricGraphData={psychometricGraphData}
                      /> */}
                      <RIASECResultgraph userID={user._id} />
                    </Grid>
                  )}
                </Grid>
              </MuiAccordionDetails>
            </MuiAccordion>
            <MuiAccordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <MuiAccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
                // disabled = {isEmpty(psychometricResult)}
              >
                {/* <Typography className={expanded === 'panel3' ? clstyle(classes.heading, classes.headingBold) :classes.heading}>Check Your Communication</Typography> */}
                {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}

                <Grid container>
                  {isEmpty(comunicationVideoURL) ? (
                    <Grid item xs={2} md={2} className="colorSpace1">
                      <div className="iconCard">
                        <ClearIcon className="Icon" />
                      </div>
                      {/* <ClearIcon className='Icon'/> */}
                    </Grid>
                  ) : (
                    <Grid item xs={2} md={2} className="colorSpace2">
                      <div className="iconCard">
                        <DoneIcon className="Icon" />
                      </div>
                      {/* <DoneIcon className='Icon'/> */}
                    </Grid>
                  )}
                  <Grid item xs={7} md={7}>
                    <Typography
                      className={
                        expanded === "panel3"
                          ? clsx(classes.heading, classes.headingBold)
                          : classes.heading
                      }
                    >
                      Introduce Yourself
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <Typography
                      className={
                        expanded === "panel3"
                          ? clsx(classes.heading, classes.headingBold)
                          : classes.heading
                      }
                    >
                      {isEmpty(comunicationVideoURL)
                        ? "Incomplete"
                        : "Complete"}
                    </Typography>
                  </Grid>
                </Grid>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <Container maxWidth="lg">
                  <Grid container spacing={4}>
                    <IntroduceYourself
                      enrolledInProgram={enrolledInProgram}
                      comunicationVideoURL={comunicationVideoURL}
                      aiFeedbackResponse={aiFeedbackResponse}
                      aiCorrectedVersion={aiCorrectedVersion}
                      aiTextToSpeechUrl={aiTextToSpeechUrl}
                    />
                  </Grid>
                </Container>
              </MuiAccordionDetails>
            </MuiAccordion>
            <MuiAccordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <MuiAccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {/* <Typography className={expanded === 'panel1' ? clsx(classes.heading, classes.headingBold) : classes.heading}>Business 101 Assessment</Typography> */}
                {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
                <Grid container>
                  {isEmpty(assessmentResult) ? (
                    <Grid item xs={2} md={2} className="colorSpace1">
                      <div className="iconCard">
                        <ClearIcon className="Icon" />
                      </div>
                      {/* <ClearIcon className='Icon'/> */}
                    </Grid>
                  ) : (
                    <Grid item xs={2} md={2} className="colorSpace2">
                      <div className="iconCard">
                        <DoneIcon className="Icon" />
                      </div>
                      {/* <DoneIcon className='Icon'/> */}
                    </Grid>
                  )}
                  <Grid item xs={7} md={7}>
                    <Typography
                      className={
                        expanded === "panel1"
                          ? clsx(classes.heading, classes.headingBold)
                          : classes.heading
                      }
                    >
                      Business 101 Assessment
                    </Typography>
                  </Grid>

                  <Grid item xs={3} md={3}>
                    <Typography
                      className={
                        expanded === "panel1"
                          ? clsx(classes.heading, classes.headingBold)
                          : classes.heading
                      }
                    >
                      Assessment Taken : {assessmentResult?.length}
                    </Typography>
                  </Grid>
                </Grid>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <Grid container>
                  {isEmpty(assessmentResult) ? (
                    <Grid item xs={12}>
                      <div className={classes.newBusinessContainer}>
                        <ButtonField
                          color="#003399"
                          name="Assessment test"
                          onClick={() =>
                            (window.location.href = `${
                              process.env.REACT_APP_CAPABILITY_URL
                            }/menu/ibridge/${USER_TOKEN()}/test/39/Business 101/Business 101/dashboard`)
                          }
                        />
                      </div>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <div className={classes.againNewBusinessTest}>
                        {assessmentResult?.length < 2 ? (
                          <ButtonField
                            color="#003399"
                            name="Assessment test"
                            onClick={() =>
                              (window.location.href = `${
                                process.env.REACT_APP_CAPABILITY_URL
                              }/menu/ibridge/${USER_TOKEN()}/test/39/Business 101/Business 101/dashboard`)
                            }
                          />
                        ) : null}
                      </div>
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className={classes.tableHeading}
                                align="left"
                              >
                                Skill Level
                              </TableCell>
                              <TableCell
                                className={classes.tableHeading}
                                align="center"
                              >
                                Topic
                              </TableCell>
                              <TableCell
                                className={classes.tableHeading}
                                align="center"
                              >
                                Date
                              </TableCell>
                              <TableCell
                                className={classes.tableHeading}
                                align="right"
                              >
                                Result
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {assessmentResult.map((list) => (
                              <TableRow key={list.id}>
                                <TableCell align="left">
                                  {list.skill_level}
                                </TableCell>
                                <TableCell align="center">
                                  {list.topicname}
                                </TableCell>
                                <TableCell align="center">
                                  {list.insertedDate}
                                </TableCell>
                                <TableCell align="right">
                                  {list.tresult}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}
                </Grid>
              </MuiAccordionDetails>
            </MuiAccordion>
            <MuiAccordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <MuiAccordionSummary
                className={classes.accordionSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                {/* <Typography className={expanded === 'panel4' ? clsx(classes.heading, classes.headingBold) :classes.heading}>Book For Counselling</Typography> */}
                {/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
                <Grid container>
                  {isEmpty(counsellingRequest) ? (
                    <Grid item xs={2} md={2} className="colorSpace1">
                      <div className="iconCard">
                        <ClearIcon className="Icon" />
                      </div>
                      {/* <ClearIcon className='Icon'/> */}
                    </Grid>
                  ) : (
                    <Grid item xs={2} md={2} className="colorSpace2">
                      <div className="iconCard">
                        <DoneIcon className="Icon" />
                      </div>
                      {/* <DoneIcon className='Icon'/> */}
                    </Grid>
                  )}
                  <Grid item xs={7} md={7}>
                    <Typography
                      className={
                        expanded === "panel4"
                          ? clsx(classes.heading, classes.headingBold)
                          : classes.heading
                      }
                    >
                      Book For Counselling
                    </Typography>
                  </Grid>

                  <Grid item xs={3} md={3}>
                    <Typography
                      className={
                        expanded === "panel4"
                          ? clsx(classes.heading, classes.headingBold)
                          : classes.heading
                      }
                    >
                      Scheduled Counselling : {counsellingRequest?.length}
                    </Typography>
                  </Grid>
                </Grid>
              </MuiAccordionSummary>
              <MuiAccordionDetails>
                <Container maxWidth="lg">
                  {!isEmpty(counsellingRequest) && (
                    <Grid container spacing={4}>
                      {counsellingRequest?.map((list) => (
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                          <Card className={classes.cardRoot}>
                            <CardContent>
                              <div className={classes.title}>
                                <Typography className={classes.titleBold}>
                                  Education :
                                </Typography>
                                <Typography>{list?.education}</Typography>
                              </div>
                              <div className={classes.title}>
                                <Typography className={classes.titleBold}>
                                  City :
                                </Typography>
                                <Typography>{list?.city}</Typography>
                              </div>
                              <div className={classes.title}>
                                <Typography className={classes.titleBold}>
                                  Scheduled Date & Time :
                                </Typography>
                                <Typography>
                                  {getDateTime(list?.scheduledDateTime)}
                                </Typography>
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <ButtonField
                          color="#003399"
                          name="Book another counselling"
                          onClick={() => setAnotherCounselling(true)}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {(!anotherCounselling && isEmpty(counsellingRequest)) ||
                  (anotherCounselling && !isEmpty(counsellingRequest)) ? (
                    <Counselling
                      user={user}
                      sendCounsellingRequest={sendCounsellingRequest}
                    />
                  ) : null}
                </Container>
              </MuiAccordionDetails>
            </MuiAccordion>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired,
  getAssessmentResult: PropTypes.func.isRequired,
  getPsychometricQuestions: PropTypes.func.isRequired,
  postPsychometricQuestions: PropTypes.func.isRequired,
  getPsychometricResultAnswers: PropTypes.func.isRequired,
  sendCounsellingRequest: PropTypes.func.isRequired,
  getCounsellingRequest: PropTypes.func.isRequired,
  getRIASECTestResultByUserId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  dashboard: state.dashboard,
  RIASECTestReducer: state.RIASECTestReducer,
});

const mapDispatchToProps = {
  getAssessmentResult,
  getPsychometricQuestions,
  postPsychometricQuestions,
  getPsychometricResultAnswers,
  sendCounsellingRequest,
  getCounsellingRequest,
  postUserAccessLog,
  getRIASECTestResultByUserId,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

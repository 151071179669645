// import React from 'react';
// import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
// import LinearProgress from '@material-ui/core/LinearProgress';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';

// function LinearProgressWithLabel(props) {
//   return (
//     <Box display="flex" alignItems="center">
//       <Box width="100%" mr={1}>
//         <LinearProgress variant="determinate" {...props} />
//       </Box>
//       <Box minWidth={35}>
//         <Typography variant="body2" color="textSecondary">{`${Math.round(
//           props.value,
//         )}%`}</Typography>
//       </Box>
//     </Box>
//   );
// }

// LinearProgressWithLabel.propTypes = {
//   /**
//    * The value of the progress indicator for the determinate and buffer variants.
//    * Value between 0 and 100.
//    */
//   value: PropTypes.number.isRequired,
// };

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '30%',
//     marginLeft: "36%" ,
//     marginTop: "3%"
//   },
//   bar:{
//     height: 10,
//     borderRadius: 5,
//     backgroundColor: theme.palette.grey[theme.palette.type === 'dark' ? 200 : 700],
//     borderRadius: 5,
//    },

//    bar:{
//     marginTop : 15,
//     marginLeft:90,
//     height: 5,
//     borderRadius: 5,
//     backgroundColor: theme.palette.grey[theme.palette.type === 'dark' ? 200 : 700],
//     backgroundColor: 'grey',

//    },
//    typography:{
//     marginTop : 10,
//     marginLeft:90,
//      fontSize:12
//    }
// }));

// export default function Progress() {
//   const classes = useStyles();
//   const [progress, setProgress] = React.useState(10);

//   React.useEffect(() => {
//     const timer = setInterval(() => {
//       setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
//     }, 800);
//     return () => {
//       clearInterval(timer);
//     };
//   }, []);

//   return (
//     <div className={classes.root}>
//     <Typography className="heading" nowrap>  RDBMS(MySql) </Typography>
//     <div className="linearbar" >
//       <LinearProgressWithLabel value={progress} className={classes.bar} />
//       </div>
//       <Typography  className={classes.typography}>  Time remain:</Typography>
//       <br/>
//     <Typography className="heading" nowrap>  AWS</Typography>
//    <div className="linearbar">
//       <LinearProgressWithLabel value={progress} className={classes.bar} />
//       </div>
//       <Typography  className={classes.typography}> Time remain: </Typography>
//       <br/>
//     <Typography className="heading" nowrap> Software Application Development&SDLC </Typography>
//     <div className="linearbar">
//       <LinearProgressWithLabel value={progress} className={classes.bar} />
//       </div>
//       <Typography  className={classes.typography}>  Time remain: </Typography>

//     </div>
//   );
// }

// import React, {useState} from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import {Grid, Button} from '@material-ui/core';
// import Typography from '@material-ui/core/Typography';
// import LinearProgress from "@material-ui/core/LinearProgress";
// import Box from '@material-ui/core/Box';
// // import ToggleButton from './ToggleButton';

// function LinearProgressWithLabel(props) {
//       return (
//         <Box display="flex" alignItems="center">
//           <Box width="100%" mr={1}>
//             <LinearProgress variant="determinate" {...props} />
//           </Box>
//           <Box minWidth={35}>
//             <Typography variant="body2" color="textSecondary">{`${Math.round(
//               props.value,
//             )}%`}</Typography>
//           </Box>
//         </Box>
//       );
//     }

// // LinearProgressWithLabel.propTypes = {
// //   value: PropTypes.number.isRequired,
// // };

// const useStyles = makeStyles((theme) => ({
//       root: {
//         width: '60%',
//         marginLeft: "16%" ,
//         marginTop: "3%"
//       },
//       bar:{
//         height: 10,
//         borderRadius: 5,
//         backgroundColor: theme.palette.grey[theme.palette.type === 'dark' ? 200 : 700],
//         borderRadius: 5,
//        },

//        bar:{
//         marginTop : 15,
//         marginLeft:90,
//         height: 5,
//         borderRadius: 5,
//         backgroundColor: theme.palette.grey[theme.palette.type === 'dark' ? 200 : 700],
//         backgroundColor: 'grey',

//        },
//        typography:{
//         marginTop : 10,
//         marginLeft:90,
//          fontSize:12
//        }
//     }));

// export default function Progress() {
//   const classes = useStyles();
//   const [progress, setProgress] = React.useState(10);

//   const types = [ "Quality Assurance", " Data Engineering","Quality Assurance", " Data Engineering"];
//   const [active, setActive] = useState(types[0]);

//   React.useEffect(() => {
//         const timer = setInterval(() => {
//           setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
//         }, 800);
//         return () => {
//           clearInterval(timer);
//         };
//       }, []);

//   return (
//    <>
//    <container>
//      <Grid container>
//         {types.map((type) => (
//           <Grid item xs={4} md={4}>
//             <Button active={active === type} onClick={() => setActive(type)}>
//               {type}
//             </Button>
//           </Grid>
//         ))}
//      </Grid>
//      <Grid container>
//        {/* { */}
//         <Grid item xs={6} md={3}>
//           <Typography>Data Engineering</Typography>
//         </Grid>
//         <Grid item xs={6} md={3}>
//         <LinearProgress value={50} className="progressBar"variant="determinate" />
//         </Grid>
//        {/* } */}
//      </Grid>
//    </container>
//    </>
//   );
// }

import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Button, Typography } from "@material-ui/core";
import { Container } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getLearnerProgressInfo } from "../../redux/actions/admin_view/mvkActions";
import CustomizedDialogs from "../../reusableComponents/DialogModal";
import { Avatar } from "@material-ui/core";
import UserProgressReport from "../Learner_Module/UserProgressReport";
import SendIcon from "@material-ui/icons/Send";

const Progress = ({
  enrolledPrograms,
  userEmail,
  getLearnerProgressInfo,
  adminMvk,
  learnerInfo,
}) => {
  // const types = [ "Quality Assurance", " Data Engineering","Business", " React"];
  const [active, setActive] = useState(
    enrolledPrograms[0] ? enrolledPrograms[0] : {}
  );
  const learnerProgress = adminMvk?.learnerProgress;

  const [open, setOpen] = useState(false);
  const [course, setCourse] = useState("");

  useEffect(() => {
    if (enrolledPrograms?.length > 0) {
      setActive(enrolledPrograms[0]);
      const data = {
        email: learnerInfo?.userDetails?.email || " ",
        programId: active?._id,
      };
      getLearnerProgressInfo(data);
    }
  }, []);

  // console.log("learnerProgress", learnerProgress);
  useEffect(() => {
    if (active._id) {
      const data = {
        email: learnerInfo?.userDetails?.email || " ",
        programId: active?._id,
      };
      getLearnerProgressInfo(data);
    }
  }, [active?._id]);
  return (
    <>
      <Container>
        <Grid container style={{ marginTop: "4%" }} spacing={2}>
          {enrolledPrograms?.map((list) => (
            <Grid item xs={4} md={3}>
              <Button
                // active={active === type}
                onClick={() => {
                  setActive(list);
                  setCourse(list);
                }}
                // className={active ? activeStyle : nonActiveStyle}
                style={
                  active.name === list.name
                    ? {
                        backgroundColor: "#18479f",
                        height: "60px",
                        width: "80%",
                        color: "white",
                      }
                    : {
                        backgroundColor: "#b8c7e4",
                        height: "60px",
                        width: "80%",
                        color: "#19479c",
                      }
                }
              >
                {list.name}
              </Button>
            </Grid>
          ))}
        </Grid>

        <Grid container style={{ marginTop: "4%" }}>
          <Grid item xs={12} md={12}>
            {learnerProgress?.map((item) => (
              <>
                <Grid container style={{ marginTop: "1%" }}>
                  <Grid item xs={2} md={2} align="left">
                    <Typography> {item.name} </Typography>
                  </Grid>
                  <Grid item xs={4} md={4} align="left">
                    <LinearProgress
                      value={item.percentage > 100 ? 100 : item.percentage}
                      className="progressBar"
                      variant="determinate"
                      style={{ marginTop: "3%", paddingTop: "2%" }}
                    />
                  </Grid>
                  <Grid item xs={1} md={1} align="left">
                    <p>{`${item.percentage > 100 ? 100 : item.percentage}%`}</p>
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid>

          <Grid item xs={12} md={12} style={{ marginTop: "2%" }} align="center">
            {course === "" ? (
              <Button
                variant="contained"
                color="primary"
                style={{ padding: "1%", textTransform: "none" }}
                // onClick={() => history.push("/admin/userProgressReport")}
                // onClick={() => setOpen(true)}
              >
                Please click on a Program to see report
                {/* <SendIcon style={{padding:"1%"}}/> */}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{ padding: "1%", textTransform: "none" }}
                // onClick={() => history.push("/admin/userProgressReport")}
                onClick={() => setOpen(true)}
              >
                View {course.name} Report
                {/* <SendIcon style={{padding:"1%"}}/> */}
              </Button>
            )}
          </Grid>
        </Grid>

        <CustomizedDialogs
          title={
            <Grid container>
              <Grid item xs={6} md={6} style={{ backgroundColor: "white" }}>
                <Grid container>
                  <Grid item xs={2} md={2}>
                    <Avatar style={{ height: "70px", width: "70px" }} />
                  </Grid>

                  <Grid item xs={10} md={10}>
                    <Grid container>
                      <Grid item xs={12} md={12}>
                        <Typography>
                          {`${learnerInfo?.userDetails?.firstName} ${learnerInfo?.userDetails?.lastName}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Typography>{course?.name}</Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Typography>
                          {`${course?.programStartDate} - ${course?.programEndDate}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                align="right"
                style={{ paddingTop: "2%", paddingRight: "-10%" }}
              >
                {/* <Button
                        variant="contained" 
                        color="primary"
                        style={{height:"40px",width:"140px",marginRight:"-200px"}}
                    >
                        Send
                        <SendIcon style={{padding:"1%",paddingLeft:"4%"}}/>
                    </Button> */}
              </Grid>
            </Grid>
          }
          children={
            <UserProgressReport
              course={course?._id}
              learnerInfo={learnerInfo?.userDetails?._id}
            />
          }
          openPopup={open}
          setOpenPopup={setOpen}
        />
      </Container>
    </>
  );
};

Progress.propTypes = {
  auth: PropTypes.object.isRequired,
  getLearnerProgressInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  adminMvk: state.adminMvk,
});

const mapDispatchToProps = { getLearnerProgressInfo };

export default connect(mapStateToProps, mapDispatchToProps)(Progress);

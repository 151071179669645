import React, { useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import Logo from "../../images/logo/ibridge.png";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import ButtonField from "../../reusableComponents/ButtonField";
import DateTimePick from "../../reusableComponents/DateTimePick";
import isEmpty from "../../reusableComponents/is-empty";
import axios from "axios";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const Counselling = ({ user, sendCounsellingRequest }) => {
  const today = new Date();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const formik = useFormik({
    initialValues: {
      education: "",
      city: "",
      phoneNumber: "",
      scheduledDateTime: "",
      reason: "",
    },
    validationSchema: Yup.object({
      education: Yup.string().trim().required("Education is required field"),
      city: Yup.string().trim().required("City is required field"),
      phoneNumber: Yup.string()
        // .matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required field'),
        .length(10, "Phone Number must have 10 numbers")
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone number is required field"),
      scheduledDateTime: Yup.string().required(
        "Scheduled Date & Time is required field"
      ),
      reason: Yup.string().trim().required("Reason is required field"),
    }),
    onSubmit: async (values) => {
      // async function displayRazorpay() {

      //     const res = await loadScript(
      //         "https://checkout.razorpay.com/v1/checkout.js"
      //     );

      //     if (!res) {
      //         alert("Razorpay SDK failed to load!!!");
      //     }

      //     const result = await axios.post('http://localhost:5050/api/checkout/razorpay')

      //     console.log(result);

      //     const options = {
      //         key: "rzp_test_huS8zrQkeOdtoP",
      //         currency: result?.data?.currency,
      //         amount: result?.data?.amount?.toString(),
      //         order_id: result?.data?.id,
      //         name: "Course Fee",
      //         description: "Thank you for purchasing the course",
      //         image: "https://ibridge.s3.us-east-2.amazonaws.com/ibridge.png",
      //         handler: function (response) {
      //             console.log(response);
      //             alert(
      //                 `Your payment has been successfully recieved. Your transaction id is ${response}`
      //             );
      //         },
      //         prefill: {
      //             name: "Anantha",
      //             email: "ananthamadhava95@gmail.com",
      //             phone_number: "9899999999",
      //         }
      //     };
      //     console.log(options);

      //     const paymentObject = await new window.Razorpay(options);
      //     await paymentObject.open();
      const counselling = JSON.parse(JSON.stringify(values));
      counselling.phoneNumber = parseInt(values.phoneNumber);
      await sendCounsellingRequest(counselling);
      // }
      // displayRazorpay();
    },
  });

  // console.log(user)
  useEffect(() => {
    if (
      !isEmpty(user.education) &&
      !isEmpty(user.city) &&
      !isEmpty(user.phoneNumber)
    ) {
      formik.setFieldValue("education", user.education);
      formik.setFieldValue("city", user.city);
      formik.setFieldValue("phoneNumber", user.phoneNumber.toString());
    }
  }, [user]);

  return (
    // <Container maxWidth="lg">
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="Education"
            type="text"
            name="education"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.education}
            errors={
              formik.touched.education && formik.errors.education
                ? formik.errors.education
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="City"
            type="text"
            name="city"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
            errors={
              formik.touched.city && formik.errors.city
                ? formik.errors.city
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="Phone Number"
            type="text"
            name="phoneNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
            errors={
              formik.touched.phoneNumber && formik.errors.phoneNumber
                ? formik.errors.phoneNumber
                : null
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className="inputFieldContainer dateTimePickerContainer"
        >
          <DateTimePick
            placeholder="Schedule your Date & Time *"
            name="scheduledDateTime"
            showLable={true}
            minDate={today}
            labelShink={false}
            className="dateTimePicker"
            inputVariant="outlined"
            selectedDate={
              isEmpty(formik.values.scheduledDateTime)
                ? null
                : formik.values.scheduledDateTime
            }
            handleDateChange={(scheduledDateTime) => {
              const scheduleDateTime = moment(scheduledDateTime).toISOString();
              console.log(scheduleDateTime);
              formik.setFieldValue("scheduledDateTime", scheduleDateTime);
            }}
            errors={
              formik.touched.scheduledDateTime &&
              formik.errors.scheduledDateTime
                ? formik.errors.scheduledDateTime
                : null
            }
          />
        </Grid>
        <Grid item xs={12} className="inputFieldContainer">
          <TextFieldGroup
            label="Reason for counselling"
            type="text"
            name="reason"
            multiline
            rows={4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.reason}
            errors={
              formik.touched.reason && formik.errors.reason
                ? formik.errors.reason
                : null
            }
          />
        </Grid>
      </Grid>
      <div className="submitButton">
        <ButtonField
          buttonStyle="formSubmit"
          type="submit"
          name="Book your Counselling"
          color="#003399"
        />
      </div>
    </form>
    // </Container>
  );
};

export default Counselling;

import {
  GET_RIASEC_TEST_QUESTIONS,
  PROGRAM_LOADING,
  GET_RIASEC_RESULT_BY_USER_ID,
} from "../actions/types";

const initialState = {
  loading: false,
  riasecTestQuestionsList: [],
  riasecResultByUserId: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROGRAM_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_RIASEC_TEST_QUESTIONS:
      return {
        ...state,
        riasecTestQuestionsList: action.payload,
        loading: false,
      };

    case GET_RIASEC_RESULT_BY_USER_ID:
      return {
        ...state,
        riasecResultByUserId: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

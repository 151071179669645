import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment, IconButton, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { BsExclamationDiamond } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: 0,
    marginBottom: 5,
  },
  textField: {
    width: "100%",
  },
}));

const TextFieldGroup = ({
  label,
  heading,
  type,
  inputClassName,
  className,
  startAdornment,
  name,
  size,
  value,
  multiline,
  rows,
  varient,
  disabled,
  onChange,
  onBlur,
  onFocus,
  onKeyPress,
  onKeyDown,
  autoFocus,
  labelShink,
  refs,
  showLable,
  errors,
  showPassword,
  passwordControl,
  placeholder,
  renderValue,
  required = true,
}) => {
  let icon = null;
  if (name === "password" || name === "confirmPassword") {
    icon = (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={passwordControl}
          onMouseDown={(text) => text}
          size="small"
        >
          {showPassword ? (
            <Visibility fontSize="small" />
          ) : (
            <VisibilityOff fontSize="small" />
          )}
        </IconButton>
      </InputAdornment>
    );
  }

  const errorText = (
    <p>
      <BsExclamationDiamond /> {errors}
    </p>
  );
  return (
    <>
      {showLable && <div className="fieldHeading">{heading}</div>}
      <TextField
        label={label}
        className={inputClassName}
        type={type}
        variant={varient || "outlined"}
        margin="dense"
        required={required}
        placeholder={placeholder}
        inputRef={refs}
        id={name}
        size={size || "small"}
        name={name}
        disabled={disabled || false}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus}
        fullWidth
        value={value}
        renderValue={renderValue}
        multiline={multiline}
        rows={rows}
        InputLabelProps={{ shrink: labelShink }}
        error={errors ? true : false}
        helperText={errors ? errorText : null}
        InputProps={{
          endAdornment: icon,
          className: className,
          startAdornment: startAdornment,
        }}
      />
    </>
  );
};

export default TextFieldGroup;

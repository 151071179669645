import React, { Component } from "react";

import { Container, Grid, Typography } from "@material-ui/core";

const RefundPolicy = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "24px", fontWeight: "600" }}
          >
            Refunds
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            Our policy lasts 30 days. If 30 days have gone by since your
            purchase, unfortunately we can’t offer you a refund or exchange.
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            To be eligible for a return, your item must be unused and in the
            same condition that you received it.To complete your return, we
            require a receipt or proof of purchase.
          </Typography>

          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            Refunds (if applicable)
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            Once your return is received and inspected, we will send you an
            email to notify you that we have received your returned item. We
            will also notify you of the approval or rejection of your refund.
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            If you are approved, then your refund will be processed within 5
            working days.
          </Typography>

          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            Late or missing refunds (if applicable)
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            If you haven’t received a refund yet, first check your bank account
            again.
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            Then contact your credit card company, it may take some time before
            your refund is officially posted.
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            Next contact your bank. There is often some processing time before a
            refund is posted.
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            If you’ve done all of this and you still have not received your
            refund yet, please contact us at: support@ibridge360.com .
          </Typography>

          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            Exchanges (if applicable)
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            We only replace items if they are defective or damaged. If you need
            to exchange it for the same item, send us an email at :
            support@ibridge360.com .
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default RefundPolicy;

import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar } from "@material-ui/core";
// import Table from '../../MaterialTable/Table';
import MUITable from "../../MaterialTable/MaterialTable";
import { getServiceProviderList } from "../../../redux/actions/admin_view/serviceProviderList";
import PageHeader from "../../../reusableComponents/PageHeader";
import { ListAlt as ListAltIcon } from "@material-ui/icons";
import { getDateTime } from "../../../reusableComponents/GetDate";
import { Search as SearchIcon, Add as AddIcon } from "@material-ui/icons";
import ButtonField from "../../../reusableComponents/ButtonField";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

const ServiceProviderList = ({
  getServiceProviderList,
  auth,
  adminServiceProvider,
  history,
}) => {
  const classes = useStyles();
  const { isAuthenticated } = auth;
  const { serviceProviderList } = adminServiceProvider;

  useEffect(() => {
    if (isAuthenticated) {
      getServiceProviderList();
    }
  }, [isAuthenticated]);

  const columns = [
    {
      title: "Photo",
      field: "userImage",
      render: (rowData) => (
        <Avatar
          alt=""
          src={rowData?.userImage}
          className={classes.tableImage}
        />
      ),
    },
    {
      title: "Service Provider Name",
      field: "providerName",
    },
    {
      title: "Email ID",
      field: "email",
    },
    {
      title: "Provider GST",
      field: "providerGST",
    },
    {
      title: "Pancard",
      field: "panCard",
    },
    {
      title: "Generated On",
      field: "createdAt",
    },
  ];

  const data = serviceProviderList?.map((list) => {
    const {
      _id,
      providerName,
      email,
      providerGST,
      panCard,
      userImage,
      createdAt,
    } = list;
    return {
      _id,
      userImage: userImage?.link,
      providerName,
      email,
      providerGST,
      panCard,
      createdAt: getDateTime(createdAt),
    };
  });

  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MUITable
            title={
              <ButtonField
                variant="outlined"
                name="Add New Service Provider"
                icon={<AddIcon />}
                onClick={() => history.push("/admin/newServiceProvider")}
              />
            }
            columns={columns}
            data={data}
            pageSize={10}
            selectedRow={(row) =>
              history.push(`/admin/serviceProviderInfo/${row._id}`)
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
};

ServiceProviderList.propTypes = {
  auth: PropTypes.object.isRequired,
  getServiceProviderList: PropTypes.func.isRequired,
  adminServiceProvider: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  adminServiceProvider: state.adminServiceProvider,
});

const mapDispatchToProps = { getServiceProviderList };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceProviderList);

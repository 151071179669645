import React,{ useState, useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Grid, Button, Container } from '@material-ui/core';
import TextFieldGroup from '../../reusableComponents/TextFieldGroup';

import { ProfileResetPassword } from '../../redux/actions/authActions';

const LearnerResetPassword = ({
    ProfileResetPassword,
    setOpen,
    auth, 
    history
}) => {

    const { isAuthenticated, user } = auth;
    const [showPassword, setShowPassword] = useState(false);

    console.log("history in comp",history)

    const formik = useFormik({
        initialValues: {
            password:"",
            confirmPassword:"", 
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .matches('(?=.*[a-z])', 'Password must contain at least 1 Lower Case character')
                .matches('(?=.*[A-Z])', 'Password must contain at least 1 Upper Case character')
                .matches('(?=.*[0-9])', 'Password must contain at least 1 Number')
                .matches('(?=.*[!@#\$%\^&\*])', 'Password must contain at least 1 special character')
                .required('Password is required field'),
            confirmPassword: Yup.string()
                .required("Password is required field")
                .oneOf([Yup.ref("password"), null], "Passwords must match"),
        }),
        onSubmit: async(values) => {
            setOpen(false)
            await ProfileResetPassword(values,history);
        }
        // onSubmit: async(values) => {
        //         console.log(values)
        //     }
    });


    return ( 
        <>
            <form
                onSubmit={formik.handleSubmit}
                noValidate
                autoComplete="off"
                style={{ paddingTop: "10px" }}
            >
            <Grid container justifyContent="center">
                <Grid item xs={12} md={6} className="inputFieldContainer">
                    <TextFieldGroup 
                        label="New Password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        passwordControl={() => setShowPassword(!showPassword)}
                        showPassword={showPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        errors={formik.touched.password && formik.errors.password ? formik.errors.password : null}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" style={{paddingTop:"2%"}}>
                <Grid item xs={12} md={6} className="inputFieldContainer">
                    <TextFieldGroup 
                        label="Confirm Password"
                        type={showPassword ? "text" : "password"}
                        name="confirmPassword"
                        passwordControl={() => setShowPassword(!showPassword)}
                        showPassword={showPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                        errors={formik.touched.confirmPassword && formik.errors.confirmPassword ? formik.errors.confirmPassword : null}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" style={{paddingTop:"2%"}}>
                <Grid item xs={12} md={6} className="inputFieldContainer" align="center">
                    <Button
                        // buttonStyle="formSubmit"
                        type="submit"
                        // className='editFormSubmit'
                        name="SIGN UP"
                        variant="contained"
                        color="primary"
                        align="center"
                        style={{width:"60%"}}
                        // onClick={ () => setOpen(false)}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
            </form>
        </>
     );
}

LearnerResetPassword.propTypes = {
    auth: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  
  const mapDispatchToProps = { 
    ProfileResetPassword 
    };
  
  export default connect(mapStateToProps, mapDispatchToProps)(LearnerResetPassword);
 
// export default LearnerResetPassword;
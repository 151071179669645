import React, { useState, useEffect } from "react";

// import "./App.css";
// import { ZoomMtg } from "@zoomus/websdk";

// ZoomMtg.setZoomJSLib("https://source.zoom.us/2.9.5/lib", "/av");

// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareWebSDK();
// // loads language files, also passes any error messages to the ui
// ZoomMtg.i18n.load("en-US");
// ZoomMtg.i18n.reload("en-US");

const ZoomMeeting = ({ user, scheduleInfo }) => {
  const [signature, setSignature] = useState(null);
  console.log(scheduleInfo);
  let signatureEndpoint = `${process.env.REACT_APP_RAZORPAY_URL}api/zoomMeeting/getSignature`;
  // This Sample App has been updated to use SDK App type credentials https://marketplace.zoom.us/docs/guides/build/sdk-app
  let sdkKey = process.env.REACT_APP_ZOOM_KEY;
  let meetingNumber = scheduleInfo?.zoomMeetingId;
  let passWord = scheduleInfo?.zoomMeetingPassword;
  let role = user?.role?.r_id !== 2 ? 1 : 0;
  let leaveUrl = "/sign-in";
  let userName = user?.firstName;
  let userEmail = user?.email;
  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
  let registrantToken = "";

  useEffect(() => {
    if (scheduleInfo) {
      getSignature();
    }
  }, [scheduleInfo]);

  const startMeeting = (signature) => {
    const ibridgeChat = ``;
    document.getElementById("zmmtg-root").style.display = "block";

    // ZoomMtg.init({
    //   leaveUrl: leaveUrl,
    //   success: (success) => {
    //     console.log("success", success);

    //     ZoomMtg.join({
    //       signature: signature,
    //       meetingNumber: meetingNumber,
    //       userName: userName,
    //       sdkKey: sdkKey,
    //       userEmail: userEmail,
    //       passWord: passWord,
    //       tk: registrantToken,
    //       success: (success) => {
    //         console.log("success", success);
    //       },
    //       error: (error) => {
    //         console.log("error", error);
    //       },
    //     });
    //   },
    //   error: (error) => {
    //     console.log("error", error);
    //   },
    // });
  };

  const getSignature = () => {
    // e.preventDefault();

    fetch(signatureEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setSignature(response?.signature);
        startMeeting(response?.signature);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      {!signature && (
        <h3 style={{ marginTop: "50px", textAlign: "center", color: "blue" }}>
          Session is Loading...
        </h3>
      )}
      {/* <div style={{ zIndex: "902" }} id="ibridgechat">
        <div
          style={{
            position: "sticky",
            zIndex: "903",
            width: "10%",
            height: "5%",
            right: 50,
            bottom: 0,
            background: "#ccc",
          }}
        >
          Ibridge Chat
        </div>
      </div> */}
    </>
  );
};

export default ZoomMeeting;

import axios from 'axios';
import { ADMIN_DASHBOARD_LOADING, ADMIN_GET_DASHBOARD_COUNT } from '../types';
import { SnackBar } from '../../../reusableComponents/SnackbarConfig';

// Get Dashboard Count
export const getdashboardCount = () => dispatch => {
    dispatch(dashboardLoading());
    axios.get('/api/adminDashboard/usersCounsellingCount')
        .then(res => 
            dispatch({
                type: ADMIN_GET_DASHBOARD_COUNT,
                payload: res?.data?.message
            })    
        )
        .catch(err => {
            const { message } = err?.response?.data;
            SnackBar.error(message);
        })
}

// Dashboard Loading
export const dashboardLoading = () => {
    return {
        type : ADMIN_DASHBOARD_LOADING
    }
}
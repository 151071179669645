import isEmpty from "../../reusableComponents/is-empty";
import { SCHEDULE_LOADING, GET_CHATGPT_JOB_TYPES, SET_USER_SKILLS, SET_USER_SKILLS_LOCATION, CLEAR_JOB_TYPES_DATA } from "../actions/types";

const initialState = {
  location: "",
  userSkills: "",
  jobTypesData: "",
  visitorEmail: "",
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SCHEDULE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_USER_SKILLS_LOCATION:
      return{
        ...state, 
        location: action.payload.location,
        userSkills: action.payload.userSkills,
        visitorEmail: action.payload.visitorEmail
      };
    case GET_CHATGPT_JOB_TYPES:
      return {
        ...state,
        jobTypesData: action.payload,
        loading: false,
      };
    case CLEAR_JOB_TYPES_DATA:
      return {
        ...state,
        location: "",
        userSkills: "",
        jobTypesData: "",
        visitorEmail: "",
        loading: false
      }
    default:
      return state;
  }
}

import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { resetPassword } from "../../redux/actions/authActions";
import AuthContainerView from "./AuthContainerView";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import { useSnackbar } from "notistack";
import { Grid, Typography } from "@material-ui/core";
import ButtonField from "../../reusableComponents/ButtonField";
import { useFormik } from "formik";

const PasswordReset = ({ resetPassword, auth, match, history }) => {
  const recaptcha = useRef();
  const [error, setError] = useState("");
  const [tok, setToken] = useState(false);
  const { token } = match.params;
  const { enqueueSnackbar } = useSnackbar();

  document.title = "Reset Password - iBridge360";

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required field")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i,
          "min 8 characters with 1 (lower, upper & symbol)"
        ),
      confirmPassword: Yup.string()
        .required("Password is required field")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: async (values) => {
      values.token = token;
      await resetPassword(values, history, enqueueSnackbar);
    },
  });

  const renderForm = (
    <div className="forgotPasswordContainer">
      <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
    <Typography className="headingText" variant="h6">Reset Password</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} className="inputFieldContainer">
            <TextFieldGroup
              label="New Password"
              type={showPassword ? "text" : "password"}
              name="password"
              passwordControl={() => setShowPassword(!showPassword)}
              showPassword={showPassword}
              autoFocus
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              errors={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : null
              }
            />
            <TextFieldGroup
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              passwordControl={() =>
                setShowConfirmPassword(!showConfirmPassword)
              }
              showPassword={showConfirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              errors={
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? formik.errors.confirmPassword
                  : null
              }
            />
          </Grid>
        </Grid>
        <div className="submitButton signUpSubmit">
          <ButtonField
            buttonStyle="formSubmit"
            type="submit"
            name="Reset Password"
          />
        </div>
      </form>
    </div>
  );

  return <AuthContainerView content={renderForm} from="passwordReset" />;
};

PasswordReset.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  auth: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { resetPassword };

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button as MuiButton } from "@material-ui/core";
import { $themeBlue } from "./Colors";

export default function ButtonField({
  name,
  type,
  variant,
  buttonStyle,
  onClick,
  icon,
  component,
  fileTag,
  color,
  disabled,
}) {
  const ColorButton = withStyles((theme) => ({
    root: {
      color: "#fff",
      backgroundColor: color ? color : $themeBlue ,
      fontWeight: 600,
      textTransform: "capitalize",
      letterSpacing: 1,
      "&:hover": {
        backgroundColor: "#008dd2",
      },
    },
  }))(MuiButton);
  return (
    <ColorButton
      type={type}
      variant={variant || "contained"}
      component={component}
      color="primary"
      className={buttonStyle}
      onClick={onClick}
      disabled={disabled}
    >
      {icon} {name} {fileTag}
    </ColorButton>
  );
}

import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Container, Grid, Typography } from "@material-ui/core";
import MUITable from "../../MaterialTable/MaterialTable";
import { getScheduleReportList } from "../../../redux/actions/scheduleActions";

const SchedulingReport = ({
  // getCounsellingList,
  auth,
  history,
  getScheduleReportList,
  schedules,
}) => {
  const { isAuthenticated } = auth;
  const { scheduleReportList } = schedules;
  // console.log("scheduleReportList",scheduleReportList)

  useEffect(() => {
    if (isAuthenticated) {
      getScheduleReportList();
    }
  }, [isAuthenticated]);

  // const handleLinkOnClick= (repoLink) => {
  //   window.open(
  //     // `${process.env.REACT_APP_ILEARN_URL}/ibridge/${USER_TOKEN()}/${
  //     //   course._id
  //     // }`,
  //     `${repoLink}`,
  //     "_blank"
  //   );
  // };

  // console.log('in component',counsellingList)

  const columns = [
    {
      title: "Schedule Title",
      field: "scheduleName",
    },
    {
      title: "Learner Name",
      field: "learnerName",
    },
    // {
    //   title: "Schedule Start Date",
    //   field: "scheduleStartDate",
    // },
    // {
    //   title: "Schedule End Date",
    //   field: "scheduleStartDate",
    // },
    {
      title: "Program batch",
      field: "programBatch",
    },
    {
      title: "Task Type",
      field: "taskType",
    },
    {
      title: "Completed Time",
      field: "completedTime",
    },
    {
      title: "Project Link",
      field: "taskLink",
    },
    // {
    //     title: 'Task Completed',
    //     field: 'taskCompleted'
    // },
    // {
    //     title: 'Reason',
    //     field: 'reason'
    // }
  ];

  const adminData = scheduleReportList?.map((list) => {
    const { schedule, updatedAt, userId, programBatch } = list;
    const date = new Date(updatedAt);
    return {
      scheduleName: schedule.name,
      learnerName: `${userId.firstName} ${userId.lastName}`,
      programBatch:programBatch?.name,
      taskType: schedule?.taskType,
      completedTime: date.toString().slice(0, 24),
      taskLink:<Typography 
                // onClick={()=>handleLinkOnClick(list?.taskLink)}
              >
                {list?.taskLink}
              </Typography>

      // scheduleStartDate: new Date(schedule.scheduleStartDate)
      //   .toString()
      //   .slice(0, 24),
      // scheduleStartDate: new Date(schedule.scheduleEndDate)
      //   .toString()
      //   .slice(0, 24),
    };
  });

  // const mentorData = scheduleReportList?.map((list) => {
  //   const { schedule, updatedAt, userId, programBatch } = list;
  //   const date = new Date(updatedAt);
  //   return {
  //     scheduleName: schedule.name,
  //     programBatch:programBatch?.name,
  //     scheduleStartDate: new Date(schedule.scheduleStartDate)
  //       .toString()
  //       .slice(0, 24),
  //     scheduleStartDate: new Date(schedule.scheduleEndDate)
  //       .toString()
  //       .slice(0, 24),
  //     learnerName: `${userId.firstName} ${userId.lastName}`,
  //     completedTime: date.toString().slice(0, 24),
  //     taskLink:list?.taskLink
  //   };
  // });

  const data= adminData
  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUITable
            title="Completed Tasks/Schedule"
            columns={columns}
            data={data}
            pageSize={10}
            // selectedRow={row => history.push(`/admin/learner/${row._id}`)}
            selectedRow={row => {}}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

// CounsellingRequest.propTypes = {
//     auth: PropTypes.object.isRequired,
// }

const mapStateToProps = (state) => ({
  auth: state.auth,
  schedules: state.schedules,
});

const mapDispatchToProps = { getScheduleReportList };

export default connect(mapStateToProps, mapDispatchToProps)(SchedulingReport);
// export default SchedulingReport;

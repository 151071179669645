import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../images/logo/ibridge.png';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuContainer: {
        marginLeft: theme.spacing(2)
    },
    title: {
        fontSize: 16,
        fontWeight: 600,
        textDecoration: 'none',
        color: '#666666',
        cursor: 'pointer',
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
        '&:hover': {
            color: '#008dd2'
        }
    }
}));

const Header = () => {
    const classes = useStyles();

    return (
        <AppBar className="headerContainer">
            <Toolbar>
                <Container maxWidth="lg" className="headerContent">
                    <img src={Logo} />
                    <div className={classes.grow} />
                    <div className={classes.menuContainer}>
                        <Typography variant="h5" noWrap>
                            <Link to="/" className={classes.title}>
                                Home
                            </Link>
                        </Typography>
                    </div>
                    <div className={classes.menuContainer}>
                        <Typography variant="h5" noWrap>
                            <Link to="/about-Us" className={classes.title}>
                                About Us
                            </Link>
                        </Typography>
                    </div>
                    <div className={classes.menuContainer}>
                        <Typography variant="h5" noWrap>
                            <Link to="/sign-Up_sign-In" className={classes.title}>
                                Sign Up / Sign In
                            </Link>
                        </Typography>
                    </div>
                </Container>
            </Toolbar>
        </AppBar>
    );
}

export default withRouter(Header)
import React from "react";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapForm: {
      display: "flex",
      justifyContent: "center",
      width: "95%",
      margin: `${theme.spacing(0)} auto`,
    },
    wrapText: {
      width: "100%",
    },
    button: {
      //margin: theme.spacing(1),
    },
  })
);

export const TextInput = ({ setMessage, onSubmit, message }) => {
  const classes = useStyles();
  return (
    <>
      <form
        className={classes.wrapForm}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
      >
        <TextField
          id="standard-text"
          name="message"
          label="Enter Message..."
          value={message}
          className={classes.wrapText}
          multiline
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              onSubmit(e);
            }
          }}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button
          onClick={(e) => onSubmit(e)}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <SendIcon />
        </Button>
      </form>
    </>
  );
};

import axios from "axios";
import {
  GET_RIASEC_TEST_QUESTIONS,
  PROGRAM_LOADING,
  GET_RIASEC_RESULT_BY_USER_ID,
} from "./types";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import history from "../../reusableComponents/history";

export const getRIASECTestQuestions = () => (dispatch) => {
  dispatch(programLoading());
  axios
    .get(`/api/RIASECTestQuestions/getRIASECTestQuestions`)
    .then((res) => {
      const { message } = res?.data;
      // SnackBar.success(message);
      dispatch({
        type: GET_RIASEC_TEST_QUESTIONS,
        payload: res?.data,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

export const saveRIASECTestResult = (data) => (dispatch) => {
  axios
    .post(`/api/RIASECTestQuestions/saveRIASECTestResult`, data)
    .then((res) => {
      const { message } = res.data;
      dispatch(getRIASECTestResultByUserId(data.userId));
      history.push("/dashboard");
      // history.push("/RIASECTestScreen");
      SnackBar.success(message);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
      history.push("/RIASECTestScreen");
    });
};

export const getRIASECTestResultByUserId = (userId) => (dispatch) => {
  axios
    .get(`/api/RIASECTestQuestions/getRIASECTestResultByUserId/${userId}`)
    .then((res) =>
      dispatch({
        type: GET_RIASEC_RESULT_BY_USER_ID,
        payload: res?.data,
      })
    )
    .catch((err) => {
      if (err.response) {
        const { message } = err?.response?.data;
        SnackBar.error(message);
      }
    });
};

export const programLoading = () => {
  return {
    type: PROGRAM_LOADING,
  };
};

import React from "react";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { BsExclamationDiamond } from "react-icons/bs";

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#1748e1",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: '#1e1e1e',
        // color: "#FDFEFE",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#1748e1",
      },
      daySelected: {
        backgroundColor: "#1748e1",
      },
      dayDisabled: {
        color: "#1748e1",
      },
      current: {
        color: "#1748e1",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#1748e1",
      },
    },
  },
});

const DateTimePick = ({
  showLable,
  heading,
  label,
  name,
  className,
  refs,
  selectedDate,
  inputVariant,
  placeholder,
  handleDateChange,
  labelShink,
  size,
  errors,
  minDate,
  disablePast,
  style,
  disabled,
}) => {
  const errorText = (
    <p>
      <BsExclamationDiamond /> {errors}
    </p>
  );
  return (
    <div>
      <ThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            disabled={disabled || false}
            label={label}
            name={name}
            className={className}
            inputRef={refs}
            inputVariant={inputVariant}
            placeholder={placeholder}
            value={selectedDate}
            onChange={handleDateChange}
            InputLabelProps={{ shrink: true }}
            size={size || "medium"}
            error={errors ? true : false}
            helperText={errors ? errorText : null}
            minDate={minDate}
            disablePast={disablePast || false}
            style={style || null}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
};

export default DateTimePick;

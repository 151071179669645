import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Typography, Button, Paper, Container } from "@material-ui/core";
import { GroupAdd as GroupAddIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  registerServiceProvider,
  getServiceProviderImage,
} from "../../../redux/actions/admin_view/addNewServiceProviderAction";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import ButtonField from "../../../reusableComponents/ButtonField";
import PageHeader from "../../../reusableComponents/PageHeader";
import FileUpload from "../../../reusableComponents/FileUpload";
import PhotoUpload from "../../../reusableComponents/PhotoUpload";
import isEmpty from "../../../reusableComponents/is-empty";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const ServiceProvider = ({
  registerServiceProvider,
  getServiceProviderImage,
  auth,
  adminAddNewServiceProvider,
  history,
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const { isAuthenticated } = auth;
  const { serviceProviderImage } = adminAddNewServiceProvider;
  useEffect(() => {
    if (isAuthenticated) {
      if (!isEmpty(serviceProviderImage)) {
        formik.setFieldValue("userImageName", serviceProviderImage?.name);
        formik.setFieldValue("userImage", serviceProviderImage?.link);
      }
    }
  }, [serviceProviderImage]);

  const phoneRegExp =
    "^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$";
  const formik = useFormik({
    initialValues: {
      providerName: "",
      contactName: "",
      email: "",
      // password: '',
      phoneNumber: "",
      userImage: "",
      userImageName: "",
      address: "",
      providerGST: "",
      city: "",
      state: "",
      zipCode: "",
      country: "India",
      panCard: "",
      description: "",
      timezone: null,
    },
    validationSchema: Yup.object({
      providerName: Yup.string()
        .trim()
        .required("Provider Name is a required field")
        .min(3, "Name must be at least 3 characters"),
      email: Yup.string()
        .email("Email is not valid")
        .required("Email is required field"),
      contactName: Yup.string()
        .trim()
        .required("Provider ContactName is required field"),
      phoneNumber: Yup.string()
        .trim()
        .length(10, "Phone Number must have 10 numbers")
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone number is required field"),
      address: Yup.string().trim().required("Address is required field"),
      // providerGST:country.toLowerCase() === "india" ?
      //   Yup.string()
      //   .trim()
      //   .required("providerGST is required field")
      // :'',
      city: Yup.string().trim().required("City is required field"),
      state: Yup.string().trim().required("State is reqired field"),
      zipCode: Yup.string().trim().required("zipCode is reqired field"),
      country: Yup.string().trim().required("Country is reqired field"),
      // panCard:  country.toLowerCase() === "india" ?
      //  Yup.string()
      //  .trim()
      //  .required("Pancard Number is reqired field")
      // :'',
      description: Yup.string()
        .trim()
        .required("Service description is reqired field"),
    }),
    onSubmit: async (values) => {
      console.log("@@@@@@@@@@");
      const userData = await values;
      delete userData.userImageName;

      if (isEmpty(userData.description)) {
        delete userData.description;
      }

      if (isEmpty(userData.userImage)) {
        delete userData.userImage;
      } else {
        userData.userImage = serviceProviderImage;
      }

      await registerServiceProvider(userData);
    },
  });

  const uploadProviderPoster = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("photo", e.target.files[0]);
    getServiceProviderImage(formData);
  };

  const renderForm = (
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={5}>
        <Grid item xs={12} className="inputfieldCenter">
          <PhotoUpload
            imageName={formik.values.userImageName}
            imageUrl={formik.values.userImage}
            onChange={(e) => uploadProviderPoster(e)}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="providerName"
            type="text"
            name="providerName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.providerName}
            errors={
              formik.touched.providerName && formik.errors.providerName
                ? formik.errors.providerName
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="Email"
            type="text"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            errors={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} className="inputFieldContainer">
                    <TextFieldGroup 
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        passwordControl={() => setShowPassword(!showPassword)}
                        showPassword={showPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        errors={formik.touched.password && formik.errors.password ? formik.errors.password : null}
                        />
                </Grid> */}
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="Provider ContactName"
            type="text"
            name="contactName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contactName}
            errors={
              formik.touched.contactName && formik.errors.contactName
                ? formik.errors.contactName
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="PhoneNumber"
            type="text"
            name="phoneNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
            errors={
              formik.touched.phoneNumber && formik.errors.phoneNumber
                ? formik.errors.phoneNumber
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="Address"
            type="text"
            name="address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            errors={
              formik.touched.address && formik.errors.address
                ? formik.errors.address
                : null
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} className="inputFieldContainer ">
          <TextFieldGroup
            label="City"
            type="text"
            name="city"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
            errors={
              formik.touched.city && formik.errors.city
                ? formik.errors.city
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="State"
            type="text"
            name="state"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.state}
            errors={
              formik.touched.state && formik.errors.state
                ? formik.errors.state
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="ZipCode"
            type="text"
            name="zipCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.zipCode}
            errors={
              formik.touched.zipCode && formik.errors.zipCode
                ? formik.errors.zipCode
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="Country"
            type="text"
            name="country"
            onChange={formik.handleChange}
            // onChange={setServiceProviderCountry(formik.values.country)}
            // onBlur={setServiceProviderCountry(formik.values.country)}
            value={formik.values.country}
            errors={
              formik.touched.country && formik.errors.country
                ? formik.errors.country
                : null
            }
          />
        </Grid>

        {/* {console.log(serviceProviderCountry)} */}

        {formik.values.country.toLowerCase() === "india" ? (
          <Grid item xs={12} sm={6} className="inputFieldContainer ">
            <TextFieldGroup
              // label="Provider GST"
              placeholder="Provider GST"
              type="text"
              name="providerGST"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.providerGST}
              errors={
                formik.touched.providerGST && formik.errors.providerGST
                  ? formik.errors.providerGST
                  : null
              }
            />
          </Grid>
        ) : null}

        {formik.values.country.toLowerCase() === "india" ? (
          <Grid item xs={12} sm={6} className="inputFieldContainer ">
            <TextFieldGroup
              // label="Pancard"
              placeholder="Pancard"
              type="text"
              name="panCard"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.panCard}
              errors={
                formik.touched.panCard && formik.errors.panCard
                  ? formik.errors.panCard
                  : null
              }
            />
          </Grid>
        ) : null}

        <Grid item xs={12} className="inputFieldContainer">
          <TextFieldGroup
            label="Service Description"
            type="text"
            name="description"
            multiline
            rows={4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            errors={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : null
            }
          />
        </Grid>
        <Grid item xs={12} className="inputFieldContainer">
          <div className={classes.submitButton}>
            <Button
              variant="contained"
              buttonStyle="formSubmit"
              type="submit"
              name="Submit"
              style={{ backgroundColor: "#008dd2", color: "white" }}
              className={classes.mentorForm}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      <PageHeader
        title="Add New Service Provider"
        subTitle="Add New Service Provider"
        icon={<GroupAddIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>{renderForm}</Paper>
    </Container>
  );
};

ServiceProvider.propTypes = {
  registerServiceProvider: PropTypes.func.isRequired,
  getServiceProviderImage: PropTypes.func.isRequired,
  // getTimeZoneList: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  adminAddNewServiceProvider: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  adminAddNewServiceProvider: state.adminAddNewServiceProvider,
});

const mapDispatchToProps = { registerServiceProvider, getServiceProviderImage };

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProvider);

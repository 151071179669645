import axios from "axios";
import {
  GET_SCHEDULE_LIST,
  SCHEDULE_LOADING,
  GET_PROGRAM_SCHEDULE_LIST,
  GET_SCHEDULE_REPOET_LIST,
  GET_SCHEDULE_INFO,
} from "./types";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import history from "../../reusableComponents/history";
import { getMentorScheduleList } from "./mentorActions";

// Get Schedule List
export const getScheduleList = () => (dispatch) => {
  dispatch(scheduleLoading());
  axios
    .get(`/api/schedule/getScheduleList`)
    .then((res) =>
      dispatch({
        type: GET_SCHEDULE_LIST,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Create Schedule
export const createSchedule = (data) => (dispatch) => {
  dispatch(scheduleLoading());
  return axios
    .post(`/api/schedule/createSchedule`, data)
    .then((res) => {
      const { message } = res.data;
      SnackBar.success(message);
      return res.data;
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
      throw new Error(message);
    });
};

// edit schedule
export const editSchedule = (data, user) => (dispatch) => {
  dispatch(scheduleLoading());
  axios
    .put(`/api/schedule/editSchedule`, data)
    .then((res) => {
      const { message } = res.data;
      SnackBar.success(message);
      if (user.role.r_id === 1) dispatch(getScheduleList());
      else dispatch(getMentorScheduleList(user._id));
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get Program Schedule List
export const getProgramScheduleList = (data) => (dispatch) => {
  dispatch(scheduleLoading());
  axios
    .get(`/api/schedule/getProgramScheduleList/${data}`)
    .then((res) =>
      dispatch({
        type: GET_PROGRAM_SCHEDULE_LIST,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

//
export const getScheduleInfo = (data) => (dispatch) => {
  dispatch(scheduleLoading());
  axios
    .get(`/api/schedule/getScheduleInfo/${data}`)
    .then((res) =>
      dispatch({
        type: GET_SCHEDULE_INFO,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Complete task
export const completeTask = (data, program) => (dispatch) => {
  dispatch(scheduleLoading());
  axios
    .post(`/api/schedule/completeSchedule`, data)
    .then((res) => {
      dispatch(getProgramScheduleList(program));
      const { message } = res.data;
      SnackBar.success(message);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

export const getScheduleReportList = () => (dispatch) => {
  dispatch(scheduleLoading());
  axios
    .get(`/api/schedule/getCompletedSchedules`)
    .then((res) =>
      dispatch({
        type: GET_SCHEDULE_REPOET_LIST,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Dashboard Loading
export const scheduleLoading = () => {
  return {
    type: SCHEDULE_LOADING,
  };
};

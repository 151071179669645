import axios from "axios";
import {
  ADMIN_MVK_LOADING,
  ADMIN_MVK_GET_LEARNERS_LIST,
  ADMIN_MVK_GET_LEARNER_INFO,
  ADMIN_MVK_GET_LEARNER_PROGRESS,
} from "../types";
import {
  ADMIN_MVK_GET_COUNSELLING_LIST,
  ADMIN_MVK_GET_LEARNER_PROGRESS_REPORT,
} from "../types";

import { SnackBar } from "../../../reusableComponents/SnackbarConfig";

// Get Learners List
export const getLearnersList = () => (dispatch) => {
  dispatch(mkvLoading());
  axios
    .get("/api/adminDashboard/learnersList")
    .then((res) =>
      dispatch({
        type: ADMIN_MVK_GET_LEARNERS_LIST,
        payload: res?.data?.message,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

//Get counselling list

export const getCounsellingList = () => (dispatch) => {
  dispatch(mkvLoading());
  axios
    .get("/api/adminDashboard/counsellingList")
    .then(
      (res) =>
        dispatch({
          type: ADMIN_MVK_GET_COUNSELLING_LIST,
          payload: res?.data?.data,
        })
      // console.log("in action", res?.data.data)
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get Learner Info
export const getLearnerInfo = (userId) => (dispatch) => {
  dispatch(mkvLoading());
  axios
    .get(`/api/adminDashboard/learnerDetails/${userId}`)
    .then((res) =>
      dispatch({
        type: ADMIN_MVK_GET_LEARNER_INFO,
        payload: res?.data?.message,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// CLear Learner Info
export const clearLearnerInfo = (userId) => (dispatch) => {
  dispatch({
    type: ADMIN_MVK_GET_LEARNER_INFO,
    payload: [],
  });
};

export const getLearnerProgressInfo = (data) => (dispatch) => {
  dispatch(mkvLoading());
  axios
    .post(`/api/programs/getUserProgramCourseProgress`, data)
    .then((res) =>
      dispatch({
        type: ADMIN_MVK_GET_LEARNER_PROGRESS,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

export const clearLearnerProgressInfo = (data) => (dispatch) => {
  console.log("clear");
  dispatch({
    type: ADMIN_MVK_GET_LEARNER_PROGRESS,
    payload: [],
  });
};

export const getLearnerProgressReport = (data) => (dispatch) => {
  dispatch(mkvLoading());
  axios
    .post(`/api/adminDashboard/getUserReport`, data)
    .then((res) =>
      dispatch({
        type: ADMIN_MVK_GET_LEARNER_PROGRESS_REPORT,
        payload: res?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

export const changeUserStatus = (status, userId) => (dispatch) => {
  const data = { userId, status };
  axios
    .put(`/api/adminDashboard/changeUserStatus`, data)
    .then((res) => {
      const { message } = res.data;
      SnackBar.success(message);
      dispatch(getLearnerInfo(userId));
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Mvk Loading
export const mkvLoading = () => {
  return {
    type: ADMIN_MVK_LOADING,
  };
};

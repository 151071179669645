import axios from "axios";
import setAuthToken from "../../reusableComponents/setAuthToken";
import {
  SET_CURRENT_LOADING,
  SET_CURRENT_USER,
  CLEAR_CURRENT_USER,
} from "./types";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import history from "../../reusableComponents/history";

// register User
export const registerUser = (userData) => (dispatch) => {
  axios
    .post("/api/user/signUp", userData)
    .then((res) => {
      const { message } = res?.data;
      history.push("/sign-In");
      SnackBar.success(message);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Login User
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/api/user/signIn", userData)
    .then((res) => {
      const { token } = res.data;

      // Set token to localstorage
      localStorage.setItem("iBridge360", JSON.stringify(token));
      // Set token to Auth Header
      setAuthToken(token);

      dispatch(setCurrentUser());
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// login Google
export const loginGoogle = (userData) => (dispatch) => {
  axios
    .post("/api/user/google", userData)
    .then((res) => {
      const { token } = res.data;

      // Set token to localstorage
      localStorage.setItem("iBridge360", JSON.stringify(token));
      // Set token to Auth Header
      setAuthToken(token);

      dispatch(setCurrentUser());
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// login Facebook
export const loginFacebook = (userData) => (dispatch) => {
  axios
    .post("/api/user/facebook", userData)
    .then((res) => {
      const { token } = res.data;

      // Set token to localstorage
      localStorage.setItem("iBridge360", JSON.stringify(token));
      // Set token to Auth Header
      setAuthToken(token);

      dispatch(setCurrentUser());
    })
    .catch((err) => {
      console.log(err?.response?.data);
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Current User
export const setCurrentUser = () => (dispatch) => {
  axios
    .get("/api/user/profile")
    .then((res) =>
      dispatch({
        type: SET_CURRENT_USER,
        payload: res.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
      dispatch(logoutUser(history));
    });
};

// Forgot Password Send Mail
export const forgotPasswordSendMail =
  (userData, history, enqueueSnackbar) => (dispatch) => {
    axios
      .put("/api/user/passwordlink", userData)
      .then((res) => {
        const { message } = res.data;
        enqueueSnackbar(message, { variant: "success" });
        history.push("/sign-in");
      })
      .catch((err) => {
        if (err.response) {
          const { message } = err.response.data;
          enqueueSnackbar(message, { variant: "error" });
        }
      });
  };

// Reset Password
export const resetPassword =
  (userData, history, enqueueSnackbar) => (dispatch) => {
    axios
      .put("/api/user/reset", userData)
      .then((res) => {
        const { message } = res.data;
        enqueueSnackbar(message, { variant: "success" });
        history.push("/sign-in");
      })
      .catch((err) => {
        if (err.response) {
          const { message } = err.response.data;
          enqueueSnackbar(message, { variant: "error" });
        }
      });
  };

// Edit User details
export const editUserDetials = (userData) => (dispatch) => {
  axios
    .put("/api/user/editProfile", userData)
    .then((res) => {
      const { message } = res?.data;
      dispatch(setCurrentUser());
      SnackBar.success(message);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Profile Reset Password

export const ProfileResetPassword = (userData) => async (dispatch) => {
  await axios
    .put("/api/user/profilePasswordReset", userData)
    .then((res) => {
      const { message } = res.data;
      SnackBar.success(message);
      // history.push("/sign-in");
    })
    .catch((err) => {
      if (err.response) {
        const { message } = err.response.data;
        SnackBar.error(message);
      }
    });
};


// post user website access log
export const postUserAccessLog = (userData) => async (dispatch) => {
  await axios
    .post("/api/user/postAccessLog", userData)
    // .then((res) => {
    //   const { message } = res.data;
    //   SnackBar.success(message);
    //   // history.push("/sign-in");
    // })
    // .catch((err) => {
    //   if (err.response) {
    //     const { message } = err.response.data;
    //     SnackBar.error(message);
    //   }
    // });
};

// Log user out
export const logoutUser = (history) => (dispatch) => {
  // Remove token from the localstorage
  localStorage.removeItem("iBridge360");
  // Remove auth headers from future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  // dispatch(setCurrentUser({}));
  history.push("/sign-in");
  dispatch({
    type: SET_CURRENT_USER,
    payload: {},
  });
  dispatch({
    type: CLEAR_CURRENT_USER,
    payload: { logout: true },
  });
  // window.location.reload();
};

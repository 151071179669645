import React, { Component } from "react";

import { Container, Grid, Typography } from "@material-ui/core";

const CancelPolicy = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "24px", fontWeight: "600" }}
          >
            Cancellation Policy
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            The Cancellation can be done before 30days of purchasing the course
            from Ibridge360.
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            No cancellation we will accepted after 30 days
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default CancelPolicy;

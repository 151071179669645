import axios from 'axios';
import { ADMIN_SERVICEPROVIDER_LOADING, ADMIN_GET_SERVICEPROVIDER_LIST, ADMIN_GET_SERVICEPROVIDER_INFO, } from '../types';
import { SnackBar } from '../../../reusableComponents/SnackbarConfig';

// Get Service Provider List
export const getServiceProviderList = () => dispatch => {
    dispatch(serviceProviderLoading());
    axios.get('/api/serviceProviders/serviceProviderList')
        .then(res => 
            dispatch({
                type: ADMIN_GET_SERVICEPROVIDER_LIST,
                payload: res?.data?.message
            })      
        )
        .catch(err => {
            const { message } = err?.response?.data;
            SnackBar.error(message);
        })
}

// Get Service Provider Info
export const getServiceProviderInfo = (userId) => dispatch => {
    dispatch(serviceProviderLoading());
    axios.get(`/api/serviceProviders/serviceProviderDetails/${userId}`)
    .then(res => 
        dispatch({
            type: ADMIN_GET_SERVICEPROVIDER_INFO,
            payload: res?.data?.message
        })    
    )
    .catch(err => {
        const { message } = err?.response?.data;
        SnackBar.error(message);
    })
}

export const serviceProviderLoading = () => {
    return {
        type :  ADMIN_SERVICEPROVIDER_LOADING
    }
}

import { PROGRAM_LEARNER_LIST, GET_MENTOR_BATCH_LIST, GET_MENTOR_SCHEDULE_LIST } from '../actions/types';

const initialState = {
loding: false,
programLearnerList: [],
mentorBatchList:[],
mentorScheduleList:[],
}

export default function(state = initialState, action) {
switch(action.type) {
   case PROGRAM_LEARNER_LIST:
       return {
           ...state,
           programLearnerList: action.payload,
           loading: false
       }
    case GET_MENTOR_BATCH_LIST:
        return {
            ...state,
            mentorBatchList: action.payload,
            loading: false
        }
    case GET_MENTOR_SCHEDULE_LIST:
        return {
            ...state,
            mentorScheduleList: action.payload,
            loading: false
        }

   default:
       return state;
}
}
import { ADMIN_SERVICEPROVIDER_LOADING, ADMIN_GET_SERVICEPROVIDER_LIST, ADMIN_GET_SERVICEPROVIDER_INFO, } from '../../actions/types';

const initialState = {
    loding: false,
    serviceProviderList: [],
    serviceProviderInfo: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case ADMIN_SERVICEPROVIDER_LOADING:
            return {
                ...state,
                loading: true
            }
        case ADMIN_GET_SERVICEPROVIDER_LIST:
            return {
                ...state,
                serviceProviderList: action.payload,
                loading: false
            }
        case ADMIN_GET_SERVICEPROVIDER_INFO:
            return {
                ...state,
                serviceProviderInfo: action.payload,
                loading: false
            }
        default:
            return state;
    }
}
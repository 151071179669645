import axios from 'axios';
import { DASHBOARD_LOADING, GET_ASSESSMENT_RESULT, 
         GET_PSYCHOMETRIC_QUESTIONS, GET_PSYCHOMETRIC_RESULT,
         GET_COUNSELLING_REQUEST, } from './types';
import { SnackBar } from '../../reusableComponents/SnackbarConfig';
import history from '../../reusableComponents/history';

// Get Assessment Result
export const getAssessmentResult = (userEmail) => dispatch => {
    dispatch(dashboardLoading());
    axios.get(`/api/dashboard/assessmentResults/${userEmail}`)
    .then(res => 
        dispatch({
            type: GET_ASSESSMENT_RESULT,
            payload: res?.data?.message
        })
    )
    .catch(err => {
        const { message } = err?.response?.data;
        // SnackBar.error(message);
    })
}

// Get Psychometric Questions
export const getPsychometricQuestions = () => dispatch => {
    dispatch(dashboardLoading());
    axios.get('/api/PsychometricQuestions/getQuestionsWithOptions')
    .then(res => 
        dispatch({
            type: GET_PSYCHOMETRIC_QUESTIONS,
            payload: res?.data
        })
    )
    .catch(err => {
        const { message } = err?.response?.data;
        // SnackBar.error(message);
    })
}

// Post Psychometric Questions
export const postPsychometricQuestions = userAnswers => dispatch => {
    axios.post(`/api/UserAnswersAndScores/saveUserQuestionsAndAnswers/${userAnswers._id}`, userAnswers.answers)
    .then(res => {
        const { message } = res?.data;
        dispatch(getPsychometricResultAnswers(userAnswers._id));
        history.push('/dashboard');
        SnackBar.success(message);
    })
    .catch(err => {
        const { message } = err?.response?.data;
        SnackBar.error(message);
    })
}

// Get Psychometric Result Answers
export const getPsychometricResultAnswers = (userId) => dispatch => {
    axios.get(`/api/UserAnswersAndScores/getUserMatrixScore/${userId}`)
    .then(res => 
        dispatch({
            type: GET_PSYCHOMETRIC_RESULT,
            payload: res?.data
        })
    )
    .catch(err => {
        if(err.response) {
            const { message } = err?.response?.data;
            SnackBar.error(message);
        }
    })
}

// Send Counselling Request
export const sendCounsellingRequest = (userDetails) => dispatch => {
    axios.post('/api/dashboard/counsellingRequest', userDetails)
    .then(res => {
        const { message } = res?.data;
        dispatch(getCounsellingRequest());
        SnackBar.success(message);
    })
    .catch(err => {
        const { message } = err?.response?.data;
        SnackBar.error(message);
    })
}

// get Counselling Request
export const getCounsellingRequest = () => dispatch => {
    axios.get('/api/dashboard/counsellingRequest')
    .then(res => 
        dispatch({
            type: GET_COUNSELLING_REQUEST,
            payload: res?.data?.message
        })    
    )
    .catch(err => {
        const { message } = err?.response?.data;
        SnackBar.error(message);
    })
}

// upload video
export const uploadVideo = (video, setUploadProgress, setIsUploading) => dispatch => {
    console.log("uploading ");
    const videoFile = video.get('video');
    if (videoFile) {
        console.log("Video file size: ", videoFile.size, "bytes");
    }
    const startTime = performance.now();

    axios.post('/api/videoUpload/uploadMediaRecorder', video,{
        onUploadProgress: progressEvent => {
            const {loaded , total} = progressEvent;
            console.log("loaded ",loaded, " total ", total);
            const percent = Math.floor((loaded *100)/total);
            setUploadProgress(percent);
        }
    })
    .then(res => {
        const endTime = performance.now();
        const duration = (endTime - startTime) / 1000;
        console.log("startTime ", startTime, " endTime ", endTime, " duration ", duration);
        setIsUploading(false);
        const { message } = res?.data;
        window.location.reload();
        SnackBar.success(message);
    })
    .catch(err => {
        const { message } = err?.response?.data;
        SnackBar.error(message);
    })
}


// Dashboard Loading
export const dashboardLoading = () => {
    return {
        type : DASHBOARD_LOADING
    }
}
import React, { useEffect } from "react";

const ProgramRedirectComponent = () => {
  useEffect(() => {
    // Redirect to the WordPress site
    window.location.replace("https://ibridge360.com/programs/");
  }, []); // The empty array ensures this runs only once when the component is mounted

  return null; // Since we are redirecting, no need to render anything
};

export default ProgramRedirectComponent;

import { ADMIN_EDUCATOR_LOADING, ADMIN_GET_EDUCATORS_LIST, ADMIN_GET_EDUCATOR_INFO } from '../../actions/types';

const initialState = {
    loding: false,
    coachList: [],
    coachInfo: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case ADMIN_EDUCATOR_LOADING:
            return {
                ...state,
                loading: true
            }
        case ADMIN_GET_EDUCATORS_LIST:
            return {
                ...state,
                coachList: action.payload,
                loading: false
            }
        case ADMIN_GET_EDUCATOR_INFO:
            return {
                ...state,
                coachInfo: action.payload,
                loading: false
            }
        default:
            return state;
    }
}
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

function ProgramSchedule({ eventData }) {
  return (
    <div>
      <Calendar
        // className="openModalBtn"
        localizer={localizer}
        events={eventData || []}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        onSelectEvent={() => console.log("selected")}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramSchedule);

import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Container, Grid } from "@material-ui/core";
import MUITable from "../MaterialTable/MaterialTable";
import { getProgramLearnerList } from "../../redux/actions/mentorActions";
import { getDateTime } from "../../reusableComponents/GetDate";

const ProgramLearnerList = ({
  getProgramLearnerList,
  auth,
  mentor,
  history,
  match,
}) => {
  const { isAuthenticated } = auth;
  const { programLearnerList } = mentor;
  const { programId } = match.params;

  useEffect(() => {
    if (isAuthenticated) {
      getProgramLearnerList(programId);
    }
  }, [isAuthenticated]);

  const columns = [
    {
      title: "Learner Name",
      field: "name",
    },
    {
      title: "Email ID",
      field: "email",
    },
    {
      title: "Education",
      field: "education",
    },
    {
      title: "Phone Number",
      field: "phoneNumber",
    },
    {
      title: "Registered On",
      field: "createdAt",
    },
  ];

  const data = programLearnerList?.map((list) => {
    const {
      _id,
      firstName,
      lastName,
      email,
      role,
      phoneNumber,
      education,
      createdAt,
    } = list;
    return {
      _id,
      name: `${firstName} ${lastName}`,
      email,
      role,
      phoneNumber,
      education,
      createdAt: getDateTime(createdAt),
    };
  });

  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUITable
            title="Learners List"
            columns={columns}
            data={data}
            pageSize={10}
            selectedRow={(row) => history.push(`/admin/mentorLearnerInfo/${row._id}`)}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

ProgramLearnerList.propTypes = {
  auth: PropTypes.object.isRequired,
  getProgramLearnerList: PropTypes.func.isRequired,
  mentor: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  mentor: state.mentor,
});

const mapDispatchToProps = { getProgramLearnerList };

export default connect(mapStateToProps, mapDispatchToProps)(ProgramLearnerList);

import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import { getMentorPrograms } from "../../redux/actions/programActions";

const MentorDashboard = ({ getMentorPrograms, programs, auth }) => {
  const { user, isAuthenticated } = auth;
  const { mentorPrograms } = programs;

  useEffect(() => {
    if (isAuthenticated) {
      getMentorPrograms(user?._id);
    }
  }, [isAuthenticated, auth]);

  console.log("authUser", user);
  console.log("Programs", programs);

  return (
    <>
      <Container className="mentorDashboardContainer">
        <Grid container spacing={2}>
          {/* {programList && programList.filter(list =>  list.programMentor._id === user._id).map((program) =>(*/}
          {mentorPrograms &&
            mentorPrograms.map((program) => (
              <Grid item xs={12} md={3} align="center">
                <Link
                  to={`/admin/programLearnerList/${program?._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Card className="programCard1">
                    <Typography className="programName">
                      {program?.name}
                      {/* The complete Data Engineering Program:  */}
                    </Typography>
                    <div className="cardImgContainer">
                      <img
                        src={program.programImage}
                        className="programCardImg1"
                      />
                    </div>

                    <div className="prgInfoContainer">
                      <p className="prgInfo1" align="left">
                        The complete {program.name} Program: Mentor/ Coach
                      </p>
                      {program?.programMentor &&
                        program?.programMentor.map((list) => {
                          return (
                            <p className="prgInfo2" align="left">
                              {`${list.firstName} ${list.lastName}`}
                            </p>
                          );
                        })}

                      {/* <LinearProgress
                                        value={50}
                                        className="progressBar"
                                        variant="determinate"
                                    />
                                    <p className="prgInfo1">50% completed</p> */}
                    </div>
                  </Card>
                </Link>
              </Grid>
            ))}
          {/* --------------------------------------------------------------------------------------------------- */}

          {/* <Grid item xs={12} md={4}>
                        <Card className='mentorDashboardCard' >
                            <Grid container>
                                <Grid item xs={4} md={4}>
                                    <CardContent className='mentorDashboardCardContent1'>
                                        <p className='cardContentText1'>
                                            The complete Data Engineering Program:  
                                        </p>
                                    </CardContent>
                                </Grid>
                                <Grid item xs={8} md={8}>
                                    <CardContent className='mentorDashboardCardContent2'>
                                        <p align="right" className='cardContentText2'>
                                            Mentor/Coach : Rajesh Kumar Shanmugam
                                            Having 25+ years of experience in IT industry. 
                                            Empowering job seekers to become good employees 
                                            who works effectively in every opportunity.
                                        </p>
                                    </CardContent>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>  */}
        </Grid>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  programs: state.programs,
});

const mapDispatchToProps = {
  getMentorPrograms,
};

export default connect(mapStateToProps, mapDispatchToProps)(MentorDashboard);

// export default mentorDashboard;

// import { Grid, Container, Typography } from "@material-ui/core";
// import React, { Component } from "react";
// import { withRouter, Link } from "react-router-dom";
// import Card from "@material-ui/core/Card";
// import LinearProgress from "@material-ui/core/LinearProgress";
// // import LinearProgress from '@mui/material/LinearProgress';
// import CardContent from "@material-ui/core/CardContent";
// import img1 from "../../images/programs/dataEngineerTheme.png";
// import { connect } from "react-redux";
// import { getUserPrograms } from "../../redux/actions/programActions";

// const MyPrograms = ({ getUserPrograms, auth, programs }) => {
//   const { userPrograms } = programs;
//   const { user, isAuthenticated } = auth;

//   React.useEffect(() => {
//     if (isAuthenticated) {
//       getUserPrograms(user?._id);
//     }
//   }, [auth]);

//   return (
//     <Container classname="myProgramsContainer">
//       {user?.programsEnrolled?.length > 0 ? (
//         <Grid container spacing={2}>
//           {userPrograms &&
//             userPrograms.map((program) => (
//               <Grid item xs={12} md={3} align="center">
//                 <Link
//                   to={`/learnerCourse/${program?._id}`}
//                   style={{ textDecoration: "none" }}
//                 >
//                   <Card className="programCard1">
//                     <Typography className="programName">
//                       {program?.name}
//                     </Typography>
//                     <div className="cardImgContainer">
//                       <img
//                         src={program?.cardImage}
//                         className="programCardImg1"
//                       />
//                     </div>

//                     <div className="prgInfoContainer">
//                       <p className="prgInfo1" align="left">
//                         The complete {program?.name} Program: Mentor/ Coach
//                       </p>
//                       <p className="prgInfo2" align="left">
//                         {`${program?.programMentor?.firstName} ${program?.programMentor?.lastName}, ${program?.programMentor?.description}`}
//                       </p>

//                       {/* <LinearProgress
//                         value={50}
//                         className="progressBar"
//                         variant="determinate"
//                       />
//                       <p className="prgInfo1">50% completed</p> */}
//                     </div>
//                   </Card>
//                 </Link>
//               </Grid>
//             ))}
//         </Grid>
//       ) : (
//         <Typography
//           className="prgInfo1"
//           variant="h4"
//           align="center"
//           style={{ color: "rgb(199, 11, 11)" }}
//         >
//           You have not purchased any Programs
//         </Typography>
//       )}
//     </Container>
//   );
// };

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   programs: state.programs,
// });

// const mapDispatchToProps = {
//   getUserPrograms,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(MyPrograms);

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, Paper, Button, Typography, Dialog, DialogContent, CircularProgress } from "@material-ui/core";
import './JobMappingGraph.scss'
import { getJobPosts } from '../../../redux/actions/jobPostsApiActions';
import axios from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    color: '#18479F',
    transition: 'color 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      color: '#FF0000', 
    },
  },
}));


export const JobProfileTypes = () => {
  const { openAIReducer, jobPostsApiReducer } = useSelector((state) => state);
  const jobTypes = openAIReducer?.jobTypesData;
  const location = openAIReducer?.location;
  const email = openAIReducer?.visitorEmail;
  const classes = useStyles();
  const [selectedJobProfile, setSelectedJobProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogJobPosts, setDialogJobPosts] = useState([]);
  const [skillsArray, setSkillsArray] = useState([]);
  const userSkills = openAIReducer?.userSkills.map((skill) => skill.name);
  const dispatch = useDispatch();

  const sendMail = (title) => {
    axios.post("/api/jobPostsApi/sendMail", {
      title: title,
      userSkills,
      email: email,
      location: location
    });
  }

  const fetchSkillData = async (title) => {
    try {
      const jsonData = await axios.post('/api/jobPostsApi/getSkills', {
        title: title
      });
      setSkillsArray(jsonData.data);
      
    } catch (error) {
      console.error('Error fetching skill data:', error);
    }
  }

  const handleDialogOpen = async (jobProfile) => {
    await fetchSkillData(jobProfile.title);
    setSelectedJobProfile(jobProfile.title);
    setDialogOpen(true)

    const data = { title: jobProfile.title, location, email };
    dispatch(getJobPosts(data));
    sendMail(jobProfile.title);
   
  }

  useEffect(() => {
    if(jobPostsApiReducer.jobPostsData.data)
    {
      console.log('jobPostsApiReducer.jobPostsData.data triggered useEffect() => ', jobPostsApiReducer.jobPostsData.data)
      setDialogJobPosts(jobPostsApiReducer.jobPostsData.data || []);
      setDialogOpen(true);
    }
  },[jobPostsApiReducer.jobPostsData.data])

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedJobProfile(null);
    setDialogJobPosts([]);
  };

  return (
    <div className="job-profile-types">
      <span style={{color: "#4D4D4D"}}>Job Profiles Found </span>
      <Chip label="3" style={{ borderRadius: '5px', backgroundColor: '#18479F', color: 'white' }} />
      <div className="job-profiles-container">
      {jobTypes.map((jobType) => (
        <Paper key={jobType.title} className="job-profile-type" elevation={16} square={false}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%", 
              color: "#4D4D4D"
            }}
          >
            <div>
              <Typography variant="body1" style={{fontWeight: 'bold', fontSize: 20, marginBottom: 8}}>{jobType.title}</Typography>
              <Typography variant="body1">
                {jobType.description}
              </Typography>
            </div>
            <div 
            style={{ alignSelf: "flex-end" }}
            >
              <Button
                style={{
                  textTransform: "none",
                  color: '#18479F',
                  marginTop: 20
                }}
                variant="outlined"
                onClick={() => handleDialogOpen(jobType)}
              >
                View Jobs
              </Button>
            </div>
          </div>
        </Paper>
      ))}

      </div>

      {dialogOpen && 
      (
      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="lg" fullWidth>
        <DialogContent>
          {(dialogJobPosts.length == 0 && !jobPostsApiReducer.noJobPostsData)? 
          (<CircularProgress />)
          :
          jobPostsApiReducer.noJobPostsData ?
          (
            <Box>

              <Box style={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: 0,
              padding: 0,
              color: "#4D4D4D"
              }}>
              <CloseIcon
              className={classes.closeIcon}
              onClick={handleDialogClose}
              /> 
              </Box>
              <h2>No Data</h2>
            </Box>            
          )
          :
          (
            <Box>
              <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 20
              }}
              >
              <Typography 
              style={{
                margin: "20px 0 0 0",
                color: "#4D4D4D"
              }}
              variant='h6'
              >
              Job Posts for {selectedJobProfile}
              </Typography>
              <Box>
              <CloseIcon 
                // style={{color:"#18479F"}}
                className={classes.closeIcon}
                onClick={handleDialogClose}
                /> 
              </Box>
            </Box>
            <Box style={{display: 'flex', justifyContent: 'space-between'}}>
            <Box style={{marginRight: 0, margin: "12px 0px", flex: 1}}>
              {skillsArray.length>0 && <Typography variant='body2' style={{color: "#4D4D4D", display: 'block'}}>General Skills for {selectedJobProfile}: </Typography>}
            </Box>
            <Box style={{flex: 5}}>
              {skillsArray.map((skill) => (
                <Chip
                  style = {{
                    borderRadius: "2px",
                    margin: "5px",
                  }} 
                  color= "primary"
                  variant='outlined'
                  key ={`${skill}`} 
                  label={`${skill}`} />
              ))}
            </Box>
          </Box>


            <div>
            <span style={{color: "#4D4D4D", marginLeft:5}}>Location: </span>
            <Chip label={`${location}`} style={{ borderRadius: '5px', backgroundColor: '#18479F', color: 'white' }} />
            </div>
            <div>
              <div className='job-posts-table' style={{border: "0", color: "#4D4D4D"}}>
                <div style={{ margin: "15px 10px", flex: 1 }}>Company</div>
                <div style={{ margin: "15px 10px", flex: 1 }}>Title</div>
                <div style={{ margin: "15px 10px", flex: 5 }}>Brief Description</div>
              </div>
              {dialogJobPosts.map((post) => (
                <Paper>
                <div
                style={{
                  color: "#4D4D4D",
                }}
                  key={post.id} 
                  className='job-posts-table'
                >
                  <div style={{ fontWeight: "bold", margin: "15px 10px", flex: 1 }}>{post.company}</div>
                  <div style={{ margin: "15px 10px", flex: 1 }}>{post.title}</div>
                  <div style={{ margin: "15px 10px", alignSelf: "flex-end", flex: 4 }}>
                    {post.description}
                  </div>
                  <div style={{ margin: "15px 10px", width: '116px', height: '23px', display: 'flex', alignSelf:'center', alignItems: 'center', flex: 0.5 }}>
                    <a href="http://www.adzuna.in" target='_blank' style={{ marginRight: 10 }}>
                    <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Jobs</span>
                    </a>
                    <span style={{ fontWeight: 'bold', fontSize: '12px', marginRight: 5 }}>By</span>
                      <a href="http://www.adzuna.in" target='_blank'>
                        <img src="https://zunastatic-abf.kxcdn.com/assets/images/press/adzuna_logo/adzuna_logo.jpg" alt="Adzuna Logo"  style={{width: '60px', height: '23px'}} />
                      </a>
                  </div>
                </div>
                </Paper>
              ))}
            </div>
          </Box>
          )}
        </DialogContent>
      </Dialog>)
      }
    </div>
  );
};

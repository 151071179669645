import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import $ from "jquery";
import data from "./data.json";
import "./JobMappingGraph.scss";
import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";
import SelectMultipleValues from "../../../reusableComponents/SelectMultipleValues";
import isEmpty from "../../../reusableComponents/is-empty";
import { Grid, Button, Typography, TextField, makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { getJobTypes, clearOpenAIJobTypesData } from "../../../redux/actions/openAIActions";
import { clearData } from "../../../redux/actions/jobPostsApiActions"
import { SnackBar } from "../../../reusableComponents/SnackbarConfig";

const availableSkills = [
  { id: 1, name: "JavaScript" },
  { id: 2, name: "React Native" },
  { id: 3, name: "Redux" },
  { id: 4, name: "CSS" },
  { id: 5, name: "HTML" },
  { id: 6, name: "GIT" },
  { id: 7, name: "API" },
  { id: 8, name: "JSON" },
  { id: 9, name: "Node.js" },
  { id: 10, name: "React.js" },
  { id: 11, name: "Swift" },
  { id: 12, name: "Objective-C" },
  { id: 13, name: "Xcode" },
  { id: 14, name: "iOS" },
  { id: 15, name: "UIKit" },
  { id: 16, name: "Core Data" },
  { id: 17, name: "Cocoa Touch" },
  { id: 18, name: "RESTful API" },
  { id: 19, name: "Firebase" },
  { id: 20, name: "Java" },
  { id: 21, name: "Android SDK" },
  { id: 22, name: "Kotlin" },
  { id: 23, name: "XML" },
  { id: 24, name: "MVC architecture" },
  { id: 25, name: "Python" },
  { id: 26, name: "SQL" },
  { id: 27, name: "MongoDB" },
  { id: 28, name: "AWS" },
  { id: 29, name: "ETL" },
  { id: 30, name: "Data modeling" },
  { id: 31, name: "Data warehousing" },
  { id: 32, name: "Big Data" },
  { id: 33, name: "Hadoop" },
  { id: 34, name: "Spark" },
  { id: 35, name: "Cloud" },
  { id: 36, name: "Tableau" },
  { id: 37, name: "Excel" },
  { id: 38, name: "R" },
  { id: 39, name: "Data Analysis" },
  { id: 40, name: "Data Visualization" },
  { id: 41, name: "Statistical Analysis" },
  { id: 42, name: "Data Mining" },
  { id: 43, name: "Machine Learning" }
]
const locations = [
 {name: "Bengaluru"},
 {name: "Mumbai"},
 {name: "Delhi"},
 {name: "Chennai"},
 {name: "Hyderabad"},
 {name:"Pune"}
]

const useStyles = makeStyles((theme) => ({
  button: {
    width: "290px",
    textTransform: "none",
    backgroundColor: '#18479F',
    color: 'white',
    '&:disabled': {
      backgroundColor: '#808080', 
    },
  },
}));

const sortedSkills = availableSkills.slice().sort((a, b) => {
  return a.name.localeCompare(b.name);
});

const sortedLocations = locations.slice().sort((a, b) => {
  return a.name.localeCompare(b.name);
});

const JobMappingGraph = () => {
  const [filteredData, setFilteredData] = useState({
    job: {},
    degree: {},
    industry: {},
  });

  const nodeSizeRef = useRef(3);
  const fontSizeRef = useState(9);
  const charactersToDisplayRef = useRef(5);
  const spaceBetweenNodesRef = useRef(1);
  
  const { openAIReducer } = useSelector((state) => state);
  const [visitorEmail, setVisitorEmail] = useState(null);
  const [isGraphVisible, setGraphVisibility] = useState(true);
  const dispatch = useDispatch();

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(()=>{
    dispatch(clearOpenAIJobTypesData());
  },[filteredData])

  useEffect(() => {
    console.log(filteredData);
    setGraphVisibility(true);
  
    if (filteredData) {
      console.log(filteredData);
      
      if((filteredData?.degree != null && Object.keys(filteredData?.degree)?.length !=0) || (filteredData?.industry != null && Object.keys(filteredData?.industry)?.length !=0) || (filteredData?.job != null && Object.keys(filteredData?.job)?.length !=0))
        {
          nodeSizeRef.current = 6.2;
          charactersToDisplayRef.current = 9;
          spaceBetweenNodesRef.current = 3.4;
          fontSizeRef.current = 14;
        }
        else{
      nodeSizeRef.current = 3;
      charactersToDisplayRef.current = 5;
      spaceBetweenNodesRef.current = 1;
      fontSizeRef.current = 9;
        }

      let d = document.getElementById("mappingGraph");
      if (!d) {
        return;
      }
      d.innerHTML = "";

      
      if (
        isEmpty(filteredData.job) &&
        isEmpty(filteredData.degree) &&
        isEmpty(filteredData.industry)
      ) {
        returnMappingGraph(data);
      } else if (
        !isEmpty(filteredData.job) &&
        isEmpty(filteredData.degree) &&
        isEmpty(filteredData.industry)
      ) {
        const jobFilter = data?.filter(
          (list) => list.Job === filteredData.job.name
        );
        returnMappingGraph(jobFilter);
      } else if (
        isEmpty(filteredData.job) &&
        !isEmpty(filteredData.degree) &&
        isEmpty(filteredData.industry)
      ) {
        const degreeFilter = data?.filter(
          (list) => list.Degree === filteredData.degree.name
        );
        returnMappingGraph(degreeFilter);
      } else if (
        isEmpty(filteredData.job) &&
        isEmpty(filteredData.degree) &&
        !isEmpty(filteredData.industry)
      ) {
        const industryFilter = data?.filter(
          (list) => list.Industry === filteredData.industry.name
        );
        returnMappingGraph(industryFilter);
      } else if (
        !isEmpty(filteredData.job) &&
        !isEmpty(filteredData.degree) &&
        !isEmpty(filteredData.industry)
      ) {
        const jobDegreeIndustryFilter = data?.filter(
          (list) =>
            list.Job === filteredData.job.name &&
            list.Degree === filteredData.degree.name &&
            list.Industry === filteredData.industry.name
        );
        returnMappingGraph(jobDegreeIndustryFilter);
      } else if (
        !isEmpty(filteredData.job) &&
        !isEmpty(filteredData.degree) &&
        isEmpty(filteredData.industry)
      ) {
        const jobDegreeFilter = data?.filter(
          (list) =>
            list.Job === filteredData.job.name &&
            list.Degree === filteredData.degree.name
        );
        returnMappingGraph(jobDegreeFilter);
      } else if (
        !isEmpty(filteredData.job) &&
        isEmpty(filteredData.degree) &&
        !isEmpty(filteredData.industry)
      ) {
        const jobIndustryFilter = data?.filter(
          (list) =>
            list.Job === filteredData.job.name &&
            list.Industry === filteredData.industry.name
        );
        returnMappingGraph(jobIndustryFilter);
      } else if (
        isEmpty(filteredData.job) &&
        !isEmpty(filteredData.degree) &&
        !isEmpty(filteredData.industry)
      ) {
        const degreeIndustryFilter = data?.filter(
          (list) =>
            list.Degree === filteredData.degree.name &&
            list.Industry === filteredData.industry.name
        );
        returnMappingGraph(degreeIndustryFilter);
      }
    }
  
    return () => d3.selectAll("svg").select("#mappingGraph").remove();
  }, [filteredData]);


  const returnMappingGraph = (data) => {
    // const margin = {
    //     top: 10,
    //     right: 20,
    //     bottom: 30,
    //     left: 30
    // };
    console.log(fontSizeRef, nodeSizeRef);
    // const width = 1080 - margin.left - margin.right;
    // const height = 850 - margin.top - margin.bottom;
    var cwidth = $("#mappingGraph").width();
    var cheight = $("#mappingGraph").height();
    var margin = { top: 20, right: 20, bottom: 0, left: 20 },
      width = cwidth - margin.left - margin.right,
      height = cheight - margin.top - margin.bottom;
    const svg = d3
      .select("#mappingGraph")
      .append("svg")
      .attr("width", cwidth)
      .attr("height", cheight)
      .call(responsivefy) // Call responsivefy to make the chart responsive
      .append("g")
      .attr("transform", `translate(${margin.left + 50}, ${margin.top})`);

    var color = d3
      .scaleOrdinal()
      .range(["#18479F", "steelblue", "#8f46e8", "#ba46e8"])
      .domain(["Company", "Prospect", "Industry"]);

    function responsivefy(svg) {
      const container = d3.select(svg.node().parentNode),
        width = parseInt(svg.style("width"), 10),
        height = parseInt(svg.style("height"), 10),
        aspect = width / height;

      // Add viewBox attribute to set the value to initial size
      // add preserveAspectRatio attribute to specify how to scale
      // and call resize so that svg resizes on page load
      svg
        .attr("viewBox", `0 0 ${width} ${height}`)
        .attr("preserveAspectRatio", "xMinYMid")
        .call(resize);

      d3.select(window).on("resize." + container.attr("id"), resize);

      function resize() {
        const targetWidth = parseInt(container.style("width"));
        svg.attr("width", targetWidth);
        svg.attr("height", Math.round(targetWidth / aspect));
      }
    }

    const renderGraph = (data) => {
      var node = [];
      var uq = [];
      var count = 0;
      var links = [];
      var radius = 6 * nodeSizeRef.current;
      data.forEach(function (d) {
        if (!uq.includes(d.Industry)) {
          uq.push(d.Industry);
          node.push({ id: d.Industry, type: "Industry", name: d.Industry });
        }

        if (!uq.includes(d.Degree)) {
          uq.push(d.Degree);
          node.push({ id: d.Degree, type: "Degree", name: d.Degree });
        }

        if (!uq.includes(d.Job)) {
          uq.push(d.Job);
          node.push({ id: d.Job, type: "Job", name: d.Job });
        }

        links.push({ source: d.Degree, target: d.Industry });
        links.push({ source: d.Job, target: d.Industry });
        links.push({ source: d.Degree, target: d.Job });
      });

      var div = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);

      var simulation = d3
        .forceSimulation(node)
        .force(
          "link",
          d3
            .forceLink(links)
            .id(function (d) {
              return d.id;
            })
            .distance(function (d) {
              return 50*spaceBetweenNodesRef.current;
            })
        )
        .force("charge", d3.forceManyBody().strength(-250))
        .force("center", d3.forceCenter(width / 2, height / 2))
        // .on("tick", ticked);
        .on("tick", tick);

      function click(d) {
        console.log(d);
        d3.select(this)
          .transition()
          .duration(750)
          .attr("r", 30)
          .style("fill", "lightsteelblue");
      }

      var link = svg
        .append("g")
        .attr("transform", "translate(" + -130 + "," + -20 + ")")
        .attr("class", "links")
        .selectAll("line")
        .data(links)
        .enter()
        .append("line")
        .attr("stroke-width", 2);

      // var node = svg
      //   .append("g")
      //   .attr("transform", "translate(" + -130 + "," + -20 + ")")
      //   .attr("class", "nodes")
      //   .selectAll("g")
      //   .data(node)
      //   .enter()
      //   .append("g");

      var node = svg
  .append("g")
  .attr("transform", "translate(" + -130 + "," + -20 + ")")
  .attr("class", "nodes")
  .selectAll("g")
  .data(node)
  .enter()
  .append("g");

      // var circles = node.append("circle")
      //     .attr("r", 20)
      //     .attr("fill", function(d) { return color(d.type); })
      // var circles = node
      //   .append("circle")
      //   .attr("r", radius + 12)
      //   .attr("fill", function (d) {
      //     return color(d.type);
      //   })
      //   // .style("stroke", function(d) { return d3.rgb(color(d.type)).darker() })
      //   .style("stroke", "#fff")
      //   .style("stroke-width", "2px")
      //   // .on('click',function(event,d){
      //   //     if (d['type']== "Job")
      //   //     {

      //   //         div.transition()
      //   //         .duration(200)
      //   //         .style("opacity", .9);
      //   //     div .html("Job :" + d['name']  + "<br/><br/>" + "Job Description: " +"Software engineers apply principles and techniques of engineering, mathematics, and computer science to the design, development, and testing of software applications for computers." )
      //   //         .style("left", (event.pageX) + "px")
      //   //         .style("top", (event.pageY - 28) + "px");
      //   //     }

      //   // })

      //   .on("mouseover", function (event, d) {
      //     if (event.defaultPrevented) return;
      //     d3.select(this).transition().duration(300).attr("r", 6*4);
      //   })
      //   .on("mouseout", function (event, d) {
      //     if (event.defaultPrevented) return;
      //     d3.select(this).transition().duration(300).attr("r", 6*3);
      //   })
      //   .call(
      //     d3
      //       .drag()
      //       .on("start", dragstarted)
      //       .on("drag", dragged)
      //       .on("end", dragended)
      //   );
   
      var circles = node
  .append("circle")
  .attr("r", 6*nodeSizeRef.current) // Default radius (6*3)
  .attr("fill", function (d) {
    return color(d.type);
  })
  .style("stroke", "#fff")
  .style("stroke-width", "2px")
  .on("mouseover", function (event, d) {
    if (event.defaultPrevented) return;
    console.log("Mouseover on node:", d);
    d3.select(this).transition().duration(300).attr("r", 7*nodeSizeRef.current); // 6*4
  })
  .on("mouseout", function (event, d) {
    if (event.defaultPrevented) return;
    console.log("Mouseout from node:", d);
    d3.select(this).transition().duration(300).attr("r", 6*nodeSizeRef.current); // 6*3
  })
  .call(
    d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended)
  );


      // var lables = node
      //   .append("text")
      //   .text(function (d) {
      //     return d["name"].length > 5
      //       ? d["name"].slice(0, 8) + "..."
      //       : d["name"];
      //   })
      //   .attr("x", -16)
      //   .attr("y", 3)
      //   .attr("text-anchor", "middle")
      //   .attr("dominant-baseline", "central")
      //   .style("font-family", "FontAwesome")
      //   .style("font-size", "15px");

      // // .on('mouseover', function(event, d){
      // //     if (event.defaultPrevented) return;
      // //     d3.select(this).transition()
      // //     .duration(300)
      // //     .style("font-weight", "bold")
      // //     .style("font-size", "11px")
      // //     .text(function(d) {
      // //         return d['name'].length > 5 ? d['name'].slice(0,5)+'...': d['name'];
      // //     })
      // //     .attr('x', -13)
      // //     .attr('y', 3)
      // // })
      // // .on('mouseout', function(event, d){
      // //     if (event.defaultPrevented) return;
      // //     d3.select(this).transition()
      // //     .duration(300)
      // //     .style("font-size", "10px")
      // //     .text(function(d) {
      // //         return d['name'].length > 5 ? d['name'].slice(0,5)+'...': d['name'];
      // //     })
      // //     .attr('x', -13)
      // //     .attr('y', 3)
      // // });
      var lables = node
      .append("text")
      .text(function (d) {
        return d["name"].length > 5
          ? d["name"].slice(0, charactersToDisplayRef.current) + "..."
          : d["name"];
      })
      .attr("x", -16)
      .attr("y", 3)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "central")
      .style("font-family", "FontAwesome")
      .style("font-size", fontSizeRef.current+"px");

      node.append("title").text(function (d) {
        return d.id;
      });

      function tick() {
        circles
          .attr("cx", function (d) {
            return (d.x = Math.max(radius, Math.min(width - radius, d.x)));
          })
          .attr("cy", function (d) {
            return (d.y = Math.max(radius, Math.min(height - radius, d.y)));
          });

        link
          .attr("x1", function (d) {
            return d.source.x;
          })
          .attr("y1", function (d) {
            return d.source.y;
          })
          .attr("x2", function (d) {
            return d.target.x;
          })
          .attr("y2", function (d) {
            return d.target.y;
          });

        // update label positions
        lables
          .attr("x", function (d) {
            return d.x;
          })
          .attr("y", function (d) {
            return d.y;
          });
      }

      // function ticked() {
      //     link
      //         .attr("x1", function(d) { return d.source.x; })
      //         .attr("y1", function(d) { return d.source.y; })
      //         .attr("x2", function(d) { return d.target.x; })
      //         .attr("y2", function(d) { return d.target.y; });

      //     node
      //         .attr("transform", function(d) {
      //         return "translate(" + (d.x) + "," + (d.y) + ")";
      //         })
      // }

      function dragstarted(event, d) {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
      }

      function dragged(event, d) {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
      }

      function dragended(event, d) {
        if (!event.active) simulation.alphaTarget(0);
        event.subject.fx = null;
        event.subject.fy = null;
      }

      var leg = ["Degree", "Job", "Industry"];

      var legends = svg.append("g").selectAll(".legends").data(leg);

      var legend = legends
        .enter()
        .append("g")
        .classed("legends", true)
        .attr("transform", function (d, i) {
          return "translate(" + (width / 2 - 350) + ", -20)";
        });
      legend
        .append("circle")
        // .attr("r", 2)
        .attr("fill", function (d) {
          return color(d);
        });

      svg
        .selectAll("mydots")
        .data(leg)
        .enter()
        .append("circle")
        .attr("cx", function (d, i) {
          return 10 + i * 100;
        })
        .attr("cy", 10) // 100 is where the first dot appears. 25 is the distance between dots
        .attr("r", 5)
        .style("fill", function (d) {
          return color(d);
        });

      svg
        .selectAll("mylabels")
        .data(leg)
        .enter()
        .append("text")
        .attr("x", function (d, i) {
          return 20 + i * 100;
        })
        .attr("y", 10) // 100 is where the first dot appears. 25 is the distance between dots
        .style("fill", function (d) {
          return color(d);
        })
        .text(function (d) {
          return d;
        })
        .attr("text-anchor", "left")
        .style("alignment-baseline", "middle")
        .attr("class", "legend_text");
    };
    renderGraph(data);
  };

  // const jobDrop = [...new Set(data?.map((item) => item.Job))];
  const jobDrop = [...new Set(data?.map((item) => item.Job))].sort((a, b) => {
    return a.localeCompare(b);
  });
  
  const alterJobDrop = jobDrop?.map((list) => {
    return { name: list };
  });
  const degreeDrop = [...new Set(data?.map((item) => item.Degree))].sort((a, b) => {
    return a.localeCompare(b);
  });
  const alterDegreeDrop = degreeDrop?.map((list) => {
    return { name: list };
  });
  const industryDrop = [...new Set(data?.map((item) => item.Industry))].sort((a, b) => {
    return a.localeCompare(b);
  });;
  const alterIndustryDrop = industryDrop?.map((list) => {
    return { name: list };
  });


  const handleFilters = () => {

    dispatch(clearData());
    if (
      !filteredData?.degree?.name ||
      !filteredData?.industry?.name ||
      !filteredData?.job?.name ||
      // !filteredData?.skills.length>0 ||
      !filteredData?.location ||
      !isEmailValid(visitorEmail)
    ) {
      SnackBar.error("Please enter all fields to get job types");
    } else {
      const data = {
        degree: filteredData?.degree?.name,
        industry: filteredData?.industry?.name,
        desiredJob: [filteredData?.job?.name],
        userSkills: filteredData?.skills?.length>0 ? [...filteredData?.skills] : [],
        location: filteredData?.location?.name,
        visitorEmail: visitorEmail 
      };
      
      dispatch(getJobTypes(data));
      setGraphVisibility(false);
      
    }
  
  };
  const classes = useStyles();
  return (
    <div className="mappingGraphContainer" style={{fontFamily: 'Inter, sans-serif'}}>
      <div className="mappingGraphDropContainer">
        <Grid container spacing={3} >
          <Grid item xs={12} sm={3}>
            <SelectSingleValues
              placeholder="Industry*"
              name="industry"
              values={alterIndustryDrop && alterIndustryDrop}
              onSelect={(industry) =>
                setFilteredData({ ...filteredData, industry })
              }
              selected={filteredData.industry}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SelectSingleValues
              placeholder="Degree*"
              name="degree"
              values={alterDegreeDrop && alterDegreeDrop}
              onSelect={(degree) =>
                setFilteredData({ ...filteredData, degree })
              }
              selected={filteredData.degree}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SelectSingleValues
              placeholder="Desired Role*"
              name="job"
              values={alterJobDrop && alterJobDrop}
              onSelect={(job) => setFilteredData({ ...filteredData, job })}
              selected={filteredData.job}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <SelectMultipleValues
            placeholder="Your Skills"
            name="skills"
            dataList={sortedSkills} 
            onSelectData={(skills) => setFilteredData({ ...filteredData, skills })}
            selectedData={filteredData.skills}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <SelectSingleValues
              placeholder="Location*"
              name="location"
              values={sortedLocations}
              onSelect={(location) => setFilteredData({ ...filteredData, location })}
              selected={filteredData.location}
            />
          </Grid> 

          <Grid item xs={12} sm={3}>
            <TextField
            style={{
              width: "290px"
            }}
            label="Email*"
            variant="outlined"
            size="small"
            value = {visitorEmail}
            onChange={(e)=>setVisitorEmail(e.target.value)}
            error={!isEmailValid(visitorEmail) && visitorEmail}
            helperText={visitorEmail && !isEmailValid(visitorEmail)?"Invalid Format":""}
          />
          </Grid>
          
          <Grid item xs={12} sm={3}>
            <Button
              className={classes.button}
              buttonStyle="formSubmit"
              type="button"
              // className="formSubmit"
              name="button"
              // color="primary"
              variant="contained"
              onClick={() => handleFilters()}
              disabled={openAIReducer?.loading}
            >
              Search Jobs
            </Button>
          </Grid>
        </Grid>
      </div>
      {/* {!openAIReducer?.jobTypesData && <Grid container style={{ marginTop: "30px" }}> */}
      {isGraphVisible && <Grid container style={{ marginTop: "30px" }}>
        <Grid item xs={12} sm={12}>
        {/* {isGraphVisible && ( */}
              <div id="mappingGraph" className="svg-container"></div>
            {/* // )} */}
        </Grid>
        
      </Grid>}
    </div>
  );
};

export default JobMappingGraph;

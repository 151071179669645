import React, { useState, useEffect } from "react";

import { Grid, Container, Typography } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import LinearProgress from "@material-ui/core/LinearProgress";
// import LinearProgress from '@mui/material/LinearProgress';
import CardContent from "@material-ui/core/CardContent";
import img1 from "../../images/programs/dataEngineerTheme.png";
import { connect } from "react-redux";
import { getUserPrograms } from "../../redux/actions/programActions";
import { postUserAccessLog } from "../../redux/actions/authActions";
import isEmpty from "../../reusableComponents/is-empty";

const MyPrograms = ({ getUserPrograms, auth, programs, postUserAccessLog }) => {
  const { userPrograms, filteredMyProgramsList } = programs;
  const { user, isAuthenticated } = auth;

  var tabVisibility = document.visibilityState;

  const [timeInterval, setTimeInterval] = useState(0);

  useEffect(() => {
    if (isEmpty(filteredMyProgramsList) && isAuthenticated) {
      getUserPrograms(user._id);
    }
  }, [userPrograms, isAuthenticated]);

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 60000);

  useEffect(() => {
    if (isAuthenticated && tabVisibility === "visible") {
      const data = {
        pageName: "My Programs",
        recordedDate: new Date(),
      };
      postUserAccessLog(data);
    }
  }, [timeInterval, tabVisibility]);

  // React.useEffect(() => {
  //   if (isAuthenticated) {
  //     getUserPrograms(user?._id);
  //   }
  // }, [auth]);

  return (
    <Container className="myProgramsContainer">
      {user?.programsEnrolled?.length > 0 ? (
        <Grid container spacing={2}>
          {filteredMyProgramsList &&
            filteredMyProgramsList?.map((program, idx) => (
              <Grid item xs={12} md={3} align="center" key={idx}>
                <Link
                  to={`/learnerCourse/${program?._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Card className="programCard1" key={idx}>
                    <Typography className="programName">
                      {program?.name}
                    </Typography>
                    <div className="cardImgContainer">
                      <img
                        src={program?.cardImage}
                        className="programCardImg1"
                      />
                    </div>

                    <div className="prgInfoContainer">
                      <p className="prgInfo1" align="left">
                        The complete {program?.name} Program: Mentor/ Coach
                      </p>
                      {/* <p className="prgInfo2" align="left">
                        {`${program?.programMentor?.firstName} ${program?.programMentor?.lastName}, ${program?.programMentor?.description}`}
                      </p> */}
                      {program?.programMentor &&
                        program?.programMentor.map((list) => {
                          return (
                            <p className="prgInfo2" align="left">
                              {`${list.firstName} ${list.lastName}`}
                            </p>
                          );
                        })}

                      {/* <LinearProgress
                        value={50}
                        className="progressBar"
                        variant="determinate"
                      />
                      <p className="prgInfo1">50% completed</p> */}
                    </div>
                  </Card>
                </Link>
              </Grid>
            ))}
        </Grid>
      ) : (
        <Typography
          className="prgInfo1"
          variant="h4"
          align="center"
          style={{ color: "rgb(199, 11, 11)" }}
        >
          You have not purchased any Programs
        </Typography>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  programs: state.programs,
});

const mapDispatchToProps = {
  getUserPrograms,
  postUserAccessLog,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPrograms);

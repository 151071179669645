import isEmpty from "../../reusableComponents/is-empty";
import {
  SET_CURRENT_LOADING,
  SET_CURRENT_USER,
  CLEAR_CURRENT_USER,
} from "../actions/types";

const initialState = {
  loading: false,
  isAuthenticated: false,
  user: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_CURRENT_USER:
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: !isEmpty(user),
        user: user,
        loading: false,
      };
    case CLEAR_CURRENT_USER:
      return {
        ...(state = undefined),
      };
    default:
      return state;
  }
}

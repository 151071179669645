import axios from "axios";
import {
  GET_PROGRAM_LIST,
  GET_PROGRAM_INFO,
  PROGRAM_LOADING,
  PROGRAM_PAYMENT_PAID,
  GET_ILEARN_COURSES,
  UPLOAD_PROGRAM_PROFILE_IMAGE,
  UPLOAD_PROGRAM_CONTENT_IMAGE,
  GET_USER_ENROLLED_PROGRAMS,
  GET_PROGRAM_COURSES,
  GET_ICAPABILITY_TOPICS,
  GET_PROGRAM_TOPICS,
  GET_MENTOR_PROGRAMS,
  GET_PROGRAM_DISENROLLED_USER_LIST,
} from "./types";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import history from "../../reusableComponents/history";
import { setCurrentUser } from "./authActions";
import { getLearnerInfo } from "./admin_view/mvkActions";

// Add new programs
export const addNewProgram = (data) => (dispatch) => {
  axios
    .post(`/api/programs/addPrograms`, data)
    .then((res) => {
      const { message } = res.data;
      history.push("/admin/programsList");
      SnackBar.success(message);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
      history.push("/admin/programsList");
    });
};

// Get Program List
export const getPrograms = () => (dispatch) => {
  dispatch(programLoading());
  axios
    .get(`/api/programs/getPrograms`)
    .then((res) => {
      const { message } = res?.data;
      // SnackBar.success(message);
      dispatch({
        type: GET_PROGRAM_LIST,
        // payload: res?.data?.data,
        payload: {
          programList: res?.data?.data,
          filteredProgramsList: res?.data?.data,
        },
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get User Program List
// export const getUserPrograms = (user) => (dispatch) => {
//   dispatch(programLoading());
//   axios
//     .get(`/api/programs/getUserPrograms/${user}`)
//     .then((res) => {
//       const { message } = res?.data;
//       // SnackBar.success(message);
//       dispatch({
//         type: GET_USER_ENROLLED_PROGRAMS,
//         payload: res?.data?.data,
//       });
//     })
//     .catch((err) => {
//       const { message } = err?.response?.data;
//       SnackBar.error(message);
//     });
// };

export const getUserPrograms = (user) => (dispatch) => {
  dispatch(programLoading());
  axios
    .get(`/api/programs/getUserPrograms/${user}`)
    .then((res) => {
      dispatch({
        type: GET_USER_ENROLLED_PROGRAMS,
        payload: {
          userPrograms: res?.data?.data,
          filteredMyProgramsList: res?.data?.data,
        },
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Search results
export const getSearchResults = (data) => (dispatch) => {
  dispatch(programLoading());
  if (data.pathname === "myPrograms") {
    dispatch({
      type: GET_USER_ENROLLED_PROGRAMS,
      payload: {
        userPrograms: data.userPrograms,
        filteredMyProgramsList: data.filteredCourseList,
      },
    });
  } else {
    dispatch({
      type: GET_PROGRAM_LIST,
      payload: {
        programList: data.programList,
        filteredProgramsList: data.filteredCourseList,
      },
    });
  }
};

// Get Program Courses
export const getProgramCourses = (data) => (dispatch) => {
  dispatch(programLoading());
  axios
    .get(`/api/programs/getProgramCourses/${data}`)
    .then((res) => {
      const { message } = res?.data;
      // SnackBar.success(message);
      dispatch({
        type: GET_PROGRAM_COURSES,
        payload: res?.data?.data,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get Program Topics
export const getProgramTopics = (data) => (dispatch) => {
  dispatch(programLoading());
  axios
    .get(`/api/programs/getProgramTopics/${data}`)
    .then((res) => {
      const { message } = res?.data;
      // SnackBar.success(message);
      dispatch({
        type: GET_PROGRAM_TOPICS,
        payload: res?.data?.data,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get Capability Topics
export const getCapabilityTopics = (data) => (dispatch) => {
  dispatch(programLoading());
  axios
    .get(`/api/programs/getTopics`)
    .then((res) => {
      const { message } = res?.data;
      // SnackBar.success(message);
      dispatch({
        type: GET_ICAPABILITY_TOPICS,
        payload: res?.data?.data,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Update Program
export const updateProgram = (data) => (dispatch) => {
  axios
    .put(`/api/programs/updateProgram/${data.programId}`, data)
    .then((res) => {
      const { message } = res.data;
      history.push("/admin/programsList");
      SnackBar.success(message);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
      history.push("/admin/programsList");
    });
};

// Get Particular Program Info
export const getProgramInfo = (id) => (dispatch) => {
  dispatch(programLoading());
  axios
    .get(`/api/programs/getProgramInfo/${id}`)
    .then((res) =>
      dispatch({
        type: GET_PROGRAM_INFO,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Save Program Payment Info
export const addProgramPayment = (paymentData) => (dispatch) => {
  dispatch(programLoading());
  axios
    .post(`/api/checkout/addProgramPayment`, paymentData)
    .then((res) => {
      // dispatch({
      //   type: PROGRAM_PAYMENT_PAID,
      //   payload: res?.data?.data,
      // })
      const { message } = res.data;
      history.push("/dashboard");
      SnackBar.success(message);
      if (paymentData.outsideUser) {
        dispatch(setCurrentUser());
      }
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get iLearn courses
export const getCourses = () => (dispatch) => {
  dispatch(programLoading());
  axios
    .get(`/api/programs/getIlearnCourses`)
    .then((res) => {
      // const { message } = res?.data;
      // SnackBar.success(message);
      dispatch({
        type: GET_ILEARN_COURSES,
        payload: res?.data?.data,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Program Profile Image
export const uploadProfileImage = (image) => (dispatch) => {
  axios
    .post("/api/adminUser/coachImage", image)
    .then((res) =>
      dispatch({
        type: UPLOAD_PROGRAM_PROFILE_IMAGE,
        payload: res?.data?.message,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Program Content Image
export const uploadContentImage = (image) => (dispatch) => {
  axios
    .post("/api/adminUser/coachImage", image)
    .then((res) =>
      dispatch({
        type: UPLOAD_PROGRAM_CONTENT_IMAGE,
        payload: res?.data?.message,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Assign Program
export const assignProgram = (data) => (dispatch) => {
  axios
    .put(`/api/programs/assignProgram`, data)
    .then((res) => {
      const { message } = res.data;
      SnackBar.success(message);
      dispatch(getLearnerInfo(data.userId));
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

export const removeAssignProgram = (data) => (dispatch) => {
  // dispatch(programBatchLoading());
  // axios
  //   .put(`/api/programBatch/removeBatchUser`, data)
  //   .then((res) => {
  //     const { message } = res.data;
  //     // history.push("/admin/program-batches");
  //     SnackBar.success(message);
  //     dispatch(getBatchUsersList(data.batchId));
  //   })
  //   .catch((err) => {
  //     const { message } = err?.response?.data;
  //     SnackBar.error(message);
  //     history.push("/admin/program-batches");
  //   });
};

// Get User mentor List
export const getMentorPrograms = (user) => (dispatch) => {
  dispatch(programLoading());
  axios
    .get(`/api/programs/getMentorPrograms/${user}`)
    .then((res) => {
      const { message } = res?.data;
      // SnackBar.success(message);
      dispatch({
        type: GET_MENTOR_PROGRAMS,
        payload: res?.data?.data,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

export const getProgramDisEnrolledUserList = (id) => (dispatch) => {
  dispatch(programLoading());
  axios
    .get(`/api/programs/getProgramDisrolledUsers/${id}`)
    .then((res) =>
      dispatch({
        type: GET_PROGRAM_DISENROLLED_USER_LIST,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Program Loading
export const programLoading = () => {
  return {
    type: PROGRAM_LOADING,
  };
};

import React, { Component } from "react";

import { Link as Link1 } from "react-scroll";

import { Container, Grid, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import ButtonField from "./ButtonField";
// import ProgramCards from '../../reusableComponents/programCards'
import { makeStyles } from "@material-ui/core/styles";

import dataEngCardImg from "../images/programs/dataEngineering_cardImg.jpg";

const useStyles = makeStyles((theme) => ({
  newButton: {
    width: "100%",
  },
}));

const ProgramCards = ({
  history,
  programs,
  setProgramId,
  userPrograms,
  handleIlearn,
}) => {
  const classes = useStyles();
  
  const enrolled = userPrograms?.find((item) => item === programs._id);
 
  return (
    <Card className="optionCard">
      <CardContent>
        <div className="imgCard imagCard1">
          <div className="cardText">
            <Typography
              className="headingText1"
              variant="h6"
              style={{ fontSize: "26px" }}
            >
              {programs.name}
              {/* {programList? programList[0].name : 'null'} */}
            </Typography>
          </div>
          <div className="cardImg">
            <img className="imgInfo" src={programs.cardImage} />
          </div>
        </div>

        <br />

        <Typography className="headingText1 noHrs" variant="h6">
          {programs.totalHours} Hours |Online
        </Typography>

        <div className={classes.newButton}>
          <Link1 to="prgDetails" smooth={true} duration={500}>
            <ButtonField
              buttonStyle={classes.newButton}
              name="Program Details"
              onClick={() =>
              setProgramId(programs)}
            />
          </Link1>
        </div>

        <div className={classes.newButton} style={{ marginTop: "5px" }}>
          {/* <Link to="/paymentProcess"> */}
          <ButtonField
            buttonStyle={classes.newButton}
            color={!enrolled ? null : "#ff8300"}
            name={!enrolled ? "Enroll To This Program" : "Enrolled"}
            onClick={() =>
              !enrolled
                ? history.push(`/paymentProcess/${programs._id}`)
                : console.log("enrolled")
            }
          />
          {/* </Link> */}
        </div>

        <div className={classes.newButton} style={{ marginTop: "5px" }}>
          <Link1 smooth={true} duration={500}>
            <ButtonField
              buttonStyle={classes.newButton}
              name="Explore Ilearn Courses"
              onClick={() => handleIlearn()}
            />
          </Link1>
        </div>
        <br />
        <hr />
        <br />

        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <Typography className="headingText1 noHrs" variant="h6">
              ESTIMATED TIME
            </Typography>
            <Typography
              className="headingText1 noHrs"
              variant="h6"
              style={{ fontWeight: 600, color: "rgb(82, 80, 80)" }}
            >
              3 Months
            </Typography>
            <Typography className="headingText1 noHrs" variant="h6">
              At {programs.hoursNeeded} hrs/day
            </Typography>
          </Grid>

          <Grid item xs={6} md={6}>
            {/* <Typography className="headingText1 noHrs" variant="h6">
              STARTS ON
            </Typography>
            <Typography
              className="headingText1 noHrs"
              variant="h6"
              style={{ fontWeight: 600, color: "rgb(82, 80, 80)" }}
            >
              {`${programs.programStartDate} to ${programs.programEndDate}`}
            </Typography> */}
            <Typography className="headingText1 noHrs" variant="h6">
              Get access to classroom immediately on enrollment
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProgramCards;

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import ZoomMeeting from "../../reusableComponents/ZoomMeeting";
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
import Logo1 from "../../images/logo/iBridge360-logo.png";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ChatComponent from "./ChatComponent";
import { useSelector, useDispatch } from "react-redux";
import { AccountCircle } from "@material-ui/icons";
import { getScheduleInfo } from "../../redux/actions/scheduleActions";

import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const drawerWidth = 480;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  customizeToolbar: {
    minHeight: "12px",
    height: "21px",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const JoinMeeting = ({ location }) => {
  const dispatch = useDispatch();
  const { scheduleId } = useParams();
  const { auth, schedules } = useSelector((state) => state);
  const { isAuthenticated, user } = auth;
  const { scheduleInfo } = schedules;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(getScheduleInfo(scheduleId));
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar className={classes.customizeToolbar}>
            <Typography variant="h6" noWrap className={classes.title}>
              Ibridge Zoom Session
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              className={clsx(open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          {/* <div className={classes.drawerHeader} /> */}
          <ZoomMeeting user={user} scheduleInfo={scheduleInfo} />
        </main>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </div>
          <ChatComponent scheduleId={scheduleId} />
        </Drawer>
      </div>
      {/* <AppBar className="headerContainer">
        <Toolbar>
          <Container maxWidth="lg" className="headerContent">
            <img
              style={{
                width: "205px",
                height: "60px",
                objectFit: "cover",
              }}
              src={Logo1}
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_WORDPRESS_URL}`)
              }
            />
            <div className={classes.grow} />
            <div className={classes.userDetails}>
              <Button
                disableRipple
                className={classes.profileIconButton}
                aria-controls="menu-appbar"
                aria-haspopup="true"
                variant="outlined"
              >
                <AccountCircle className={classes.profileIcon} />{" "}
                {user?.firstName}{" "}
              </Button>
            </div>
          </Container>
        </Toolbar>
      </AppBar> */}
    </>
  );
};

export default JoinMeeting;

import axios from 'axios';
import { ADMIN_ADD_NEW_COACH_LOADING, ADMIN_GET_INDUSTRY_LIST, ADMIN_GET_SKILLS_LIST, ADMIN_GET_DAYS_LIST, ADMIN_GET_TIMINGS_LIST, ADMIN_GET_COACH_IMAGE, ADMIN_GET_TIMEZONE_LIST } from '../types';
import { SnackBar } from '../../../reusableComponents/SnackbarConfig';
import history from '../../../reusableComponents/history';

// register Coach
export const registerCoach = (userData) => dispatch => {
    axios.post('/api/adminUser/coachSignUp', userData)
    .then(res => {
        const { message } = res?.data;
        history.push('/admin/coachList');
        SnackBar.success(message);
    })
    .catch(err => {
        const { message } = err?.response?.data;
        SnackBar.error(message);
    })
}

// Coach Image
export const getCoachImage = (userImage) => dispatch => {
    axios.post('/api/adminUser/coachImage', userImage)
    .then(res => 
        dispatch({
            type: ADMIN_GET_COACH_IMAGE,
            payload: res?.data?.message
        })
    )
    .catch(err => {
        const { message } = err?.response?.data;
        SnackBar.error(message);
    })
}

// Get Indusry List
export const getIndustryList = () => dispatch => {
    dispatch(addNewCoachLoading());
    axios.get('/api/industry/getIndustryList')
    .then(res => 
        dispatch({
            type: ADMIN_GET_INDUSTRY_LIST,
            payload: res?.data?.message
        })    
    )
    .catch(err => {
        const { message } = err?.response?.data;
        SnackBar.error(message);
    })
}

// Get Skills List
export const getSkillsList = () => dispatch => {
    dispatch(addNewCoachLoading());
    axios.get('/api/skills/getSkillsList')
    .then(res => 
        dispatch({
            type: ADMIN_GET_SKILLS_LIST,
            payload: res?.data?.message
        })    
    )
    .catch(err => {
        const { message } = err?.response?.data;
        SnackBar.error(message);
    })
}

// Get Days List
export const getDaysList = () => dispatch => {
    dispatch(addNewCoachLoading());
    axios.get('/api/d&T/getDays')
    .then(res => 
        dispatch({
            type: ADMIN_GET_DAYS_LIST,
            payload: res?.data?.message
        })    
    )
    .catch(err => {
        const { message } = err?.response?.data;
        SnackBar.error(message);
    })
}

// Get Timings List
export const getTimingsList = () => dispatch => {
    dispatch(addNewCoachLoading());
    axios.get('/api/d&T/getTimings')
    .then(res => 
        dispatch({
            type: ADMIN_GET_TIMINGS_LIST,
            payload: res?.data?.message
        })    
    )
    .catch(err => {
        const { message } = err?.response?.data;
        SnackBar.error(message);
    })
}

// Get Timezone List
export const getTimeZoneList = () => dispatch => {
    dispatch(addNewCoachLoading());
    axios.get('/api/d&T/getTimeZoneList')
    .then(res => 
        dispatch({
            type: ADMIN_GET_TIMEZONE_LIST,
            payload: res?.data?.message
        })    
    )
    .catch(err => {
        const { message } = err?.response?.data;
        SnackBar.error(message);
    })
}

// Add New Coach Loading
export const addNewCoachLoading = () => {
    return {
        type : ADMIN_ADD_NEW_COACH_LOADING
    }
}
import React from "react";
import { Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import AdminLayout from "./components/Admin_View/Layout/AdminLayout";

import Dashboard from "./components/Admin_View/Dashboard/Dashboard";
import Mkv from "./components/Admin_View/Mkv/Mkv";
import Learner from "./components/Admin_View/LearnerInfo/Learner";
import AddNewCoach from "./components/Admin_View/AddNewCoach/AddNewCoach";
import CoachList from "./components/Admin_View/CoachInfo/CoachList";
import CoachInfo from "./components/Admin_View/CoachInfo/CoachInfo";
import NewServiceProvider from "./components/Admin_View/ServiceProvider/NewServiceProvider";
import ServiceProviderList from "./components/Admin_View/ServiceProvider/ServiceProviderList";
import ServiceProviderInfo from "./components/Admin_View/ServiceProvider/ServiceProviderInfo";
import CounsellingRequest from "./components/Admin_View/CounsellingRequest/counsellingRequest";
import SchedulingList from "./components/Admin_View/SchedulingList/schedulingList";
import ProgramBatch from "./components/Admin_View/ProgramBatch/programBatch";

import ProgramsList from "./components/Admin_View/Programs/ProgramList";
import AddPrograms from "./components/Admin_View/Programs/AddPrograms";
import AddSchedulings from "./components/Admin_View/SchedulingList/AddSchedulings";
import ProgramInfo from "./components/Admin_View/Programs/ProgramInfo";
import CreateBatch from "./components/Admin_View/ProgramBatch/CreateBatch";
import AddBatchUsers from "./components/Admin_View/ProgramBatch/AddBatchUsers";
import BatchUsersList from "./components/Admin_View/ProgramBatch/BatchUsersList";

import SchedulingReport from "./components/Admin_View/SchedulingList/SchedulingReport";
import LearnerTaskReport from "./components/Admin_View/LearnerInfo/LearnerTaskReport";

// service provider
import ServiceProviderDashboard from "./components/ServiceProvider_Module/ServiceProviderDashboard";

// mentors
import mentorDashboard from "./components/Mentor_Module/MentorDashboard";
import programLearnerList from "./components/Mentor_Module/programLearnerList";
import MentorLearnerInfo from "./components/Mentor_Module/MentorLearnerInfo";

const AdminRoutes = ({ match }) => {
  return (
    <Switch>
      <AdminLayout>
        <PrivateRoute path={`${match.url}/dashboard`} component={Dashboard} />
        <PrivateRoute
          path={`${match.url}/mentorDashboard`}
          component={mentorDashboard}
        />
        <PrivateRoute
          path={`${match.url}/serviceProviderDashboard`}
          component={ServiceProviderDashboard}
        />
        <PrivateRoute path={`${match.url}/mvkLists`} component={Mkv} />
        <PrivateRoute
          path={`${match.url}/learner/:userId`}
          component={Learner}
        />
        <PrivateRoute
          path={`${match.url}/addNewcoach`}
          component={AddNewCoach}
        />
        <PrivateRoute path={`${match.url}/coachList`} component={CoachList} />
        <PrivateRoute
          path={`${match.url}/coach/:userId`}
          component={CoachInfo}
        />
        <PrivateRoute
          path={`${match.url}/newServiceProvider`}
          component={NewServiceProvider}
        />
        <PrivateRoute
          path={`${match.url}/serviceProviderList`}
          component={ServiceProviderList}
        />
        <PrivateRoute
          path={`${match.url}/serviceProviderInfo/:userId`}
          component={ServiceProviderInfo}
        />

        <PrivateRoute
          path={`${match.url}/counsellingRequest`}
          component={CounsellingRequest}
        />
        <PrivateRoute
          path={`${match.url}/schedulingList`}
          component={SchedulingList}
        />
        <PrivateRoute
          path={`${match.url}/program-batches`}
          component={ProgramBatch}
        />
        <PrivateRoute
          path={`${match.url}/batchUsersList/:batchId/:batchName`}
          component={BatchUsersList}
        />

        <PrivateRoute
          path={`${match.url}/programsList`}
          component={ProgramsList}
        />
        <PrivateRoute
          path={`${match.url}/addPrograms`}
          component={AddPrograms}
        />
        <PrivateRoute
          path={`${match.url}/addSchedulings`}
          component={AddSchedulings}
        />
        <PrivateRoute
          path={`${match.url}/createBatch`}
          component={CreateBatch}
        />
        <PrivateRoute
          path={`${match.url}/addBatchUsers/:batchId`}
          component={AddBatchUsers}
        />
        <PrivateRoute
          path={`${match.url}/programInfo/:programId`}
          component={ProgramInfo}
        />

        <PrivateRoute
          path={`${match.url}/getAllUserTasks`}
          component={LearnerTaskReport}
        />

        {/* mentor's */}
        <PrivateRoute
          path={`${match.url}/programLearnerList/:programId`}
          component={programLearnerList}
        />

        <PrivateRoute
          path={`${match.url}/mentorLearnerInfo/:userId`}
          component={MentorLearnerInfo}
        />

        <PrivateRoute
          path={`${match.url}/schedulingReport`}
          component={SchedulingReport}
        />
      </AdminLayout>
    </Switch>
  );
};

export default AdminRoutes;

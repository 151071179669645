import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Button, Paper, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import { getProgramBatchList } from "../../../redux/actions/programBatchActions";
import { createSchedule } from "../../../redux/actions/scheduleActions";
import DateTimePick from "../../../reusableComponents/DateTimePick";
import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";
import { getMentorBatchList } from "../../../redux/actions/mentorActions";
import {
  getScheduleInfo,
  editSchedule,
} from "../../../redux/actions/scheduleActions";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const EditSchedulings = ({
  setOpenEdit,
  scheduleId,
  getProgramBatchList,
  auth,
  programBatch,
  createSchedule,
  getMentorPrograms,
  getMentorBatchList,
  mentor,
  getScheduleInfo,
  schedules,
  editSchedule,
}) => {
  const classes = useStyles();
  const { programBatchList } = programBatch;
  const { mentorBatchList } = mentor;
  const { isAuthenticated, user } = auth;
  const currentUser = user?.role?.r_id === 1 ? "admin" : "mantor";
  const { scheduleInfo } = schedules;

  useEffect(() => {
    if (isAuthenticated) {
      if (currentUser === "admin") {
        getProgramBatchList();
      } else {
        getMentorBatchList(user?._id);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      getScheduleInfo(scheduleId);
    }
  }, [isAuthenticated, auth]);

  useEffect(() => {
    formik.setFieldValue(
      "scheduleTitle",
      scheduleInfo && scheduleInfo.name ? scheduleInfo.name : ""
    );
    formik.setFieldValue(
      "programBatch",
      scheduleInfo ? scheduleInfo?.programBatch : {}
    );
    formik.setFieldValue(
      "scheduleStartDate",
      scheduleInfo && scheduleInfo.scheduleStartDate
        ? new Date(scheduleInfo.scheduleStartDate)
        : new Date()
    );
    formik.setFieldValue(
      "scheduleEndDate",
      scheduleInfo && scheduleInfo.scheduleEndDate
        ? new Date(scheduleInfo.scheduleEndDate)
        : new Date()
    );
    formik.setFieldValue(
      "scheduleDescription",
      scheduleInfo && scheduleInfo.description ? scheduleInfo.description : ""
    );
    formik.setFieldValue(
      "zoomMeetingId",
      scheduleInfo && scheduleInfo.zoomMeetingId
        ? scheduleInfo.zoomMeetingId
        : undefined
    );
    formik.setFieldValue(
      "zoomMeetingPassword",
      scheduleInfo && scheduleInfo.zoomMeetingPassword
        ? scheduleInfo.zoomMeetingPassword
        : undefined
    );
    formik.setFieldValue("taskType", {
      name: scheduleInfo ? scheduleInfo?.taskType : "",
    });
  }, [scheduleInfo]);

  const formik = useFormik({
    initialValues: {
      scheduleStartDate: new Date(),
      scheduleEndDate: new Date(),
      scheduleTitle: "",
      programBatch: {},
      scheduleDescription: "",
      taskType: [],
    },
    validationSchema: Yup.object({
      scheduleTitle: Yup.string()
        .trim()
        .required("Schedule Title is required field"),
      scheduleDescription: Yup.string()
        .trim()
        .required("Schedule Description is required field"),
      //   scheduleStartDate: Yup.date().required(
      //     "Program Start date is a required field"
      //   ).min(
      //       Yup.ref((new Date()).toString()),
      //       "Program Start date can't be before current date"
      //     ),
      //   scheduleEndDate: Yup.date().required(
      //     "Program Start date is a required field"
      //   ).min(
      //       Yup.ref((new Date(scheduleStartDate)).toString()),
      //       "Program End date can't be before Start date"
      //     ),
    }),

    onSubmit: async (values) => {
      const scheduleData = {
        _id: scheduleId,
        name: values.scheduleTitle,
        description: values.scheduleDescription,
        programBatch: values.programBatch?._id,
        scheduleStartDate: values.scheduleStartDate,
        scheduleEndDate: values.scheduleEndDate,
        taskType: values.taskType.name,
        zoomMeetingId: values?.zoomMeetingId?.replace(/ +/g, ""),
        zoomMeetingPassword: values.zoomMeetingPassword,
      };
      setOpenEdit(false);
      editSchedule(scheduleData, auth.user);
      // console.log("scheduleData",scheduleData)
    },
  });

  const taskTypeList = [
    { name: "Zoom / Face to Face session" },
    { name: "iLearn Session" },
    { name: "iCapability Session" },
    { name: "iProductivity Session" },
    { name: "Lab Session" },
    { name: "Project Session" },
    { name: "Other" },
  ];

  const handleStartDate = (e) => {
    console.log(e);
    // const startDate = new Date(e) < new Date() ? new Date : new Date(e) ;
    const startDate = new Date(e);
    formik.setFieldValue("scheduleStartDate", startDate);
    const endDate =
      new Date(e) < startDate ? startDate.getHours() + 1 : new Date(e);
    endDate.setHours(endDate.getHours() + 1);
    formik.setFieldValue("scheduleEndDate", endDate);
  };

  const handleEndDate = (e) => {
    console.log(
      "formik.values.scheduleStartDate",
      e,
      "formik.values.scheduleStartDate.getHours() +1",
      formik.values.scheduleStartDate.getHours() + 1,
      new Date(e) < new Date(formik.values.scheduleStartDate),
      formik.values.scheduleStartDate
    );
    const endDate =
      new Date(e) < new Date(formik.values.scheduleStartDate)
        ? formik.values.scheduleStartDate
        : new Date(e);
    console.log("endDate", endDate);
    formik.setFieldValue("scheduleEndDate", endDate);
  };

  const adminProgramBatchData = programBatchList?.map((program) => {
    const { _id, name } = program;
    return {
      _id,
      name,
    };
  });

  const mentorProgramBatchData = mentorBatchList?.map((program) => {
    const { _id, name } = program;
    return {
      _id,
      name,
    };
  });

  const programBatchData =
    currentUser === "admin" ? adminProgramBatchData : mentorProgramBatchData;

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextFieldGroup
            label=" Schedule Title"
            type="text"
            name="scheduleTitle"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.scheduleTitle}
            errors={
              formik.touched.scheduleTitle && formik.errors.scheduleTitle
                ? formik.errors.scheduleTitle
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <div>
            <SelectSingleValues
              label="Select Batch"
              name="programBatch"
              values={programBatchData || []}
              onSelect={(batch) => formik.setFieldValue("programBatch", batch)}
              selected={formik.values.programBatch}
              errors={
                formik.touched.programBatch && formik.errors.programBatch
                  ? formik.errors.programBatch
                  : null
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <DateTimePick
            label="Schedule Start date"
            name="scheduleStartDate"
            showLable={true}
            // disablePast={true}
            className="dateTimePicker"
            // inputVariant="outlined"
            selectedDate={formik.values.scheduleStartDate}
            handleDateChange={(scheduledDateTime) =>
              handleStartDate(scheduledDateTime)
            }
            // errors={
            //     formik.touched.scheduleStartDate && formik.errors.scheduleStartDate
            //       ? formik.errors.scheduleStartDate
            //       : null
            //   }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DateTimePick
            label="Schedule End date"
            name="scheduleEndDate"
            showLable={true}
            // disablePast={true}
            className="dateTimePicker"
            // inputVariant="outlined"
            selectedDate={formik.values.scheduleEndDate}
            handleDateChange={(scheduledDateTime) =>
              handleEndDate(scheduledDateTime)
            }
            // handleEndDate(scheduledDateTime)
            // }
            // errors={
            //     formik.touched.scheduleStartDate && formik.errors.scheduleStartDate
            //       ? formik.errors.scheduleStartDate
            //       : null
            //   }
          />
        </Grid>

        <Grid item xs={12} md={6} align="center">
          <SelectSingleValues
            label="Task Type"
            name="taskType"
            values={taskTypeList || []}
            onSelect={(batch) => formik.setFieldValue("taskType", batch)}
            selected={formik.values.taskType}
            errors={
              formik.touched.taskType && formik.errors.taskType
                ? formik.errors.taskType
                : null
            }
          />
        </Grid>

        {formik?.values?.taskType?.name === "Zoom / Face to Face session" ? (
          <>
            <Grid item xs={12} md={6}>
              <TextFieldGroup
                label="Zoom Meeting Id"
                type="text"
                name="zoomMeetingId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.zoomMeetingId}
                errors={
                  formik.touched.zoomMeetingId && formik.errors.zoomMeetingId
                    ? formik.errors.zoomMeetingId
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldGroup
                label="Zoom Meeting Password"
                type="text"
                name="zoomMeetingPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.zoomMeetingPassword}
                errors={
                  formik.touched.zoomMeetingPassword &&
                  formik.errors.zoomMeetingPassword
                    ? formik.errors.zoomMeetingPassword
                    : null
                }
              />
            </Grid>
          </>
        ) : null}

        <Grid item xs={12} md={6} align="center">
          <TextFieldGroup
            label="Schedule Description"
            type="text"
            name="scheduleDescription"
            multiline
            rows={4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.scheduleDescription}
            errors={
              formik.touched.scheduleDescription &&
              formik.errors.scheduleDescription
                ? formik.errors.scheduleDescription
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={12} align="center">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="submit"
              name="submit"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              // onClick={submitForm}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      {renderForm}
    </Container>
  );
};

EditSchedulings.propTypes = {
  auth: PropTypes.object.isRequired,
  getProgramBatchList: PropTypes.func.isRequired,
  createSchedule: PropTypes.func.isRequired,
  getMentorBatchList: PropTypes.func.isRequired,
  getScheduleInfo: PropTypes.func.isRequired,
  editSchedule: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  adminCoach: state.adminCoach,
  programBatch: state.programBatch,
  mentor: state.mentor,
  schedules: state.schedules,
});

const mapDispatchToProps = {
  getProgramBatchList,
  createSchedule,
  getMentorBatchList,
  getScheduleInfo,
  editSchedule,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSchedulings);

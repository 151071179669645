// import React, { Component } from 'react';

// const EditLearnerProfile = () => {
//     return (
//         <h1>
//             Hello Edit Learner Profile
//         </h1>
//      );
// }

// export default EditLearnerProfile;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Button, Container } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthContainerView from "../SignIn_SignUp/AuthContainerView";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import ButtonField from "../../reusableComponents/ButtonField";
import { editUserDetials } from "../../redux/actions/authActions";

const EditLearnerProfile = ({ editUserDetials, auth, history }) => {
  const { isAuthenticated, user } = auth;

  useEffect(() => {
    formik.setFieldValue(
      "firstName",
      user && user.firstName ? user.firstName : ""
    );
    formik.setFieldValue(
      "lastName",
      user && user.lastName ? user.lastName : ""
    );
    formik.setFieldValue(
      "userName",
      user && user.userName ? user.userName : ""
    );
    formik.setFieldValue(
      "phoneNumber",
      user && user.phoneNumber ? user.phoneNumber : ""
    );
    formik.setFieldValue(
      "education",
      user && user.education ? user.education : ""
    );
    formik.setFieldValue("city", user && user.city ? user.city : "");
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  // const passwordRegExp ="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})";
  const phoneRegExp =
    "^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$";
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      phoneNumber: "",
      education: "",
      city: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .trim()
        .required("First Name is a required field")
        .min(3, "Name must be at least 3 characters"),
      lastName: Yup.string().trim().required("Last Name is a required field"),
      userName: Yup.string()
        .trim()
        .required("Name is a required field")
        .min(4, "Name must be at least 4 characters"),
      phoneNumber: Yup.string()
        // .min(10, 'Name must be at least 6 characters')
        .length(10, "Phone Number must have 10 numbers")
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone number is required field"),
      education: Yup.string().trim().required("Education is required"),
      city: Yup.string().trim().required("City is required"),
    }),
    onSubmit: async (values) => {
      let userData = values;
      userData._id = user?._id;
      await editUserDetials(userData);
    },
  });

  // useEffect(() => {
  //     if(isAuthenticated) {
  //         history.push('/dashboard')
  //     }
  // }, [auth])

  return (
    <>
      <Container className="learnerEditProfileContainer">
        <form
          onSubmit={formik.handleSubmit}
          noValidate
          autoComplete="off"
          style={{ paddingTop: "10px" }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                label="FirstName"
                type="text"
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                errors={
                  formik.touched.firstName && formik.errors.firstName
                    ? formik.errors.firstName
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                label="LastName"
                type="text"
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                errors={
                  formik.touched.lastName && formik.errors.lastName
                    ? formik.errors.lastName
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                label="UserName"
                type="text"
                name="userName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.userName}
                errors={
                  formik.touched.userName && formik.errors.userName
                    ? formik.errors.userName
                    : null
                }
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} className="inputFieldContainer">
                        <TextFieldGroup 
                            label="Email"
                            type="text"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            errors={formik.touched.email && formik.errors.email ? formik.errors.email : null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className="inputFieldContainer">
                        <TextFieldGroup 
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            passwordControl={() => setShowPassword(!showPassword)}
                            showPassword={showPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            errors={formik.touched.password && formik.errors.password ? formik.errors.password : null}
                        />
                    </Grid> */}
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                label="PhoneNumber"
                type="text"
                name="phoneNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                errors={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                    ? formik.errors.phoneNumber
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                label="Education"
                type="text"
                name="education"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.education}
                errors={
                  formik.touched.education && formik.errors.education
                    ? formik.errors.education
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                label="City"
                type="text"
                name="city"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                errors={
                  formik.touched.city && formik.errors.city
                    ? formik.errors.city
                    : null
                }
              />
            </Grid>
          </Grid>
          <div className=" signUpSubmit submitButton" align="center">
            <Button
              buttonStyle="formSubmit"
              type="submit"
              className="editFormSubmit"
              name="SIGN UP"
              variant="contained"
              color="primary"
              // style={{backgroundColor:"#008dd2"}}
              style={{ marginTop: "2%" }}
            >
              Save
            </Button>
          </div>
        </form>
      </Container>
    </>
  );
};

EditLearnerProfile.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { editUserDetials };

export default connect(mapStateToProps, mapDispatchToProps)(EditLearnerProfile);

import axios from "axios";
import {  SCHEDULE_LOADING, GET_JOB_POSTS, SET_JOB_TITLE, NO_JOB_POSTS_DATA, CLEAR_JOB_POSTS_DATA } from "./types";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import history from "../../reusableComponents/history";




// Get Job description List
export const getJobPosts = (data) =>{
  return (dispatch) => {
  dispatch(scheduleLoading());
  dispatch({type: SET_JOB_TITLE, payload: data})
  axios.post(`/api/jobPostsApi/search`, data)
    .then((res) =>{
    if(res.data.length == 0){
      dispatch({type: NO_JOB_POSTS_DATA})
    }
    else{
      dispatch({
        type: GET_JOB_POSTS,
        payload: res,
      })
    }
  })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

}


// Schedule Loading
export const scheduleLoading = () => {
  return {
    type: SCHEDULE_LOADING,
  };
};

export const clearData = () => {
  return {
    type: CLEAR_JOB_POSTS_DATA
  }
}
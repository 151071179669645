import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar, Button } from "@material-ui/core";
// import Table from '../../MaterialTable/Table';
import MUITable from "../../MaterialTable/MaterialTable";
import EventIcon from "@material-ui/icons/Event";
import PageHeader from "../../../reusableComponents/PageHeader";
import { ListAlt as ListAltIcon } from "@material-ui/icons";
import { getScheduleList } from "../../../redux/actions/scheduleActions";
import { getDateTime } from "../../../reusableComponents/GetDate";
import { Search as SearchIcon, Add as AddIcon } from "@material-ui/icons";
import ButtonField from "../../../reusableComponents/ButtonField";
import { getMentorScheduleList } from "../../../redux/actions/mentorActions";

import CustomizedDialogs from "../../../reusableComponents/DialogModal";
// import ProgramSchedule from "../../Learner_Module/ProgramSchedule";
import BigCalendar from "../../../reusableComponents/BigCalendar";
import EditSchedulings from "./EditSchedulings";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

const SchedulingList = ({
  getScheduleList,
  schedules,
  getMentorScheduleList,
  mentor,
  auth,
  history,
}) => {
  const classes = useStyles();
  const { isAuthenticated, user } = auth;
  const [open, setOpen] = useState(false);
  const [scheduleId, setScheduleId] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [adminEvent, setAdminEvent] = useState({
    title: "",
    start: "",
    end: "",
  });

  const { scheduleList } = schedules;
  const { mentorScheduleList } = mentor;
  const currentUser = user?.role?.r_id === 1 ? "admin" : "mentor";

  useEffect(() => {
    if (isAuthenticated) {
      if (currentUser === "admin") getScheduleList();
      else {
        getMentorScheduleList(user?._id);
      }
    }
  }, [isAuthenticated]);

  const columns = [
    {
      title: "Schedule Title",
      field: "name",
    },
    // {
    //   title: "Schedule Description",
    //   field: "description",
    // },
    // {
    //   title: "Program Name",
    //   field: "programName",
    // },
    {
      title: "Program batch",
      field: "programBatch",
    },
    {
      title: "Task Type",
      field: "taskType",
    },
    {
      title: "ScheduleStart Date",
      field: "scheduleStartDate",
    },
    {
      title: "Schedule End Date",
      field: "scheduleEndDate",
    },
    {
      title: "Calendar View",
      field: "calendarView",
    },
    {
      title: "Edit Schedule",
      field: "editSchedule",
    },
  ];

  const adminData = scheduleList?.map((schedule) => {
    const {
      _id,
      name,
      // description,
      programBatch,
      // programName,
      taskType,
      scheduleEndDate,
      scheduleStartDate,
    } = schedule;
    return {
      _id,
      name,
      // description,
      // programName: programBatch?.programName?.name,
      programBatch: programBatch?.name,
      taskType,
      scheduleStartDate: new Date(scheduleStartDate).toDateString(),
      scheduleEndDate: new Date(scheduleEndDate).toDateString(),
      calendarView: (
        <EventIcon
          onClick={() =>
            currentUser === "admin"
              ? adminCalendarView(programBatch?.name)
              : mentorCalendarView(programBatch?.name)
          }
        />
      ),
      editSchedule: (
        <Button
          variant="contained"
          color="primary"
          type="submit"
          name="submit"
          onClick={() => {
            setOpenEdit(true);
            setScheduleId(_id);
          }}
        >
          Edit
        </Button>
      ),
    };
  });

  const adminCalendarView = (row) => {
    const data = scheduleList
      ?.filter((item) => item?.programBatch?.name == row)
      .map((item1) => {
        return {
          title: `${item1?.name}\n${item1?.description}`,
          start: new Date(item1?.scheduleStartDate),
          end: new Date(item1?.scheduleEndDate),
        };
      });
    setAdminEvent(data);
    setOpen(true);
  };

  // ------------------mentor--------------------------------------
  const mentorData = mentorScheduleList?.map((schedule) => {
    const {
      _id,
      name,
      // description,
      programBatch,
      programMentor,
      // programName,
      taskType,
      scheduleEndDate,
      scheduleStartDate,
    } = schedule;
    return {
      _id,
      name,
      // description,
      // programName: programName?.name,
      programBatch: programBatch?.name,
      taskType,
      scheduleStartDate: new Date(scheduleStartDate).toDateString(),
      scheduleEndDate: new Date(scheduleEndDate).toDateString(),
      calendarView: (
        <EventIcon
          onClick={(row) =>
            currentUser === "admin"
              ? adminCalendarView(programBatch?.name)
              : mentorCalendarView(programBatch?.name)
          }
        />
      ),
      editSchedule: (
        <Button
          variant="contained"
          color="primary"
          type="submit"
          name="submit"
          onClick={() => {
            setOpenEdit(true);
            setScheduleId(_id);
          }}
        >
          Edit
        </Button>
      ),
    };
  });

  const mentorCalendarView = (row) => {
    const data = mentorScheduleList
      ?.filter((item) => item.programBatch.name == row)
      .map((item1) => {
        return {
          title: `${item1.name}\n${item1.description}`,
          end: new Date(item1.scheduleEndDate),
          start: new Date(item1.scheduleStartDate),
        };
      });
    setAdminEvent(data);
    setOpen(true);
  };

  const data = currentUser === "admin" ? adminData : mentorData;

  return (
    <>
      <Container maxWidth="lg" className="adminMvk">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MUITable
              title={
                <ButtonField
                  variant="outlined"
                  name="Create Schedule"
                  icon={<AddIcon />}
                  onClick={() => history.push("addSchedulings")}
                  // onClick={setOpen(true)}
                />
              }
              columns={columns}
              data={data}
              pageSize={10}
              selectedRow={(row) => console.log("clicked")}
              // selectedRow={() => setOpen(true)}
              // {

              // selectedRow={(row) =>
              //   currentUser === "admin"
              //     ? adminCalendarView(row)
              //     : mentorCalendarView(row)
              // }
              // }
              // selectedRow={(row) => calendarView(row)}
              // selectedRow={(row) => history.push(`/admin/programInfo/${row._id}`)}
            />
          </Grid>
        </Grid>
        {/* {
        currentUser === 'admin'?
        <CustomizedDialogs title="calendar View" children={<BigCalendar  eventData={adminEvent}/>} openPopup={open} setOpenPopup = {setOpen} />
        :
        <CustomizedDialogs title="calendar View" children={<BigCalendar  eventData={adminEvent}/>} openPopup={open} setOpenPopup = {setOpen} />
      } */}
        <CustomizedDialogs
          title="calendar View"
          children={<BigCalendar eventData={adminEvent} />}
          openPopup={open}
          setOpenPopup={setOpen}
        />
        <CustomizedDialogs
          title="Edit Schedule"
          children={
            <EditSchedulings
              scheduleId={scheduleId}
              setOpenEdit={setOpenEdit}
            />
          }
          openPopup={openEdit}
          setOpenPopup={setOpenEdit}
        />
      </Container>
    </>
  );
};

SchedulingList.propTypes = {
  auth: PropTypes.object.isRequired,
  getScheduleList: PropTypes.func.isRequired,
  //   adminCoach: PropTypes.object.isRequired,
  getMentorScheduleList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  schedules: state.schedules,
  mentor: state.mentor,
});

const mapDispatchToProps = { getScheduleList, getMentorScheduleList };

export default connect(mapStateToProps, mapDispatchToProps)(SchedulingList);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Container, Grid, Typography, Button } from "@material-ui/core";
import AuthContainerView from "./AuthContainerView";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import ButtonField from "../../reusableComponents/ButtonField";
import { loginUser } from "../../redux/actions/authActions";
import { Link } from "react-router-dom";

const SignIn = ({ loginUser, auth, history }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { isAuthenticated, user } = auth;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().trim().required("Username / Email is required field"),
      password: Yup.string().required("Password is required field"),
    }),
    onSubmit: async (values) => {
      await loginUser(values);
    },
  });

  useEffect(() => {
    if (isAuthenticated) {
      if (user?.role?.r_id === 1) {
        history.push("/admin/dashboard");
      } 
      
      else if (user?.role?.r_id === 3) {
        history.push("/admin/mentorDashboard");
      } 
      
      else if (user?.role?.r_id === 4) {
        history.push("/admin/serviceProviderDashboard");
      }
       
      else if (user?.role?.r_id === 2) {
        if (user?.programsEnrolled?.length > 0) {
          history.push("/myPrograms");
        } else {
          history.push("/dashboard");
        }
      }
    }
  }, [auth]);

  const renderForm = (
    <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <TextFieldGroup
        label="Username / Email"
        type="text"
        name="email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        errors={
          formik.touched.email && formik.errors.email
            ? formik.errors.email
            : null
        }
      />
      <TextFieldGroup
        label="Password"
        type={showPassword ? "text" : "password"}
        name="password"
        passwordControl={() => setShowPassword(!showPassword)}
        showPassword={showPassword}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        errors={
          formik.touched.password && formik.errors.password
            ? formik.errors.password
            : null
        }
      />
      <div className="submitButton">
        {/*<ButtonField buttonStyle="formSubmit" type="submit" name="Sign In" /> */}
        <Button
          buttonStyle="formSubmit"
          type="submit"
          className="formSubmit"
          name="Sign In"
          variant="contained"
        >
          Sign In
        </Button>
      </div>
      <div className="forgotPasswordContainer">
        <Link to="/forgot-password">Forgot Password?</Link>
      </div>
    </form>
  );

  return (
    <>
      <Container maxWidth="lg" className="SignInContainer">
        <Grid container>
          <Grid item xs={12}>
            <div className="mainViewHeading">
              <Typography className="headingLine" variant="h5">
                Sign In with iBridge360
              </Typography>
            </div>
            {/* <Typography className="headingTagLine" variant="h5">
                            - Register for our Business Bridge courses, which will fill you with practical knowledge in how to excel in the industry of your choice.
                        </Typography> */}
            {/* <Typography className="headingTagLine" variant="h5">
              Take our individual assessments and discover your greatness.
            </Typography>
            <Typography className="headingTagLine" variant="h5">
              Access our exclusive webinars to interact with experts and
              evaluate your strengths for a cross-section of industries.
            </Typography> */}
            {/* <Typography className="headingTagLine" variant="h5">
                            - Enrol as an iBridge360 educator to teach our students about your industry.
                        </Typography>
                        <Typography className="headingTagLine" variant="h5">
                            - Take our survey to share your perspective on the employability needs and gaps of young Indians. Be the first to receive our Results Report.
                        </Typography> */}
          </Grid>
          {/* <Grid items xs={12}>
                        <div className="tryItContainer">
                            <ButtonField 
                                name="Try it"
                                onClick={() => history.push('/sign-in')}
                            />
                        </div>
                    </Grid> */}
        </Grid>
      </Container>
      <AuthContainerView content={renderForm} from="signIn" />
    </>
  );
};

SignIn.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { loginUser };

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

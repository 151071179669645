import React from "react";
import MediaRecorder from "./MediaRecorder";
import { Grid, Typography } from "@material-ui/core";
import isEmpty from "../../reusableComponents/is-empty";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  heading: {
    paddingLeft: "10px",
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  titleBold: {
    fontWeight: 600,
    paddingRight: 4,
  },
  centerTitle: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
}));

const IntroduceYourself = ({
  enrolledInProgram,
  comunicationVideoURL,
  aiFeedbackResponse,
  aiCorrectedVersion,
  aiTextToSpeechUrl,
}) => {
  const classes = useStyles();

  return (
    <>
      {isEmpty(comunicationVideoURL) ||
      (enrolledInProgram && !isEmpty(comunicationVideoURL)) ? (
        <>
          <Grid className="videoViewContainer" item xs={12} sm={6} md={6}>
            <div className="videoView">
              <MediaRecorder />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <div className="videoContent">
              <Typography className={classes.heading}>
                Here are some points to talk about yourself:
              </Typography>
              <ol>
                <li>Introduce yourself.</li>
                <li>Share details about your education.</li>
                <li>Describe your profession or the skills you possess.</li>
                <li>Discuss what you want to achieve in the next two years.</li>
                <li>
                  Click "Submit" only when you are satisfied with the recorded
                  video.
                </li>
              </ol>
            </div>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            You need to be enrolled in a program to unlock re-recording
          </Typography>
        </Grid>
      )}
      {aiFeedbackResponse && (
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Feedback
          </Typography>
          <Typography
            className={clsx(
              classes.heading,
              classes.titleBold,
              classes.centerTitle
            )}
          >
            {aiFeedbackResponse}
          </Typography>
        </Grid>
      )}
      {aiCorrectedVersion && (
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Improved Version
          </Typography>
          <Typography
            className={clsx(
              classes.heading,
              classes.titleBold,
              classes.centerTitle
            )}
          >
            {aiCorrectedVersion}
          </Typography>

          {aiTextToSpeechUrl && (
            <div
              className={clsx(classes.centerTitle)}
              style={{ marginTop: 20 }}
            >
              <audio controls preload="auto">
                <source src={aiTextToSpeechUrl} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            </div>
          )}
        </Grid>
      )}
    </>
  );
};

export default IntroduceYourself;

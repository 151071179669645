import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Button, Paper, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import DateTimePick from "../../reusableComponents/DateTimePick";
import { postUserTask } from "../../redux/actions/userAction";
import { getUserTaskById, editUserTask } from "../../redux/actions/userAction";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const EditTask = ({ programId, batchId, taskId, setOpenEdit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { auth, usertaskReducer } = useSelector((state) => state);

  const usertaskdataById = usertaskReducer?.usertaskdataById[0];

  useEffect(() => {
    if (auth?.isAuthenticated) {
      dispatch(getUserTaskById(taskId, auth?.user?._id));
    }
  }, [auth?.isAuthenticated]);

  useEffect(() => {
    formik.setFieldValue(
      "taskDescripton",
      usertaskdataById && usertaskdataById.description
        ? usertaskdataById.description
        : ""
    );
    formik.setFieldValue(
      "taskStartDate",
      usertaskdataById && usertaskdataById.taskStartDate
        ? new Date(usertaskdataById.taskStartDate).toDateString()
        : new Date()
    );
    formik.setFieldValue(
      "taskEndDate",
      usertaskdataById && usertaskdataById.taskEndDate
        ? new Date(usertaskdataById.taskEndDate).toDateString()
        : new Date()
    );
    formik.setFieldValue(
      "taskLink",
      usertaskdataById && usertaskdataById.taskLink
        ? usertaskdataById.taskLink
        : ""
    );
  }, [usertaskReducer]);

  // const currentUser = user?.role?.r_id === 1 ? "admin" : "mantor";

  const formik = useFormik({
    initialValues: {
      taskStartDate: new Date(),
      taskEndDate: new Date(),
      taskLink: "",
      taskDescripton: "",
      programId: "",
      batchId: "",
    },
    validationSchema: Yup.object({
      taskLink: Yup.string().trim(),
      // .required("Task Title is required field"),
      taskDescripton: Yup.string()
        .trim()
        .required("Task Description is required field"),
    }),

    onSubmit: async (values) => {
      const taskData = {
        _id: taskId,
        userId: auth.user._id,
        programId: programId,
        programBatch: batchId || undefined,
        taskLink: values.taskLink,
        taskStartDate: values.taskStartDate,
        taskEndDate: values.taskEndDate,
        description: values.taskDescripton,
      };
      dispatch(editUserTask(taskData));
      setOpenEdit(false);
    },
  });

  const handleStartDate = (e) => {
    // console.log(e);
    // const startDate = new Date(e) < new Date() ? new Date : new Date(e) ;
    const startDate = new Date(e);
    formik.setFieldValue("taskStartDate", startDate);
    const endDate =
      new Date(e) < startDate ? startDate.getHours() + 1 : new Date(e);
    endDate.setHours(endDate.getHours() + 1);
    formik.setFieldValue("taskEndDate", endDate);
  };

  const handleEndDate = (e) => {
    // console.log(
    //   "formik.values.taskStartDate",
    //   e,
    //   "formik.values.taskStartDate.getHours() +1",
    //   formik.values.taskStartDate.getHours() + 1,
    //   new Date(e) < new Date(formik.values.taskStartDate),
    //   formik.values.taskStartDate
    // );
    const endDate =
      new Date(e) < new Date(formik.values.scheduleStartDate)
        ? formik.values.scheduleStartDate
        : new Date(e);
    // console.log("endDate", endDate);
    formik.setFieldValue("taskEndDate", endDate);
  };

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} align="center">
          <TextFieldGroup
            label="Task Description"
            type="text"
            name="taskDescripton"
            multiline
            rows={4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.taskDescripton}
            errors={
              formik.touched.taskDescripton && formik.errors.taskDescripton
                ? formik.errors.taskDescripton
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <DateTimePick
            disabled={true}
            label="Task Start date"
            name="taskStartDate"
            showLable={true}
            // disablePast={true}
            className="dateTimePicker"
            size="large"
            style={{ width: "100%" }}
            // inputVariant="outlined"
            selectedDate={formik.values.taskStartDate}
            handleDateChange={(scheduledDateTime) =>
              handleStartDate(scheduledDateTime)
            }
            errors={
              formik.touched.taskStartDate && formik.errors.taskStartDate
                ? formik.errors.taskStartDate
                : null
            }
          />
        </Grid>
        <Grid item xs={0} md={1}></Grid>
        <Grid item xs={12} md={3}>
          <DateTimePick
            disabled={true}
            label="Task End date"
            name="taskEndDate"
            showLable={true}
            // disablePast={true}
            className="dateTimePicker"
            style={{ width: "100%" }}
            // inputVariant="outlined"
            selectedDate={formik.values.taskEndDate}
            handleDateChange={(scheduledDateTime) =>
              handleEndDate(scheduledDateTime)
            }
            errors={
              formik.touched.taskStartDate && formik.errors.taskStartDate
                ? formik.errors.taskStartDate
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <TextFieldGroup
            label="Task Link"
            type="text"
            name="taskLink"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.taskLink}
            errors={
              formik.touched.taskLink && formik.errors.taskLink
                ? formik.errors.taskLink
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={12} align="center">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="submit"
              name="submit"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              // onClick={submitForm}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      {renderForm}
    </Container>
  );
};

EditTask.propTypes = {
  auth: PropTypes.object.isRequired,
};

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   schedules: state.schedules,
// });

// const mapDispatchToProps = {
//   postUserTask
// };

export default EditTask;

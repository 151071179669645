import React, { useEffect, useState } from "react";

// --------------Material Card------------------
import { Container, Grid, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
// import CardActions from '@material-ui/core/CardActions';
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import ButtonField from "../../reusableComponents/ButtonField";

//------------------image------------------------------

import screenImg from "../../images/programs/dataEngineerTheme.png";

const ProgramDetails = ({ programInfo, history }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    buttomButton: {
      marginTop: "2%",
      marginLeft: "35%",
      width: "200px",
      height: "50px",
    },
  }));

  const classes = useStyles();

  return (
    <Container>
      <Card className="prgDetailsCard">
        <CardContent>
          <div className="programDetails">
            <div className="prgDetails">
              <Typography className="headingTagLine" varient="h5">
                Program Details
              </Typography>
            </div>
          </div>

          <br />
          <br />
          <br />

          <div>
            <Typography
              className="headingTagLine"
              varient="h1"
              style={{ fontWeight: 600, fontSize: "18px" }}
            >
              {/* {console.log("programs Details",programInfo)} */}
              {programInfo && programInfo.name ? programInfo.name : ""}
            </Typography>
          </div>
          <br />
          {/* <br/> */}
          <div>
            <Typography className="headingTagLine" varient="h5">
              {programInfo && programInfo.description
                ? programInfo.description
                : ""}
            </Typography>
          </div>
          <br />

          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <div>
                <Card>
                  <CardContent>
                    <div className="programDetails">
                      <div className="prgDetails">
                        <Typography className="headingTagLine" varient="h5">
                          Program Path
                        </Typography>
                      </div>
                    </div>
                    <br />
                    <br />
                    <ul className="prgPathList">
                      {programInfo &&
                        programInfo.programPath?.map((item) => (
                          <li>
                            <Typography
                              className="headingTagLine"
                              varient="subtitle1"
                            >
                              {item}
                            </Typography>
                          </li>
                        ))}
                    </ul>

                    <br />
                    <br />
                  </CardContent>
                </Card>
              </div>
            </Grid>

            <Grid item xs={12} md={8}>
              <div className="imgDataEng">
                <img
                  src={
                    programInfo && programInfo.programImage
                      ? programInfo.programImage
                      : screenImg
                  }
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <br />
      <div className="prgMantorContainer">
        <br />

        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <div className="programDetails" style={{ marginLeft: "11px" }}>
                  <div className="prgDetails">
                    <Typography className="headingTagLine" varient="h5">
                      PROGRAM MENTOR
                    </Typography>
                  </div>
                </div>
                <br />
                <div
                  style={{
                    height: "500px",
                    overflow: "auto",
                    scrollbarWidth: "thin",
                  }}
                >
                  {programInfo &&
                    programInfo.programMentor?.map((mentor) => (
                      <Card className="mentorCard" style={{ marginTop: "2%" }}>
                        <CardContent>
                          <Grid container>
                            <Grid item xs={12} md={3}>
                              <div className="mentorImg">
                                <img src={mentor?.userImage?.link} />
                              </div>
                            </Grid>

                            <Grid item xs={12} md={9}>
                              <Typography
                                className="headingTagLine"
                                varient="h3"
                              >
                                {programInfo &&
                                  `${mentor?.firstName} ${
                                    mentor ? mentor.lastName : ""
                                  }`}
                              </Typography>
                              <Typography
                                className="headingText1 noHrs"
                                variant="h6"
                              >
                                Mentor / Coach
                              </Typography>
                              <Typography
                                className="headingTagLine"
                                varient="h3"
                              >
                                {mentor?.description}
                              </Typography>
                            </Grid>
                          </Grid>

                          <br />

                          <div className="collabText">
                            <div className="collabText1">
                              <div>
                                <Typography
                                  className="headingText1 noHrs"
                                  variant="h6"
                                >
                                  In Collaboration With
                                </Typography>
                                <Typography
                                  className="headingText1"
                                  variant="h6"
                                  style={{ fontWeight: 600, fontSize: "18px" }}
                                >
                                  Aroha Technologies
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    ))}
                </div>
              </Grid>

              <Grid item xs={12} md={12}>
                <div className="enrollButton">
                  <ButtonField
                    buttonStyle={classes.buttomButton}
                    color={"#003399"}
                    name="Enroll To This Program"
                    onClick={
                      () => history.push(`/paymentProcess/${programInfo?._id}`)
                      // console.log(programInfo?._id)
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography
                  className="headingTagLine"
                  varient="h1"
                  style={{ fontWeight: 600, fontSize: "20px" }}
                >
                  Learning Path
                </Typography>

                <br />

                <ul className="prgPathList">
                  {programInfo &&
                    programInfo.learningPath?.map((item) => (
                      <li>
                        <p className="headingTagLine" varient="subtitle1">
                          {item}
                        </p>
                      </li>
                    ))}
                </ul>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default ProgramDetails;

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { loginGoogle, loginFacebook } from "../../redux/actions/authActions";

const AuthContainerView = ({ from, content, loginGoogle, loginFacebook }) => {
  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };

  const responseGoogle = async (response) => {
    // console.log('Google', response?.profileObj);
    const googleData = await response?.profileObj;
    if (googleData) {
      // console.log(googleData)
      const sendGoogleData = {
        googleId: googleData?.googleId,
        firstName: googleData?.givenName,
        lastName: capitalizeFirstLetter(googleData?.familyName),
        email: googleData?.email,
      };
      // console.log(sendGoogleData)
      loginGoogle(sendGoogleData);
    }
  };

  const responseFacebook = async (response) => {
    // console.log('Facebook', response);
    const facebookData = await response;
    if (facebookData) {
      // console.log(facebookData)
      const name = facebookData.name.split(" ");
      const sendFacebookData = {
        facebookId: facebookData.id,
        firstName: name[0],
        lastName: name[1],
        email: facebookData.email,
      };
      // console.log(sendFacebookData)
      loginFacebook(sendFacebookData);
    }
  };

  return (
    <Container maxWidth="lg" className="SignIn_SignUp_Container">
      <Grid container>
        <Grid item xs={12} className="innerContainer">
          <Card>
            <CardContent className="cardContent">
              <Typography className="headingText" variant="h5">
                {from === "signIn" ? (
                  "Sign In"
                ) : from === "signUp" ? (
                  "Sign Up"
                ) : from === "ForgotPassword" ? (
                  <div className="fieldforgotHeading">
                    Forgot your password?
                  </div>
                ) : null}
              </Typography>
              <Typography className="headingText" variant="h6">
                {from === "ForgotPassword" && (
                  <div className="fieldPageForgot">
                    Don't worry! Just fill in your email and we'll send you a
                    link to reset your password
                  </div>
                )}
              </Typography>
              {content}
              {from === "signIn" || from === "signUp" ? (
                <>
                  <p className="or">or Login with</p>
                  <div className="socialIconsContainer">
                    <FacebookLogin
                      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                      autoLoad={false}
                      fields="name,email,picture"
                      render={(renderProps) => (
                        <FaFacebook
                          className="facebook-social"
                          onClick={renderProps.onClick}
                          color="#262BA3"
                          size={25}
                        />
                      )}
                      callback={responseFacebook}
                    />
                    <GoogleLogin
                      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                      autoLoad={false}
                      render={(renderProps) => (
                        <FcGoogle
                          className="google-social"
                          onClick={renderProps.onClick}
                          color="#DC4E41"
                          size={25}
                        />
                      )}
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                    {/* <TwitterLogin 
                                        consumerKey={process.env.REACT_APP_TWITTER_CUSTOMER_KEY}
                                        consumerSecret={process.env.REACT_APP_TWITTER_CUSTOMER_SECRET}
                                        callbackUrl={from === 'sigIn' ? `${process.env.REACT_APP_TWITTER_CALLBACK}/sign-in/` : `${process.env.REACT_APP_TWITTER_CALLBACK}/sign-up/`}
                                        children={<AiFillTwitterCircle className="twitter-social" color="#1DA1F2" size={27} />}
                                        authCallback={responseTwitter}
                                    /> */}
                    {/* <TwitterLogin 
                                        loginUrl={`${process.env.REACT_APP_AUTH_URL}/api/login/auth/twitter`}
                                        onFailure={failureTwitter} 
                                        onSuccess={successTwitter}
                                        showIcon={true}
                                        className="twitter-social-container"
                                        children={<AiFillTwitterCircle className="twitter-social" color="#1DA1F2" size={29} />}
                                        requestTokenUrl={`${process.env.REACT_APP_AUTH_URL}/api/login/twitter/reverse`}
                                    /> */}
                  </div>
                </>
              ) : null}
              {/* {from === 'signIn' && <p className="forgotPasswordContainer">Or <Typography variant="subtitle2"><Link className="forgotPassword" to="forgot-password">Forgot Password</Link></Typography></p>} */}
              {from === "signIn" && (
                <p className="alreadyAccountContainer">
                  Don't have an account?{" "}
                  <Typography variant="subtitle2">
                    <Link className="alreadyAccount" to="sign-up">
                      Sign Up
                    </Link>
                  </Typography>
                </p>
              )}
              {from === "signUp" || from === "ForgotPassword" ? (
                <p className="alreadyAccountContainer">
                  Already have an account?{" "}
                  <Typography variant="subtitle2">
                    <Link className="alreadyAccount" to="sign-in">
                      Sign In
                    </Link>
                  </Typography>
                </p>
              ) : null}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

AuthContainerView.propTypes = {
  loginGoogle: PropTypes.func.isRequired,
  loginFacebook: PropTypes.func.isRequired,
};

const mapDispatchToProps = { loginGoogle, loginFacebook };

export default connect(null, mapDispatchToProps)(AuthContainerView);

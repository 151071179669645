import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Card,
  Grid,
  Paper,
  Typography,
  Button,
} from "@material-ui/core";
import { PeopleOutline as PeopleOutlineIcon } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import ButtonField from "../../../reusableComponents/ButtonField";
import DynamicTextField from "../../../reusableComponents/DynamicTextField";

import { getCoachList } from "../../../redux/actions/admin_view/coachList";
import isEmpty from "../../../reusableComponents/is-empty";

// ----------redux------------------------------

// import { getProgramInfo } from "../../redux/actions/programActions";
import {
  getProgramInfo,
  getCourses,
  uploadContentImage,
  uploadProfileImage,
  updateProgram,
  getCapabilityTopics,
} from "../../../redux/actions/programActions";

// ----------redux------------------------------

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import PhotoUpload from "../../../reusableComponents/PhotoUpload";

import SelectMultipleValues from "../../../reusableComponents/SelectMultipleValues";
import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
    marginBottom: theme.spacing(4),
  },
  gridAssignRoot: {
    margin: theme.spacing(4, 0),
  },
  paperRoot: {
    backgroundColor: "#fdfdff",
    padding: theme.spacing(3, 4),
  },
  pageAssignHeader: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
  },
  pageAssignIcon: {
    display: "inline-block",
    padding: theme.spacing(1),
    color: "#3d4977",
  },
  pageAssignTitle: {
    paddingLeft: theme.spacing(2),
    "& .MuiTypography-h6": {
      fontWeight: 600,
    },
  },
  pageEditButton: {
    display: "flex",
    alignItems: "right",
    justifyContent: "right",
    marginLeft: "660px",
  },
  inputFields: {
    margin: "1%",
    width: "60%",
  },
  LableText: {
    paddingLeft: "2%",
    paddingTop: "2%",
    fontSize: "18px",
  },
  inputButton: {
    marginTop: "3%",
    marginLeft: "2%",
    width: "60%",
  },
  inputPhoto: {
    marginTop: "10%",
    borderRadius: "0",
    height: "2000px",
  },
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const ProgramInfo = ({
  history,
  getProgramInfo,
  getCoachList,
  getCourses,
  auth,
  adminCoach,
  programs,
  uploadContentImage,
  uploadProfileImage,
  updateProgram,
  getCapabilityTopics,
  match,
}) => {
  const {
    iLearnCourses,
    programProfileImage,
    programContentImage,
    iCapabilityTopics,
    programInfo,
  } = programs;

  const { isAuthenticated, user } = auth;

  const { coachList } = adminCoach;
  const [textFieldProp, setTextFieldProp] = useState(true);
  const { programId } = match.params;

  const programDurationData = [
    { name: "1 Month" },
    { name: "1.5 Months" },
    { name: "2 Months" },
    { name: "2.5 Months" },
    { name: "3 Months" },
    { name: "3.5 Months" },
    { name: "4 Months" },
    { name: "4.5 Months" },
    { name: "5 Months" },
    { name: "5.5 Months" },
    { name: "6 Months" },
    { name: "6.5 Months" },
    { name: "7 Months" },
    { name: "7.5 Months" },
    { name: "8 Months" },
    { name: "8.5 Months" },
    { name: "9 Months" },
    { name: "9.5 Months" },
    { name: "10 Months" },
    { name: "10.5 Months" },
    { name: "11 Months" },
    { name: "11.5 Months" },
    { name: "12 Months" },
    { name: "12.5 Months" },
  ];

  const classes = useStyles();

  const today = new Date();

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedDate1, setSelectedDate1] = React.useState(new Date());

  useEffect(() => {
    if (isAuthenticated) {
      getCoachList();
      getCourses();
      getProgramInfo(programId);
      getCourses();
      getCapabilityTopics();
    }
  }, [isAuthenticated]);
  useEffect(() => {
    formik.setFieldValue(
      "selectCourses",
      programInfo && programInfo.courses
        ? iLearnCourses?.filter((item) =>
            programInfo?.courses.includes(item._id)
          )
        : []
    );
  }, [iLearnCourses]);
  useEffect(() => {
    formik.setFieldValue(
      "capabilityTopics",
      programInfo && programInfo.capabilityTopics
        ? iCapabilityTopics?.filter((item) =>
            programInfo?.capabilityTopics.includes(item.id)
          )
        : []
    );
  }, [iCapabilityTopics]);

  const handleDateChange = (programStartDate) => {
    formik.setFieldValue("programStartDate", programStartDate);
    // console.log(selectedDate);
    setSelectedDate(programStartDate);
  };

  const handleDateChange1 = (programEndDate) => {
    formik.setFieldValue("programEndDate", programEndDate);
    // console.log(selectedDate1);
    setSelectedDate1(programEndDate);
  };

  const mentorData = coachList?.map((list) => {
    const { _id, firstName, lastName } = list;
    return {
      _id,
      name: `${firstName} ${lastName}`,
    };
  });

  useEffect(() => {
    formik.setFieldValue(
      "programName",
      programInfo && programInfo.name ? programInfo.name : ""
    );
    formik.setFieldValue(
      "addProgramPath",
      // programInfo ? programInfo?.programPath.join(",") : ""
      programInfo ? programInfo?.programPath : []
    );
    formik.setFieldValue(
      "addProgramLearningPath",
      // programInfo && programInfo.learningPath
      //   ? programInfo.learningPath.join(",")
      //   : ""
      programInfo ? programInfo?.learningPath : []
    );
    formik.setFieldValue(
      "totalHours",
      programInfo && programInfo?.totalHours
        ? programInfo?.totalHours.toString()
        : ""
    );
    formik.setFieldValue(
      "programDuration",
      programInfo && programInfo?.estimatedTime
        ? programDurationData.find(
            (item) => item.name === programInfo?.estimatedTime
          )
        : {}
    );
    formik.setFieldValue(
      "odooAccess",
      programInfo && programInfo?.odooAccess
        ? { name: "Yes", value: true }
        : { name: "No", value: false }
    );
    console.log(new Date(programInfo?.programStartDate), "S");
    formik.setFieldValue(
      "programStartDate",
      programInfo && programInfo?.programStartDate
        ? new Date(programInfo?.programStartDate)
        : new Date()
    );
    formik.setFieldValue(
      "programEndDate",
      programInfo && programInfo?.programEndDate
        ? new Date(programInfo?.programEndDate)
        : new Date()
    );
    setSelectedDate(new Date(programInfo?.programStartDate));
    setSelectedDate1(new Date(programInfo?.programEndDate));
    formik.setFieldValue(
      "programMentor",
      programInfo && programInfo?.programMentor
        ? programInfo?.programMentor.map((list) => {
            list.name = `${list.firstName} ${list.lastName}`;
            return list;
          })
        : // ? mentorData?.filter(item => item._id === programInfo.programMentor._id)
          []
    );
    formik.setFieldValue(
      "programPrice",
      programInfo && programInfo?.price ? programInfo?.price : ""
    );
    formik.setFieldValue(
      "programDescription",
      programInfo && programInfo?.description ? programInfo?.description : ""
    );
    formik.setFieldValue(
      "cardImage",
      programInfo && programInfo?.cardImage ? programInfo?.cardImage : ""
    );
    formik.setFieldValue(
      "programImage",
      programInfo && programInfo?.programImage ? programInfo?.programImage : ""
    );
    // formik.setFieldValue(
    //   "description",
    //   serviceProviderInfo && serviceProviderInfo.description
    //     ? serviceProviderInfo.description
    //     : ""
    // );
  }, [programInfo]);

  const formik = useFormik({
    initialValues: {
      programName: "",
      addProgramPath: [],
      addProgramLearningPath: [],
      selectCourses: [],
      programDuration: {},
      programStartDate: new Date(),
      programEndDate: new Date(),
      programMentor: [],
      programDescription: "",
      programPrice: "",
      programImage: "",
      programImageName: "",
      cardImage: "",
      cardImageName: "",
      totalHours: "",
      capabilityTopics: [],
      odooAccess: {},
    },
    validationSchema: Yup.object({
      programName: Yup.string()
        .trim()
        .required("Program Name is required field"),
      totalHours: Yup.number()
        .typeError("Total Hours must be in Numbers")
        .required("Total Hours is required field"),
      // addProgramPath: Yup.string()
      //   .trim()
      //   .required("Program Path is required field"),
      // addProgramLearningPath: Yup.string()
      //   .trim()
      //   .required("Program Learning Path is required field"),
      // selectCourses: Yup.object({name:Yup.string("Select Courses is required field")})
      // // .trim()
      // .required("Select Courses is required field"),
      // programDuration: Yup.object({
      //   name: Yup.string("Program Duration is required field"),
      // })
      // .trim()
      // .required("Program Duration is required field"),
      programStartDate: Yup.date().required(
        "Program Start date is a required field"
      ),
      programEndDate: Yup.date().required(),
      // .min(
      //   Yup.ref("programStartDate"),
      //   "Program end date can't be before Start date"
      // ),
      // programMentor: Yup.string()
      // // .trim()
      // .required("Program Mentor is required field"),
      programDescription: Yup.string()
        .trim()
        .required("Program Description is required field"),
      programPrice: Yup.string().trim().required("Price is a required field"),
    }),

    // onSubmit: async (values) => {
    //   console.log(values,"Test123")
    //   alert("test123 : ",values)
    // }

    onSubmit: async (values) => {
      console.log("values in  onSubmit", values, programId);
      const programData = {
        name: values?.programName,
        description: values?.programDescription,
        price: parseFloat(values.programPrice),
        estimatedTime: values?.programDuration.name,
        programPath: values?.addProgramPath,
        learningPath: values?.addProgramLearningPath,
        // programPath: values.addProgramPath.split(","),
        // learningPath: values.addProgramLearningPath.split(","),
        programMentor: values?.programMentor.map((mentor) => mentor._id),
        courses: values?.selectCourses.map((selectCourse) => selectCourse._id),
        programStartDate:
          values.programStartDate.toString().slice(0, 15) ||
          (programInfo && programInfo?.programStartDate),
        programEndDate:
          values?.programEndDate.toString().slice(0, 15) ||
          (programInfo && programInfo?.programEndDate),
        capabilityTopics: values?.capabilityTopics.map((topic) => topic.id),
        addedBy: user._id,
        totalHours: parseInt(values.totalHours),
        programImage: values?.programImage,
        cardImage: values?.cardImage,
        odooAccess: values?.odooAccess.value,
        programId,
      };
      console.log("programData", programData);
      updateProgram(programData);
      // console.log("\n\n", programData, "\n\n", values);
    },
  });

  useEffect(() => {
    console.log("sdf", formik.errors);
  }, [formik.errors]);

  useEffect(() => {
    if (isAuthenticated) {
      if (!isEmpty(programContentImage)) {
        formik.setFieldValue("programImageName", programContentImage.name);
        formik.setFieldValue("programImage", programContentImage.link);
      }
    }
  }, [isAuthenticated, programContentImage]);
  useEffect(() => {
    if (isAuthenticated) {
      if (!isEmpty(programProfileImage)) {
        formik.setFieldValue("cardImageName", programProfileImage.name);
        formik.setFieldValue("cardImage", programProfileImage.link);
      }
    }
  }, [isAuthenticated, programProfileImage]);

  const uploadProgramProfileImage = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("photo", e.target.files[0]);

    uploadProfileImage(formData);
  };

  const uploadProgramContentImage = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("photo", e.target.files[0]);

    uploadContentImage(formData);
  };

  const userData = () => {
    return (
      <>
        <form
          onSubmit={formik.handleSubmit}
          enableReinitialize={true}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <TextFieldGroup
                    label=" Program Name"
                    type="text"
                    className={classes.inputFields}
                    name="programName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.programName}
                    disabled={textFieldProp}
                    errors={
                      formik.touched.programName && formik.errors.programName
                        ? formik.errors.programName
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  {/* <TextFieldGroup
                    label="Program Path"
                    type="text"
                    multiline
                    className={classes.inputFields}
                    name="addProgramPath"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.addProgramPath}
                    disabled={textFieldProp}
                    errors={
                      formik.touched.addProgramPath &&
                      formik.errors.addProgramPath
                        ? formik.errors.addProgramPath
                        : null
                    }
                  /> */}
                  <DynamicTextField
                    label="Program Path"
                    type="text"
                    multiline
                    className={classes.inputFields}
                    name="addProgramPath"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onChangeData={(programPathValue) => {
                      // console.log(programPathValue);
                      formik.setFieldValue("addProgramPath", programPathValue);
                    }}
                    value={formik.values.addProgramPath}
                    disabled={textFieldProp}
                    errors={
                      formik.touched.addProgramPath &&
                      formik.errors.addProgramPath
                        ? formik.errors.addProgramPath
                        : null
                    }
                  ></DynamicTextField>
                </Grid>

                <Grid item xs={12} md={12}>
                  {/* <TextFieldGroup
                    label="Learning Path"
                    type="text"
                    className={classes.inputFields}
                    multiline
                    name="addProgramLearningPath"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={textFieldProp}
                    value={formik.values.addProgramLearningPath}
                    errors={
                      formik.touched.addProgramLearningPath &&
                      formik.errors.addProgramLearningPath
                        ? formik.errors.addProgramLearningPath
                        : null
                    }
                  /> */}

                  <DynamicTextField
                    label="Learning Path"
                    type="text"
                    multiline
                    className={classes.inputFields}
                    name="addProgramLearningPath"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onChangeData={(learningPathValue) => {
                      // console.log(programPathValue);
                      formik.setFieldValue(
                        "addProgramLearningPath",
                        learningPathValue
                      );
                    }}
                    value={formik.values.addProgramLearningPath}
                    disabled={textFieldProp}
                    errors={
                      formik.touched.addProgramLearningPath &&
                      formik.errors.addProgramLearningPath
                        ? formik.errors.addProgramLearningPath
                        : null
                    }
                  ></DynamicTextField>
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextFieldGroup
                    label="Total Hours"
                    type="text"
                    multiline
                    className={classes.inputFields}
                    name="totalHours"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={textFieldProp}
                    value={formik.values.totalHours}
                    errors={
                      formik.touched.totalHours && formik.errors.totalHours
                        ? formik.errors.totalHours
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <SelectMultipleValues
                    disabled={textFieldProp}
                    label="Select Courses"
                    className={classes.inputFields}
                    name="selectCourses"
                    //   dataList={iLearnCourses} //?iLearnCourses:[]
                    value="Select Courses"
                    dataList={iLearnCourses}
                    onSelectData={(course) =>
                      formik.setFieldValue("selectCourses", course)
                    }
                    selectedData={formik.values.selectCourses}
                    // disabled={textFieldProp}
                    // selectedData={[]}
                    errors={
                      formik.touched.selectCourses &&
                      formik.errors.selectCourses
                        ? formik.errors.selectCourses
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12} style={{ marginTop: "5px" }}>
                  <SelectMultipleValues
                    disabled={textFieldProp}
                    label="Select Capability Topics"
                    className={classes.inputFields}
                    name="capabilityTopics"
                    dataList={iCapabilityTopics} //?iLearnCourses:[]
                    value="Select Topics"
                    onSelectData={(topic) =>
                      formik.setFieldValue("capabilityTopics", topic)
                    }
                    selectedData={formik.values.capabilityTopics}
                    // selectedData={[]}
                    errors={
                      formik.touched.capabilityTopics &&
                      formik.errors.capabilityTopics
                        ? formik.errors.capabilityTopics
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12} style={{ marginTop: "5px" }}>
                  <SelectSingleValues
                    label="Program Duration"
                    name="programDuration"
                    className={classes.inputFields}
                    values={programDurationData}
                    disabled={textFieldProp}
                    onSelect={(duration) =>
                      formik.setFieldValue("programDuration", duration)
                    }
                    // onSelect={(programDuration) => console.log(programDuration)}
                    selected={formik.values.programDuration}
                    errors={
                      formik.touched.programDuration &&
                      formik.errors.programDuration
                        ? formik.errors.programDuration
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      // margin="normal"
                      name="programStartDate"
                      label="Program Start Date"
                      format="MM/dd/yyyy"
                      inputVariant="outlined"
                      value={selectedDate}
                      className={classes.inputFields}
                      disabled={textFieldProp}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      // margin="normal"
                      name="programEndDate"
                      label="Program End Date"
                      format="MM/dd/yyyy"
                      inputVariant="outlined"
                      value={selectedDate1}
                      disabled={textFieldProp}
                      className={classes.inputFields}
                      onChange={handleDateChange1}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                  {/* <SelectSingleValues
                    label="Select Mentor"
                    name="programMentor"
                    className={classes.inputFields}
                    disabled={textFieldProp}
                    values={mentorData}
                    onSelect={(programMentor) =>
                      formik.setFieldValue("programMentor", programMentor)
                    }
                    selected={formik.values.programMentor}
                    errors={
                      formik.touched.programMentor &&
                      formik.errors.programMentor
                        ? formik.errors.programMentor
                        : null
                    }
                  /> */}

                  <SelectMultipleValues
                    disabled={textFieldProp}
                    label="Select Mentor"
                    className={classes.inputFields}
                    name="programMentor"
                    style={{ marginTop: "5%", paddingTop: "5%" }}
                    dataList={mentorData}
                    // dataList={[{name:"vishwas",_id:"123"}]}

                    value="Select mentors"
                    onSelectData={(mentor) =>
                      formik.setFieldValue("programMentor", mentor)
                    }
                    selectedData={formik.values.programMentor}
                    // selectedData={[]}
                    errors={
                      formik.touched.programMentor &&
                      formik.errors.programMentor
                        ? formik.errors.programMentor
                        : null
                    }
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextFieldGroup
                    label="Price"
                    type="text"
                    className={classes.inputFields}
                    name="programPrice"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.programPrice}
                    disabled={textFieldProp}
                    errors={
                      formik.touched.programPrice && formik.errors.programPrice
                        ? formik.errors.programPrice
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <SelectSingleValues
                    label="Odoo Access"
                    name="odooAccess"
                    className={classes.inputFields}
                    values={[
                      { name: "Yes", value: true },
                      { name: "No", value: false },
                    ]}
                    disabled={textFieldProp}
                    onSelect={(access) =>
                      formik.setFieldValue("odooAccess", access)
                    }
                    // onSelect={(programDuration) => console.log(programDuration)}
                    selected={formik.values.odooAccess}
                    errors={
                      formik.touched.odooAccess && formik.errors.odooAccess
                        ? formik.errors.odooAccess
                        : null
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4} align="center">
              <Grid container spacing={5}>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.LableText}>
                    Add Program Profile Image
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <PhotoUpload
                    disabled={textFieldProp}
                    name={"profileImage"}
                    className={classes.inputPhoto}
                    imageName={formik.values.cardImageName}
                    imageUrl={formik.values.cardImage}
                    htmlFor={"profiileImage"}
                    onChange={(e) => uploadProgramProfileImage(e)}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography className={classes.LableText}>
                    Add Program Content Image
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <PhotoUpload
                    disabled={textFieldProp}
                    name={"contentImage"}
                    className={classes.inputPhoto}
                    imageName={formik.values.programImageName}
                    imageUrl={formik.values.programImage}
                    htmlFor={"contentImage"}
                    onChange={(e) => uploadProgramContentImage(e)}
                    // style={{ cursor: textFieldProp ? "default" : "pointer" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} align="center">
              <TextFieldGroup
                label="Program Description"
                type="text"
                name="programDescription"
                multiline
                rows={4}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.programDescription}
                disabled={textFieldProp}
                errors={
                  formik.touched.programDescription &&
                  formik.errors.programDescription
                    ? formik.errors.programDescription
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={12} align="center">
              <div className={classes.submitButton}>
                <Button
                  buttonStyle="submit"
                  type="submit"
                  name="submit"
                  color="primary"
                  className={classes.inputFields}
                  variant="outlined"
                  disabled={textFieldProp}
                  // onClick={submitForm}
                >
                  Submit
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  return (
    <Container>
      <div className={classes.gridRoot}>
        <Paper className={classes.paperRoot}>
          <div className={classes.pageAssignHeader}>
            <Card className={classes.pageAssignIcon}>
              <PeopleOutlineIcon fontSize="large" />
            </Card>
            <div className={classes.pageAssignTitle}>
              <Typography variant="h6" component="div">
                Program Details
              </Typography>
            </div>

            <ButtonField
              buttonStyle={classes.pageEditButton}
              name={"Edit"}
              onClick={() => setTextFieldProp((prev) => !prev)}
            />
          </div>
          <Grid container spacing={3}>
            {userData()}
          </Grid>
        </Paper>
      </div>
    </Container>
  );
};

ProgramInfo.propTypes = {
  getProgramInfo: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  programs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  programs: state.programs,
  adminCoach: state.adminCoach,
});

const mapDispatchToProps = {
  getProgramInfo,
  getCoachList,
  getCourses,
  uploadContentImage,
  uploadProfileImage,
  updateProgram,
  getCapabilityTopics,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramInfo);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Button, Paper, Container } from "@material-ui/core";
import { GroupAdd as GroupAddIcon, YouTube } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import PageHeader from "../../../reusableComponents/PageHeader";
import { getCoachList } from "../../../redux/actions/admin_view/coachList";
import { getProgramBatchList } from "../../../redux/actions/programBatchActions";
import { getPrograms } from "../../../redux/actions/programActions";
import { createSchedule } from "../../../redux/actions/scheduleActions";
import DateTimePick from "../../../reusableComponents/DateTimePick";
import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";
import { getMentorPrograms } from "../../../redux/actions/programActions";
import { getMentorBatchList } from "../../../redux/actions/mentorActions";
import history from "../../../reusableComponents/history";
const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const AddSchedulings = ({
  getCoachList,
  getProgramBatchList,
  getPrograms,
  auth,
  adminCoach,
  programs,
  programBatch,
  createSchedule,
  getMentorPrograms,
  getMentorBatchList,
  mentor,
}) => {
  const classes = useStyles();
  const { programList, mentorPrograms } = programs;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { programBatchList } = programBatch;
  const { mentorBatchList } = mentor;
  const { coachList } = adminCoach;
  const { isAuthenticated, user } = auth;
  const currentUser = user?.role?.r_id === 1 ? "admin" : "mantor";
  useEffect(() => {
    if (isAuthenticated) {
      if (currentUser === "admin") {
        getPrograms();
        getProgramBatchList();
      } else {
        getMentorPrograms(user?._id);
        getMentorBatchList(user?._id);
      }
    }
  }, [isAuthenticated]);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     getMentorPrograms(user?._id);
  //   }
  // }, [isAuthenticated, auth]);

  const formik = useFormik({
    // initialValues: {
    //   scheduleStartDate: new Date(),
    //   scheduleEndDate: new Date(),
    //   // scheduleStartDate:"",
    //   // scheduleEndDate:"",
    //   scheduleTitle: "",
    //   programBatch: { _id: "", name: "" },
    //   scheduleDescription: "",
    //   taskType: { name: "" },
    // },
    initialValues: {
      scheduleStartDate: new Date(),
      scheduleEndDate: new Date(),
      scheduleTitle: "",
      programBatch: { _id: "", name: "" },
      taskType: { name: "" },
      scheduleDescription: "",
    },
    validationSchema: Yup.object({
      scheduleTitle: Yup.string()
        .trim()
        .required("Schedule Title is required field"),
      programBatch: Yup.object({
        _id: Yup.string().trim(),
        name: Yup.string().trim().required("Program Batch is required field"),
      }).required("Program Batch is required field"),
      taskType: Yup.object({
        name: Yup.string().trim().required("Task Type is required field"),
      }).required("Task Type is required field"),
      scheduleDescription: Yup.string()
        .trim()
        .required("Schedule Description is required field"),
    }),

    // validationSchema: Yup.object({
    //   scheduleTitle: Yup.string()
    //     .trim()
    //     .required("Schedule Title is required field"),
    //   programBatch: Yup.object({
    //     name: Yup.string().trim().required("Program Batch is required field"),
    //   })
    //     .nullable()
    //     .required("Program Batch is reqired field"),
    //   taskType: Yup.object().nullable().required("Task Type is reqired field"),
    //   scheduleDescription: Yup.string()
    //     .trim()
    //     .required("Schedule Description is required field"),
    // }),
    onSubmit: async (values) => {
      if (isSubmitting) return;
      setIsSubmitting(true);

      const scheduleData = {
        name: values.scheduleTitle,
        description: values.scheduleDescription,
        programBatch: values.programBatch._id,
        addedBy: user._id,
        scheduleStartDate: values.scheduleStartDate,
        scheduleEndDate: values.scheduleEndDate,
        taskType: values.taskType.name,
        zoomMeetingId: values.zoomMeetingId?.trim(),
        zoomMeetingPassword: values.zoomMeetingPassword,
      };

      try {
        await createSchedule(scheduleData);
        history.push("/admin/schedulingList");
      } catch (error) {
        console.error("Error creating schedule:", error);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const taskTypeList = [
    { name: "Zoom / Face to Face session" },
    { name: "iLearn Session" },
    { name: "iCapability Session" },
    { name: "iProductivity Session" },
    { name: "Lab Session" },
    { name: "Project Session" },
    { name: "Other" },
  ];

  const handleStartDate = (e) => {
    console.log(e);
    formik.setFieldValue("scheduleStartDate", e);
  };

  const handleEndDate = (e) => {
    const endDate =
      new Date(e) < new Date(formik.values.scheduleStartDate)
        ? formik.values.scheduleStartDate
        : new Date(e);
    console.log("endDate", endDate);
    formik.setFieldValue("scheduleEndDate", endDate);
  };

  useEffect(() => {
    if (formik.values.scheduleStartDate) {
      const startDate = new Date(formik.values.scheduleStartDate);
      const endDate = new Date(startDate);
      endDate.setHours(startDate.getHours() + 1);

      formik.setFieldValue("scheduleEndDate", endDate);
    }
  }, [formik.values.scheduleStartDate]);

  // const adminProgramData = programList?.map((list) => {
  //   const { _id, name } = list;
  //   return {
  //     _id,
  //     name: name,
  //   };
  // });

  // const mentorProgramData = mentorPrograms?.map((list) => {
  //   const { _id, name } = list;
  //   return {
  //     _id,
  //     name: name,
  //   };
  // });

  // const programData =
  //   currentUser === "admin" ? adminProgramData : mentorProgramData;

  const adminProgramBatchData = programBatchList?.map((program) => {
    const { _id, name } = program;
    return {
      _id,
      name,
    };
  });

  const mentorProgramBatchData = mentorBatchList?.map((program) => {
    const { _id, name } = program;
    return {
      _id,
      name,
    };
  });

  const programBatchData =
    currentUser === "admin" ? adminProgramBatchData : mentorProgramBatchData;

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextFieldGroup
            label=" Schedule Title"
            type="text"
            name="scheduleTitle"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.scheduleTitle}
            errors={
              formik.touched.scheduleTitle && formik.errors.scheduleTitle
                ? formik.errors.scheduleTitle
                : null
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <div>
            {/* <SelectSingleValues
              label="Select Batch"
              name="programBatch"
              values={programBatchData || []}
              onSelect={(batch) => formik.setFieldValue("programBatch", batch)}
              selected={formik.values.programBatch}
              errors={
                formik.touched.programBatch && formik.errors.programBatch
                  ? formik.errors.programBatch
                  : null
              }
            /> */}
            <SelectSingleValues
              label="Select Batch"
              name="programBatch.name"
              values={programBatchData || []}
              onSelect={(batch) => {
                formik.setFieldValue("programBatch._id", batch._id);
                formik.setFieldValue("programBatch.name", batch.name);
              }}
              selected={formik.values.programBatch.name}
              errors={
                formik.touched.programBatch && formik.errors.programBatch
                  ? formik.errors.programBatch.name
                  : null
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <DateTimePick
            label="Schedule Start date"
            name="scheduleStartDate"
            showLable={true}
            // disablePast={true}
            className="dateTimePicker"
            // inputVariant="outlined"
            selectedDate={formik.values.scheduleStartDate}
            handleDateChange={(scheduledDateTime) =>
              handleStartDate(scheduledDateTime)
            }
            // errors={validError.scheduledDateTime}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DateTimePick
            label="Schedule End date"
            name="scheduleEndDate"
            showLable={true}
            // disablePast={true}
            className="dateTimePicker"
            // inputVariant="outlined"
            selectedDate={formik.values.scheduleEndDate}
            handleDateChange={
              (scheduledDateTime) => handleEndDate(scheduledDateTime)
              // console.log("scheduledDateTime",scheduledDateTime)
              // formik.setFieldValue("scheduleEndDate", scheduledDateTime)
            }
            // errors={validError.scheduledDateTime}
          />
        </Grid>

        <Grid item xs={12} md={6} align="center">
          {/* <SelectSingleValues
            label="Task Type"
            name="taskType"
            values={taskTypeList || []}
            onSelect={(batch) => formik.setFieldValue("taskType", batch)}
            selected={formik.values.taskType}
            errors={
              formik.touched.taskType && formik.errors.taskType
                ? formik.errors.taskType
                : null
            }
          /> */}
          <SelectSingleValues
            label="Task Type"
            name="taskType.name"
            values={taskTypeList || []}
            onSelect={(type) => {
              // formik.setFieldValue("taskType._id", type._id);
              formik.setFieldValue("taskType.name", type.name);
            }}
            selected={formik.values.taskType.name}
            errors={
              formik.touched.taskType && formik.errors.taskType
                ? formik.errors.taskType.name
                : null
            }
          />
        </Grid>

        {formik?.values?.taskType?.name === "Zoom / Face to Face session" ? (
          <>
            <Grid item xs={12} md={6}>
              <TextFieldGroup
                label="Zoom Meeting Id"
                type="text"
                name="zoomMeetingId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.zoomMeetingId}
                errors={
                  formik.touched.zoomMeetingId && formik.errors.zoomMeetingId
                    ? formik.errors.zoomMeetingId
                    : null
                }
                required={false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldGroup
                label="Zoom Meeting Password"
                type="text"
                name="zoomMeetingPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.zoomMeetingPassword}
                errors={
                  formik.touched.zoomMeetingPassword &&
                  formik.errors.zoomMeetingPassword
                    ? formik.errors.zoomMeetingPassword
                    : null
                }
                required={false}
              />
            </Grid>
          </>
        ) : null}

        <Grid item xs={12} md={6} align="center">
          <TextFieldGroup
            label="Schedule Description"
            type="text"
            name="scheduleDescription"
            multiline
            rows={4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.scheduleDescription}
            errors={
              formik.touched.scheduleDescription &&
              formik.errors.scheduleDescription
                ? formik.errors.scheduleDescription
                : null
            }
          />
        </Grid>
        <Grid item xs={12} md={12} align="center">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="submit"
              name="submit"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              disabled={isSubmitting}
              // onClick={submitForm}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      <PageHeader
        title="Create Schedule"
        icon={<GroupAddIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>{renderForm}</Paper>
    </Container>
  );
};

AddSchedulings.propTypes = {
  auth: PropTypes.object.isRequired,
  getCoachList: PropTypes.func.isRequired,
  programs: PropTypes.object.isRequired,
  getProgramBatchList: PropTypes.func.isRequired,
  getPrograms: PropTypes.func.isRequired,
  createSchedule: PropTypes.func.isRequired,
  getMentorBatchList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  adminCoach: state.adminCoach,
  programs: state.programs,
  programBatch: state.programBatch,
  mentor: state.mentor,
});

const mapDispatchToProps = {
  getCoachList,
  getProgramBatchList,
  createSchedule,
  getPrograms,
  getMentorPrograms,
  getMentorBatchList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSchedulings);

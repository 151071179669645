import React, { useEffect } from "react";
import {Button, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function AlertDialog({ showOpen, onClose, handleSubmit, taskType, selectedEvent }) {
  const [open, setOpen] = React.useState(showOpen);
  // const taskType = "project"

  useEffect(() => {
    setOpen(showOpen);
  }, [showOpen]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
     taskLink:"",
    },
    // validationSchema: Yup.object({
    //   taskLink: Yup.string()
    //     .trim()
    //     .required("Git repository Link is required field"),
    // }),

    onSubmit: async (values) => {
      console.log("values",values)
     
      const data = {
        schedule: selectedEvent?.schedule,
        programBatch: selectedEvent?.programBatch,
        userId: selectedEvent?.userId,
        taskLink:values.taskLink,
      };
      if(taskType != "Lab Session")
      if(taskType != "Project Session"){
        console.log("taskType",taskType)
        delete data.taskLink
      }
      handleSubmit(data)
    },
  });

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Complete Task"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Did you Complete this task?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <form
            onSubmit={formik.handleSubmit}
            enableReinitialize={true}
            noValidate
            autoComplete="off"
          >
          <Grid container style={{width:"500px",padding:"0 2%"}}>
            {taskType === "Lab Session" || taskType === "Project Session" ?
            <>
              <Grid item xs={12} md={12}>
                <TextFieldGroup
                  label=" Git repository Link "
                  type="text"
                  name="taskLink"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.taskLink}
                  errors={
                    formik.touched.taskLink && formik.errors.taskLink
                      ? formik.errors.taskLink
                      : null
                  }
                />
              </Grid>
                 <Grid item xs={12} md={12} align="center" style={{paddingTop:"2%"}}>
                 <Button
                   buttonStyle="submit"
                   type="submit"
                   name="submit"
                   color="primary"
                   // className={classes.inputButtonFields}
                   variant="contained"
                   // onClick={submitForm}
                 >
                  Submit
                 </Button>
               </Grid>
              </>
              :
              <>
                <Grid item xs={12} md={6} align="center" style={{paddingTop:"0%"}}>
                  <Button
                    style={{width:'70%',backgroundColor:"green",color:"white"}}
                    buttonStyle="submit"
                    type="submit"
                    name="submit"
                    
                    // className={classes.inputButtonFields}
                    variant="contained"
                    // onClick={submitForm}
                  >
                    Yes
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} align="center" style={{paddingTop:"0%"}}>
                  <Button
                    style={{width:'70%',backgroundColor:"red",color:"white"}}
                    buttonStyle="button"
                    type="button"
                    name="cancel"
                    color="primary"
                    // className={classes.inputButtonFields}
                    variant="contained"
                    onClick={onClose}
                  >
                    No
                  </Button>
                </Grid>
              </>
              
            }
              
                
            </Grid>
          </form>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import * as yup from "yup";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { forgotPasswordSendMail } from "../../redux/actions/authActions";
import AuthContainerView from "./AuthContainerView";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, Typography, Button } from "@material-ui/core";
import ButtonField from "../../reusableComponents/ButtonField";

const ForgotPassword = ({ forgotPasswordSendMail, auth, history }) => {
  const recaptcha = useRef();
  const [error, setError] = useState("");
  const [token, setToken] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required field")
        .email("Email is not valid"),
    }),
    onSubmit: async (values) => {
      await forgotPasswordSendMail(values, history, enqueueSnackbar);
    },
  });

  document.title = "Forgot Password - iBridge360";

  //   const onSubmit = (data) => {
  //     forgotPasswordSendMail(data, history, enqueueSnackbar);
  //   };

  const renderForm = (
    <div className="forgotPasswordContainer">
      <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12} className="inputFieldContainer">
            <TextFieldGroup
              label="Email"
              type="text"
              name="email"
              autoFocus
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              errors={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
          </Grid>
        </Grid>
        <div className="submitButton signUpSubmit">
          <Button
            buttonStyle="formSubmit"
            type="submit"
            name="Reset Password"
            style={{ backgroundColor: "#008dd2", color: "white",width:"100%" }}
            
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
  return <AuthContainerView content={renderForm} from="ForgotPassword" />;
};

ForgotPassword.propTypes = {
  forgotPasswordSendMail: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { forgotPasswordSendMail };

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

 const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Last 3 Tests Graph',
    },
  },
};



const BarChart = ({
  barGraphData,
  barGraphLabels,
}) => {

  const labels =  barGraphLabels? barGraphLabels : [];

  const data = {
     labels,
     datasets: barGraphData.map((item) => {
         return{
           label:item.label,
           data:item.data,
           backgroundColor:item.backgroundColor,
         }
       })
  };
    
  
  return ( 
    <>
      <Bar options={options} data={data} />
    </>
   );
}
 
export default BarChart ;


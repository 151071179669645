import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getServiceProviderInfo } from "../../../redux/actions/admin_view/serviceProviderList";
import { editServiceProvider } from "../../../redux/actions/admin_view/addNewServiceProviderAction";

import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
} from "@material-ui/core";
import { PeopleOutline as PeopleOutlineIcon } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import MUITable from "../../MaterialTable/MaterialTable";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import ButtonField from "../../../reusableComponents/ButtonField";
import { getDateTime } from "../../../reusableComponents/GetDate";
import isEmpty from "../../../reusableComponents/is-empty";
import PersonalityAssessmentGraph from "../../Dashboard/PersonalityAssessmentGraph";

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
    marginBottom: theme.spacing(4),
  },
  gridAssignRoot: {
    margin: theme.spacing(4, 0),
  },
  paperRoot: {
    backgroundColor: "#fdfdff",
    padding: theme.spacing(3, 4),
  },
  pageAssignHeader: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
  },
  pageAssignIcon: {
    display: "inline-block",
    padding: theme.spacing(1),
    color: "#3d4977",
  },
  pageAssignTitle: {
    paddingLeft: theme.spacing(2),
    "& .MuiTypography-h6": {
      fontWeight: 600,
    },
  },
  pageEditButton: {
    display: "flex",
    alignItems: "right",
    justifyContent: "right",
    marginLeft: "660px",
  },
  cardRoot: {
    minWidth: 275,
  },
  title: {
    display: "flex",
    color: "#2B2B2B",
    margin: theme.spacing(1, 0),
  },
  tableHeading: {
    fontWeight: 600,
  },
  titleBold: {
    fontWeight: 600,
    paddingRight: 4,
  },
  noRecords: {
    width: "100%",
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

const ServiceProviderInfo = ({
  getServiceProviderInfo,
  auth,
  adminServiceProvider,
  editServiceProvider,
  match,
}) => {
  const classes = useStyles();
  const { isAuthenticated } = auth;
  const { userId } = match.params;
  const { serviceProviderInfo } = adminServiceProvider;
  const [textFieldProp, setTextFieldProp] = useState(true);

  useEffect(() => {
    getServiceProviderInfo(userId);
  }, []);

  useEffect(() => {
    formik.setFieldValue(
      "providerName",
      serviceProviderInfo && serviceProviderInfo.providerName
        ? serviceProviderInfo.providerName
        : ""
    );
    formik.setFieldValue(
      "contactName",
      serviceProviderInfo && serviceProviderInfo.contactName
        ? serviceProviderInfo.contactName
        : ""
    );
    formik.setFieldValue(
      "email",
      serviceProviderInfo && serviceProviderInfo.email
        ? serviceProviderInfo.email
        : ""
    );
    formik.setFieldValue(
      "phoneNumber",
      serviceProviderInfo && serviceProviderInfo.phoneNumber
        ? serviceProviderInfo.phoneNumber
        : ""
    );
    formik.setFieldValue(
      "address",
      serviceProviderInfo && serviceProviderInfo.address
        ? serviceProviderInfo.address
        : ""
    );
    formik.setFieldValue(
      "providerGST",
      serviceProviderInfo && serviceProviderInfo.providerGST
        ? serviceProviderInfo.providerGST
        : ""
    );
    formik.setFieldValue(
      "city",
      serviceProviderInfo && serviceProviderInfo.city
        ? serviceProviderInfo.city
        : ""
    );
    formik.setFieldValue(
      "state",
      serviceProviderInfo && serviceProviderInfo.state
        ? serviceProviderInfo.state
        : ""
    );
    formik.setFieldValue(
      "zipCode",
      serviceProviderInfo && serviceProviderInfo.zipCode
        ? serviceProviderInfo.zipCode
        : ""
    );
    formik.setFieldValue(
      "country",
      serviceProviderInfo && serviceProviderInfo.country
        ? serviceProviderInfo.country
        : ""
    );
    formik.setFieldValue(
      "panCard",
      serviceProviderInfo && serviceProviderInfo.panCard
        ? serviceProviderInfo.panCard
        : ""
    );
    formik.setFieldValue(
      "description",
      serviceProviderInfo && serviceProviderInfo.description
        ? serviceProviderInfo.description
        : ""
    );
  }, [serviceProviderInfo]);

  const phoneRegExp =
    "^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$";
  const formik = useFormik({
    initialValues: {
      providerName: "",
      contactName: "",
      email: "",
      // password: '',
      phoneNumber: "",
      // userImage: serviceProviderInfo?.providerName,
      // userImageName: serviceProviderInfo?.providerName,
      address: "",
      providerGST: "",
      city: "",
      state: "",
      zipCode: "",
      country: "India",
      panCard: "",
      description: "",
    },
    validationSchema: Yup.object({
      providerName: Yup.string()
        .trim()
        .required("Provider Name is a required field")
        .min(3, "Name must be at least 3 characters"),
      email: Yup.string()
        .email("Email is not valid")
        .required("Email is required field"),
      contactName: Yup.string()
        .trim()
        .required("Provider ContactName is required field"),
      phoneNumber: Yup.string()
        .trim()
        .length(10, "Phone Number must have 10 numbers")
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone number is required field"),
      address: Yup.string().trim().required("Address is required field"),
      //   providerGST: formik.values.country.toLowerCase() === "india" ?
      //   Yup.string()
      //   .trim()
      //   .required("providerGST is required field")
      // :'',
      city: Yup.string().trim().required("City is required field"),
      state: Yup.string().trim().required("State is reqired field"),
      zipCode: Yup.string().trim().required("zipCode is reqired field"),
      country: Yup.string().trim().required("Country is reqired field"),
      // panCard:country.toLowerCase() === "india" ?
      //  Yup.string()
      //  .trim()
      //  .required("Pancard Number is reqired field")
      //  :'',
    }),
    onSubmit: async (values) => {
      // console.log("@@@@@@@@@@", values);
      const userData = await values;
      //   delete userData.userImageName;

      if (isEmpty(userData.description)) {
        delete userData.description;
      }
      if (isEmpty(userData.providerGST)) {
        delete userData.providerGST;
      }
      if (isEmpty(userData.panCard)) {
        delete userData.panCard;
      }
      userData.userId = userId;

      // console.log("@@@@@@@@@@", userData);
      await editServiceProvider(userData);
    },
  });

  const userData = () => {
    return (
      <>
        <form
          onSubmit={formik.handleSubmit}
          enableReinitialize={true}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                label="providerName"
                type="text"
                name="providerName"
                disabled={textFieldProp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.providerName}
                errors={
                  formik.touched.providerName && formik.errors.providerName
                    ? formik.errors.providerName
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                label="Email"
                type="text"
                name="email"
                disabled={textFieldProp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                errors={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : null
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                label="Provider Contact Name"
                type="text"
                name="contactName"
                disabled={textFieldProp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contactName}
                errors={
                  formik.touched.contactName && formik.errors.contactName
                    ? formik.errors.contactName
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                label="Phone Number"
                type="text"
                name="phoneNumber"
                disabled={textFieldProp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
                errors={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                    ? formik.errors.phoneNumber
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                label="Address"
                type="text"
                name="address"
                disabled={textFieldProp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                errors={
                  formik.touched.address && formik.errors.address
                    ? formik.errors.address
                    : null
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} className="inputFieldContainer ">
              <TextFieldGroup
                label="City"
                type="text"
                name="city"
                disabled={textFieldProp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                errors={
                  formik.touched.city && formik.errors.city
                    ? formik.errors.city
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                label="State"
                type="text"
                name="state"
                disabled={textFieldProp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.state}
                errors={
                  formik.touched.state && formik.errors.state
                    ? formik.errors.state
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                label="ZipCode"
                type="text"
                name="zipCode"
                disabled={textFieldProp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.zipCode}
                errors={
                  formik.touched.zipCode && formik.errors.zipCode
                    ? formik.errors.zipCode
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} className="inputFieldContainer">
              <TextFieldGroup
                label="Country"
                type="text"
                name="country"
                disabled={textFieldProp}
                onChange={formik.handleChange}
                // onChange={setServiceProviderCountry(formik.values.country)}
                // onBlur={setServiceProviderCountry(formik.values.country)}
                value={formik.values.country}
                errors={
                  formik.touched.country && formik.errors.country
                    ? formik.errors.country
                    : null
                }
              />
            </Grid>

            {/* {console.log(serviceProviderCountry)} */}

            {serviceProviderInfo?.country.toLowerCase() === "india" ? (
              <Grid item xs={12} sm={6} className="inputFieldContainer ">
                <TextFieldGroup
                  // label="Provider GST"
                  placeholder="Provider GST"
                  type="text"
                  name="providerGST"
                  disabled={textFieldProp}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.providerGST}
                  errors={
                    formik.touched.providerGST && formik.errors.providerGST
                      ? formik.errors.providerGST
                      : null
                  }
                />
              </Grid>
            ) : null}

            {serviceProviderInfo?.country.toLowerCase() === "india" ? (
              <Grid item xs={12} sm={6} className="inputFieldContainer ">
                <TextFieldGroup
                  // label="Pancard"
                  placeholder="Pancard"
                  type="text"
                  name="panCard"
                  disabled={textFieldProp}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.panCard}
                  errors={
                    formik.touched.panCard && formik.errors.panCard
                      ? formik.errors.panCard
                      : null
                  }
                />
              </Grid>
            ) : null}

            <Grid item xs={12} className="inputFieldContainer">
              <TextFieldGroup
                label="Service Description"
                type="text"
                name="description"
                disabled={textFieldProp}
                multiline
                rows={4}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                errors={
                  formik.touched.description && formik.errors.description
                    ? formik.errors.description
                    : null
                }
              />
            </Grid>
            {textFieldProp === false ? (
              <Grid item xs={12} className="inputFieldContainer">
                <div className={classes.submitButton}>
                  <Button
                    variant="contained"
                    buttonStyle="formSubmit"
                    type="submit"
                    name="Save"
                    style={{ backgroundColor: "#008dd2", color: "white" }}
                    className={classes.mentorForm}
                  >
                    Save
                  </Button>
                </div>
              </Grid>
            ) : null}
            {/* <Grid item xs={12} className="inputFieldContainer">
              <div className={classes.submitButton}>
                <ButtonField
                  variant="contained"
                  buttonStyle="formSubmit"
                  type="submit"
                  name="save"
                  style={{ backgroundColor: "#008dd2", color: "white" }}
                  className={classes.mentorForm}
                >
                  Save
                </ButtonField>
              </div>
            </Grid> */}
          </Grid>
        </form>
      </>
    );
  };

  return (
    <Container maxWidth="lg">
      <div className={classes.gridRoot}>
        <Paper className={classes.paperRoot}>
          <div className={classes.pageAssignHeader}>
            <Card className={classes.pageAssignIcon}>
              <PeopleOutlineIcon fontSize="large" />
            </Card>
            <div className={classes.pageAssignTitle}>
              <Typography variant="h6" component="div">
                Service Provider Details
              </Typography>
            </div>

            <ButtonField
              buttonStyle={classes.pageEditButton}
              name={"Edit"}
              onClick={() => setTextFieldProp(false)}
            />
          </div>
          <Grid container spacing={3}>
            {userData()}
          </Grid>
        </Paper>
      </div>
    </Container>
  );
};

ServiceProviderInfo.propTypes = {
  auth: PropTypes.object.isRequired,
  getServiceProviderInfo: PropTypes.func.isRequired,
  editServiceProvider: PropTypes.func.isRequired,
  adminServiceProvider: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  adminServiceProvider: state.adminServiceProvider,
});

const mapDispatchToProps = { getServiceProviderInfo, editServiceProvider };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceProviderInfo);

import { ADMIN_DASHBOARD_LOADING, ADMIN_GET_DASHBOARD_COUNT } from '../../actions/types';

const initialState = {
    loading: false,
    dashboardCounts: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case ADMIN_DASHBOARD_LOADING:
            return {
                ...state,
                loading: true
            }
        case ADMIN_GET_DASHBOARD_COUNT:
            return {
                ...state,
                dashboardCounts: action.payload,
                loading: false
            }
        default:
            return state;
    }
}
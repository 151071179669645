import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography } from "@material-ui/core";
import MUITable from "../../MaterialTable/MaterialTable";
import { getAllTaskList } from "../../../redux/actions/userAction";

const LearnerTaskReport = ({
  // auth,
  history,
}) => {
  const { auth, usertaskReducer } = useSelector((state) => state);
  const dispatch = useDispatch();
  // console.log("scheduleReportList",scheduleReportList)

  useEffect(() => {
    if (auth?.isAuthenticated) {
      dispatch(getAllTaskList());
    }
  }, [auth?.isAuthenticated]);
  console.log(usertaskReducer?.usertaskdata);

  // const handleLinkOnClick= (repoLink) => {
  //   window.open(
  //     // `${process.env.REACT_APP_ILEARN_URL}/ibridge/${USER_TOKEN()}/${
  //     //   course._id
  //     // }`,
  //     `${repoLink}`,
  //     "_blank"
  //   );
  // };

  // console.log('in component',counsellingList)

  const columns = [
    {
      title: "Learner Name",
      field: "learnerName",
    },
    {
      title: "Task Description",
      field: "description",
    },
  

    {
      title: "Program batch",
      field: "programBatch",
    },
    {
      title: "Start Date",
      field: "taskStartDate",
    },
    {
      title: "End Date",
      field: "taskEndDate",
    },

    {
      title: "Task Link",
      field: "taskLink",
    },
    // {
    //     title: 'Reason',
    //     field: 'reason'
    // }
  ];

  const adminData = usertaskReducer?.usertaskdata?.map((list) => {
    const {
      description,
      updatedAt,
      userId,
      programBatch,
      taskLink,
      taskStartDate,
      taskEndDate,
    } = list;
    // const date = new Date(updatedAt);
    return {
      // scheduleName: schedule.name,
      // learnerName: `${userId.firstName} ${userId.lastName}`,
      description,
      programBatch: programBatch?.name,
      taskStartDate: new Date(taskStartDate).toDateString(),
      taskEndDate: new Date(taskEndDate).toDateString(),
      taskLink,
      learnerName:`${userId?.firstName} ${userId?.lastName}`
      // taskLink: (
      //   <Typography
      //   // onClick={()=>handleLinkOnClick(list?.taskLink)}
      //   >
      //     {taskLink}
      //   </Typography>
      // ),

      // scheduleStartDate: new Date(schedule.scheduleStartDate)
      //   .toString()
      //   .slice(0, 24),
      // scheduleStartDate: new Date(schedule.scheduleEndDate)
      //   .toString()
      //   .slice(0, 24),
    };
  });

  // const mentorData = scheduleReportList?.map((list) => {
  //   const { schedule, updatedAt, userId, programBatch } = list;
  //   const date = new Date(updatedAt);
  //   return {
  //     scheduleName: schedule.name,
  //     programBatch:programBatch?.name,
  //     scheduleStartDate: new Date(schedule.scheduleStartDate)
  //       .toString()
  //       .slice(0, 24),
  //     scheduleStartDate: new Date(schedule.scheduleEndDate)
  //       .toString()
  //       .slice(0, 24),
  //     learnerName: `${userId.firstName} ${userId.lastName}`,
  //     completedTime: date.toString().slice(0, 24),
  //     taskLink:list?.taskLink
  //   };
  // });

  const data = adminData;
  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUITable
            title="User Tasks"
            columns={columns}
            data={data}
            pageSize={10}
            // selectedRow={row => history.push(`/admin/learner/${row._id}`)}
            selectedRow={(row) => {}}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

// CounsellingRequest.propTypes = {
//     auth: PropTypes.object.isRequired,
// }

// const mapStateToProps = (state) => ({
//   auth: state.auth,
//   schedules: state.schedules,
// });

// const mapDispatchToProps = { getScheduleReportList };

//export default connect(mapStateToProps, mapDispatchToProps)(LearnerTaskReport);
export default LearnerTaskReport;

import axios from "axios";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import {
  GET_USER_TASKS,
  GET_ALL_USER_TASKS,
  GET_USER_TASKS_BY_ID,
} from "./types";

// Add  User Task
export const postUserTask = (userData) => (dispatch) => {
  axios
    .post("/api/tasks/addUserTasks", userData)
    .then((res) => {
      dispatch(getUserTaskList(userData.userId, userData.programId));
      const { message } = res?.data;
      SnackBar.success(message);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

//Get User Tasks
export const getUserTaskList = (user, programId) => (dispatch) => {
  // dispatch(programLoading());
  axios
    .get(`/api/tasks/getTaskByUserId/${user}/${programId}`)
    .then((res) => {
      dispatch({
        type: GET_USER_TASKS,
        payload: res?.data?.data,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

export const getAllTaskList = () => (dispatch) => {
  // dispatch(programLoading());
  axios
    .get(`/api/tasks/getAllTasks`)
    .then((res) => {
      dispatch({
        type: GET_ALL_USER_TASKS,
        payload: res?.data?.data,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

export const getUserTaskById = (id, userId) => (dispatch) => {
  // dispatch(programLoading());
  console.log("id", id);

  console.log("userId", userId);
  axios
    .get(`/api/tasks/getUserTaskById/${id}/${userId}`)
    .then((res) => {
      dispatch({
        type: GET_USER_TASKS_BY_ID,
        payload: res?.data?.data,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// edit UserTask
export const editUserTask = (data, user) => (dispatch) => {
  // dispatch(scheduleLoading());
  axios
    .put("/api/tasks/editUserTask", data)
    .then((res) => {
      const { message } = res.data;
      SnackBar.success(message);
      dispatch(getUserTaskList(data.userId, data.programId));
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

import React, { useState, forwardRef } from "react";
import MUITable, { MTableToolbar } from "material-table";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import { $themeBlue } from "../../reusableComponents/Colors";

const MaterialTable = ({ title, columns, data, pageSize, selectedRow }) => {
  // const [selectedRow, setSelectedRow] = useState(null);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const options = {
    searchable: true,
    sorting: true,
    pageSize: pageSize,
    headerStyle: {
      // backgroundColor: "#003399",
      backgroundColor: $themeBlue,
      color: "#F8F9F9",
      fontWeight: 900,
    },
    // rowStyle: rowData => ({
    //     backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
    // })
  };

  return (
    <MUITable
      title={title}
      columns={columns}
      data={data}
      options={options}
      onRowClick={(evt, rowData) => selectedRow(rowData)}
      icons={tableIcons}
      components={{
        Toolbar: (props) => (
          <div style={{ fontWeight: 900 }}>
            <MTableToolbar {...props} />
          </div>
        ),
      }}
    />
  );
};

export default MaterialTable;

import React, { useEffect, useState, Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ReactPlayer from "react-player";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Card,
  CardContent,
} from "@material-ui/core";
import { getDateTime } from "../../reusableComponents/GetDate";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import PersonalityAssessmentGraph from "../Dashboard/PersonalityAssessmentGraph";
import RIASECResultgraph from "../Dashboard/RIASECResultgraph";
import CustomizedDialogs from "../../reusableComponents/DialogModal";
import UserProgressReport from "../Learner_Module/UserProgressReport";

import isEmpty from "../../reusableComponents/is-empty";
import Accordion from "@material-ui/core/Accordion";
import Progress from "./Progress";
// import ToggleButton from './ToggleButton'
// import AccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SendIcon from "@material-ui/icons/Send";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SelectSingleValues from "../../reusableComponents/SelectSingleValues";
import { Avatar } from "@material-ui/core";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import {
  getPrograms,
  assignProgram,
  removeAssignProgram,
} from "../../redux/actions/programActions";

import {
  getLearnerInfo,
  changeUserStatus,
  clearLearnerProgressInfo,
  clearLearnerInfo,
} from "../../redux/actions/admin_view/mvkActions";

const useStyles = makeStyles((theme) => ({
  root: {
    "&$before": {
      backgroundColor: "white",
    },
    width: "100%",
    padding: "2%",
  },
  MuiAccordionRoot: { before: { backgroundColor: "white" } },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const AccordionSummary = withStyles({
  root: {
    // margin: "0",
    // padding:'0',
    backgroundColor: "#003399",
    color: "white",
  },
  content: {
    margin: "0",
    padding: "0",
    backgroundColor: "#003399",
    "&$expanded": {
      margin: "0",
      padding: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const MentorLearnerInfo = ({
  getLearnerInfo,
  clearLearnerInfo,
  auth,
  adminMvk,
  programs,
  assignProgram,
  getPrograms,
  changeUserStatus,
  history,
  clearLearnerProgressInfo,
  match,
}) => {
  const { isAuthenticated } = auth;
  const { userId } = match.params;
  const { learnerInfo } = adminMvk;
  const { programList } = programs;
  const [selectedProgramId, setSelectedProgramId] = useState(null);
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  // const firstOne =
  //   learnerInfo?.userDetails?.psychometricResults[0]?.scores?.traitOne;
  // const secondOne =
  //   learnerInfo?.userDetails?.psychometricResults[0]?.scores?.traitTwo;
  // const thirdOne =
  //   learnerInfo?.userDetails?.psychometricResults[0]?.scores?.traitThree;
  // const fourthOne =
  //   learnerInfo?.userDetails?.psychometricResults[0]?.scores?.traitFour;

  // const psychometricGraphData = [
  //   {
  //     trait_name: "Introvert",
  //     scores: isEmpty(firstOne?.Introvert) ? 0 : firstOne?.Introvert,
  //   },
  //   {
  //     trait_name: "Extrovert",
  //     scores: isEmpty(firstOne?.Extrovert) ? 0 : firstOne?.Extrovert,
  //   },
  //   {
  //     trait_name: "Thinker",
  //     scores: isEmpty(secondOne?.Thinker) ? 0 : secondOne?.Thinker,
  //   },
  //   {
  //     trait_name: "Judgemental",
  //     scores: isEmpty(secondOne?.Judgemental) ? 0 : secondOne?.Judgemental,
  //   },
  //   {
  //     trait_name: "AttentionToDetails",
  //     scores: isEmpty(thirdOne?.AttentionToDetails)
  //       ? 0
  //       : thirdOne?.AttentionToDetails,
  //   },
  //   {
  //     trait_name: "HighFlying",
  //     scores: isEmpty(thirdOne?.HighFlying) ? 0 : thirdOne?.HighFlying,
  //   },
  //   {
  //     trait_name: "Composed",
  //     scores: isEmpty(fourthOne?.Composed) ? 0 : fourthOne?.Composed,
  //   },
  //   {
  //     trait_name: "Restless",
  //     scores: isEmpty(fourthOne?.Restless) ? 0 : fourthOne?.Restless,
  //   },
  // ];

  useEffect(() => {
    clearLearnerInfo();
    clearLearnerProgressInfo();
    getLearnerInfo(userId);
    getPrograms();
  }, []);

  // const { userDetails , assessmentResult} = learnerInfo;

  const personalDetails = () => {
    return (
      <Grid
        container
        spacing={2}
        align="center"
        style={{ paddingTop: "2%", paddingBottom: "2%" }}
      >
        <Grid item xs={4} md={2}>
          <Typography className="accordionDetailsHeading">
            First Name
          </Typography>
        </Grid>
        <Grid item xs={8} md={4}>
          <TextFieldGroup
            disabled={true}
            //  label="firstName"
            type="text"
            name="firstName"
            value={learnerInfo?.userDetails?.firstName}
          />
        </Grid>

        <Grid item xs={4} md={2}>
          <Typography className="accordionDetailsHeading">Last Name</Typography>
        </Grid>
        <Grid item xs={8} md={4}>
          <TextFieldGroup
            disabled={true}
            // label="lastName"
            type="text"
            name="lastName"
            value={learnerInfo?.userDetails?.lastName}
          />
        </Grid>

        <Grid item xs={4} md={2}>
          <Typography className="accordionDetailsHeading">Email</Typography>
        </Grid>
        <Grid item xs={8} md={4}>
          <TextFieldGroup
            disabled={true}
            // label="Email"
            type="text"
            name="email"
            value={learnerInfo?.userDetails?.email}
          />
        </Grid>

        <Grid item xs={4} md={2}>
          <Typography className="accordionDetailsHeading">Phone No</Typography>
        </Grid>
        <Grid item xs={8} md={4}>
          <TextFieldGroup
            disabled={true}
            // label="phoneNumber"
            type="text"
            name="phoneNumber"
            value={learnerInfo?.userDetails?.phoneNumber}
          />
        </Grid>
      </Grid>
    );
  };

  const communicationResult = () => {
    return (
      <>
        {!isEmpty(
          learnerInfo?.userDetails?.psychometricResults[0]?.comunicationVideoURL
        ) ? (
          <>
            <Grid item xs={4}>
              <div className={classes.reactPlayerContainer}>
                <ReactPlayer
                  className={classes.reactPlayer}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                      },
                    },
                  }}
                  url={
                    learnerInfo?.userDetails?.psychometricResults[0]
                      ?.comunicationVideoURL
                  }
                  width="300px"
                  height="100%"
                  // style={{ pointerEvents: 'none', display: 'block', margin: 'auto' }}
                  controls
                />
              </div>
            </Grid>
            {learnerInfo?.userDetails?.psychometricResults[0]?.analysisDone &&
              learnerInfo?.userDetails?.psychometricResults[0]
                ?.sentimentAnalysis && (
                <Grid item xs={8}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className={classes.tableHeading}
                            align="left"
                          >
                            Final Assessment
                          </TableCell>
                          <TableCell
                            className={classes.tableHeading}
                            align="center"
                          >
                            Grammatical Errors
                          </TableCell>
                          <TableCell
                            className={classes.tableHeading}
                            align="center"
                          >
                            Accent Problems
                          </TableCell>
                          <TableCell
                            className={classes.tableHeading}
                            align="right"
                          >
                            Total Duration
                          </TableCell>
                          {learnerInfo?.userDetails?.psychometricResults[0]
                            ?.sentimentAnalysis.errorMessage !== "N/A" && (
                            <TableCell
                              className={classes.tableHeading}
                              align="right"
                            >
                              Errors
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">
                            {
                              learnerInfo?.userDetails?.psychometricResults[0]
                                ?.sentimentAnalysis.finalAssessment
                            }
                          </TableCell>
                          <TableCell align="center">
                            {
                              learnerInfo?.userDetails?.psychometricResults[0]
                                ?.sentimentAnalysis.grammaticalErrors
                            }
                          </TableCell>
                          <TableCell align="center">
                            {
                              learnerInfo?.userDetails?.psychometricResults[0]
                                ?.sentimentAnalysis.spellingErrors
                            }
                          </TableCell>
                          <TableCell align="right">
                            {
                              learnerInfo?.userDetails?.psychometricResults[0]
                                ?.sentimentAnalysis.totalDuration
                            }
                          </TableCell>

                          {learnerInfo?.userDetails?.psychometricResults[0]
                            ?.sentimentAnalysis.errorMessage !== "N/A" && (
                            <TableCell
                              className={classes.tableHeading}
                              align="right"
                            >
                              {
                                learnerInfo?.userDetails?.psychometricResults[0]
                                  ?.sentimentAnalysis.finalAssessment
                              }
                            </TableCell>
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
          </>
        ) : (
          <Typography
            variant="h6"
            component="div"
            className={classes.noRecords}
          >
            Communication Video test has not taken yet
          </Typography>
        )}
      </>
    );
  };

  const counsellingData = () => {
    return learnerInfo?.userDetails?.counsellingInfo?.map((list) => (
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <Card className={classes.cardRoot}>
          <CardContent>
            <div className={classes.title}>
              <Typography className={classes.titleBold}>
                Education : {list?.education}
              </Typography>
              {/* <Typography>{}</Typography> */}
            </div>
            <div className={classes.title}>
              <Typography className={classes.titleBold}>
                City : {list?.city}
              </Typography>
              {/* <Typography></Typography> */}
            </div>
            <div className={classes.title}>
              <Typography className={classes.titleBold}>
                Scheduled Date & Time : {getDateTime(list?.scheduledDateTime)}
              </Typography>
              {/* <Typography></Typography> */}
            </div>
          </CardContent>
        </Card>
      </Grid>
    ));
  };

  const counsellingDetails = () => {
    return (
      <>
        {/* <Card className={classes.pageAssignIcon}>
            <PeopleOutlineIcon fontSize="large" />
          </Card>
          <div className={classes.pageAssignTitle}>
            <Typography variant="h6" component="div">
              Counselling Details
            </Typography>
          </div> */}
        <Grid container spacing={3}>
          {!isEmpty(learnerInfo?.userDetails?.counsellingInfo) ? (
            counsellingData()
          ) : (
            <Typography
              variant="h6"
              component="div"
              className={classes.noRecords}
            >
              No Counselling Records
            </Typography>
          )}
        </Grid>
      </>
    );
  };
  const business101Result = () => {
    return (
      <Grid xs={12} md={12} style={{ paddingTop: "2%" }}>
        {!isEmpty(learnerInfo?.assessmentResult) ? (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeading} align="left">
                    Skill Level
                  </TableCell>
                  <TableCell className={classes.tableHeading} align="center">
                    Topic
                  </TableCell>
                  <TableCell className={classes.tableHeading} align="center">
                    Date
                  </TableCell>
                  <TableCell className={classes.tableHeading} align="right">
                    Result
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {learnerInfo?.assessmentResult.slice(0, 5).map((list) => (
                  <TableRow key={list.id}>
                    <TableCell align="left">{list.skill_level}</TableCell>
                    <TableCell align="center">{list.topicname}</TableCell>
                    <TableCell align="center">{list.insertedDate}</TableCell>
                    <TableCell align="right">{list.tresult}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography
            variant="h6"
            component="div"
            className={classes.noRecords}
          >
            Business 101 Assessment has not taken yet
          </Typography>
        )}
      </Grid>
    );
  };

  const assigningProgram = () => {
    const handleProgram = () => {
      if (!selectedProgramId) {
        SnackBar.error("Please select any program to assign");
      }
      const programData = {
        userId,
        programId: selectedProgramId?._id,
      };
      assignProgram(programData);
    };
    return (
      <Grid container>
        <Grid item xs={12} md={12} style={{ marginTop: "5%" }}>
          <SelectSingleValues
            label="Assign Programs"
            name="programName"
            className={classes.inputFields}
            values={
              programList &&
              learnerInfo?.userDetails?.programsEnrolled?.length > 0
                ? programList.filter(
                    (item1) =>
                      !learnerInfo?.userDetails.programsEnrolled.some(
                        (item2) =>
                          item2._id === item1._id && item2.name === item1.name
                      )
                  )
                : programList
            }
            onSelect={(program) => setSelectedProgramId(program)}
            selected={selectedProgramId}
          />
        </Grid>
        <Grid item xs={12} md={12} align="center">
          <div className={classes.submitButton} style={{ paddingTop: "2%" }}>
            <Button
              buttonStyle="submit"
              type="button"
              name="submit"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
              onClick={handleProgram}
            >
              Assign Program
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  };

  const RIASECTestResult = () => {
    return (
      <Grid item xs={12}>
        {!isEmpty(learnerInfo?.userDetails?.RIASECTestResult) ? (
          <RIASECResultgraph userID={learnerInfo?.userDetails?._id} />
        ) : (
          <Typography
            variant="h6"
            component="div"
            className={classes.noRecords}
          >
            RIASEC Assessment has not taken yet
          </Typography>
        )}
      </Grid>
    );
  };

  //---------------------- main return -------------------------------------
  return (
    <>
      <Grid container style={{ paddingLeft: "2%", paddingRight: "2%" }}>
        <Grid item xs={6} md={6} align="left">
          <Typography
            style={{
              color: "#18479f",
              fontSize: "24px",
              paddingTop: "",
              textTransform: "capitalize",
            }}
          >
            {/* <AccountCircleIcon  /> */}
            {learnerInfo?.userDetails?.firstName}{" "}
            {learnerInfo?.userDetails?.lastName}
          </Typography>
        </Grid>

        <Grid item xs={6} md={6} align="right">
          {!learnerInfo?.userDetails?.status ? (
            <Button
              type="button"
              variant="contained"
              color="primary"
              style={{ padding: "2%" }}
              onClick={() =>
                changeUserStatus(!learnerInfo?.userDetails?.status, userId)
              }
            >
              <PersonAddIcon style={{ paddingRight: "4px" }} /> Activate User
            </Button>
          ) : (
            <Button
              type="button"
              variant="contained"
              color="secondary"
              style={{ padding: "2%" }}
              onClick={() =>
                changeUserStatus(!learnerInfo?.userDetails?.status, userId)
              }
            >
              <PersonAddDisabledIcon style={{ paddingRight: "4px" }} />
              Disable User
            </Button>
          )}
        </Grid>
      </Grid>
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          >
            <Grid container>
              <Grid item xs={4} md={4}>
                <Typography className="accordionSummaryHeading">
                  Personal Details
                </Typography>
              </Grid>
              {/* <Grid item xs={8} md={8}>
                        <Typography className="heading">Total : {learnerInfo?.userDetails?.programsEnrolled?.length}</Typography>
                      </Grid> */}
            </Grid>
            {/* <Typography className="heading">Program Enrolled</Typography> */}
          </AccordionSummary>
          <AccordionDetails>{personalDetails()}</AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          >
            <Grid container>
              <Grid item xs={4} md={4}>
                <Typography className="heading">Program Enrolled</Typography>
              </Grid>
              <Grid item xs={8} md={8}>
                <Typography className="heading">
                  Total : {learnerInfo?.userDetails?.programsEnrolled?.length}
                </Typography>
              </Grid>
            </Grid>
            {/* <Typography className="heading">Program Enrolled</Typography> */}
          </AccordionSummary>
          <AccordionDetails>
            <Progress
              enrolledPrograms={
                learnerInfo?.userDetails?.programsEnrolled || []
              }
              learnerInfo={learnerInfo ? learnerInfo : " "}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          >
            <Grid container>
              <Grid item xs={4} md={4}>
                <Typography className="heading">Assign Program</Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>{assigningProgram()}</AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            className="accordionContainer"
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          >
            <Grid container>
              <Grid item xs={4} md={4}>
                <Typography className="heading">
                  Business 101 Assessment Result
                </Typography>
              </Grid>
              <Grid item xs={4} md={4}>
                <Typography className="heading">
                  Test Taken : {learnerInfo?.assessmentResult?.length}
                </Typography>
              </Grid>
              {/* <Grid item xs={4} md={4}>
                        <Typography className="heading">Best Score:{}</Typography>
                      </Grid> */}
            </Grid>
            {/* <Typography className="heading">Business 101 Assessment Result</Typography> */}
          </AccordionSummary>
          <AccordionDetails>{business101Result()}</AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            className="accordionContainer"
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          >
            <Grid container>
              <Grid item xs={4} md={4}>
                <Typography className="heading">
                  RIASEC Assessment Result
                </Typography>
              </Grid>
              <Grid item xs={8} md={8}>
                <Typography className="heading">
                  Taken :{" "}
                  {learnerInfo?.userDetails?.RIASECTestResult.length > 0
                    ? "yes"
                    : "No"}
                </Typography>
              </Grid>
            </Grid>
            {/* <Typography className="heading">Personality Assessment Result</Typography> */}
          </AccordionSummary>
          <AccordionDetails>{RIASECTestResult()}</AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            className="accordionContainer"
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          >
            <Grid container>
              <Grid item xs={4} md={4}>
                <Typography className="heading">
                  Communication Result
                </Typography>
              </Grid>
              <Grid item xs={8} md={8}>
                <Typography className="heading">
                  Taken :{" "}
                  {learnerInfo?.userDetails?.psychometricResults[0]
                    ?.comunicationVideoURL?.length > -1
                    ? "Yes"
                    : "No"}
                </Typography>
              </Grid>
            </Grid>
            {/* <Typography className="heading">Communication Result</Typography> */}
          </AccordionSummary>
          <AccordionDetails>{communicationResult()}</AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            className="accordionContainer"
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          >
            <Grid container>
              <Grid item xs={4} md={4}>
                <Typography className="heading">Counselling Details</Typography>
              </Grid>
              <Grid item xs={2} md={2}>
                <Typography className="heading">
                  Booked : {learnerInfo?.userDetails?.counsellingInfo?.length}
                </Typography>
              </Grid>
              {/* <Grid item xs={2} md={2}>
                        <Typography className="heading">Consulted:{}</Typography>
                      </Grid>
                      <Grid item xs={2} md={2}>
                        <Typography className="heading">Pending:{}</Typography>
                      </Grid>  */}
            </Grid>
            {/* <Typography className="heading">Counselling Details</Typography> */}
          </AccordionSummary>
          <AccordionDetails>{counsellingDetails()}</AccordionDetails>
        </Accordion>
      </div>

      {/* <CustomizedDialogs 
        title = {<Grid container >
                  <Grid item xs={6} md={6} style={{backgroundColor:"white"}}>
                    <Grid container>
                        <Grid item xs={2} md={2}>
                          <Avatar style={{height:"70px",width:"70px"}}/>
                        </Grid>

                          <Grid item xs={10} md={10}>
                            <Grid container>
                              <Grid item xs={12} md={12}>
                                  <Typography>
                                  Vishwas.V.Bharadwaj
                                  </Typography>
                              </Grid>
                              <Grid item xs={12} md={12}>
                              <Typography>
                                  Data 
                                  </Typography>
                              </Grid> 
                              <Grid item xs={12} md={12}>
                                  <Typography>
                                  02/3/2022 - 03/5/2022
                                  </Typography>
                              </Grid>    
                            </Grid>
                          </Grid>

                    </Grid>
                  </Grid>
                  <Grid item xs={6} md={6} align="right" style={{paddingTop:"2%",paddingRight:"4%"}}>
                    <Button
                        variant="contained" 
                        color="primary"
                        style={{padding:"2%",width:"140px"}}
                    >
                        Send
                    </Button>
                  </Grid>
                </Grid>}
        children={<UserProgressReport />} 
        openPopup={open} 
        setOpenPopup = {setOpen} /> */}
    </>
  );
};

// export default MentorLearnerInfo;

MentorLearnerInfo.propTypes = {
  auth: PropTypes.object.isRequired,
  getLearnerInfo: PropTypes.func.isRequired,
  clearLearnerProgressInfo: PropTypes.func.isRequired,
  clearLearnerInfo: PropTypes.func.isRequired,
  adminMvk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  adminMvk: state.adminMvk,
  programs: state.programs,
});

const mapDispatchToProps = {
  getLearnerInfo,
  assignProgram,
  getPrograms,
  changeUserStatus,
  clearLearnerProgressInfo,
  clearLearnerInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(MentorLearnerInfo);

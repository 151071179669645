// import React from "react";
// // import "./styles.css";
// import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import IconButton from "@material-ui/core/IconButton";
// import DeleteIcon from "@material-ui/icons/Delete";
// import { Box, Grid } from "@material-ui/core";

// import TextFieldGroup from "./TextFieldGroup";

// const DynamicTextField = () => {

//   const [open, setOpen] = React.useState(false);
// const [values, setValues] = React.useState([]);
// const [text, setText] = React.useState("");
// const [res, setRes] = React.useState(false);
//   const handleClickOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//     setValues([]);
//   };
//   const handleChangeText = (e) => {
//     setText(e.target.value);
//   };
// const addValue = () => {
//   setValues([...values, ""]);
// };
// const handleValueChange = (index, e) => {
//   const updatedValues = values.map((value, i) => {
//     if (i === index) {
//       return e.target.value;
//     } else {
//       return value;
//     }
//   });
//   setValues(updatedValues);
// };
// const deleteValue = (jump) => {
//   setValues(values.filter((j) => j !== jump));
// };
//     return ( <div>
//  <TextFieldGroup
//   label="Program path"
//   type="text"
//   name="programPath"
//   multiline
//   rows={4}
//   onFocus={handleClickOpen}
//  >

//      </TextFieldGroup>
//       <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="form-dialog-title"
//       >
//         <DialogTitle id="form-dialog-title">New Dialog</DialogTitle>
//         <DialogContent>
//           <DialogContentText>Sample Text.</DialogContentText>
//           <TextField
//             autoFocus
//             margin="dense"
//             value={text}
//             onChange={handleChangeText}
//             label="Text"
//             fullWidth
//           />
// {values.map((jump, index) => (
//   <Box key={"jump" + index}>
//     <Grid container spacing={1} alignItems="flex-end">
//       <Grid item xs={10}>
//         <TextField
//           autoFocus
//           margin="dense"
//           label="Value"
//           value={jump || ""}
//           onChange={(e) => handleValueChange(index, e)}
//           fullWidth
//         />
//       </Grid>
//       <Grid item xs={2}>
//         <div
//           className="font-icon-wrapper"
//           onClick={() => deleteValue(jump)}
//         >
//           <IconButton aria-label="delete">
//             <DeleteIcon />
//           </IconButton>
//         </div>
//       </Grid>
//     </Grid>
//   </Box>
// ))}
//         </DialogContent>
// {res === true ? <TextField>{values}</TextField> : null}
// <Button onClick={addValue} color="primary">
//   Add
// </Button>
//         <DialogActions>
//           <Button onClick={handleClose} variant="contained" color="secondary">
//             Cancel
//           </Button>
//           <Button
//             onClick={() => {console.log("values",values)}}
//             variant="contained"
//             color="primary"
//           >
//             Create
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div> );
//   }

//   export default DynamicTextField;

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";

import TextFieldGroup from "./TextFieldGroup";
import { Box, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

const DynamicTextField = ({
  disabled,
  name,
  label,
  className,
  value,
  onChangeData,
  errors,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState([]);
  const [textValue, setTextValue] = React.useState([""]);
  // const [text, setText] = React.useState("");
  const [res, setRes] = React.useState(false);

  useEffect(() => {
    value ? setValues(value) : setValues([]);
  }, [value]);

  const handleClickOpen = () => {
    const text = document.getElementsByName(name);
    // console.log("text",text)
    text[0].blur();
    setOpen(true);
    // const text = document.getElementsByName(name);
    // console.log("text",text)
    // text[0].blur();
  };

  const handleClose = () => {
    setOpen(false);
    disabled = true;
  };

  const addValue = () => {
    setValues([...values, ""]);
  };
  const handleValueChange = (index, e) => {
    const updatedValues = values.map((value, i) => {
      if (i === index) {
        return e.target.value;
      } else {
        return value;
      }
    });
    setValues(updatedValues);
  };
  const deleteValue = (jump) => {
    setValues(values.filter((j) => j !== jump));
  };

  const handleDone = () => {
    // console.log("values", values);
    onChangeData(values);
    setOpen(false);
  };
  return (
    <React.Fragment>
      <TextFieldGroup
        label={label}
        type="text"
        name={name}
        className={className || ""}
        disabled={false || disabled}
        multiline
        rows={4}
        onFocus={handleClickOpen}
        errors={errors ? errors : null}
        // value={textValue}
        value={values?.join(",")}
      />
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{label}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText> */}
          {values.map((jump, index) => (
            <Box key={"jump" + index}>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={10}>
                  <TextFieldGroup
                    autoFocus
                    margin="dense"
                    label="Value"
                    value={jump || ""}
                    onChange={(e) => handleValueChange(index, e)}
                    fullWidth
                    // errors={errors ? errors : null}
                  />
                </Grid>
                <Grid item xs={2}>
                  <div
                    className="font-icon-wrapper"
                    onClick={() => deleteValue(jump)}
                  >
                    <IconButton aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Box>
          ))}
        </DialogContent>
        {/* {res === true ? <TextField>{values}</TextField> : null} */}

        <Grid container spacing={1} align="right">
          <Grid item xs={6} md={6}>
            <Button onClick={addValue} color="primary">
              Add
            </Button>
            <Button
              onClick={handleDone}
              variant="contained"
              color="primary"
              maxWidth="sm"
            >
              Done
            </Button>
          </Grid>
        </Grid>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DynamicTextField;

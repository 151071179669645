import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Grid } from '@material-ui/core';
import DashboardCard from '../../reusableComponents/DashboardCard';
import { getdashboardCount} from '../../redux/actions/admin_view/dashboardActions';

const ServiceProviderDashboard = ({
    getdashboardCount,   
    auth,
    adminDashboard,
    history
}) => {

    const { isAuthenticated } = auth;
    const { dashboardCounts } = adminDashboard;

    useEffect(() => {
        if(isAuthenticated) {
            getdashboardCount();           
        }
    }, [isAuthenticated]);

    const renderCounts = () => {
        return dashboardCounts?.map(list => (
            <Grid key={list.id} item xs={12} sm={6} md={4}>
                <DashboardCard 
                    title={list?.name}
                    count={list?.count}
                />
            </Grid>
        ))
    }
    return (
        <Container maxWidth="lg" className="adminDashboard">
            <Grid container spacing={4}>
                {renderCounts()}          
            </Grid>
        </Container>
    );
};

ServiceProviderDashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    getdashboardCount: PropTypes.func.isRequired,  
    adminDashboard: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    adminDashboard: state.adminDashboard
});

const mapDispatchToProps = { getdashboardCount}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProviderDashboard);
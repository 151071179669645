import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  Container,
  LinearProgress,
  Box,
  Typography,
} from "@material-ui/core";
import MUITable from "../../MaterialTable/MaterialTable";
import { Search as SearchIcon, Add as AddIcon } from "@material-ui/icons";
import { Avatar } from "@material-ui/core";

import { GroupAdd as GroupAddIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import ButtonField from "../../../reusableComponents/ButtonField";
import PageHeader from "../../../reusableComponents/PageHeader";
import isEmpty from "../../../reusableComponents/is-empty";
import {
  getBatchUsersList,
  getBatchApplicableUsers,
  addBatchUser,
  removeBatchUser,
} from "../../../redux/actions/programBatchActions";
import SelectMultipleValues from "../../../reusableComponents/SelectMultipleValues";
// import DialogModal from "../../../reusableComponents/DialogModal";
import GradeIcon from "@material-ui/icons/Grade";

// --------------------------Dialog Box-----------------------
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CustomizedDialogs from "../../../reusableComponents/DialogModal";
import UserProgressReport from "../../Learner_Module/UserProgressReport";
import Spinner from "../../../reusableComponents/Spinner";

// ----------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  dialogContentText: {
    color: "black",
  },
  selectMultipleValues: {
    color: "black",
    width: "500px",
    // width:"100%"
  },
  // selectMultipleValuesGrid:{
  //   width:"100%"
  // }
}));

const BatchUsersList = ({
  getBatchUsersList,
  getBatchApplicableUsers,
  addBatchUser,
  removeBatchUser,
  programBatch,
  auth,
  match,
  history,
}) => {
  const classes = useStyles();
  const { isAuthenticated, user } = auth;
  const { batchId, batchName } = match.params;
  // console.log("match.params", match.params);
  // console.log("programBatch", programBatch);
  const { batchUsersList, batchApplicableUsers, loading } = programBatch;

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [openPopup, setOpenPopup] = useState(false);
  const [learner, setLearner] = useState("");
  const [learnerId, setLearnerId] = useState("");
  const [program, setProgram] = useState({});

  useEffect(() => {
    if (isAuthenticated) {
      getBatchUsersList(batchId);
      getBatchApplicableUsers(batchId);
    }
  }, [isAuthenticated]);

  // const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      selectUser: [],
    },
    validationSchema: Yup.object({
      selectUser: Yup.array(
        Yup.object({
          _id: Yup.string(),
          name: Yup.string(),
        })
      )
        .min(1, "Please Select Atleast One User")
        .required("Please Select Atleast One User"),
    }),
    onSubmit: async (values) => {
      console.log("onSubmitValue", values.selectUser);
      const data = {
        batchId: batchId,
        users: values.selectUser.map((item) => item._id),
      };
      console.log("onSubmitData", data);
      addBatchUser(data);
      setOpen(false);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(event.target.value);
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const columns = [
    // {
    //   title: "Id",
    //   field: "id",
    // },
    {
      title: "Name",
      field: "name",
    },
    // {
    //   title: "email",
    //   field: "email",
    // },
    // {
    //   title: "Phone Number",
    //   field: "phoneNumber",
    // },
    {
      title: "Video Consumption",
      field: "videoConsumption",
    },
    {
      title: "iCapability",
      field: "iCapability",
    },
    {
      title: "View Report",
      field: "viewReport",
    },
    {
      title: "Remove User",
      field: "removeUser",
    },
  ];

  const data = batchUsersList?.map((item) => {
    return {
      name: (
        <Typography
          variant="body2"
          style={{ display: "flex", alignItems: "center", paddingRight: "10%" }}
        >
          {`${item.firstName}${item.lastName ? item.lastName : ""}`}{" "}
          {batchUsersList[0]?._id === item._id ? (
            <GradeIcon style={{ color: "gold", paddingLeft: "2%" }} />
          ) : null}
        </Typography>
      ),
      // phoneNumber: item.phoneNumber,
      videoConsumption: (
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress
              style={{ borderRadius: 5, height: 7 }}
              variant="determinate"
              color={item.progress > 35 ? "primary" : "secondary"}
              value={item.progress}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2">{`${item.progress}%`}</Typography>
          </Box>
        </Box>
      ),
      iCapability: (
        <Typography variant="body2">{`Test Taken: ${item.capabilityTestsTaken}`}</Typography>
      ),

      viewReport: (
        <ButtonField
          color={"#003399"}
          // buttonStyle={classes.newButton}
          variant="outlined"
          name="Report"
          onClick={() => {
            setLearner(
              `${item.firstName}${item.lastName ? item.lastName : ""}`
            );
            setLearnerId(item._id);
            setProgram({
              programId: item.programId,
              programName: item.programName,
            });
            setOpenPopup(true);
          }}
        />
      ),

      removeUser: (
        <ButtonField
          color={"#003399"}
          buttonStyle={classes.newButton}
          variant="outlined"
          name="Remove"
          onClick={() => {
            const data = {
              batchId: batchId,
              userId: item._id,
            };
            console.log("data in remove OnClick", data);
            removeBatchUser(data);
          }}
        />
      ),
    };
  });

  const dataList = batchApplicableUsers?.map((item) => {
    return {
      name: `${item.firstName}${item.lastName ? item.lastName : ""}`,
      _id: item._id,
    };
  });

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Container maxWidth="lg" className="adminAddNewEducator">
          <Grid container>
            <Grid item xs={12} md={12}>
              <PageHeader
                // title="Batch User's List"
                title={batchName}
                icon={<GroupAddIcon fontSize="large" />}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MUITable
                title={
                  <ButtonField
                    color={"#003399"}
                    buttonStyle={classes.newButton}
                    variant="outlined"
                    name="Add User to Batch"
                    icon={<AddIcon />}
                    onClick={
                      () =>
                        // history.push(`/admin/addBatchUsers/${batchId}`)
                        setOpen(true)

                      // console.log("Working",{batchId})
                    }
                  />
                }
                columns={columns}
                data={data}
                pageSize={10}
                selectedRow={(row) => console.log("clicked")}
                // selectedRow={(row) =>
                // // history.push(`/admin/addBatchUsers/${row._id}`)
                // history.push(`/admin/batchUsersList/${row._id}`)
                // }
              />
            </Grid>
          </Grid>

          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle
              id="max-width-dialog-title"
              style={{ color: "#003399" }}
            >
              Add User
            </DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.dialogContentText}>
                Select the users for the batch
              </DialogContentText>
              <form
                className={classes.form}
                onSubmit={formik.handleSubmit}
                noValidate
                autoComplete="off"
              >
                <Grid content spacing={2}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    className={classes.selectMultipleValuesGrid}
                  >
                    <SelectMultipleValues
                      label="users"
                      name="selectUser"
                      className={classes.selectMultipleValues}
                      dataList={dataList}
                      onSelectData={
                        (selectUser) =>
                          formik.setFieldValue("selectUser", selectUser)
                        // console.log("selectUser",selectUser)
                      }
                      errors={
                        formik.touched.selectUser && formik.errors.selectUser
                          ? formik.errors.selectUser
                          : null
                      }
                      // selectedData={formik.values.preferredDays}
                      // selectedData={[{_id:1,name:"Vishwas"}]}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    align="center"
                    style={{ paddingTop: "5%" }}
                  >
                    <ButtonField
                      buttonStyle="formSubmit"
                      type="submit"
                      name="Submit"
                      className={classes.mentorForm}
                    />
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      )}

      {/* <DialogModal
            title="Add User"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
          >
            <form className={classes.form} onSubmit={formik.handleSubmit} noValidate autoComplete="off">
                <Grid content spacing={2} width="500px">
                  <Grid item xs={12} md={12} className={classes.selectMultipleValuesGrid} >
                    <SelectMultipleValues 
                      label="users"
                      name="selectUser"
                      className={classes.selectMultipleValues}
                      Style={{width:"500px"}}
                      dataList={dataList}
                      onSelectData={(selectUser) =>
                        // formik.setFieldValue("selectUser", selectUser)
                        console.log("selectUser",selectUser)
                      }
                      errors={
                        formik.touched.selectUser && formik.errors.selectUser
                          ? formik.errors.selectUser
                          : null
                      }
                      // selectedData={formik.values.preferredDays}
                      // selectedData={[{_id:1,name:"Vishwas"}]}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} align="center" style={{paddingTop:"5%"}}>
                    <ButtonField
                      buttonStyle="formSubmit"
                      type="submit"
                      name="Submit"
                      className={classes.mentorForm}
                    />
                  </Grid>
                </Grid>
              </form>
          </DialogModal> */}
      <CustomizedDialogs
        title={
          <Grid container>
            <Grid item xs={6} md={6} style={{ backgroundColor: "white" }}>
              <Grid container>
                <Grid item xs={2} md={2}>
                  <Avatar style={{ height: "70px", width: "70px" }} />
                </Grid>

                <Grid item xs={10} md={10}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <Typography>{learner}</Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography>{program.programName}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              align="right"
              style={{ paddingTop: "2%", paddingRight: "-10%" }}
            >
              {/* <Button
                        variant="contained" 
                        color="primary"
                        style={{height:"40px",width:"140px",marginRight:"-200px"}}
                    >
                        Send
                        <SendIcon style={{padding:"1%",paddingLeft:"4%"}}/>
                    </Button> */}
            </Grid>
          </Grid>
        }
        children={
          <UserProgressReport
            course={program.programId}
            learnerInfo={learnerId}
          />
        }
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      />
    </>
  );
};

BatchUsersList.propTypes = {
  auth: PropTypes.object.isRequired,
  getBatchUsersList: PropTypes.func.isRequired,
  getBatchApplicableUsers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  programBatch: state.programBatch,
});

const mapDispatchToProps = {
  getBatchUsersList,
  getBatchApplicableUsers,
  addBatchUser,
  removeBatchUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchUsersList);

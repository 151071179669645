import isEmpty from "../../reusableComponents/is-empty";
import {
  GET_SCHEDULE_LIST,
  SCHEDULE_LOADING,
  GET_PROGRAM_SCHEDULE_LIST,
  GET_SCHEDULE_REPOET_LIST,
  GET_SCHEDULE_INFO,
} from "../actions/types";

const initialState = {
  loading: false,
  scheduleList: null,
  programSchedule: null,
  scheduleInfo:null,
  scheduleReportList:[],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SCHEDULE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_SCHEDULE_LIST:
      return {
        ...state,
        scheduleList: action.payload,
        loading: false,
      };
    case GET_PROGRAM_SCHEDULE_LIST:
      return {
        ...state,
        programSchedule: action.payload,
        loading: false,
      };
      case GET_SCHEDULE_INFO:
      return {
        ...state,
        scheduleInfo: action.payload,
        loading: false,
      };
      case GET_SCHEDULE_REPOET_LIST:
        return {
          ...state,
          scheduleReportList : action.payload,
          loading: false,
        };
    default:
      return state;
  }
}

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, { useState, useEffect } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
import CalenderDialog from "../../Learner_Module/CalenderDialog";
import { completeTask } from "../../../redux/actions/scheduleActions";
import { connect } from "react-redux";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});


function ProgramSchedule({ eventData, completeTask }) {
  const events = [
    {
      title: "Big Meeting",
      allDay: true,
      start: new Date(2021, 6, 0),
      end: new Date(2021, 6, 0),
    },
    {
      title: "Vacation",
      start: new Date(2021, 6, 7),
      end: new Date(2021, 6, 10),
    },
    {
      title: "Conference",
      start: new Date(2021, 6, 20),
      end: new Date(2021, 6, 23),
    },
  ];
  const [newEvent, setNewEvent] = useState({ title: "", start: "", end: "" });
  const [allEvents, setAllEvents] = useState(eventData);
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});

  function handleAddEvent() {
    setAllEvents([...allEvents, newEvent]);
  }
  const handleClickOpen = (e) => {
    if(e.completed)
    {setOpen(false);}
    else {
      setSelectedEvent(e);
      setOpen(true);}
  };


  console.log("selectedEvent",selectedEvent)

  const handleSubmit = (e) => {
    const programId = e?.programId;
    completeTask(e, programId);
    // console.log("completeTask",e)
    setOpen(false);
  };

  return (
    <div>
      <Calendar
        // className="openModalBtn"
        localizer={localizer}
        events={eventData || []}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        eventPropGetter={(event) => {
          const backgroundColor = event.completed ? "green" : "red";
          return { style: { backgroundColor } };
        }}
        onSelectEvent={handleClickOpen}
      />
      {console.log("programschedulepage", open)}
      <CalenderDialog
        showOpen={open}
        onClose={() => setOpen(false)}
        handleSubmit={(e) => handleSubmit(e)}
        selectedEvent={selectedEvent}
        taskType={selectedEvent?.taskType}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = {
  completeTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramSchedule);

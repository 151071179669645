import React, {useEffect} from "react";
import { makeStyles, Container, Box, Grid, Typography, AppBar, Toolbar, Button } from "@material-ui/core";
import JobMappingGraph from "./JobMappingGraph/JobMappingGraph";
import ButtonField from "../../reusableComponents/ButtonField";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { JobProfileTypes } from "./JobMappingGraph/jobProfiles";
import { useSelector} from "react-redux";
import JobTypes from './JobMappingGraph/jobTypes'
import JobPosts from './JobMappingGraph/jobPosts'

const Explore = ({ history }) => {

  const { openAIReducer, jobPostsApiReducer } = useSelector((state) => state);
  const useStyles = makeStyles((theme) => ({
    button: {
      textTransform: "none",
      backgroundColor: '#18479F',
      color: 'white',
      '&:disabled': {
        backgroundColor: '#808080', 
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    const timer = setTimeout(() => {
      const navBarDOM = document.querySelector('.navBar');
      console.log('navBarDOM ', navBarDOM);
      if (navBarDOM) navBarDOM.remove();
    }, 100); 
  
    return () => clearTimeout(timer); 
  }, []);
  

  return (
    <>
  <AppBar position="fixed">
        <Toolbar
        style={{
          backgroundColor: "#fff",
          height: 75
        }}>
          <Box style={{display: 'flex', justifyContent: 'space-between', width: "85%"}}>
          <img src="https://ibridge360.com/wp-content/uploads/2024/02/final-illustrator-logo-1-1-2048x590.png" alt="logo"
          style={{
            height: 48,
            marginLeft: 165
          }}
          />
          <Box>
            <a href="https://ibridge360.com">
            <Button
            className={classes.button}
            variant="contained"
            style={{marginTop: 10}}
            startIcon={<ArrowBackIosIcon />}
            >
              Home
            </Button>
            </a>
          </Box>
          </Box>
          <Typography variant="h6"></Typography>
        </Toolbar>
      </AppBar>
    <Container maxWidth="lg">   
        <Typography variant="h5"
              style={{
                display: 'block',
                marginTop: 50,
                marginBottom: 40
              }}>
          Explore Jobs
        </Typography>
      <JobMappingGraph />
      {openAIReducer?.jobTypesData && <JobProfileTypes />}
      {/* {openAIReducer?.jobTypesData && <JobTypes />} */}
      {/* {jobPostsApiReducer?.jobPostsData && <JobPosts />} */}

    </Container>
    </>
  );
};

export default Explore;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Grid } from '@material-ui/core';
import MUITable from '../../MaterialTable/MaterialTable';
import { getCounsellingList } from '../../../redux/actions/admin_view/mvkActions';
import { getDateTime } from '../../../reusableComponents/GetDate';

const CounsellingRequest = ({  
    getCounsellingList,
    auth,
    adminMvk,
    history
}) => {

    const { isAuthenticated } = auth;
    const { counsellingList } = adminMvk;

    
    useEffect(() => {
        if(isAuthenticated) {       
            getCounsellingList();
        }
    }, [isAuthenticated]);
  

    console.log('in component',counsellingList)


    const columns = [
        {
            title: 'Name',
            field: 'name'
        },
        {
            title: 'Email ID',
            field: 'email'
        },
        {
            title: 'Education',
            field: 'education'
        },
        {
            title: 'Phone Number',
            field: 'phoneNumber'
        },
        {
            title: 'Scheduled date',
            field: 'scheduledDate'
        },
        {
            title: 'Reason',
            field: 'reason'
        }
    ]

    const data = counsellingList?.map(list => { 
        const { _id, userId, role, phoneNumber, education, scheduledDateTime, reason } = list;
        const { firstName, lastName, email } = userId;
        return {
            _id,
            name: `${firstName} ${lastName}`,
            email,
            role,
            phoneNumber,
            education,
            scheduledDate: getDateTime(scheduledDateTime),
            reason,
        }
    })

    return (
        <Container maxWidth="lg" className="adminMvk">
            <Grid container spacing={4}>               
                <Grid item xs={12}>
                    <MUITable 
                        title="Counselling Request List"
                        columns={columns}
                        data={data}
                        pageSize={10}
                        // selectedRow={row => history.push(`/admin/learner/${row._id}`)}
                        selectedRow={row => history.push('#')}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

CounsellingRequest.propTypes = {
    auth: PropTypes.object.isRequired,    
    getCounsellingList: PropTypes.func.isRequired,
    adminMvk: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    adminMvk: state.adminMvk
});

const mapDispatchToProps = {getCounsellingList }

export default connect(mapStateToProps, mapDispatchToProps)(CounsellingRequest);
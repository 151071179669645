import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardActions as MuiCardActions,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 'auto',
  },
  content: {
    display: "flex",
    // flexDirection: 'column',
    justifyContent: "space-between",
    [theme.breakpoints.up("lg")]: {
      height: 70,
    },
    [theme.breakpoints.down("md")]: {
      height: 90,
    },
    [theme.breakpoints.down("sm")]: {
      height: 110,
    },
    [theme.breakpoints.down("xs")]: {
      height: 60,
    },
    alignItems: "center",
    "&:last-child": {
      paddingBottom: 16,
    },
  },
  title: {
    paddingLeft: "8%",
    paddingTop: "2%",
    fontSize: 16,
    color: "#2B2B2B",
  },
  count: {
    fontSize: 18,
    // color: '#2B2B2B',
    color: "white",
    fontWeight: 600,
    paddingTop: "25%",
    paddingRight: "10%",
    textAlign: "right",
  },
}));

export default function DashboardCard({ title, count, onClick }) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <>
      <MuiCard className={`${classes.root} dashboardCard`}>
        <Grid container onClick={onClick} style={{ cursor: "pointer" }}>
          <Grid item xs={6} md={8}>
            {/* <MuiCardContent className={`${classes.content} dashboardCardContent1`} > */}
            <Typography variant="h6" component="h2" className={classes.title}>
              {title}
            </Typography>
            {/* </MuiCardContent> */}
          </Grid>

          <Grid item xs={6} md={4} className="dashboardCardContent2">
            {/* <MuiCardContent className={`${classes.content} ` } > */}
            <Typography variant="h6" component="h2" className={classes.count}>
              {count}
            </Typography>
            {/* </MuiCardContent> */}
          </Grid>
        </Grid>
      </MuiCard>
    </>
  );
}

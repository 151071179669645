import axios from "axios";
import {
  PROGRAM_LEARNER_LIST,
  GET_MENTOR_BATCH_LIST,
  GET_MENTOR_SCHEDULE_LIST,
} from "./types";

import { SnackBar } from "../../reusableComponents/SnackbarConfig";

// Get Learners List
export const getProgramLearnerList = (id) => (dispatch) => {
  // dispatch(mkvLoading());
  axios
    .get(`/api/adminDashboard/programLearnerList/${id}`)
    .then((res) =>
      dispatch({
        type: PROGRAM_LEARNER_LIST,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get Learners List
export const getMentorBatchList = (id) => (dispatch) => {
  // dispatch(mkvLoading());
  axios
    .get(`/api/programBatch/getMentorBatches/${id}`)
    .then((res) => {
      console.log("res?.data?.data", res?.data?.data);
      dispatch({
        type: GET_MENTOR_BATCH_LIST,
        payload: res?.data?.data,
      });
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

export const getMentorScheduleList = (id) => (dispatch) => {
  // dispatch(mkvLoading());
  axios
    .get(`/api/schedule/getMentorScheduleList/${id}`)
    .then((res) =>
      dispatch({
        type: GET_MENTOR_SCHEDULE_LIST,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Mvk Loading
// export const mkvLoading = () => {
//     return {
//         type : ADMIN_MVK_LOADING
//     }
// }

import React, { Component, useState } from "react";

import { Grid, Typography } from "@material-ui/core";

//---------------Function Components-----------------

import TermsAndCondition from "./TermsAndCondition";
import PrivacyPolicy from "./PrivacyPolicy";
import RefundPolicy from "./RefundPolicy";
import CancelPolicy from "./CancelPolicy";
import DataDeletionPolicy from "./DataDeletionPolicy";
const TermsConditions = () => {
  const [termsType, setTermsType] = useState("termsAndCondition");

  return (
    <Grid container className="termsContainer">
      <Grid item xs={12} md={2}>
        <Grid container align="center" className="termsOptionContainer">
          <Grid item xs={12} md={12}>
            <Typography
              className={
                termsType === "termsAndCondition"
                  ? "termsOptions"
                  : "termsOptions1"
              }
              onClick={() => setTermsType("termsAndCondition")}
              style={{ cursor: "pointer" }}
            >
              Terms and Conditions
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              className={
                termsType === "privacyPolicy" ? "termsOptions" : "termsOptions1"
              }
              onClick={() => setTermsType("privacyPolicy")}
              style={{ cursor: "pointer" }}
            >
              Privacy Policy
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              className={
                termsType === "refundPolicy" ? "termsOptions" : "termsOptions1"
              }
              onClick={() => setTermsType("refundPolicy")}
              style={{ cursor: "pointer" }}
            >
              Refund Policy
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              className={
                termsType === "cancelPolicy" ? "termsOptions" : "termsOptions1"
              }
              onClick={() => setTermsType("cancelPolicy")}
              style={{ cursor: "pointer" }}
            >
              Cancellation Policy
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              className={
                termsType === "DataDeletionPolicy"
                  ? "termsOptions"
                  : "termsOptions1"
              }
              onClick={() => setTermsType("DataDeletionPolicy")}
              style={{ cursor: "pointer" }}
            >
              Data Deletion Policy
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {termsType === "termsAndCondition" ? (
        <Grid item xs={12} md={9}>
          <TermsAndCondition />
        </Grid>
      ) : null}
      {termsType === "privacyPolicy" ? (
        <Grid item xs={12} md={9}>
          <PrivacyPolicy />
        </Grid>
      ) : null}
      {termsType === "refundPolicy" ? (
        <Grid item xs={12} md={9}>
          <RefundPolicy />
        </Grid>
      ) : null}
      {termsType === "cancelPolicy" ? (
        <Grid item xs={12} md={9}>
          <CancelPolicy />
        </Grid>
      ) : null}
      {termsType === "DataDeletionPolicy" ? (
        <Grid item xs={12} md={9}>
          <DataDeletionPolicy />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default TermsConditions;

import React from "react";
import { Switch, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import Layout from "./Layout";

import Welcome from "./components/Welcome/Welcome";
import AboutUs from "./components/About/AboutUs";

import Explore from "./components/SignIn_SignUp/Explore";
import SignIn from "./components/SignIn_SignUp/SignIn";
import SignUp from "./components/SignIn_SignUp/SignUp";
import Programs from "./components/programs/programs";
import ForgotPassword from "./components/SignIn_SignUp/ForgotPassword";
import PasswordReset from "./components/SignIn_SignUp/PasswordReset";

import PaymentProcess from "./components/PaymentProcess/paymentProcess";

import Dashboard from "./components/Dashboard/Dashboard";

import TermsConditions from "./components/terms&conditions/TermsConditions";

// Learners Module
import MyPrograms from "./components/Learner_Module/MyPrograms";
import LearnerCourse from "./components/Learner_Module/LearnerCourse";
import JoinMeeting from "./components/Zoom/JoinMeeting";
import EditLearnerProfile from "./components/Learner_Module/EditLearnerProfile";
import WelcomeRedirectComponent from "./components/Welcome/WelcomeRedirectComponent";
import ProgramRedirectComponent from "./components/programs/ProgramRedirectComponent";
import AboutRedirectComponent from "./components/About/AboutRedirectComponent";
// import LearnerResetPassword from "./components/Learner_Module/LearnerResetPassword";

// Admin Routes
import AdminRoutes from "./AdminRoutes";

const App = () => {
  return (
    <Switch>
      <Route path="/paymentProcess/:programId" component={PaymentProcess} />

      <Route exact path="/" component={WelcomeRedirectComponent} />
      <Route path="/about-us" component={AboutRedirectComponent} />
      <Route path="/programs" component={ProgramRedirectComponent} />

      {/**---- Admin Main Route ----**/}
      <PrivateRoute path="/admin" component={AdminRoutes} />
      <PrivateRoute path="/join-meeting/:scheduleId" component={JoinMeeting} />
      <Layout>
        {/* <Route exact path="/" component={Welcome} /> */}

        {/* <Route path="/about-us" component={AboutUs} /> */}

        <Route path="/explore" component={Explore} />
        {/* <Route path="/programs" component={Programs} /> */}
        <Route path="/explorePrograms" component={Programs} />
        <Route path="/sign-in" component={SignIn} />
        <Route path="/sign-up" component={SignUp} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/password-reset/:token" component={PasswordReset} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        {/* <Route path="/paymentProcess/:programId" component={PaymentProcess} /> */}

        {/* Learner's Module */}
        <PrivateRoute path="/myPrograms" component={MyPrograms} />
        <PrivateRoute
          path="/learnerCourse/:programId"
          component={LearnerCourse}
        />

        <Route path="/termsConditions" component={TermsConditions} />
      </Layout>
    </Switch>
  );
};

export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import history from "./reusableComponents/history";
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from './reusableComponents/SnackbarConfig';
import jwt_decode from 'jwt-decode';
import setAuthToken from './reusableComponents/setAuthToken';
import { setCurrentUser, logoutUser } from './redux/actions/authActions';

import { Provider } from 'react-redux';
import store from './store';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

if(localStorage.iBridge360) {
  const iBridge = JSON.parse(localStorage.getItem('iBridge360'));
  // Set auth token header auth
  setAuthToken(iBridge);
  // Decode token and get user info and exp
  const decoded = jwt_decode(iBridge);
  // Set user and is Authenticated
  store.dispatch(setCurrentUser());

  // check for expired token
  const currentTime = Date.now() / 1000;
  if(decoded.exp < currentTime) {
      // Logout User
      store.dispatch(logoutUser());
      // TODO: Clear current Profile

      // Redirect to login
      window.location.href = '/'
  }
}

ReactDOM.render(
  <Provider store={store}>
    <Router forceRefresh={true} history={history}>
      <SnackbarProvider 
        maxSnack={3}
        autoHideDuration={3000}
        transitionDuration={600}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        style={{width: 300, flexWrap: 'wrap'}}
      >
        <SnackbarUtilsConfigurator />
        <App />
      </SnackbarProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {
  ADMIN_MVK_LOADING,
  ADMIN_MVK_GET_LEARNERS_LIST,
  ADMIN_MVK_GET_LEARNER_INFO,
  ADMIN_MVK_GET_COUNSELLING_LIST,
  ADMIN_MVK_GET_LEARNER_PROGRESS,
  ADMIN_MVK_GET_LEARNER_PROGRESS_REPORT,
} from "../../actions/types";

const initialState = {
  loding: false,
  learnersList: [],
  learnerInfo: null,
  counsellingList: [],
  learnerProgress: [],
  learnerProgressReport: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADMIN_MVK_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_MVK_GET_LEARNERS_LIST:
      return {
        ...state,
        learnersList: action.payload,
        loading: false,
      };
    case ADMIN_MVK_GET_LEARNER_INFO:
      return {
        ...state,
        learnerInfo: action.payload,
        loading: false,
      };

    case ADMIN_MVK_GET_COUNSELLING_LIST:
      return {
        ...state,
        counsellingList: action.payload,
        loading: false,
      };
    // console.log('in reducer',action.payload)

    case ADMIN_MVK_GET_LEARNER_PROGRESS:
      return {
        ...state,
        learnerProgress: action.payload,
        loading: false,
      };
    case ADMIN_MVK_GET_LEARNER_PROGRESS_REPORT:
      return {
        ...state,
        learnerProgressReport: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

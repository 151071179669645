import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import FormHelperText from '@material-ui/core/FormHelperText';

// import { MenuProps, useStyles, dataList} from "./utils";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
  // selectField: {
  //   width: "100%",
  // },
  // // formControl: {
  // //   margin: theme.spacing(1),
  // //   width: 300
  // // },
  // indeterminateColor: {
  //   color: "#f50057",
  // },
  // // selectAllText: {
  // //   fontWeight: 500
  // // },
  // selectedAll1: {
  //   backgroundColor: "rgba(0, 0, 0, 0.08)",
  //   "&:hover": {
  //     backgroundColor: "rgba(0, 0, 0, 0.08)",
  //   },
  // },
}));

function MultiSelect({
  dataList,
  name,
  variant,
  disabled,
  label,
  selectedData,
  showLable,
  placeholder,
  onSelectData,
  className,
  errors,
}) {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  useEffect(() => {
    if (selectedData && selected.length === 0) {
      setSelected(selectedData);
    }
  }, [selectedData]);
  const isAllSelected =
    dataList.length > 0 && selected.length === dataList.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === dataList.length ? [] : dataList);
      return;
    }
    const filteredValues = [
      ...new Map(value.map((item) => [item["name"], item])).values(),
    ];

    setSelected(filteredValues);
    console.log(value, filteredValues);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="mutiple-select-label">{label}</InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple
        placeholder={placeholder}
        label={label}
        variant={variant || "outlined"}
        disabled={disabled || false}
        value={selected}
        error={errors ? true : false}
        helperText={errors ? errors : null}
        onChange={handleChange}
        onClose={() => {
          onSelectData(selected);
        }}
        // onSelectData={selected}
        renderValue={(selected) =>
          selected.map((item) => item?.name).join(", ")
        }
        MenuProps={MenuProps}
      >
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : "",
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < dataList.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Select All"
          />
        </MenuItem>
        {dataList.map((option) => (
          <MenuItem key={option._id} value={option}>
            <ListItemIcon>
              <Checkbox
                checked={
                  selected.findIndex((item) => item.name === option.name) > -1
                }
              />
            </ListItemIcon>
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errors}</FormHelperText>
    </FormControl>
  );
}

export default MultiSelect;

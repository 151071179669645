import { combineReducers } from "redux";

import authReducer from "./authReducer";
import dashboardReducer from "./dashboardReducer";

import adminDashboardReducer from "./admin_view/dashboardReducer";
import adminMvkReducer from "./admin_view/mvkReducer";
import adminCoachListReducer from "./admin_view/coachListReducer";
import addNewCoachReducer from "./admin_view/addNewCoachReducer";
import adminServiceProviderListReducer from "./admin_view/serviceProviderReducer";
import adminAddNewServiceProviderReducer from "./admin_view/addNewServiceProviderReducer";
import programReducer from "./programReducer";
import scheduleReducer from "./scheduleReducer";
import programBatchReducer from "./programBatchReducer";
import mentorReducer from "./mentorReducer";
import openAIReducer from "./openAIReducer";
import jobPostsApiReducer from "./jobPostsApiReducer";
import usertaskReducer from "./usertaskReducer";

import RIASECTestReducer from "./RIASECTestReducer";
const appReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  adminDashboard: adminDashboardReducer,
  adminMvk: adminMvkReducer,
  adminAddNewCoach: addNewCoachReducer,
  adminCoach: adminCoachListReducer,
  adminServiceProvider: adminServiceProviderListReducer,
  adminAddNewServiceProvider: adminAddNewServiceProviderReducer,
  programs: programReducer,
  schedules: scheduleReducer,
  programBatch: programBatchReducer,
  mentor: mentorReducer,
  openAIReducer: openAIReducer,
  jobPostsApiReducer: jobPostsApiReducer,
  usertaskReducer: usertaskReducer,
  RIASECTestReducer: RIASECTestReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "CLEAR_CURRENT_USER") {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;

// Auth User
export const SET_CURRENT_LOADING = "SET_CURRENT_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CLEAR_CURRENT_USER = "CLEAR_CURRENT_USER";

// Token
export const USER_TOKEN = () => {
  const token = JSON.parse(localStorage.getItem("iBridge360"));
  return token;
};

// Dashboard Assessment
export const DASHBOARD_LOADING = "DASHBOARD_LOADING";
export const GET_ASSESSMENT_RESULT = "GET_ASSESSMENT_RESULT";
export const GET_PSYCHOMETRIC_QUESTIONS = "GET_PSYCHOMETRIC_QUESTIONS";
export const GET_PSYCHOMETRIC_RESULT = "GET_PSYCHOMETRIC_RESULT";
export const GET_COUNSELLING_REQUEST = "GET_COUNSELLING_REQUEST";

// Admin Dashboard View
export const ADMIN_DASHBOARD_LOADING = "ADMIN_DASHBOARD_LOADING";
export const ADMIN_GET_DASHBOARD_COUNT = "ADMIN_GET_DASHBOARD_COUNT";

// Admin MVK
export const ADMIN_MVK_LOADING = "ADMIN_MVK_LOADING";
export const ADMIN_MVK_GET_LEARNERS_LIST = "ADMIN_MVK_GET_LEARNERS_LIST";
export const ADMIN_MVK_GET_LEARNER_INFO = "ADMIN_MVK_GET_LEARNER_INFO";

export const ADMIN_MVK_GET_COUNSELLING_LIST = "ADMIN_MVK_GET_COUNSELLING_LIST"; // Counselling request List
export const ADMIN_MVK_GET_LEARNER_PROGRESS = "ADMIN_MVK_GET_LEARNER_PROGRESS";
export const ADMIN_MVK_GET_LEARNER_PROGRESS_REPORT =
  "ADMIN_MVK_GET_LEARNER_PROGRESS_REPORT";

//Admin Coach
export const ADMIN_EDUCATOR_LOADING = "ADMIN_EDUCATOR_LOADING";
export const ADMIN_GET_EDUCATORS_LIST = "ADMIN_GET_EDUCATORS_LIST";
export const ADMIN_GET_EDUCATOR_INFO = "ADMIN_GET_EDUCATOR_INFO";

//Admin Service Provider
export const ADMIN_SERVICEPROVIDER_LOADING = "ADMIN_SERVICEPROVIDER_LOADING";
export const ADMIN_GET_SERVICEPROVIDER_LIST = "ADMIN_GET_SERVICEPROVIDER_LIST";
export const ADMIN_GET_SERVICEPROVIDER_INFO = "ADMIN_GET_SERVICEPROVIDER_INFO";

// Admin Add Service Provider Coach
export const ADMIN_ADD_NEW_SERVICEPROVIDER_LOADING =
  "DMIN_ADD_NEW_SERVICEPROVIDER_LOADING";
export const ADMIN_GET_SERVICEPROVIDER_IMAGE =
  "ADMIN_GET_SERVICEPROVIDER_IMAGE";
export const ADMIN_GET_sERVICEPROVIDER_TIMEZONE_LIST =
  "ADMIN_GET_sERVICEPROVIDER_TIMEZONE_LIST";

// Admin Add New Coach
export const ADMIN_ADD_NEW_COACH_LOADING = "ADMIN_ADD_NEW_COACH_LOADING";
export const ADMIN_GET_INDUSTRY_LIST = "ADMIN_GET_INDUSTRY_LIST";
export const ADMIN_GET_SKILLS_LIST = "ADMIN_GET_SKILLS_LIST";
export const ADMIN_GET_DAYS_LIST = "ADMIN_GET_DAYS_LIST";
export const ADMIN_GET_TIMINGS_LIST = "ADMIN_GET_TIMINGS_LIST";
export const ADMIN_GET_COACH_IMAGE = "ADMIN_GET_COACH_IMAGE";
export const ADMIN_GET_TIMEZONE_LIST = "ADMIN_GET_TIMEZONE_LIST";

//Programs and Payment
export const PROGRAM_LOADING = "PROGRAM_LOADING";
export const GET_PROGRAM_LIST = "GET_PROGRAM_LIST";
export const GET_PROGRAM_INFO = "GET_PROGRAM_INFO";
export const PROGRAM_PAYMENT_PAID = "PROGRAM_PAYMENT_PAID";
export const GET_ILEARN_COURSES = "GET_ILEARN_COURSES";
export const UPLOAD_PROGRAM_PROFILE_IMAGE = "UPLOAD_PROGRAM_PROFILE_IMAGE";
export const UPLOAD_PROGRAM_CONTENT_IMAGE = "UPLOAD_PROGRAM_CONTENT_IMAGE";
export const GET_USER_ENROLLED_PROGRAMS = "GET_USER_ENROLLED_PROGRAMS";
export const GET_PROGRAM_COURSES = "GET_PROGRAM_COURSES";
export const GET_ICAPABILITY_TOPICS = "GET_ICAPABILITY_TOPICS";
export const GET_PROGRAM_TOPICS = "GET_PROGRAM_TOPICS";
export const GET_PROGRAM_DISENROLLED_USER_LIST =
  "GET_PROGRAM_DISENROLLED_USER_LIST";

//Program Schedule
export const SCHEDULE_LOADING = "SCHEDULE_LOADING";
export const CREATE_SCHEDULE = "CREATE_SCHEDULE";
export const GET_SCHEDULE_LIST = "GET_SCHEDULE_LIST";
export const GET_SCHEDULE_INFO = "GET_SCHEDULE_INFO";
export const GET_PROGRAM_SCHEDULE_LIST = "GET_PROGRAM_SCHEDULE_LIST";
export const GET_SCHEDULE_REPOET_LIST = "GET_SCHEDULE_REPOET_LIST";

//Program Batch
export const PROGRAM_BATCH_LOADING = "PROGRAM_BATCH_LOADING";
export const CREATE_PROGRAM_BATCH = "CREATE_PROGRAM_BATCH";
export const GET_PROGRAM_BATCH_LIST = "GET_PROGRAM_BATCH_LIST";
export const GET_BATCH_USERS_LIST = "GET_BATCH_USERS_LIST";
export const GET_BATCH_APPLICABLE_USERS_LIST =
  "GET_BATCH_APPLICABLE_USERS_LIST";
export const GET_BATCH_BY_PROGRAM_ID = "GET_BATCH_BY_PROGRAM_ID";

//Mentor Programs
export const GET_MENTOR_PROGRAMS = "GET_MENTOR_PROGRAMS";
// mentor MVK
export const PROGRAM_LEARNER_LIST = "PROGRAM_LEARNER_LIST";
//Mentor Batch and Schedule List
export const GET_MENTOR_BATCH_LIST = "GET_MENTOR_BATCH_LIST";
export const GET_MENTOR_SCHEDULE_LIST = "GET_MENTOR_SCHEDULE_LIST";

//OpenAI
export const GET_CHATGPT_JOB_TYPES = "GET_CHATGPT_JOB_TYPES";
export const SET_USER_SKILLS_LOCATION = "SET_USER_SKILLS_LOCATION";
export const CLEAR_JOB_TYPES_DATA = "CLEAR_JOB_TYPES_DATA";

//JobPostsApi
export const GET_JOB_POSTS = "GET_JOB_POSTS";
export const SET_JOB_TITLE = "SET_JOB_TITLE";
export const NO_JOB_POSTS_DATA = "NO_JOB_POSTS_DATA";
export const CLEAR_JOB_POSTS_DATA = "CLEAR_JOB_POSTS_DATA";

//UserTaskexport
export const GET_USER_TASKS = "GET_USER_TASKS";
export const GET_ALL_USER_TASKS = "GET_USER_TASKS";
export const GET_USER_TASKS_BY_ID = "GET_USER_TASKS_BY_ID";

//RIASECTestQuestions
export const GET_RIASEC_TEST_QUESTIONS = "GET_RIASEC_TEST_QUESTIONS";
export const GET_RIASEC_RESULT_BY_USER_ID = "GET_RIASEC_RESULT_BY_USER_ID";

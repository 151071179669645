// // import React, { useEffect } from 'react';
// // import { connect } from 'react-redux';
// // import PropTypes from 'prop-types';
// // import { Container, Grid, Typography } from '@material-ui/core';

// // const AboutUs = ({
// //     auth,
// //     history
// // }) => {
// //     const { isAuthenticated, user } = auth;

// //     useEffect(() => {
// //         if(isAuthenticated) {
// //             if(user?.role?.r_id === 1) {
// //                 history.push('/admin/dashboard');
// //             } else if(user?.role?.r_id === 2) {
// //                 history.push('/dashboard');
// //             }
// //         }
// //     }, [auth])

// //     return (
// //         <Container maxWidth="lg" className="aboutUsContainer">
// //             <Grid container spacing={5}>
// //                 <Grid item xs={12} md={6}>
// //                     <div className="aboutUsContentContainer">
// //                         <div className="aboutInnerContent">
// //                             <Typography className="headingText" variant="h5">
// //                                 We are three close friends with a common concern:
// //                             </Typography>
// //                             <ul className="aboutList">                               
// //                                 <li>
// //                                     <Typography className="headingTagLine" variant="h5">
// //                                         18-25 year olds to fight for few opportunities in an extremely competitive market. This would result in further inequality and an ocean of unemployment.
// //                                     </Typography>
// //                                 </li>
// //                                 <li>
// //                                     <Typography className="headingTagLine" variant="h5">
// //                                         The bulk of learning and development solutions offered by higher education institutions are designed, priced, and delivered for high performing individuals who have a strong foundation an exclusive group in an already privileged pool.
// //                                     </Typography>
// //                                 </li>
// //                                 <li>
// //                                     <Typography className="headingTagLine" variant="h5">
// //                                         Massive Open Online Courses and other low cost options only add to the problem because they do not serve the employability needs of local employers.
// //                                     </Typography>
// //                                 </li>
// //                                 <li>
// //                                     <Typography className="headingTagLine" variant="h5">
// //                                         Fear makes people to enrol into courses and acquire knowledge without gaining practical insights and skills that various industries require.
// //                                     </Typography>
// //                                 </li>
// //                                 <li>
// //                                     <Typography className="headingTagLine" variant="h5">
// //                                         Artificial Intelligence and other technological advancements will soon take away all repeatable tasks.
// //                                     </Typography>
// //                                 </li>
// //                             </ul>                            
// //                             <Typography className="subHeadingText" variant="h5">
// //                                 We offer programs that bridge the gap between foundational strength and the needs of industries.
// //                             </Typography>                            
// //                         </div>
// //                     </div>
// //                 </Grid>
// //                 <Grid item xs={12} md={6}>
// //                     <div className="aboutUsContentContainer info">
// //                         <div className="aboutInfo">
// //                             <div className="infoImage" />
// //                             <div className="infoUser">
// //                                 <Typography className="headingText" variant="h5">
// //                                     Aman Mustafa
// //                                 </Typography>
// //                                 <Typography className="subHeadingText" variant="h5">
// //                                     Founder
// //                                 </Typography>
// //                             </div>
// //                         </div>
// //                         <Typography className="subText" variant="body1" >
// //                             Aman is a global leader with extensive experience in strategizing and leading organization at the brink of change and growth. His success include leveraging current and emerging technologies, acquiring and retaining global talent, uncovering opportunities directing trailblazing strategies to improve operations, developing leading-edge solutions, and increasing marketability for forward-moving companies.
// //                         </Typography>
// //                     </div>
// //                     <div className="aboutUsContentContainer info">
// //                         <div className="aboutInfo">
// //                             <div className="infoImage" />
// //                             <div className="infoUser">
// //                                 <Typography className="headingText" variant="h5">
// //                                     Rajesh Kumar
// //                                 </Typography>
// //                                 <Typography className="subHeadingText" variant="h5">
// //                                     Founder
// //                                 </Typography>
// //                             </div>
// //                         </div>
// //                         <Typography className="subText" variant="body1" >
// //                             Rajesh is a software engineer with 25years of experience in application development in both operational and data analysis. He has mentored more than 1000 people in last 15 years in database, data warehousing and analytics related technologies. He is passionate about transforming job seekers into empowered professionals.
// //                         </Typography>
// //                     </div>
// //                     <div className="aboutUsContentContainer info">
// //                         <div className="aboutInfo">
// //                             <div className="infoImage" />
// //                             <div className="infoUser">
// //                                 <Typography className="headingText" variant="h5">
// //                                     Mahesh Sriram
// //                                 </Typography>
// //                                 <Typography className="subHeadingText" variant="h5">
// //                                     Founder
// //                                 </Typography>
// //                             </div>
// //                         </div>
// //                         <Typography className="subText" variant="body1" >
// //                             Mahesh works with world-renowned faculty and senior leaders of multinational companies to adapt, co-create, and implement Executive Education Programs in emerging economies. He has delivered Leadership Programs for over 1000 leaders of a dozen MNCs, including two Fortune 10s, five Fortune 200s, and two of India's top 10.
// //                         </Typography>
// //                     </div>
// //                 </Grid>
// //             </Grid>
// //         </Container>
// //     );
// // };

// // AboutUs.propTypes = {
// //     auth: PropTypes.object.isRequired
// // }

// // const mapStateToProps = state => ({
// //     auth: state.auth
// // });

// // export default connect(mapStateToProps, null)(AboutUs);






// //------------------------------------------NEW ABOUT US 0.1 ---------------------------------------------------------//






// import React, { useEffect } from 'react';
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
// import { Container, Grid, Typography } from '@material-ui/core';


// // import Logo from '../../images/logo/ibridge.png';

// const AboutUs = ({
//     auth,
//     history
// }) => {
//     const { isAuthenticated, user } = auth;

//     useEffect(() => {
//         if(isAuthenticated) {
//             if(user?.role?.r_id === 1) {
//                 history.push('/admin/dashboard');
//             } else if(user?.role?.r_id === 2) {
//                 history.push('/dashboard');
//             }
//         }
//     }, [auth])

//     return (
//         <Container maxWidth="lg" className="mainContainer">
//             <Typography className="headingText" variant="h3">
//                 ABOUT US
//             </Typography>
//             <br/>
//             <br/>
//             <Typography className="subHeadingText" variant="h6">
//                 We offer programs that bridge the gap between foundational strength and the needs of industries.
//             </Typography> 
//             <br/>
//             <br/>
//             <Grid container spacing={5}>

//                 <Grid item xs={12} md={4} >
//                     <div className="aboutUsContentContainer info">
//                         <div className="textInfo1">
//                             <Typography className="headingText1" variant="h6">
//                                 Aman Mustafa
//                             </Typography>
//                             <Typography className="headingText1" variant="h6">
//                                 Founder
//                             </Typography>
//                         </div>

//                         <div>
//                             <img className='imgInfo'  />
//                         </div>
//                     </div>
//                 </Grid>

//                 <Grid item xs={12} md={4} >
                   
//                 </Grid>

//                 <Grid item xs={12} md={4} >
//                     <div className="aboutUsContentContainer info">
//                         <div>
//                             <img className='imgInfo'  />
//                         </div>

//                         <div className="textInfo2">
//                             <Typography className="headingText1" variant="h6">
//                                 Rajesh Kumar
//                             </Typography>
//                             <Typography className="headingText1" variant="h6">
//                                 Founder
//                             </Typography>
//                         </div>

//                     </div>
//                 </Grid>

//                 <Grid item xs={12} md={4} >
//                     <Typography className="subText" variant="body2" >
//                         Aman is a global leader with extensive experience in strategizing and leading organization at the brink of change and growth. His success include leveraging current and emerging technologies, acquiring and retaining global talent, uncovering opportunities directing trailblazing strategies to improve operations, developing leading-edge solutions, and increasing marketability for forward-moving companies.
//                     </Typography>
//                 </Grid>

//                 <Grid item xs={12} md={4} >
//                     <Typography className="subText midGrid" variant="h3" >
//                         "We are three friends with a common concern"
//                     </Typography>
//                 </Grid>

//                 <Grid item xs={12} md={4} >
//                     <Typography className="subText" variant="body2" >
//                         Rajesh is a software engineer with 25years of experience in application development in both operational and data analysis. He has mentored more than 1000 people in last 15 years in database, data warehousing and analytics related technologies. He is passionate about transforming job seekers into empowered professionals.
//                     </Typography>
//                 </Grid>

//                 <Grid item xs={12} md={4} >
//                 </Grid>

//                 <Grid item xs={12} md={4} >
                    
//                     <div className="aboutUsContentContainer1 info">

//                         <div className='imgClass3'>
//                             <img className='imgInfo' />
//                         </div>   
//                         <br/>
//                         <br/>
                        
//                         <div className="textInfo">
//                             <Typography className="headingText1" variant="h5">
//                                 Mahesh Sriram
//                             </Typography>
//                             <Typography className="headingText1" variant="h5">
//                                 Founder
//                             </Typography>
//                         </div>
//                     </div>
//                 </Grid>

//                 <Grid item xs={12} md={4} >
//                 </Grid>

//                 <Grid item xs={12} md={4} >
//                 </Grid>

//                 <Grid item xs={12} md={4} >
//                     <Typography className="subText textBody" variant="body2" >
//                         Mahesh works with world-renowned faculty and senior leaders of multinational companies to adapt, co-create, and implement Executive Education Programs in emerging economies. He has delivered Leadership Programs for over 1000 leaders of a dozen MNCs, including two Fortune 10s, five Fortune 200s, and two of India's top 10.
//                     </Typography>
//                 </Grid>

//                 <Grid item xs={12} md={4} >
//                 </Grid>
//             </Grid>

//             <br/>
//             <br/>
//             <Typography className="headingText" variant="h3">
//                 WHAT WE CONCERN
//             </Typography>
//             <br/>
//             <br/>
            

//         <Grid container spacing={5}>

//             <Grid item xs={12} md={1} >
//                 <div className="box">
//                     <Typography className="headingTagLine listPointer" variant="body1">
//                         1 
//                     </Typography>
//                 </div>
//             </Grid>
//             <Grid item xs={12} md={11} >
//                 <Typography className="headingTagLine" variant="body1">
//                     18-25 year olds to fight for few opportunities in an extremely competitive market. This would result in further inequality and an ocean of unemployment.
//                 </Typography>
//             </Grid>

//             <Grid item xs={12} md={1} >
//                 <div className="box">
//                     <Typography className="headingTagLine listPointer" variant="body1">
//                         2 
//                     </Typography>
//                 </div>
//             </Grid>
//             <Grid item xs={12} md={11} >
//                 <Typography className="headingTagLine" variant="body1">
//                     Massive Open Online Courses and other low cost options only add to the problem because they do not serve the employability needs of local employers.
//                 </Typography>
//             </Grid>

//             <Grid item xs={12} md={1} >
//                 <div className="box">
//                     <Typography className="headingTagLine listPointer" variant="body1">
//                         3
//                     </Typography>
//                 </div>
//             </Grid>
//             <Grid item xs={12} md={11} >
//                 <Typography className="headingTagLine" variant="body1">
//                     Fear makes people to enrol into courses and acquire knowledge without gaining practical insights and skills that various industries require.
//                 </Typography>
//             </Grid>

//             <Grid item xs={12} md={1} >
//                 <div className="box">
//                     <Typography className="headingTagLine listPointer" variant="body1">
//                         4
//                     </Typography>
//                 </div>
//             </Grid>
//             <Grid item xs={12} md={11} >
//                 <Typography className="headingTagLine" variant="body1">
//                     Artificial Intelligence and other technological advancements will soon take away all repeatable tasks.
//                 </Typography>
                
//             </Grid>
//         </Grid>

//         <br/>
//         <br/>
//         <br/>
//         <br/>
//         </Container>
//     );
// };

// AboutUs.propTypes = {
//     auth: PropTypes.object.isRequired
// }

// const mapStateToProps = state => ({
// //     auth: state.auth
// });

// export default connect(mapStateToProps, null)(AboutUs);

// ------------------------------------------------- New About Us 0.2---------------------------------------------------------//



// import React, { useEffect } from 'react';
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
// import { Container, Grid, Typography } from '@material-ui/core';

// import Card from '@material-ui/core/Card';
// // import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';



// const AboutUs = ({
//     auth,
//     history
// }) => {
//     const { isAuthenticated, user } = auth;

//     useEffect(() => {
//         if(isAuthenticated) {
//             if(user?.role?.r_id === 1) {
//                 history.push('/admin/dashboard');
//             } else if(user?.role?.r_id === 2) {
//                 history.push('/dashboard');
//             }
//         }
//     }, [auth])

//     return (
//         <Container maxWidth="lg" className="mainViewContainer">
//             <div className="mainViewHeading">
//                 <Typography className="headingTagLine" varient="h5">
//                         common concerns which initiated iBridge360
//                 </Typography>
//             </div>
            
//             <br/>

//                 <Grid container spacing={5}>

//                     <Grid item xs={12} md={1} >
//                         <div className="box">
//                             <Typography className="headingTagLine listPointer" variant="body1">
//                                 1 
//                             </Typography>
//                         </div>
//                     </Grid>
//                     <Grid item xs={12} md={11} >
//                         <Typography className="headingTagLine" variant="body1">
//                             18-25 year olds to fight for few opportunities in an extremely competitive market. This would result in further inequality and an ocean of unemployment.
//                         </Typography>
//                     </Grid>

//                     <Grid item xs={12} md={1} >
//                         <div className="box">
//                             <Typography className="headingTagLine listPointer" variant="body1">
//                                 2 
//                             </Typography>
//                         </div>
//                     </Grid>
//                     <Grid item xs={12} md={11} >
//                         <Typography className="headingTagLine" variant="body1">
//                             Massive Open Online Courses and other low cost options only add to the problem because they do not serve the employability needs of local employers.
//                         </Typography>
//                     </Grid>

//                     <Grid item xs={12} md={1} >
//                         <div className="box">
//                             <Typography className="headingTagLine listPointer" variant="body1">
//                                 3
//                             </Typography>
//                         </div>
//                     </Grid>
//                     <Grid item xs={12} md={11} >
//                         <Typography className="headingTagLine" variant="body1">
//                             Fear makes people to enrol into courses and acquire knowledge without gaining practical insights and skills that various industries require.
//                         </Typography>
//                     </Grid>

//                     <Grid item xs={12} md={1} >
//                         <div className="box">
//                             <Typography className="headingTagLine listPointer" variant="body1">
//                                 4
//                             </Typography>
//                         </div>
//                     </Grid>
//                     <Grid item xs={12} md={11} >
//                         <Typography className="headingTagLine" variant="body1">
//                             Artificial Intelligence and other technological advancements will soon take away all repeatable tasks.
//                         </Typography>
                        
//                     </Grid>
//                     <Grid item xs={12} md={1} >
//                         <div className="box">
//                             <Typography className="headingTagLine listPointer" variant="body1">
//                                 5
//                             </Typography>
//                         </div>
//                     </Grid>
//                     <Grid item xs={12} md={11} >
//                         <Typography className="headingTagLine" variant="body1">
//                             We offer programs that bridge the gap between job seekers foundational strength and the needs of industries.
//                         </Typography>
                        
//                     </Grid>
                
//                </Grid>

//             <br/>
//             <br/>
//             <br/>
//             <br/>
//             <br/>
//             <br/>

//             <Card>
//             <CardContent>

//             <Grid container spacing={5}>

//                 <Grid item xs={12} md={4} >
//                     <div className="aboutUsContentContainer info">
//                         <div className="textInfo1">
//                             <Typography className="headingText1" variant="h6">
//                                 Aman Mustafa
//                             </Typography>
//                             <Typography className="headingText1" variant="h6">
//                                 Founder
//                             </Typography>
//                         </div>

//                         <div>
//                             <img className='imgInfo'  />
//                         </div>
//                     </div>
//                 </Grid>

//                 <Grid item xs={12} md={4} >
//                     <div className="testDiv" >
//                         <div className="aboutUsContentContainer1 info">

//                             <div className="textInfo">
//                                 <Typography className="headingText1" variant="h6">
//                                     Mahesh Sriram
//                                 </Typography>
//                                 <Typography className="headingText1" variant="h6">
//                                     Founder
//                                 </Typography>
//                             </div>
//                             <br/>
//                             <br/>
//                             <div className='imgClass3'>
//                                 <img className='imgInfo' />
//                             </div>  
//                         </div>
//                     </div>
//                 </Grid>

//                 <Grid item xs={12} md={4} >

//                     <div className="aboutUsContentContainer info">
//                         {/* <div>
//                             <img className='imgInfo'  />
//                         </div> */}
//                         <div className="testDiv1" >
//                             <div className="textInfo2">
//                                 <Typography className="headingText1" variant="h6">
//                                     Rajesh Kumar
//                                 </Typography>
//                                 <Typography className="headingText1" variant="h6">
//                                     Founder
//                                 </Typography>
//                             </div>
//                         </div>
//                         <div>
//                             <img className='imgInfo'  />
//                         </div>

//                     </div>
//                 </Grid>

//                 <Grid item xs={12} md={4} >
//                     <Typography className="subText" variant="body1" >
//                         Aman is a global leader with extensive experience in strategizing and leading organization at the brink of change and growth. His success include leveraging current and emerging technologies, acquiring and retaining global talent, uncovering opportunities directing trailblazing strategies to improve operations, developing leading-edge solutions, and increasing marketability for forward-moving companies.
//                     </Typography>
//                 </Grid>

//                 <Grid item xs={12} md={4} >
//                     <Typography className="subText textBody" variant="body1" >
//                         Mahesh works with world-renowned faculty and senior leaders of multinational companies to adapt, co-create, and implement Executive Education Programs in emerging economies. He has delivered Leadership Programs for over 1000 leaders of a dozen MNCs, including two Fortune 10s, five Fortune 200s, and two of India's top 10.
//                     </Typography>
//                 </Grid>

//                 <Grid item xs={12} md={4} >
//                     <Typography className="subText" variant="body1" >
//                         Rajesh is a software engineer with 25years of experience in application development in both operational and data analysis. He has mentored more than 1000 people in last 15 years in database, data warehousing and analytics related technologies. He is passionate about transforming job seekers into empowered professionals.
//                     </Typography>
//                 </Grid>

//             </Grid>

//             </CardContent>
//             </Card>

//             <br/>
//             <br/>
//             <br/>
//             <br/>




//         </Container>

//     );
// };

// AboutUs.propTypes = {
//     auth: PropTypes.object.isRequired
// }

// const mapStateToProps = state => ({
//     auth: state.auth
// });

// export default connect(mapStateToProps, null)(AboutUs);







//------------------------------------------------New About Us 0.3--------------------------------------------------------------



import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Grid, Typography } from '@material-ui/core';

//------------Carousel reusable component-------------------------
import Carousel from '../../reusableComponents/Carousel';


import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Avatar from '@material-ui/core/Avatar';

// import s1 from '../../images/About_Us/s1.png'
// import s22 from '../../images/About_Us/s22.png'
// import slider3 from '../../images/About_Us/slider3.png'
// import up from '../../images/About_Us/up.png'

// import Aman from '../../images/About_Us/Aman.jpg'
// import Mahesh from '../../images/About_Us/Mahesh.jpg'
// import rajesh from '../../images/About_Us/rajesh.jpg'




const AboutUs = ({
    auth,
    history
}) => {
    const { isAuthenticated, user } = auth;

    useEffect(() => {
        if(isAuthenticated) {
            if(user?.role?.r_id === 1) {
                history.push('/admin/dashboard');
            } else if(user?.role?.r_id === 2) {
                history.push('/dashboard');
            }
        }
    }, [auth])

    const items = [
        {
            name: 'About_1',
            image:'https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/s1.png',
            // image:s1,
            title:"Artificial Intelligence ",
            discription:"We are the cusp of a revolution where robots could replace 800 million jobs by 2030, while 60% of today's Grad School kids will end up at jobs that haven't been invented yet!",
        },
        {
            name: 'About_2',
            image:'https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/s22.png',
            // image:s22,
            title:"Learning In The Future",
            discription:"In an environment of such uncertainty, we are left with questions of how students will use their bodies and minds to learn what will be important in the 21st Century! ",
        },
        {
            name: 'About_3',
            image:'https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/slider+3+removed+content.png',
            // image:slider3,
            title:"Skills Of The 21st Century",
            discription:"",
            cardTitle1:"Mathematical Reasoning & Computational Thinking",
            cardDiscription1:"Learn To Break Down Complex Problems",
            cardTitle2:"Collaboration & Creative Expression",
            cardDiscription2:"Innovative Solutions",
            cardTitle3:"Scientific Argumentation & Design Thinking",
            cardDiscription3:"Evidence - Based Argumentation",
        },
        // {
        //     name: 'About_4',
        //     image:'https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/About-4+slider.png'
        // }
    ]

    return (
        <Container maxWidth="lg" className="mainViewContainer">
            <div className="mainViewHeading">
                <Typography className="headingTagLine" varient="h5">
                        common concerns which initiated iBridge360
                </Typography>
            </div>
            
            <br/>

            
            {/* <Grid container>
                <Grid item xs={12} md={12} >
                    <Carousel items={items} />
                </Grid>
            </Grid>            */}
                  
            <Grid container>
                <Grid item xs={12} md={12} >
                    <div className="imagecontainer">
                        <img 
                            className="staticImage"
                            src='https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/up.png'
                            alt='Image not found'
                        />
                        <div className="imageContent">
                            <Typography className="imageDiscription" varient="h2">
                                As we see the future quickly unfolding to a culture that will bet on 
                                artificial - augmented - alternative Intelligence, BlockChain & Bots, 
                                our future generation will need the wisdom, experience, 
                                and the tribal knowledge to shape their technology - driven future, 
                                without ignoring the fundamental human values.
                            </Typography>
                            <Typography className="imageTagLine" varient="h3">
                                We are excited to share our experiences with the future workforce
                                and be a part of their digitally transformed culture.
                            </Typography> 
                        </div>
                    </div>
                </Grid>
            </Grid>   

        
            <Grid container spacing={5}>
                <Grid item xs={12} md={6} >
                    <Card className="aboutCard">
                        <CardContent>
                            <div className="testDiv" >
                                    <div className="aboutUsContentContainer1 info">

                                        <div className="textInfo">
                                            <Typography className="headingText1" variant="h6">
                                                Aman Mustafa
                                            </Typography>
                                            <Typography className="headingText1" variant="h6">
                                                Founder
                                            </Typography>
                                        </div>
                                        <br/>
                                        <br/>
                                        {/* <div className='imgClass3'> */}
                                            <img className='imgClass3' src='https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/Aman+Mustafa+-+org+chart+size.jpg' />
                                        {/* </div>  */}

                                        <br/>
                                        <br/> 
                                    </div>
                                </div>
                                <div className='contentInfo'>
                                    <Typography className="subText" variant="body1">
                                        {/* Aman is a global leader with extensive experience in strategizing and leading organization at the brink of change and growth. His success include leveraging current and emerging technologies, acquiring and retaining global talent, uncovering opportunities directing trailblazing strategies to improve operations, developing leading-edge solutions, and increasing marketability for forward-moving companies. */}
                                        Aman is a global leader with over two decades of Corporate experience
                                        in strategizing and leading Organizations at the brink of change and growth. 
                                        He successfully led multi-cultural global teams delivering solutions to Fortune/100 clients. 
                                        Excels in leveraging current and emerging technologies, acquiring and retaining global talent, 
                                        uncovering opportunities and directing trailblazing strategies. Living in the US with his family,
                                        Aman takes Innovation to Execution, with a passion for mentoring Gen-Z.
                                    </Typography>
                                </div>
                        </CardContent>
                    </Card>
                </Grid>

                {/* <Grid item xs={12} md={4} >
                
                    <Card className="aboutCard">
                        <CardContent>
                            <div className="testDiv" >
                                <div className="aboutUsContentContainer1 info">

                                    <div className="textInfo">
                                        <Typography className="headingText1" variant="h6">
                                            Mahesh Sriram
                                        </Typography>
                                        <Typography className="headingText1" variant="h6">
                                            Founder
                                        </Typography>
                                    </div>
                                    <br/>
                                    <br/>
                                    {/* <div className='imgClass3'> 
                                        <img className='imgClass3' src='https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/IMG_6358.jpg' />
                                    {/* </div>  

                                    <br/> 
                                    <br/>

                                </div>
                            </div>
                            <div className='contentInfo'>
                                <Typography className="subText" variant="body1" style={{paddingBottom:'56px'}}>
                                Mahesh works with world-renowned faculty and senior leaders of multinational companies to adapt, co-create, and implement Executive Education Programs in emerging economies. He has delivered Leadership Programs for over 1000 leaders of a dozen MNCs, including two Fortune 10s, five Fortune 200s, and two of India's top 10.
                                </Typography>
                                <br/>
                                <br/>
                            </div>
                        </CardContent>
                    </Card>
                </Grid> */}


                <Grid item xs={12} md={6} >
                    <Card className="aboutCard">
                        <CardContent>
                            <div className="testDiv" >
                                <div className="aboutUsContentContainer1 info">

                                    <div className="textInfo">
                                        <Typography className="headingText1" variant="h6">
                                            Rajesh Kumar
                                        </Typography>
                                        <Typography className="headingText1" variant="h6">
                                            Founder
                                        </Typography>
                                    </div>
                                    <br/>
                                    <br/>
                                    {/* <div className='imgClass3'> */}
                                    <img className='imgClass3' src='https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/rajesh_pic_for_about_us.jpg' />
                                    {/* </div>  */}

                                    <br/> 
                                    <br/>

                                </div>
                            </div>
                            <div className='contentInfo'>
                                <Typography className="subText" variant="body1" style={{paddingBottom:'50px'}} >
                                Rajesh is a software engineer with 25years of experience in application development in both operational and data analysis. He has mentored more than 1000 people in last 15 years in database, data warehousing and analytics related technologies. He is passionate about transforming job seekers into empowered professionals.
                                </Typography>
                                <br/>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>


        </Container>

    );
};

AboutUs.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, null)(AboutUs);
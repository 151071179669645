import axios from "axios";
import {
  ADMIN_EDUCATOR_LOADING,
  ADMIN_GET_EDUCATORS_LIST,
  ADMIN_GET_EDUCATOR_INFO,
} from "../types";
import { SnackBar } from "../../../reusableComponents/SnackbarConfig";
import history from "../../../reusableComponents/history";

// Get Coach List
export const getCoachList = () => (dispatch) => {
  dispatch(coachLoading());
  axios
    .get("/api/adminUser/getCoachList")
    .then((res) =>
      dispatch({
        type: ADMIN_GET_EDUCATORS_LIST,
        payload: res?.data?.message,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get Coach Info
export const getCoachInfo = (userId) => (dispatch) => {
  dispatch(coachLoading());
  axios
    .get(`/api/user/profileDetails/${userId}`)
    .then((res) =>
      dispatch({
        type: ADMIN_GET_EDUCATOR_INFO,
        payload: res?.data?.message,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

//
export const clearCoachInfo = () => (dispatch) => {
  dispatch({
    type: ADMIN_GET_EDUCATOR_INFO,
    payload: null,
  });
};

// edit coach Info

export const editCoachInfo = (userData) => (dispatch) => {
  axios
    .put(`/api/adminUser/editCoachInfo`, userData)
    .then((res) => {
      console.log(res.data.message);
      // const message = res?.data?.message;
      history.push("/admin/coachList");
      SnackBar.success(res.data.message);
    })
    .catch((err) => {
      console.log(err);
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

export const coachLoading = () => {
  return {
    type: ADMIN_EDUCATOR_LOADING,
  };
};

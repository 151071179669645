import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar, Button } from "@material-ui/core";
// import Table from '../../MaterialTable/Table';
import MUITable from "../../MaterialTable/MaterialTable";
import PageHeader from "../../../reusableComponents/PageHeader";
import { ListAlt as ListAltIcon } from "@material-ui/icons";
import { getPrograms } from "../../../redux/actions/programActions";
import { getDateTime } from "../../../reusableComponents/GetDate";
import { Search as SearchIcon, Add as AddIcon } from "@material-ui/icons";
import ButtonField from "../../../reusableComponents/ButtonField";
import CustomizedDialogs from "../../../reusableComponents/DialogModal";
import AddingUserToProgram from "../AddingUserToProgram";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

const ProgramsList = ({ getPrograms, programs, auth, history }) => {
  const classes = useStyles();
  const { isAuthenticated } = auth;
  const { programList } = programs;
  const [openAddLearner, setOpenAddLearner] = useState(false);
  const [programId, setProgramId] = useState();

  useEffect(() => {
    if (isAuthenticated) {
      getPrograms();
    }
  }, [isAuthenticated]);

  const columns = [
    {
      title: "Photo",
      field: "cardImage",
      render: (rowData) => (
        <Avatar
          alt=""
          src={rowData?.cardImage}
          className={classes.tableImage}
        />
      ),
    },
    {
      title: "Program Name",
      field: "name",
    },
    // {
    //   title: "Program Discription",
    //   field: "description",
    // },
    // {
    //   title: "Total Hours",
    //   field: "totalHours",
    // },
    {
      title: "Date",
      field: "date",
    },
    // {
    //   title: "Duration",
    //   field: "estimatedTime",
    // },
    {
      title: "Price",
      field: "price",
    },
    {
      title: "Program Info",
      field: "programInfo",
    },
    {
      title: "Add Learners",
      field: "addLearners",
    },

    // {
    //   title: "Date",
    //   field: "industry",
    //   render: (rowData) => (
    //     <div>
    //       <ul className={classes.industryList}>
    //         {rowData?.industry?.map((list) => (
    //           <li key={list._id}>{list.name}</li>
    //         ))}
    //       </ul>
    //     </div>
    //   ),
    // },
  ];

  const data = programList?.map((program) => {
    const {
      _id,
      cardImage,
      name,
      //   description,
      totalHours,
      startsOn,
      programStartDate,
      programEndDate,
      estimatedTime,
      price,
    } = program;
    const date = `${programStartDate} to ${programEndDate}`;
    return {
      _id,
      cardImage,
      name,
      //   description,
      totalHours,
      date,
      estimatedTime,
      price,
      programInfo:<Button
                    style={{height:"45px",width:"90%"}}
                    variant="contained" 
                    color="primary"
                    type="submit"
                    name="submit" 
                    onClick={() => history.push(`/admin/programInfo/${_id}`)}
                  > 
                    Program Info
                  </Button>,
      addLearners:<Button
                    style={{height:"45px",width:"90%"}}
                    variant="contained" 
                    color="primary"
                    type="submit"
                    name="submit" 
                    onClick={()=>{setOpenAddLearner(true)
                                  setProgramId(_id)}
                            }  
                  > 
                    Add Learners 
                  </Button>,
    };
  });
  return (
    <Container maxWidth="lg" className="adminMvk">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MUITable
            title={
              <ButtonField
                variant="outlined"
                name="Add New Program"
                icon={<AddIcon />}
                onClick={() => history.push("addPrograms")}
              />
            }
            columns={columns}
            data={data}
            pageSize={10}
            // selectedRow={(row) => history.push(`/admin/programInfo/${row._id}`)}
            selectedRow={(row) => {}}
          />
        </Grid>
      </Grid>
      <CustomizedDialogs
        title="Add Learners"
        children={<AddingUserToProgram setOpenAddLearner={setOpenAddLearner} programId={programId}/>}
        openPopup={openAddLearner}
        setOpenPopup={setOpenAddLearner}
      />
    </Container>
  );
};

ProgramsList.propTypes = {
  auth: PropTypes.object.isRequired,
  getPrograms: PropTypes.func.isRequired,
  //   adminCoach: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  programs: state.programs,
});

const mapDispatchToProps = { getPrograms };

export default connect(mapStateToProps, mapDispatchToProps)(ProgramsList);

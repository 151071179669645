import React, { Component, useEffect } from "react";

import { connect } from "react-redux";

import {
  Paper,
  Container,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
} from "@material-ui/core";

import LinearProgress from "@material-ui/core/LinearProgress";

import BarChart from "../../reusableComponents/BarChart";
import { getLearnerProgressReport } from "../../redux/actions/admin_view/mvkActions";

const UserProgressReport = ({
  course,
  learnerInfo,
  adminMvk,
  getLearnerProgressReport,
  auth,
}) => {
  const { isAuthenticated } = auth;

  useEffect(() => {
    if (isAuthenticated) {
      const data = {
        userId: learnerInfo,
        programId: course,
      };
      getLearnerProgressReport(data);
    }
  }, [isAuthenticated]);

  const { learnerProgressReport } = adminMvk;

  const datefroamt1 = new Date(
    1000 *
      (learnerProgressReport?.ilearnData?.courseLengthDetails?.duration
        ? learnerProgressReport?.ilearnData?.courseLengthDetails?.duration
        : 0)
  )
    .toISOString()
    .substr(11, 8);

  const datefroamt2 = new Date(
    1000 *
      (learnerProgressReport?.ilearnData?.courseLengthDetails
        ?.userCourseProgress
        ? learnerProgressReport?.ilearnData?.courseLengthDetails
            ?.userCourseProgress
        : 0)
  )
    .toISOString()
    .substr(11, 8);

  const timeLeft = (duration, userCourseProgress) => {
    if (userCourseProgress > duration) {
      return "00:00";
    } else {
      const timeLeftDateFormate = duration - userCourseProgress;
      return new Date(1000 * (timeLeftDateFormate ? timeLeftDateFormate : 0))
        .toISOString()
        .substr(11, 8);
      // .replace("00:", "");
    }
  };

  return (
    <>
      <Container style={{ backgroundColor: "#e2eaf7", paddingBottom: "2%" }}>
        {/* <Grid container style={{paddingTop:"1%"}} >
            <Grid item xs={6} md={6} style={{backgroundColor:"white",padding:"1%"}}>
                <Grid container>
                    <Grid item xs={2} md={2}>
                    </Grid>

                    <Grid item xs={10} md={10}>
                        <Grid container>
                        <Grid item xs={12} md={12}>
                            <Typography>
                            Vishwas.V.Bharadwaj
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                        <Typography>
                            Data 
                            </Typography>
                        </Grid> 
                        <Grid item xs={12} md={12}>
                            <Typography>
                            02/3/2022 - 03/5/2022
                            </Typography>
                        </Grid>    
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={6} md={6} align="right" style={{backgroundColor:"white",paddingTop:"2%",paddingRight:"2%"}}>
                <Button
                    variant="contained" 
                    color="primary"
                    style={{padding:"2%",width:"140px"}}
                >
                    Send
                </Button>
            </Grid>
        </Grid> */}

        <Typography
          style={{ fontWeight: "600", paddingTop: "1%", paddingLeft: "0" }}
          className="reportGeneralText"
        >
          Learner Activity Overview
        </Typography>

        <Grid container style={{ marginTop: "2%", padding: "0%" }} spacing={1}>
          <Grid item xs={3} md={3}>
            <Paper elevation={0} className="timePaper">
              <Typography className="reportGeneralText">
                Total Course Time
              </Typography>
              <p className="timeText">{datefroamt1}</p>
            </Paper>
          </Grid>
          <Grid item xs={3} md={3}>
            <Paper elevation={0} className="timePaper">
              <Typography className="reportGeneralText">
                Total Active Time
              </Typography>
              <p className="timeText">
                {/* 05:50 */}
                {datefroamt2}
              </p>
            </Paper>
          </Grid>
          {/* <Grid item xs={3} md={3}>
                    <Paper elevation={0} className='timePaper'>
                    <Typography className='reportGeneralText'>
                        Weekly Visit Duration
                    </Typography>
                    <p className='timeText'>
                        05:50
                    </p>
                    </Paper>
                </Grid>
                <Grid item xs={3} md={3}>
                    <Paper elevation={0} className='timePaper'>
                    <Typography className='reportGeneralText'>
                        Previous Visit Duration
                    </Typography>
                    <p className='timeText'>
                        05:50
                    </p>
                    </Paper>
                </Grid> */}
        </Grid>

        <Grid container style={{ marginTop: "3%" }} spacing={2}>
          <Grid item xs={3} md={3}>
            <Typography
              className="reportGeneralText"
              style={{ fontWeight: "600" }}
            >
              Submission Activity
            </Typography>
            <Grid container style={{ paddingTop: "3%" }}>
              <Grid item xs={12} md={12}>
                {/* <Typography className='reportGeneralText'>
                                Unopened Course {}
                            </Typography>
                            <LinearProgress 
                                value={50} 
                                className="progressBar" 
                                variant="determinate" 
                                style={{marginTop:"3%",padding:"5%"}}
                            /> */}
              </Grid>

              <Grid item xs={12} md={12}>
                {/* <Typography className='reportGeneralText'>
                                Opened Course
                            </Typography>
                            <LinearProgress 
                                value={50} 
                                className="progressBar" 
                                variant="determinate" 
                                style={{marginTop:"3%",padding:"5%"}}
                            /> */}
              </Grid>

              <Grid item xs={12} md={12}>
                {/* <Typography className='reportGeneralText'>
                                Capability Test Attended
                            </Typography>
                            <LinearProgress 
                                value={50} 
                                className="progressBar" 
                                variant="determinate" 
                                style={{marginTop:"3%",padding:"5%"}}
                            /> */}
              </Grid>

              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={8} md={8} align="left">
                    <Typography className="reportGeneralText">
                      Completed Tasks
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={4} align="right">
                    <Typography className="reportGeneralText">
                      {`${learnerProgressReport?.iBridgeData?.completedTasks}/${learnerProgressReport?.iBridgeData?.scheduleCount}`}
                    </Typography>
                  </Grid>
                </Grid>
                <LinearProgress
                  value={
                    (learnerProgressReport?.iBridgeData?.completedTasks /
                      learnerProgressReport?.iBridgeData?.scheduleCount) *
                    100
                  }
                  className="progressBar"
                  variant="determinate"
                  style={{ marginTop: "3%", padding: "5%" }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Grid container>
                  <Grid item xs={8} md={8} align="left">
                    <Typography className="reportGeneralText">
                      Capability Test Attended
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={4} align="right">
                    <Typography className="reportGeneralText">
                      {`${learnerProgressReport?.capabilityData?.capabilityTestsTaken}`}
                    </Typography>
                  </Grid>
                </Grid>
                <LinearProgress
                  value={
                    (learnerProgressReport?.capabilityData
                      ?.capabilityTestsTaken /
                      learnerProgressReport?.capabilityData
                        ?.capabilityTestsTaken) *
                    100
                  }
                  className="progressBar"
                  variant="determinate"
                  style={{ marginTop: "3%", padding: "5%" }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={9} md={9}>
            <Paper elevation={0}>
              {console.log(
                "learnerProgressReport?.capabilityData?.barGraphData",
                learnerProgressReport?.capabilityData?.barGraphData
              )}
              <BarChart
                barGraphData={
                  learnerProgressReport?.capabilityData?.barGraphData
                    ? learnerProgressReport?.capabilityData?.barGraphData
                    : []
                }
                barGraphLabels={
                  learnerProgressReport?.capabilityData?.barGraphLabels || [
                    "sql",
                    "react",
                  ]
                }
              />
            </Paper>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          style={{ marginTop: "3%", backgroundColor: "white" }}
        >
          <Typography
            className="reportGeneralText"
            style={{ paddingTop: "2%", paddingBottom: "2%", fontWeight: "600" }}
          >
            Course Performance
          </Typography>
        </Grid>

        <Grid container style={{ marginTop: "3%" }} spacing={2} align="center">
          <Grid item xs={3} md={3}>
            <Typography
              className="reportGeneralText"
              style={{ fontWeight: "600" }}
            >
              Course Name
            </Typography>
          </Grid>
          <Grid item xs={3} md={3}>
            <Typography
              className="reportGeneralText"
              style={{ fontWeight: "600" }}
            >
              Progress
            </Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography
              className="reportGeneralText"
              style={{ fontWeight: "600" }}
            >
              Time Left in Course
            </Typography>
          </Grid>
          <Grid item xs={2} md={2}>
            <Typography
              className="reportGeneralText"
              style={{ fontWeight: "600" }}
            >
              Last Activity
            </Typography>
          </Grid>
          {/* <Grid item xs={2} md={2}>
                    <Typography className='reportGeneralText' style={{fontWeight:"600"}}>
                        Test Taken
                    </Typography>
                </Grid> */}
        </Grid>

        <hr style={{ marginTop: "1%" }}></hr>

        {learnerProgressReport?.ilearnData?.courseData?.map((item) => {
          return (
            <>
              <Grid
                container
                style={{ marginTop: "3%" }}
                spacing={2}
                align="center"
              >
                <Grid item xs={3} md={3}>
                  <Typography className="reportGeneralText">
                    {item.title}
                  </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                  <LinearProgress
                    value={item.percentage}
                    className="progressBar"
                    variant="determinate"
                    style={{
                      marginTop: "3%",
                      padding: "1.5%",
                      borderRadius: "50px 50px 50px 50px",
                    }}
                  />
                  <Typography className="reportGeneralText">
                    {`${item.percentage}%`}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className="reportGeneralText">
                    {timeLeft(item.duration, item.userCourseProgress)}
                  </Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Typography className="reportGeneralText">
                    {/* {item.lastActivity?.toString()} */}
                    {item.lastActivity === "Unable to fetch"
                      ? item.lastActivity
                      : new Date(item.lastActivity || 0).toDateString()}
                  </Typography>
                </Grid>
                {/* <Grid item xs={2} md={2}>
                            <Typography className='reportGeneralText'>
                                2
                            </Typography>
                        </Grid> */}
              </Grid>

              <hr style={{ marginTop: "1%" }}></hr>
            </>
          );
        })}

        {/* <Grid container style={{marginTop:"3%"}} spacing={2} align="center">
                <Grid item xs={3} md={3}>
                    <Typography className='reportGeneralText'>
                        Business 101
                    </Typography>
                </Grid>
                <Grid item xs={3} md={3}>
                    <LinearProgress value={50} className="progressBar" variant="determinate" style={{marginTop:"3%",padding:"1.5%",borderRadius:"50px 50px 50px 50px"}} />
                </Grid>
                <Grid item xs={2} md={2}>
                    <Typography className='reportGeneralText'>
                        7
                    </Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                    <Typography className='reportGeneralText'>
                        2 Days Ago
                    </Typography>
                </Grid>
                <Grid item xs={2} md={2}>
                    <Typography className='reportGeneralText'>
                        2
                    </Typography>
                </Grid>
            </Grid>

            <hr style={{marginTop:"1%"}}></hr> */}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  adminMvk: state.adminMvk,
});

const mapDispatchToProps = { getLearnerProgressReport };

export default connect(mapStateToProps, mapDispatchToProps)(UserProgressReport);

// export default UserProgressReport;

import React from 'react';
import MuiCarousel from 'react-material-ui-carousel';
import { Paper } from '@material-ui/core';
import { Container, Grid, Typography } from '@material-ui/core';


const Item = ({ item }) => {
    return (
        <Paper
            elevation={0}
            square
        >
            
                {/* <img 
                    className="carouselImage"
                    src={item.image}
                    alt={item.name}
                /> */}
                {/* <div className='.text-block'>
                    {/* <Typography className="carouselHeading" varient="h2">
                        {item.title}
                    </Typography>
                    <Typography className="carouselDiscription" varient="h3">
                        {item.discription}
                    </Typography> 
                    <h1>{item.title}</h1>
                    <p>{item.discription}</p>
                </div> */}
            <div className="container">
                <img 
                    className="carouselImage"
                    src={item.image}
                    alt={item.name}
                />
                <div className="top-left">
                    <Typography className="carouselHeading" varient="h2">
                        {item.title}
                    </Typography>
                    <Typography className="carouselDiscription" varient="h3">
                        {item.discription}
                    </Typography> 
                </div>

                <div className="cardTitle1">
                    <Typography className="cardHeading" varient="h2">
                        {item.cardTitle1}
                    </Typography>
                </div>
                <div className="cardDiscription1">
                    <Typography className="cardDiscription" varient="h3">
                        {item.cardDiscription1}
                    </Typography> 
                </div>
                <div className="cardTitle2">
                    <Typography className="cardHeading" varient="h2">
                        {item.cardTitle2}
                    </Typography>
                </div>
                <div className="cardDiscription2">
                    <Typography className="cardDiscription" varient="h3">
                        {item.cardDiscription2}
                    </Typography> 
                </div>
                <div className="cardTitle3">
                    <Typography className="cardHeading" varient="h2">
                        {item.cardTitle3}
                    </Typography>
                </div>
                <div className="cardDiscription3">
                    <Typography className="cardDiscription" varient="h3">
                        {item.cardDiscription3}
                    </Typography> 
                </div>
            </div>
        </Paper>
    )
}

const Carousel = ({
    items
}) => {
    return (
        <MuiCarousel
            cycleNavigation="false"
            className="muiCarouselContainer"
            interval='5000'
            animation='slide'
        >
            {
                items?.map((item, i) => <Item key={i} item={item} />)
            }
        </MuiCarousel>
    );
};

export default Carousel;
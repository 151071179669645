import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// --------------Material Card------------------
import { Container, Grid, Typography, Button } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
//------------Reusable Components------------------------
import ButtonField from "../../reusableComponents/ButtonField";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
//-------------Redux Calls-----------------------------
import { connect } from "react-redux";
import {
  getProgramInfo,
  addProgramPayment,
} from "../../redux/actions/programActions";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import { postUserAccessLog } from "../../redux/actions/authActions";
//-------------images-----------------------------

import dataEngCardImg from "../../images/programs/dataEngineering_cardImg.jpg";
import { FormatStrikethrough } from "@material-ui/icons";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const PaymentProcess = ({
  getProgramInfo,
  addProgramPayment,
  match,
  programs,
  auth,
  history,
  postUserAccessLog,
}) => {
  const [expanded, setExpanded] = useState("panel1");
  const { programId } = match.params;
  const { user, isAuthenticated } = auth;

  const [fullPayment, setFullPayment] = useState("full");

  var tabVisibility = document.visibilityState;

  const [timeInterval, setTimeInterval] = useState(0);

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 60000);

  useEffect(() => {
    if (isAuthenticated && tabVisibility === "visible") {
      const data = {
        pageName: "Payment process page",
        recordedDate: new Date(),
        programId: programId,
      };
      // postUserAccessLog(data);
    }
    // console.log("Individual Course Useage , Date", new Date(),"UserId :", user._id,"ProgramId :", programId,"Location : Program Dashboard");
  }, [timeInterval, tabVisibility]);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    accordionSummary: {
      padding: "0",
      content: {
        margin: 0,
      },
      textAlign: "center",
      // backgroundColor: numberCard === "number" ? "#008dd2" : "white",
    },
    newButton: { width: "200px" },
  }));

  const AccordionSummary = withStyles({
    content: {
      margin: "0",
      "&$expanded": {
        margin: "0",
        padding: 0,
        backgroundColor: "#003399",
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  const classes = useStyles();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const formik = useFormik({
    initialValues: {
      name: user ? user?.firstName : "",
      email: user ? user?.email : "",
      confirmEmail: user ? user?.email : "",
      phoneNumber: user ? user?.phoneNumber : "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required("Name is required field")
        .min(3, "Name must be at least 3 characters"),
      email: Yup.string().email().required("Email is required field"),
      confirmEmail: Yup.string()
        .required("Confirm Email is required field")
        .oneOf([Yup.ref("email"), null], "Emails does not match"),
      phoneNumber: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone number is required field")
        .min(10, "Phone number must be at 10 digits")
        .max(10, "Phone number must be at 10 digits"),
    }),
    onSubmit: async (values) => {
      async function displayRazorpay() {
        const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
          alert("Razorpay SDK failed to load!!!");
        }
        const userData = {
          firstName: values.name,
          email: values.email,
          phoneNumber: values.phoneNumber.toString(),

          amount:
            fullPayment === "full"
              ? // ? Math.round(programInfo?.price + programInfo?.price * 0.18)
                Math.round(programInfo?.price)
              : Math.round(
                  // (programInfo?.price + programInfo?.price * 0.18) * 0.5
                  programInfo?.price * 0.5
                ),
          programName: programInfo?.name,
          programId: programInfo?._id,
          fullPayment: fullPayment === "full" ? true : false,
        };
        console.log("final values", userData);
        const result = await axios
          .post(
            `${process.env.REACT_APP_RAZORPAY_URL}api/checkout/generateOrderId`,
            userData
          )
          .then(async (result) => {
            const options = {
              key: process.env.REACT_APP_RAZORPAY_KEY,
              currency: result?.data?.currency,
              amount: result?.data?.amount?.toString(),
              order_id: result?.data?.id,
              name: programInfo?.name,
              description: "Thank you for purchasing the course",
              image:
                "https://ibridge.s3.us-east-2.amazonaws.com/IbridgeImages/iBridge360-logo.png",
              handler: function (response) {
                // console.log(response);
                const userData = {
                  firstName: values.name,
                  email: values.email,
                  phoneNumber: values.phoneNumber,
                  transactionId: response.razorpay_payment_id,
                  orderId: response.razorpay_order_id,
                  programId: programInfo?._id,
                  programName: programInfo?.name,
                  amount: result?.data?.amount,
                  outsideUser: user ? true : false,
                };
                addProgramPayment(userData, history);
                // alert(
                //   `Your payment has been successfully recieved. Your transaction id is ${response.razorpay_payment_id}`
                // );
              },
              prefill: {
                name: values.name,
                email: values.email,
                phone_number: values.phoneNumber,
              },
              notes: {
                address: "iBridge Corporate Office",
              },
            };
            // console.log(options);

            const paymentObject = await new window.Razorpay(options);
            await paymentObject.open();
            paymentObject.on("payment.failed", function (response) {
              console.log(response.error.description, response);
            });
          })
          .catch((err) => {
            const { message } = err?.response?.data;
            SnackBar.error(message);
          });
      }
      displayRazorpay();
    },
  });

  useEffect(() => {
    getProgramInfo(programId);
  }, []);

  const handleRadioChange = (event) => {
    setFullPayment(event.target.value);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleProceed = () => {
    setExpanded("panel2");
  };
  const handleBack = () => {
    history.push("/programs");
  };
  const { programInfo } = programs;

  return (
    <Container
      maxWidth="lg"
      className="mainViewContainer"
      style={{ marginTop: "40px" }}
    >
      <div className="mainViewHeading">
        <Typography className="headingTagLine" varient="h5">
          Payment Process
        </Typography>
      </div>

      <div className={classes.root}>
        {/* ------------------------------------Cart Summary-------------------------------------------------------------------------- */}

        <Accordion
          className={classes.accordionCard}
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            className={classes.accordionSummary}
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            {/* <Grid item xs={1} md={1}> */}
            <Typography
              className={
                expanded === "panel1" ? "headingNumberClosed" : "headingNumber"
              }
              align="center"
              varient="h1"
              style={{ paddingLeft: "15px", paddingRight: "25px" }}
            >
              1
            </Typography>
            {/* </Grid>

            <Grid item xs={10} md={10}> */}
            <Typography
              align="left"
              className={
                expanded === "panel1" ? "headingTextClosed" : "headingText"
              }
            >
              CART SUMMARY
            </Typography>
            {expanded !== "panel1" ? (
              <>
                <Typography className="grandTotal" varient="h5">
                  Total Payable Amount: ₹{" "}
                  {fullPayment === "half"
                    ? Math.round(
                        (programInfo?.price ||
                          0 + programInfo?.price ||
                          0 * 0.18) * 0.5
                      )
                    : Math.round(
                        programInfo?.price || 0 + programInfo?.price || 0 * 0.18
                      )}
                  .00
                </Typography>
                <div align="right">
                  <ButtonField
                    buttonStyle="changeButton"
                    name="Change Program"
                    color="white"
                    variant="outlined"
                    onClick={() => handleBack()}
                  />
                </div>
              </>
            ) : null}
            {/* </Grid> */}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container className="containerPadding" spacing={1}>
              <Grid item xs={12} md={2}>
                <div className="cartSummaryImg">
                  <img className="imgInfo" src={programInfo?.cardImage} />
                </div>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography className="productHeading" varient="h2">
                  {programInfo?.name}
                </Typography>
                <Typography className="productDescription" varient="h5">
                  Training type: Online
                </Typography>
                <Typography className="productDescription" varient="h5">
                  Access Validity: 500 Hours
                </Typography>
                <Typography className="productDescription" varient="h5">
                  No. of learners: 1
                </Typography>
                <Typography className="productDescription" varient="h5">
                  Would like to do Full Payment or Part Payment :
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="payment"
                    name="payment1"
                    value={fullPayment}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="full"
                      control={<Radio />}
                      label="Full Payment"
                    />
                    <FormControlLabel
                      value="half"
                      control={<Radio />}
                      label="Booking Fees"
                    />
                    <FormControlLabel
                      value="inst1"
                      control={<Radio />}
                      label="1st Installment payment"
                    />
                    <FormControlLabel
                      value="inst2"
                      control={<Radio />}
                      label="2nd Installment payment"
                    />

                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid className="productHeading" item xs={12} md={3}>
                <Typography className="productPrize" align="right" varient="h2">
                  :₹ {programInfo?.price || 0}
                </Typography>

                <Grid className="gstGrid" align="right">
                  <Typography className="productDescription" varient="h5">
                    Gst: ₹ {(programInfo?.price || 0)*0.18}
                  </Typography>
                  <Typography className="productDescription" varient="h6">
                    {/* CGST+SGST(18.00%): ₹ {0} */}
                  </Typography>
                  <Typography className="productDescription" varient="h6">
                    Grand Total:
                    <span className="finalPrice">
                      {" "}
                      ₹{" "}
                      {Math.round(
                        programInfo?.price || 0 + programInfo?.price || 0 * 0.18
                      )*1.18}
                    </span>
                  </Typography>
                  {fullPayment === "half" ? (
                    <>
                    <Typography className="productDescription" varient="h6">
                        Total Amount to be paid is :
                        <span className="finalPrice">
                          {" "}
                          ₹{" "}
                          {Math.round(
                            (programInfo?.price ||
                              0 + programInfo?.price ||
                              0 * 0.18) * 1.18
                          )}
                        </span>
                      </Typography>
                      <Typography className="productDescription" varient="h6">
                        Your Advance payment is :
                        <span className="finalPrice">
                          {" "}
                          ₹{" "}
                          {Math.round(
                            (programInfo?.price ||
                              0 + programInfo?.price ||
                              0 * 0.18) * 0.18
                          )}
                        </span>
                      </Typography>
                      
                    </>
                  ) : null}
                  {fullPayment === "inst1" ? (
                    <>
                      <Typography className="productDescription" varient="h6">
                        Balance to be paid is :
                        <span className="finalPrice">
                          {" "}
                          ₹{" "}
                          {Math.round(
                            (programInfo?.price ||
                              0 + programInfo?.price ||
                              0 * 0.18) * 1
                          )}
                        </span>
                      </Typography>
                      <Typography className="productDescription" varient="h6">
                        Your First installment is :
                        <span className="finalPrice">
                          {" "}
                          ₹{" "}
                          {Math.round(
                            (programInfo?.price ||
                              0 + programInfo?.price ||
                              0 * 0.18) * 0.5
                          )}
                        </span>
                      </Typography>
                    
                    </>
                  ) : null}
                  {fullPayment === "inst2" ? (
                    <>
                    <Typography className="productDescription" varient="h6">
                        Balance to be paid is :
                        <span className="finalPrice">
                          {" "}
                          ₹{" "}
                          {Math.round(
                            (programInfo?.price ||
                              0 + programInfo?.price ||
                              0 * 0.18) * 0.5
                          )}
                        </span>
                      </Typography>
                      <Typography className="productDescription" varient="h6">
                        Your Second installment is :
                        <span className="finalPrice">
                          {" "}
                          ₹{" "}
                          {Math.round(
                            (programInfo?.price ||
                              0 + programInfo?.price ||
                              0 * 0.18) * 0.5
                          )}
                        </span>
                      </Typography>
                      
                    </>
                  ) : null}
                </Grid>

                <div className="cartSummaryButton">
                  <ButtonField
                    buttonStyle={classes.newButton}
                    name="PROCEED"
                    color="#ff8300"
                    onClick={handleProceed}
                  />
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* ------------------------------------LEARNER DETAILS-------------------------------------------------------------------------- */}

        <Accordion
          className={classes.accordionCard}
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            className={classes.accordionSummary}
            // expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            {/* <Grid item xs={1} md={1}> */}
            <Typography
              className={
                expanded === "panel2" ? "headingNumberClosed" : "headingNumber"
              }
              align="center"
              varient="h1"
              style={{ paddingRight: "5px" }}
            >
              2
            </Typography>
            {/* </Grid> */}

            {/* <Grid item xs={10} md={10}> */}
            <Typography
              align="left"
              className={
                expanded === "panel2" ? "headingTextClosed" : "headingText"
              }
            >
              LEARNER DETAILS
            </Typography>
            {/* </Grid> */}
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              align="center"
              className="learnerDetails"
              item
              xs={12}
              md={12}
            >
              <form
                onSubmit={formik.handleSubmit}
                noValidate
                autoComplete="off"
                className="formWidth"
              >
                <div className="learnerDetailsTextFields">
                  <TextFieldGroup
                    label="Name"
                    type="text"
                    name="name"
                    varient="standard"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    errors={
                      formik.touched.name && formik.errors.name
                        ? formik.errors.name
                        : null
                    }
                    disabled={user && user?.firstName ? true : false}
                  />
                </div>

                <div className="learnerDetailsTextFields">
                  <TextFieldGroup
                    label="Email"
                    type="text"
                    name="email"
                    varient="standard"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    errors={
                      formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : null
                    }
                    disabled={user && user?.email ? true : false}
                  />
                </div>

                <div className="learnerDetailsTextFields">
                  <TextFieldGroup
                    label="Confirm Email"
                    type="text"
                    name="confirmEmail"
                    varient="standard"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmEmail}
                    errors={
                      formik.touched.confirmEmail && formik.errors.confirmEmail
                        ? formik.errors.confirmEmail
                        : null
                    }
                    disabled={user && user?.email ? true : false}
                  />
                </div>

                <div className="learnerDetailsTextFields">
                  <TextFieldGroup
                    label="Phone Number"
                    type="text"
                    name="phoneNumber"
                    varient="standard"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                    errors={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? formik.errors.phoneNumber
                        : null
                    }
                    fullWidth
                    disabled={user && user?.phoneNumber ? true : false}
                  />
                </div>
                <div className="cartSummaryButton">
                  <Button
                    type="submit"
                    className="classes.button proceedButton"
                    color={"#FFFFFF"}
                    style={{ color: "#FFFFFF", backgroundColor: "none" }}
                  >
                    PROCEED
                  </Button>
                </div>
              </form>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    </Container>
  );
};

PaymentProcess.propTypes = {
  getProgramInfo: PropTypes.func.isRequired,
  addProgramPayment: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  programs: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  programs: state.programs,
});

const mapDispatchToProps = {
  getProgramInfo,
  addProgramPayment,
  postUserAccessLog,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentProcess);

import isEmpty from "../../reusableComponents/is-empty";
import {
  GET_PROGRAM_BATCH_LIST,
  PROGRAM_BATCH_LOADING,
  GET_BATCH_USERS_LIST,
  GET_BATCH_APPLICABLE_USERS_LIST,
  GET_BATCH_BY_PROGRAM_ID,
} from "../actions/types";

const initialState = {
  loading: false,
  programBatchList: null,
  batchUsersList:null,
  batchApplicableUsers:null,
  batchListByProgramId:[],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PROGRAM_BATCH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_PROGRAM_BATCH_LIST:
      return {
        ...state,
        programBatchList: action.payload,
        loading: false,
      };
    case GET_BATCH_USERS_LIST:
      return {
        ...state,
        batchUsersList: action.payload,
        loading: false,
      };
    case GET_BATCH_APPLICABLE_USERS_LIST:
      return {
        ...state,
        batchApplicableUsers: action.payload,
        loading: false,
      };
    case GET_BATCH_BY_PROGRAM_ID:
      return {
        ...state,
        batchListByProgramId: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

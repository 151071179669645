import React, { useState, useEffect, useRef } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { TextInput } from "../../reusableComponents/TextInput.js";
import { MessageLeft, MessageRight } from "../../reusableComponents/Message";
import { useSelector, useDispatch } from "react-redux";
// import io from "socket.io-client";
// const socket = io(process.env.REACT_APP_RAZORPAY_URL);

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: "80vw",
      height: "80vh",
      maxWidth: "460px",
      maxHeight: "700px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
    },
    paper2: {
      width: "80vw",
      maxWidth: "500px",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
    },
    container: {
      // width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      marginLeft: "20px",
    },
    messagesBody: {
      width: "calc( 100% - 20px )",
      margin: 10,
      overflowY: "scroll",
      height: "calc( 100% - 80px )",
    },
  })
);

const ChatComponent = ({ scheduleId }) => {
  const classes = useStyles();
  const { auth } = useSelector((state) => state);
  const [guestsAttended, setGuestsAttended] = useState(0);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const scrollRef = useRef();
  const dispatch = useDispatch();
  const { isAuthenticated, user } = auth;
  useEffect(() => {
    if (isAuthenticated) {
      delete user?.password;
      // socket.emit("join-room", { roomId: scheduleId, user }, (error) => {
      //   if (error) {
      //     alert(error);
      //   }
      // });
    }
  }, [auth]);
  useEffect(() => {
    // socket.on("recieve-end-shhparty", (endShhParty) => {
    //   setPartyCompletedModal(true);
    // });
    // socket.on("recieve-end-shhparty-error", (errorEndShhparty) => {
    //   setEndShhpartyError(errorEndShhparty.message);
    // });
    // socket.on("list-of-comments", (message) => {
    //   setMessages(message);
    // });
    // socket.on("guest-count", (count) => {
    //   setGuestsAttended(count);
    // });
    // socket.on("recieve-comment", (currentMsg) => {
    //   setMessages((messages) => [...messages, currentMsg]);
    // });
  }, []);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const onSubmit = (e) => {
    e.preventDefault();

    const roomId = scheduleId;
    if (message) {
      delete user?.password;
      // socket.emit(
      //   "send-comment",
      //   { roomId, userData: user, content: message },
      //   () => setMessage("")
      // );
      setMessage("");
    }
  };
  return (
    <div className={classes.container}>
      <Paper className={classes.paper} zdepth={2}>
        <Paper id="style-1" className={classes.messagesBody}>
          {messages?.length > 0 &&
            messages?.map((item) => {
              if (user?._id?.toString() === item?.userData?._id.toString()) {
                return (
                  <div ref={scrollRef}>
                    <MessageLeft
                      message={item?.content}
                      timestamp="MM/DD 00:00"
                      displayName={`${item?.userData?.firstName} ${item?.userData?.lastName}`}
                    />
                  </div>
                );
              } else {
                return (
                  <div ref={scrollRef}>
                    <MessageRight
                      message={item?.content}
                      timestamp="MM/DD 00:00"
                      displayName={`${item?.userData?.firstName} ${item?.userData?.lastName}`}
                    />
                  </div>
                );
              }
            })}
          {/* <MessageLeft
            message="sdf"
            timestamp="MM/DD 00:00"
            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
            displayName="AJ"
            avatarDisp={true}
          />
          <MessageRight
            message="sdf"
            timestamp="MM/DD 00:00"
            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
            displayName="CJ"
            avatarDisp={true}
          />
          <MessageRight
            message="sdfsdf"
            timestamp="MM/DD 00:00"
            photoURL="https://lh3.googleusercontent.com/a-/AOh14Gi4vkKYlfrbJ0QLJTg_DLjcYyyK7fYoWRpz2r4s=s96-c"
            displayName="DJ"
            avatarDisp={false}
          /> */}
        </Paper>
        <TextInput
          setMessage={setMessage}
          onSubmit={onSubmit}
          message={message}
        />
      </Paper>
    </div>
  );
};

export default ChatComponent;

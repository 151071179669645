import React, { useEffect } from 'react';
import * as d3 from "d3";
import $ from "jquery";
import './PersonalityAssessmentGraph.scss'

const PersonalityAssessmentGraph = ({
    psychometricGraphData
}) => {

    useEffect(() => {
        if(psychometricGraphData) {
            let d = document.getElementById("PersonalityAssessmentGraph");
            d.innerHTML = ""
            renderPersonalityAssessmentGraph(psychometricGraphData)
        }
    }, [psychometricGraphData])

    const renderPersonalityAssessmentGraph = (data) => {
        var cwidth = $("#PersonalityAssessmentGraph").width();   
        var cheight = $("#PersonalityAssessmentGraph").height();

        // set the dimensions and margins of the graph
        var margin = {top: 20, right: 50, bottom: 20, left: 20},
            innerwidth = cwidth - margin.left - margin.right,
            innerheight = cheight - margin.top - margin.bottom;

        // set the ranges
        var x = d3.scaleBand()
                .range([0, innerwidth])
                .padding(0.6)
            
        var y = d3.scaleLinear()
                    .range([innerheight, 0])
                
        const xaxistickformat = number => d3.format("d")(number);

       
        // append the svg object to the body of the page
        // append a 'group' element to 'svg'
        // moves the 'group' element to the top left margin
        var svg = d3.select('#PersonalityAssessmentGraph')
                .append('svg')
                .attr('width', cwidth)
                .attr('height', cheight)
                .call(responsivefy) // Call 
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");


        function responsivefy(svg) { 
              
            // Container is the DOM element, svg is appended. 
            // Then we measure the container and find its 
            // aspect ratio. 
            const container = d3.select(svg.node().parentNode), 
                width = parseInt(svg.style('width'), 10), 
                height = parseInt(svg.style('height'), 10), 
                aspect = width / height; 
                
            // Add viewBox attribute to set the value to initial size 
            // add preserveAspectRatio attribute to specify how to scale  
            // and call resize so that svg resizes on page load 
            svg.attr('viewBox', `0 0 ${width} ${height}`). 
            attr('preserveAspectRatio', 'xMinYMid'). 
            call(resize); 
            
            d3.select(window).on('resize.' + container.attr('id'), resize); 
    
            function resize() { 
                const targetWidth = parseInt(container.style('width')); 
                svg.attr('width', targetWidth); 
                svg.attr('height', Math.round(targetWidth / aspect)); 
            } 
        } 

        // gridlines in y axis function
        function make_y_gridlines() {		
            return d3.axisLeft(y)
                .ticks()
        }

        // get the data
        const renderGraph = data => {


            // format the data
            data.forEach(function(d) {
                d.scores = +d.scores;
                //console.log(d.scores)
            });

            // Scale the range of the data in the domains
            x.domain(data.map(function(d) { return d.trait_name; }));
            y.domain([0, d3.max(data, function(d) { return d.scores; })]);

            //linear gradient for bars
            var defs =svg.append("defs");
            var linearGradient = defs.append("linearGradient")
                            .attr("id","animate-gradient")
                            .attr("deg", "45")
                            .attr("x1","0%")
                            .attr("y1","0%")
                            .attr("x2","100%")
                            .attr("y2","0")
                            .attr("spreadMethod", "reflect");
                        
            // var colours = ["white","#fce043","#fabc3c","#facc6b","#f7b42c"];

            var colours = ["#b8c7e4"]
            
                linearGradient.selectAll(".stop")
                            .data(colours)
                            .enter().append("stop")
                            .attr("offset", function(d,i) { return i/(colours.length-1); })   
                            .attr("stop-color", function(d) { return d; });
                    
                linearGradient.append("animate")
                            .attr("attributeName","x1")
                            .attr("values","0%;100%")
                            .attr("dur","7s")
                            .attr("repeatCount","indefinite");
                            
                linearGradient.append("animate")
                            .attr("attributeName","x2")
                            .attr("values","100%;200%")
                            .attr("dur","7s")
                            .attr("repeatCount","indefinite");


                // add the Y gridlines
                svg.append("g")			
                    .attr("class", "grid")
                    .call(make_y_gridlines()
                        .tickSize(-cwidth)
                        .tickFormat("")
                    )

                // append the rectangles for the bar chart
                svg.selectAll(".bar")
                        .data(data)
                        .enter().append("rect")
                        .attr("class", "bar")
                        .attr("x", function(d) { return x(d.trait_name); })
                        .attr("width", x.bandwidth())
                        .attr("y", function(d) { return y(d.scores); })
                        .attr("height", function(d) { return innerheight - y(d.scores); })
                        .attr("fill", "url(#animate-gradient)");
        
                svg.selectAll(".text")
                                .data(data)
                                .enter()
                                .append("text")
                                .attr("x", d => x(d.trait_name)+(x.bandwidth()/2))
                                .attr("y", d => y(d.scores)-5)
                                .style("text-anchor", "start")
                                .text(function(d){ return xaxistickformat(d.scores) })

                // add the x Axis
                const xaxisG = svg.append("g")
                                    .attr("transform", "translate(0," + innerheight + ")")
                                    .call(d3.axisBottom(x))
                    xaxisG.selectAll('.domain, .tick line')
                                                .remove();

        }
        renderGraph(data);
    }

    return (
        <div id="PersonalityAssessmentGraph"></div>
    );
};

export default PersonalityAssessmentGraph;
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextFieldGroup from "./TextFieldGroup";
// import "./SearchBar.css";
import {
  getUserPrograms,
  getPrograms,
  getSearchResults,
} from "../redux/actions/programActions";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { useLocation } from "react-router-dom";

function SearchComponent({
  placeholder,
  data,
  getPrograms,
  getUserPrograms,
  getSearchResults,
  programs,
  auth,
}) {
  const { user, isAuthenticated } = auth;
  const location = useLocation();

  const { userPrograms, programList } = programs;

  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      if (location.pathname === "/myPrograms") {
        getUserPrograms(user?._id);
      } else {
        getPrograms();
      }
    }
  }, [auth]);

  useEffect(() => {
    clearInput();
    document.getElementById("searchTextfield").value = "";
  }, [location.pathname]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter =
      location.pathname === "/myPrograms"
        ? userPrograms?.filter((value) => {
            return value.name.toLowerCase().includes(searchWord?.toLowerCase());
          })
        : programList?.filter((value) => {
            return value.name.toLowerCase().includes(searchWord?.toLowerCase());
          });
    if (searchWord === "") {
      setFilteredData([]);
      if (location.pathname === "/myPrograms") {
        const data = {
          userPrograms: userPrograms,
          filteredCourseList: userPrograms,
          pathname: "myPrograms",
        };
        getSearchResults(data);
      } else {
        const data = {
          programList: programList,
          filteredCourseList: programList,
          pathname: "programs",
        };
        getSearchResults(data);
      }
    } else {
      setFilteredData(newFilter);
      if (location.pathname === "/myPrograms") {
        const data = {
          userPrograms: userPrograms || [],
          filteredCourseList: newFilter || [],
          pathname: "myPrograms",
        };
        getSearchResults(data);
      } else {
        const data = {
          programList: programList,
          filteredCourseList: newFilter,
          pathname: "programs",
        };
        getSearchResults(data);
      }
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    if (location.pathname === "/myPrograms") {
      const data = {
        userPrograms: userPrograms,
        filteredCourseList: userPrograms,
        pathname: "myPrograms",
      };
      getSearchResults(data);
    } else {
      const data = {
        coursesList: programList,
        filteredCourseList: programList,
        pathname: "programs",
      };
      getSearchResults(data);
    }
    setWordEntered("");
  };

  return (
    <div className="search">
      <div className="searchBox">
        {filteredData.length === 0 ? <SearchIcon /> : null}
        <TextFieldGroup
          // label="Search"
          className="searchTextfield"
          name="searchTextfield"
          varient="standard"
          placeholder="Search"
          onChange={handleFilter}
        />
        {filteredData.length > 0 ? <CloseIcon onClick={clearInput} /> : null}
      </div>

      {filteredData.length != 0 && (
        <div className="dataResult">
          {filteredData.slice(0, 15).map((value, key) => {
            return (
              <a className="dataItem" href={value.link} target="_blank">
                <p>{value.title} </p>
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  programs: state.programs,
});

const mapDispatchToProps = {
  getUserPrograms,
  getPrograms,
  getSearchResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent);
// export default SearchComponent;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Button,
  Paper,
  Container,
  Select,
  MenuItem,
} from "@material-ui/core";
import { GroupAdd as GroupAddIcon, YouTube } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import ButtonField from "../../../reusableComponents/ButtonField";
import PageHeader from "../../../reusableComponents/PageHeader";
import { getPrograms } from "../../../redux/actions/programActions";
import isEmpty from "../../../reusableComponents/is-empty";
import DateTimePick from "../../../reusableComponents/DateTimePick";
import { getCoachList } from "../../../redux/actions/admin_view/coachList";
import { createNewProgramBatch } from "../../../redux/actions/programBatchActions";
import { getMentorPrograms } from "../../../redux/actions/programActions";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";

const useStyles = makeStyles((theme) => ({
  inputFields: {
    margin: "1%",
    width: "98%",
    //marginRight:"2%",
  },
  inputField: {
    marginTop: "1%",
    width: "99%",
  },
  input: {
    paddingTop: "3%",
  },
  inputButtonFields: {
    margin: "1%",
    width: "60%",
  },
  LableText: {
    paddingLeft: "2%",
    paddingTop: "2%",
    fontSize: "18px",
  },
  inputButton: {
    marginTop: "3%",
    marginLeft: "2%",
    width: "60%",
  },

  pageContent: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: "10px 0",
    mentorForm: {
      width: "100%",
    },
  },
  mForm: {
    margin: "8px",
  },
}));

const CreateBatch = ({
  getCoachList,
  auth,
  getPrograms,
  adminCoach,
  programs,
  createNewProgramBatch,
  getMentorPrograms,
}) => {
  const classes = useStyles();
  const { coachList } = adminCoach;
  const { programList, mentorPrograms } = programs;
  const { isAuthenticated, user } = auth;

  const currentUser = user?.role?.r_id === 1 ? "admin" : "mentor";

  useEffect(() => {
    if (isAuthenticated) {
      // getCoachList();
      if (currentUser === "admin") getPrograms();
      else {
        getMentorPrograms(user?._id);
      }
    }
  }, [isAuthenticated]);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDate1, setSelectedDate1] = useState(new Date());

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     getMentorPrograms(user?._id);
  //   }
  // }, [isAuthenticated, auth]);

  // console.log("below deleration", selectedDate, selectedDate1);

  const mentorData = coachList?.map((list) => {
    const { _id, firstName, lastName } = list;
    return {
      _id,
      name: `${firstName} ${lastName}`,
    };
  });

  const adminProgramData = programList?.map((list) => {
    const { _id, name } = list;
    return {
      _id,
      name: name,
    };
  });

  const mentorProgramData = mentorPrograms?.map((list) => {
    const { _id, name } = list;
    return {
      _id,
      name: name,
    };
  });

  const programData =
    currentUser === "admin" ? adminProgramData : mentorProgramData;

  const formik = useFormik({
    initialValues: {
      batchTitle: "",
      programMentor: {},
      programName: {},
      batchStartDate: selectedDate1,
      batchEndDate: selectedDate,
    },
    validationSchema: Yup.object({
      batchTitle: Yup.string().trim().required("Batch Title is required field"),
      // scheduleStartDate: Yup.string()
      //   .trim()
      //   .required("Schedule Start Date required field"),
      // scheduleEndDate: Yup.string()
      //   .trim()
      //   .required("Schedule End Date required field"),
    }),
    onSubmit: async (values) => {
      // console.log(values, formik.errors);
      const programBatchData = {
        name: values.batchTitle,
        programMentor: values.programMentor?._id,
        programName: values.programName?._id,
        batchStartDate: values?.batchStartDate?.toString().slice(0, 15),
        batchEndDate: values?.batchEndDate?.toString().slice(0, 15),
        addedBy: user._id,
      };
      createNewProgramBatch(programBatchData);
      // console.log("\n\n", programBatchData, "\n\n");
    },
  });

  useEffect(() => {
    // console.log(formik.errors, formik.values);
  }, [formik.errors, formik.values]);

  const handleDateChange = (batchEndDate) => {
    formik.setFieldValue("batchEndDate", batchEndDate);
    setSelectedDate(batchEndDate);
    // console.log(formik.values.batchEndDate);
  };

  const handleDateChange1 = (batchStartDate) => {
    formik.setFieldValue("batchStartDate", batchStartDate);
    setSelectedDate1(batchStartDate);
    // console.log(formik.values.batchStartDate);
  };

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      enableReinitialize={true}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={5}>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <TextFieldGroup
                label=" Batch Title"
                type="text"
                className={classes.inputFields}
                name="batchTitle"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.batchTitle}
                errors={
                  formik.touched.batchTitle && formik.errors.batchTitle
                    ? formik.errors.batchTitle
                    : null
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  // margin="normal"
                  name="batchStartDate"
                  label="Batch Start Date"
                  format="MM/dd/yyyy"
                  inputVariant="outlined"
                  value={selectedDate1}
                  style={{ marginTop: "5%" }}
                  className={classes.inputFields}
                  onChange={handleDateChange1}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  // margin="normal"
                  name="batchEndDate"
                  label="Batch End Date"
                  format="MM/dd/yyyy"
                  inputVariant="outlined"
                  style={{ marginTop: "5%" }}
                  value={selectedDate}
                  className={classes.inputFields}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} align="center">
          <Grid container>
            <Grid item xs={12} md={12}>
              <div className={classes.input}>
                <SelectSingleValues
                  label="Select Program"
                  name="selectPrograms"
                  values={programData ? programData : []}
                  value="program"
                  onSelect={(program) =>
                    formik.setFieldValue("programName", program)
                  }
                  selected={formik.values.programName}
                  errors={
                    formik.touched.programName && formik.errors.programName
                      ? formik.errors.programName
                      : null
                  }
                />
              </div>
            </Grid>

            {/* <Grid item xs={12} md={12}>
              <div className={classes.input}>
                <SelectSingleValues
                  label="Select Mentor"
                  name="programMentor"
                  values={mentorData}
                  onSelect={(programMentor) =>
                    formik.setFieldValue("programMentor", programMentor)
                  }
                  selected={formik.values.programMentor}
                  errors={
                    formik.touched.programMentor && formik.errors.programMentor
                      ? formik.errors.programMentor
                      : null
                  }
                />
              </div>
            </Grid> */}
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} align="center">
          <div className={classes.submitButton}>
            <Button
              buttonStyle="submit"
              type="submit"
              name="submit"
              color="primary"
              className={classes.inputButtonFields}
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container maxWidth="lg" className="adminAddNewEducator">
      <PageHeader
        title="Create Batch"
        icon={<GroupAddIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent}>{renderForm}</Paper>
    </Container>
  );
};

CreateBatch.propTypes = {
  auth: PropTypes.object.isRequired,
  getCoachList: PropTypes.func.isRequired,
  programs: PropTypes.object.isRequired,
  getPrograms: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  adminCoach: state.adminCoach,
  programs: state.programs,
});

const mapDispatchToProps = {
  getCoachList,
  getPrograms,
  createNewProgramBatch,
  getMentorPrograms,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBatch);

import {
  GET_USER_TASKS,
  GET_ALL_USER_TASKS,
  GET_USER_TASKS_BY_ID,
} from "../actions/types";

const initialState = {
  loading: false,
  usertaskdata: [],
  allusertaskdata: [],
  usertaskdataById: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    // case USER_TASKS_LOADING:
    //     return {
    //       ...state,
    //       loading: true,
    //     };
    case GET_USER_TASKS:
      return {
        ...state,
        usertaskdata: action.payload,
        loading: true,
      };
    case GET_ALL_USER_TASKS:
      return {
        ...state,
        allusertaskdata: action.payload,
        loading: false,
      };
    case GET_USER_TASKS_BY_ID:
      return {
        ...state,
        usertaskdataById: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

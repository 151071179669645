import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Avatar, Button } from "@material-ui/core";
// import Table from '../../MaterialTable/Table';
import MUITable from "../MaterialTable/MaterialTable";
import { Search as SearchIcon, Add as AddIcon } from "@material-ui/icons";
import ButtonField from "../../reusableComponents/ButtonField";
import { getUserTaskList } from "../../redux/actions/userAction";

import CustomizedDialogs from "../../reusableComponents/DialogModal";
// import ProgramSchedule from "../../Learner_Module/ProgramSchedule";
import LearnerTask from "./LearnerTask";
import EditTask from "./EditUserTask";

const useStyles = makeStyles((theme) => ({
  tableImage: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  industryList: {
    listStyleType: "none",
  },
}));

const TaskList = ({ programId, batchId }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [taskId, setTaskId] = useState("");
  const dispatch = useDispatch();

  const { auth, usertaskReducer } = useSelector((state) => state);

  useEffect(() => {
    if (auth?.isAuthenticated) {
      dispatch(getUserTaskList(auth?.user?._id, programId));
    }
  }, [auth?.isAuthenticated]);

  console.log(usertaskReducer?.usertaskdata);
  // const data=usertaskReducer?.usertaskdata;
  console.log("auth", auth);
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     if (currentUser === "admin") getScheduleList();
  //     else {
  //       getMentorScheduleList(user?._id);
  //     }
  //   }
  // }, [isAuthenticated]);

  const columns = [
    {
      title: "Task Description",
      field: "description",
    },

    {
      title: "Task start Date",
      field: "taskStartDate",
    },
    {
      title: "Task End Date",
      field: "taskEndDate",
    },
    {
      title: "Task Link",
      field: "taskLink",
    },
    {
      title: "Edit Task",
      field: "editTask",
    },
  ];

  const data = usertaskReducer?.usertaskdata?.map((item) => {
    const { _id, description, taskLink, taskStartDate, taskEndDate } = item;
    return {
      _id,
      description,
      taskLink,
      taskStartDate: new Date(taskStartDate).toDateString(),
      taskEndDate: new Date(taskEndDate).toDateString(),
      editTask: (
        <Button
          variant="contained"
          color="primary"
          type="submit"
          name="submit"
          onClick={() => {
            setOpenEdit(true);
            setTaskId(_id);
          }}
        >
          Edit
        </Button>
      ),
    };
  });

  // const data =

  return (
    <>
      <Container maxWidth="lg" className="adminMvk">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MUITable
              title={
                <ButtonField
                  variant="outlined"
                  name="Add task"
                  icon={<AddIcon />}
                  onClick={() => setOpen(true)}
                />
              }
              columns={columns}
              data={data}
              pageSize={10}
              selectedRow={(row) => console.log("clicked")}
            />
          </Grid>
        </Grid>

        <CustomizedDialogs
          title="Add User Task"
          children={
            <LearnerTask
              programId={programId}
              batchId={batchId}
              setOpen={setOpen}
            />
          }
          openPopup={open}
          setOpenPopup={setOpen}
        />

        <CustomizedDialogs
          title="Edit User Task"
          children={
            <EditTask
              taskId={taskId}
              programId={programId}
              batchId={batchId}
              setOpenEdit={setOpenEdit}
            />
          }
          openPopup={openEdit}
          setOpenPopup={setOpenEdit}
        />
      </Container>
    </>
  );
};

export default TaskList;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Container, Grid } from "@material-ui/core";
import DashboardCard from "../../../reusableComponents/DashboardCard";
import { getdashboardCount } from "../../../redux/actions/admin_view/dashboardActions";

const Dashboard = ({ getdashboardCount, auth, adminDashboard, history }) => {
  const { isAuthenticated } = auth;
  const { dashboardCounts } = adminDashboard;
  const [rout, setRout] = useState("");

  const handleRout = (id) => {
    if (id === 1) {
      return history.push("/admin/mvkLists");
    } else if (id === 2) {
      return history.push("/admin/programsList");
    } else if (id === 3) {
      return history.push("/admin/counsellingRequest");
    } else if (id === 4) {
      return history.push("/admin/program-batches");
    } else if (id === 5) {
      return history.push("/admin/schedulingList");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getdashboardCount();
    }
  }, [isAuthenticated]);

  const renderCounts = () => {
    return dashboardCounts?.map((item) => (
      <Grid key={item.id} item xs={12} sm={6} md={4}>
        <DashboardCard
          title={item?.name}
          count={item?.count}
          onClick={() => handleRout(item.id)}
        />
      </Grid>
    ));
  };
  return (
    <Container maxWidth="lg" className="adminDashboard">
      <Grid container spacing={4}>
        {renderCounts()}
      </Grid>
    </Container>
  );
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  getdashboardCount: PropTypes.func.isRequired,
  adminDashboard: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  adminDashboard: state.adminDashboard,
});

const mapDispatchToProps = { getdashboardCount };

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

import React, { Component } from "react";

import { Container, Grid, Typography } from "@material-ui/core";

const DataDeletionPolicy = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "24px", fontWeight: "600" }}
          >
            Data Deletion Policy and Bug Reporting
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            We value your feedback and are committed to providing you with the
            best experience possible. If you encounter any bugs or issues,
            please contact us via email at support@ibridge360.com. Your email
            will be collected and used solely for the purpose of resolving the
            reported issues. If you wish to request the deletion of your email
            and any associated data, please email us at support@ibridge360.com
            with your request. We will process your data deletion request
            promptly and ensure that your data is removed from our records.
            Thank you for helping us improve our service.
          </Typography>

          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            Instructions for Requesting Data Deletion
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            If you wish to request the deletion of your email and any associated
            data from our records, please send an email to
            support@ibridge360.com with the subject line "Data Deletion
            Request." In your email, include your full name and the email
            address associated with your account, along with a brief statement
            requesting the deletion of your data. Our team will process your
            request and confirm the deletion of your data. If you have any
            questions or need further assistance, please do not hesitate to
            contact us.
          </Typography>

          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            Account Deletion Request
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            If you wish to delete your account, please send an email to
            support@ibridge360.com with the subject line "Account Deletion
            Request." In your email, include your full name, the email address
            associated with your account, and a brief statement requesting the
            deletion of your account. Our team will process your request and
            confirm the deletion of your account.
          </Typography>

          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            USE OF PROGRAMS
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            iBridge360 reserves the right to cancel or reschedule any Program or
            live lectures/webinars, or to alter, modify or rearrange the
            schedule of topics, as well as the point value or weight of
            assignments, tests, quizzes, exams, projects and other such
            evaluations of progress. You also understand that iBridge360 at its
            sole discretion, may limit, suspend, or terminate your use of the
            Platform or Programs and/or all iBridge360 provided services related
            to the Programs, such as access to iBridge360 coaches or support
            services, evaluation services, or certifications. You also
            understand that iBridge may modify or discontinue all services
            related to its Programs at its sole discretion. You agree that
            iBridge360 shall not be liable to you or to any third party for any
            such modification, suspension or discontinuance. Nothing in these
            Terms shall be construed to obligate iBridge360 to maintain and
            support the Platform or Programs or any part or portion thereof or
            any associated services.
          </Typography>

          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            MODIFICATIONS TO THE SERVICE AND PRICES
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            iBridge360 reserves the right to change prices for all our products,
            offers, or deals. These changes are done due to market conditions,
            course termination, providers, price changes, errors in
            advertisements and other extenuating circumstances. However, the
            price you paid at the time of purchase still holds for you.
          </Typography>

          <Typography
            className="termsAndConditionContent1"
            align="Left"
            style={{ fontSize: "20px", fontWeight: "600" }}
          >
            CONTACT INFORMATION
          </Typography>
          <Typography className="termsAndConditionContent2" align="Left">
            Questions about the Terms of Service should be sent to us at :
            support@ibridge360.com .
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default DataDeletionPolicy;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Button } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthContainerView from "./AuthContainerView";
import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import ButtonField from "../../reusableComponents/ButtonField";
import { registerUser } from "../../redux/actions/authActions";

const SignUp = ({ registerUser, auth, history }) => {
  const { isAuthenticated } = auth;

  const [showPassword, setShowPassword] = useState(false);
  // const passwordRegExp ="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})";
  const phoneRegExp =
    "^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$";
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      phoneNumber: "",
      education: "",
      city: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .trim()
        .required("First Name is a required field")
        .min(3, "Name must be at least 3 characters")
        .matches(/^[A-Za-z\s]+$/, "First Name should contain only alphabets"),
      lastName: Yup.string()
        .trim()
        .required("Last Name is a required field")
        .min(3, "Name must be at least 3 characters")
        .matches(/^[A-Za-z\s]+$/, "First Name should contain only alphabets"),
      userName: Yup.string()
        .trim()
        .required("Name is a required field")
        .min(4, "Name must be at least 4 characters"),
      email: Yup.string()
        .trim()
        .email("Email is not valid")
        .required("Email is required field"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(
          "(?=.*[a-z])",
          "Password must contain at least 1 Lower Case character"
        )
        .matches(
          "(?=.*[A-Z])",
          "Password must contain at least 1 Upper Case character"
        )
        .matches("(?=.*[0-9])", "Password must contain at least 1 Number")
        .matches(
          "(?=.*[!@#$%^&*])",
          "Password must contain at least 1 special character"
        )
        .required("Password is required field"),
      phoneNumber: Yup.string()
        // .min(10, 'Name must be at least 6 characters')
        .length(10, "Phone Number must have 10 numbers")
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone number is required field"),
      education: Yup.string().trim().required("Education is required"),
      city: Yup.string().trim().required("City is required"),
    }),
    onSubmit: async (values) => {
      await registerUser(values);
    },
  });

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/dashboard");
    }
  }, [auth]);

  const renderForm = (
    <form
      onSubmit={formik.handleSubmit}
      noValidate
      autoComplete="off"
      style={{ paddingTop: "10px" }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="FirstName"
            type="text"
            name="firstName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            errors={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="LastName"
            type="text"
            name="lastName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            errors={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="UserName"
            type="text"
            name="userName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.userName}
            errors={
              formik.touched.userName && formik.errors.userName
                ? formik.errors.userName
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="Email"
            type="text"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            errors={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="Password"
            type={showPassword ? "text" : "password"}
            name="password"
            passwordControl={() => setShowPassword(!showPassword)}
            showPassword={showPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            errors={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="PhoneNumber"
            type="text"
            name="phoneNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
            errors={
              formik.touched.phoneNumber && formik.errors.phoneNumber
                ? formik.errors.phoneNumber
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="Education"
            type="text"
            name="education"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.education}
            errors={
              formik.touched.education && formik.errors.education
                ? formik.errors.education
                : null
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} className="inputFieldContainer">
          <TextFieldGroup
            label="City"
            type="text"
            name="city"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
            errors={
              formik.touched.city && formik.errors.city
                ? formik.errors.city
                : null
            }
          />
        </Grid>
      </Grid>
      <div className=" signUpSubmit submitButton">
        <Button
          buttonStyle="formSubmit"
          type="submit"
          className="formSubmit"
          name="SIGN UP"
          variant="contained"
          // style={{backgroundColor:"#008dd2"}}
        >
          SIGN UP
        </Button>
      </div>
    </form>
  );

  return <AuthContainerView content={renderForm} from="signUp" />;
};

SignUp.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { registerUser };

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

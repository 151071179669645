import axios from "axios";
import { GET_PROGRAM_BATCH_LIST, GET_BATCH_USERS_LIST, 
  PROGRAM_BATCH_LOADING, 
  GET_BATCH_APPLICABLE_USERS_LIST,
  GET_BATCH_BY_PROGRAM_ID,
 } from "./types";
import { SnackBar } from "../../reusableComponents/SnackbarConfig";
import history from "../../reusableComponents/history";

// Get Schedule List
export const getProgramBatchList = (userEmail) => (dispatch) => {
  dispatch(programBatchLoading());
  axios
    .get(`/api/programBatch/getProgramBatchList`)
    .then((res) =>
      dispatch({
        type: GET_PROGRAM_BATCH_LIST,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Get Batch User's List
export const getBatchUsersList = (batchId) => (dispatch) => {
  dispatch(programBatchLoading());
  axios
    .get(`/api/programBatch/getBatchListUsers/${batchId}`)
    .then((res) =>
      dispatch({
        type: GET_BATCH_USERS_LIST,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

export const getBatchApplicableUsers = (batchId) => (dispatch) => {
  dispatch(programBatchLoading());
  axios
    .get(`/api/programBatch/getBatchApplicableUsers/${batchId}`)
    .then((res) =>
      dispatch({
        type: GET_BATCH_APPLICABLE_USERS_LIST,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Create NewProgramBatch
export const createNewProgramBatch = (data) => (dispatch) => {
  dispatch(programBatchLoading());
  axios
    .post(`/api/programBatch/createProgramBatch`, data)
    .then((res) => {
      const { message } = res.data;
      history.push("/admin/program-batches");
      SnackBar.success(message);
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
      history.push("/admin/program-batches");
    });
};

// Adding Users to batch
export const addBatchUser = (data) => (dispatch) => {
  dispatch(programBatchLoading());
  axios
    .post(`/api/programBatch/addBatchUser`, data)
    .then((res) => {
      const { message } = res.data;
      // history.push("/admin/program-batches");
      SnackBar.success(message);
      dispatch(getBatchUsersList(data.batchId));
    })
   
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
      history.push("/admin/program-batches");
    });
};

// Remove Users to batch
export const removeBatchUser = (data) => (dispatch) => {
  dispatch(programBatchLoading());
  axios
    .put(`/api/programBatch/removeBatchUser`, data)
    .then((res) => {
      const { message } = res.data;
      // history.push("/admin/program-batches");
      SnackBar.success(message);
      dispatch(getBatchUsersList(data.batchId));
    })
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
      history.push("/admin/program-batches");
    });
};


export const getBatchByProgramId = (programId) => (dispatch) => {
  dispatch(programBatchLoading());
  axios
    .get(`/api/programBatch/getBatchByProgramId/${programId}`)
    .then((res) =>
      dispatch({
        type: GET_BATCH_BY_PROGRAM_ID,
        payload: res?.data?.data,
      })
    )
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
    });
};

// Assign MultipleUsers to batch
export const assignMultipleUsers = (data) => (dispatch) => {
  dispatch(programBatchLoading());
  axios
    .post(`/api/programBatch/addMultipleUsers`, data)
    .then((res) => {
      const { message } = res.data;
      // history.push("/admin/program-batches");
      SnackBar.success(message);
      dispatch(getBatchUsersList(data.batchId));
    })
   
    .catch((err) => {
      const { message } = err?.response?.data;
      SnackBar.error(message);
      history.push("/admin/program-batches");
    });
};

// Dashboard Loading
export const programBatchLoading = () => {
  return {
    type: PROGRAM_BATCH_LOADING,
  };
};

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getLearnerInfo } from "../../../redux/actions/admin_view/mvkActions";
import { makeStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import {
  Container,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  Button,
} from "@material-ui/core";
import { PeopleOutline as PeopleOutlineIcon } from "@material-ui/icons";
import CollectionsIcon from "@material-ui/icons/Collections";
import MUITable from "../../MaterialTable/MaterialTable";
import TextFieldGroup from "../../../reusableComponents/TextFieldGroup";
import { getDateTime } from "../../../reusableComponents/GetDate";
import isEmpty from "../../../reusableComponents/is-empty";
import PersonalityAssessmentGraph from "../../Dashboard/PersonalityAssessmentGraph";
import {
  getPrograms,
  assignProgram,
} from "../../../redux/actions/programActions";
import SelectSingleValues from "../../../reusableComponents/SelectSingleValues";
import { SnackBar } from "../../../reusableComponents/SnackbarConfig";

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    flexGrow: 1,
    marginBottom: theme.spacing(4),
  },
  gridAssignRoot: {
    margin: theme.spacing(4, 0),
  },
  paperRoot: {
    backgroundColor: "#fdfdff",
    padding: theme.spacing(3, 4),
  },
  pageAssignHeader: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
  },
  pageAssignIcon: {
    display: "inline-block",
    padding: theme.spacing(1),
    color: "#3d4977",
  },
  pageAssignTitle: {
    paddingLeft: theme.spacing(2),
    "& .MuiTypography-h6": {
      fontWeight: 600,
    },
  },
  cardRoot: {
    minWidth: 275,
  },
  title: {
    display: "flex",
    color: "#2B2B2B",
    margin: theme.spacing(1, 0),
  },
  tableHeading: {
    fontWeight: 600,
  },
  titleBold: {
    fontWeight: 600,
    paddingRight: 4,
  },
  noRecords: {
    width: "100%",
    padding: theme.spacing(2),
    textAlign: "center",
  },
  reactPlayerContainer: {
    position: "relative",
  },
  reactPlayer: {
    position: "relative",
    height: 250,
    top: 0,
    left: 0,
  },
}));

const Learner = ({
  getLearnerInfo,
  auth,
  adminMvk,
  programs,
  getPrograms,
  assignProgram,
  match,
}) => {
  const classes = useStyles();
  const { isAuthenticated } = auth;
  const { userId } = match.params;
  const { learnerInfo } = adminMvk;
  const { programList } = programs;
  const [selectedProgramId, setSelectedProgramId] = useState(null);

  useEffect(() => {
    getLearnerInfo(userId);
    getPrograms();
  }, []);

  const firstOne =
    learnerInfo?.userDetails?.psychometricResults[0]?.scores?.traitOne;
  const secondOne =
    learnerInfo?.userDetails?.psychometricResults[0]?.scores?.traitTwo;
  const thirdOne =
    learnerInfo?.userDetails?.psychometricResults[0]?.scores?.traitThree;
  const fourthOne =
    learnerInfo?.userDetails?.psychometricResults[0]?.scores?.traitFour;

  const psychometricGraphData = [
    {
      trait_name: "Introvert",
      scores: isEmpty(firstOne?.Introvert) ? 0 : firstOne?.Introvert,
    },
    {
      trait_name: "Extrovert",
      scores: isEmpty(firstOne?.Extrovert) ? 0 : firstOne?.Extrovert,
    },
    {
      trait_name: "Thinker",
      scores: isEmpty(secondOne?.Thinker) ? 0 : secondOne?.Thinker,
    },
    {
      trait_name: "Judgemental",
      scores: isEmpty(secondOne?.Judgemental) ? 0 : secondOne?.Judgemental,
    },
    {
      trait_name: "AttentionToDetails",
      scores: isEmpty(thirdOne?.AttentionToDetails)
        ? 0
        : thirdOne?.AttentionToDetails,
    },
    {
      trait_name: "HighFlying",
      scores: isEmpty(thirdOne?.HighFlying) ? 0 : thirdOne?.HighFlying,
    },
    {
      trait_name: "Composed",
      scores: isEmpty(fourthOne?.Composed) ? 0 : fourthOne?.Composed,
    },
    {
      trait_name: "Restless",
      scores: isEmpty(fourthOne?.Restless) ? 0 : fourthOne?.Restless,
    },
  ];

  const userData = () => {
    return (
      <>
        {/* <Grid item xs={12} md={6}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} md={2}>
              Learner ID
            </Grid>
            <Grid item xs={12} md={10}>
              <TextFieldGroup
                disabled
                type="text"
                value={learnerInfo?.userDetails?._id}
              />
            </Grid>
          </Grid>
        </Grid> */}

        <Grid item xs={12} md={6}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} md={2}>
              First Name
            </Grid>
            <Grid item xs={12} md={10}>
              <TextFieldGroup
                disabled
                type="text"
                value={learnerInfo?.userDetails?.firstName}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} md={2}>
              Last Name
            </Grid>
            <Grid item xs={12} md={10}>
              <TextFieldGroup
                disabled
                type="text"
                value={learnerInfo?.userDetails?.lastName}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} md={2}>
              Email
            </Grid>
            <Grid item xs={12} md={10}>
              <TextFieldGroup
                disabled
                type="text"
                value={learnerInfo?.userDetails?.email}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} md={2}>
              First Name
            </Grid>
            <Grid item xs={12} md={10}>
              <TextFieldGroup
                disabled
                type="text"
                value={learnerInfo?.userDetails?.firstName}
              />
            </Grid>
          </Grid>
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} md={2}>
              Last Name
            </Grid>
            <Grid item xs={12} md={10}>
              <TextFieldGroup
                disabled
                type="text"
                value={learnerInfo?.userDetails?.lastName}
              />
            </Grid>
          </Grid>
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} md={2}>
              Generated On
            </Grid>
            <Grid item xs={12} md={10}>
              <TextFieldGroup
                disabled
                type="text"
                value={getDateTime(learnerInfo?.userDetails?.createdAt)}
              />
            </Grid>
          </Grid>
        </Grid> */}
        {!isEmpty(learnerInfo?.userDetails?.education) && (
          <Grid item xs={12} md={6}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={12} md={2}>
                Education
              </Grid>
              <Grid item xs={12} md={10}>
                <TextFieldGroup
                  disabled
                  type="text"
                  value={learnerInfo?.userDetails?.education}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const counsellingData = () => {
    return learnerInfo?.userDetails?.counsellingInfo?.map((list) => (
      <Grid item xs={12} sm={6} md={6} lg={3}>
        <Card className={classes.cardRoot}>
          <CardContent>
            <div className={classes.title}>
              <Typography className={classes.titleBold}>Education :</Typography>
              <Typography>{list?.education}</Typography>
            </div>
            <div className={classes.title}>
              <Typography className={classes.titleBold}>City :</Typography>
              <Typography>{list?.city}</Typography>
            </div>
            <div className={classes.title}>
              <Typography className={classes.titleBold}>
                Scheduled Date & Time :
              </Typography>
              <Typography>{getDateTime(list?.scheduledDateTime)}</Typography>
            </div>
          </CardContent>
        </Card>
      </Grid>
    ));
  };

  const programData = () => {
    return learnerInfo?.userDetails?.programsEnrolled?.map((list) => (
      <Grid item xs={12} md={4}>
        <Card className={classes.cardRoot}>
          <CardContent>
            <div className={classes.title}>
              <Typography className={classes.titleBold}>
                Program Name :
              </Typography>
              <Typography>{list?.name}</Typography>
            </div>
            {/* <div className={classes.title}>
              <Typography className={classes.titleBold}>City :</Typography>
              <Typography>{list?.city}</Typography>
            </div>
            <div className={classes.title}>
              <Typography className={classes.titleBold}>
                Scheduled Date & Time :
              </Typography>
              <Typography>{getDateTime(list?.scheduledDateTime)}</Typography>
            </div> */}
          </CardContent>
        </Card>
      </Grid>
    ));
  };

  const handleProgram = () => {
    if (!selectedProgramId) {
      SnackBar.error("Please select any program to assign");
    }
    const programData = {
      userId,
      programId: selectedProgramId?._id,
    };
    assignProgram(programData);
  };

  return (
    <Container maxWidth="lg">
      <div className={classes.gridRoot}>
        <Paper className={classes.paperRoot}>
          <div className={classes.pageAssignHeader}>
            <Card className={classes.pageAssignIcon}>
              <PeopleOutlineIcon fontSize="large" />
            </Card>
            <div className={classes.pageAssignTitle}>
              <Typography variant="h6" component="div">
                Learner Details
              </Typography>
            </div>
          </div>
          <Grid container spacing={3}>
            {userData()}
          </Grid>
        </Paper>
      </div>

      <div className={classes.gridRoot}>
        <Paper className={classes.paperRoot}>
          <div className={classes.pageAssignHeader}>
            <Card className={classes.pageAssignIcon}>
              <CollectionsIcon fontSize="large" />
            </Card>
            <div className={classes.pageAssignTitle}>
              <Typography variant="h6" component="div">
                Programs Enrolled
              </Typography>
            </div>
          </div>
          <Grid container spacing={3}>
            {!isEmpty(learnerInfo?.userDetails?.programsEnrolled) ? (
              programData()
            ) : (
              <Typography
                variant="h6"
                component="div"
                className={classes.noRecords}
              >
                Not enrolled to any Programs
              </Typography>
            )}
            {
              <>
                <Grid item xs={12} md={12} style={{ marginTop: "5%" }}>
                  <SelectSingleValues
                    label="Assign Programs"
                    name="programName"
                    className={classes.inputFields}
                    values={
                      programList &&
                      learnerInfo?.userDetails?.programsEnrolled?.length > 0
                        ? programList.filter(
                            (item1) =>
                              !learnerInfo?.userDetails.programsEnrolled.some(
                                (item2) =>
                                  item2._id === item1._id &&
                                  item2.name === item1.name
                              )
                          )
                        : programList
                    }
                    onSelect={(program) => setSelectedProgramId(program)}
                    selected={selectedProgramId}
                  />
                </Grid>
                <Grid item xs={12} md={12} align="center">
                  <div className={classes.submitButton}>
                    <Button
                      buttonStyle="submit"
                      type="button"
                      name="submit"
                      color="primary"
                      className={classes.inputButtonFields}
                      variant="contained"
                      onClick={handleProgram}
                    >
                      Assign Program
                    </Button>
                  </div>
                </Grid>
              </>
            }
          </Grid>
        </Paper>
      </div>

      <div className={classes.gridRoot}>
        <Paper className={classes.paperRoot}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.pageAssignTitle}>
                <Typography variant="h6" component="div">
                  Business 101 Assessment Result
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              {!isEmpty(learnerInfo?.assessmentResult) ? (
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableHeading}
                          align="left"
                        >
                          Skill Level
                        </TableCell>
                        <TableCell
                          className={classes.tableHeading}
                          align="center"
                        >
                          Topic
                        </TableCell>
                        <TableCell
                          className={classes.tableHeading}
                          align="center"
                        >
                          Date
                        </TableCell>
                        <TableCell
                          className={classes.tableHeading}
                          align="right"
                        >
                          Result
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {learnerInfo?.assessmentResult.slice(0, 5).map((list) => (
                        <TableRow key={list.id}>
                          <TableCell align="left">{list.skill_level}</TableCell>
                          <TableCell align="center">{list.topicname}</TableCell>
                          <TableCell align="center">
                            {list.insertedDate}
                          </TableCell>
                          <TableCell align="right">{list.tresult}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography
                  variant="h6"
                  component="div"
                  className={classes.noRecords}
                >
                  Business 101 Assessment has not taken yet
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
      </div>
      <div className={classes.gridRoot}>
        <Paper className={classes.paperRoot}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.pageAssignTitle}>
                <Typography variant="h6" component="div">
                  Personality Assessment Result
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              {!isEmpty(learnerInfo?.userDetails?.psychometricResults) ? (
                <PersonalityAssessmentGraph
                  psychometricGraphData={psychometricGraphData}
                />
              ) : (
                <Typography
                  variant="h6"
                  component="div"
                  className={classes.noRecords}
                >
                  Personality Assessment has not taken yet
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
      </div>
      <div className={classes.gridRoot}>
        <Paper className={classes.paperRoot}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.pageAssignTitle}>
                <Typography variant="h6" component="div">
                  Communication Result
                </Typography>
              </div>
            </Grid>
            {!isEmpty(
              learnerInfo?.userDetails?.psychometricResults[0]
                ?.comunicationVideoURL
            ) ? (
              <>
                <Grid item xs={4}>
                  <div className={classes.reactPlayerContainer}>
                    <ReactPlayer
                      className={classes.reactPlayer}
                      config={{
                        file: {
                          attributes: {
                            controlsList: "nodownload",
                          },
                        },
                      }}
                      url={
                        learnerInfo?.userDetails?.psychometricResults[0]
                          ?.comunicationVideoURL
                      }
                      width="300px"
                      height="100%"
                      // style={{ pointerEvents: 'none', display: 'block', margin: 'auto' }}
                      controls
                    />
                  </div>
                </Grid>
                {learnerInfo?.userDetails?.psychometricResults[0]
                  ?.analysisDone &&
                  learnerInfo?.userDetails?.psychometricResults[0]
                    ?.sentimentAnalysis && (
                    <Grid item xs={8}>
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className={classes.tableHeading}
                                align="left"
                              >
                                Final Assessment
                              </TableCell>
                              <TableCell
                                className={classes.tableHeading}
                                align="center"
                              >
                                Grammatical Errors
                              </TableCell>
                              <TableCell
                                className={classes.tableHeading}
                                align="center"
                              >
                                Accent Problems
                              </TableCell>
                              <TableCell
                                className={classes.tableHeading}
                                align="right"
                              >
                                Total Duration
                              </TableCell>
                              {learnerInfo?.userDetails?.psychometricResults[0]
                                ?.sentimentAnalysis.errorMessage !== "N/A" && (
                                <TableCell
                                  className={classes.tableHeading}
                                  align="right"
                                >
                                  Errors
                                </TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="left">
                                {
                                  learnerInfo?.userDetails
                                    ?.psychometricResults[0]?.sentimentAnalysis
                                    .finalAssessment
                                }
                              </TableCell>
                              <TableCell align="center">
                                {
                                  learnerInfo?.userDetails
                                    ?.psychometricResults[0]?.sentimentAnalysis
                                    .grammaticalErrors
                                }
                              </TableCell>
                              <TableCell align="center">
                                {
                                  learnerInfo?.userDetails
                                    ?.psychometricResults[0]?.sentimentAnalysis
                                    .spellingErrors
                                }
                              </TableCell>
                              <TableCell align="right">
                                {
                                  learnerInfo?.userDetails
                                    ?.psychometricResults[0]?.sentimentAnalysis
                                    .totalDuration
                                }
                              </TableCell>

                              {learnerInfo?.userDetails?.psychometricResults[0]
                                ?.sentimentAnalysis.errorMessage !== "N/A" && (
                                <TableCell
                                  className={classes.tableHeading}
                                  align="right"
                                >
                                  {
                                    learnerInfo?.userDetails
                                      ?.psychometricResults[0]
                                      ?.sentimentAnalysis.finalAssessment
                                  }
                                </TableCell>
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}
              </>
            ) : (
              <Typography
                variant="h6"
                component="div"
                className={classes.noRecords}
              >
                Communication Video test has not taken yet
              </Typography>
            )}
          </Grid>
        </Paper>
      </div>
      <div className={classes.gridRoot}>
        <Paper className={classes.paperRoot}>
          <div className={classes.pageAssignHeader}>
            <Card className={classes.pageAssignIcon}>
              <PeopleOutlineIcon fontSize="large" />
            </Card>
            <div className={classes.pageAssignTitle}>
              <Typography variant="h6" component="div">
                Counselling Details
              </Typography>
            </div>
          </div>
          <Grid container spacing={3}>
            {!isEmpty(learnerInfo?.userDetails?.counsellingInfo) ? (
              counsellingData()
            ) : (
              <Typography
                variant="h6"
                component="div"
                className={classes.noRecords}
              >
                No Counselling Records
              </Typography>
            )}
          </Grid>
        </Paper>
      </div>
    </Container>
  );
};

Learner.propTypes = {
  auth: PropTypes.object.isRequired,
  getLearnerInfo: PropTypes.func.isRequired,
  adminMvk: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  adminMvk: state.adminMvk,
  programs: state.programs,
});

const mapDispatchToProps = { getLearnerInfo, getPrograms, assignProgram };

export default connect(mapStateToProps, mapDispatchToProps)(Learner);

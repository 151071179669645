import React from 'react'
import { makeStyles, Fab } from '@material-ui/core';
import AddIcon from "@material-ui/icons/Add";


const useStyles = makeStyles(theme => ({
    root: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    buttonContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        textTransform: 'capitalize',
        background: '#233044',
        color: '#F8F9F9',
    },
    fileName: {
        fontSize: '1.15em',
        color: 'rgba(0, 0, 0, 0.5)',
        paddingLeft: 10,
        width: '80%',
        overflow: 'hidden',
        position: 'relative',
        display: 'inlineBlock',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
}))

export default function FileUpload({ name, type, onChange, displayName, accept, fileName }) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <label htmlFor="upload-photo">
                <input
                    style={{ display: "none" }}
                    accept={accept}
                    id="upload-photo"
                    name={name}
                    type={type ||"file"}
                    onChange={onChange}
                />
                <Fab
                    className={classes.buttonContainer}
                    size="small"
                    component="span"
                    aria-label="add"
                    variant="extended"
                >
                    <AddIcon /> {displayName}
                </Fab>
            </label>
            <span className={classes.fileName}>{fileName}</span>
        </div>
    )
}

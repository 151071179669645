
import React, { useEffect } from 'react';
import { connect } from "react-redux";

import {
    Grid,
    Typography,
    Button,
  } from "@material-ui/core";
import PropTypes from "prop-types";
import { getProgramDisEnrolledUserList } from '../../redux/actions/programActions';
import { getBatchByProgramId, assignMultipleUsers } from '../../redux/actions/programBatchActions';
import SelectMultipleValues from '../../reusableComponents/SelectMultipleValues';
import ButtonField from '../../reusableComponents/ButtonField';
import SelectSingleValues from "../../reusableComponents/SelectSingleValues";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";



const useStyles = makeStyles((theme) => ({
   
    selectMultipleValues:{
    //   color:"black",
    //   width:"500px",
      // width:"100%"
    },
    // selectMultipleValuesGrid:{
    //   width:"100%"
    // }
  }));


const AddingUserToProgram = ({
  getProgramDisEnrolledUserList,
  programs,
  getBatchByProgramId,
  programBatch,
  setOpenAddLearner,
  programId,
  assignMultipleUsers,
  auth,
} ) => {

  console.log("programId",programId)
    const {programDisEnrolledUserList} = programs;
    const {batchListByProgramId}=programBatch

    const disEnrolledUserList = programDisEnrolledUserList.map((item)=> {
      return{ 
        _id:item._id,
        name:`${item.firstName} ${item.lastName}`
      }
    })

    const classes = useStyles();
    const { isAuthenticated, user } = auth;
    const formik = useFormik({
        initialValues: {
          selectUser: [],
          selectBatch:"",
        },

        validationSchema: Yup.object({
          selectUser: Yup.array(
       
            Yup.object({
              _id: Yup.string(),
              name: Yup.string(),
            })
          )
          .min(1, "Select Atleast One User")
          .required("Select Atleast One User"),
          selectBatch: Yup.object().nullable().required("Program Batch is reqired field"),
        }),
        onSubmit: async (values) => {
           const data = {
            programId:programId,
            batchId:values.selectBatch._id,
            users:values.selectUser.map((item) => item._id)
          } 
          assignMultipleUsers(data)
          console.log("data",data)
          setOpenAddLearner(false)
        },
      });

      useEffect(() => {
        if (isAuthenticated) {
            getProgramDisEnrolledUserList(programId)
            getBatchByProgramId(programId)
        }
      }, [isAuthenticated]);
    return ( 
        <>
          <form
            onSubmit={formik.handleSubmit}
            enableReinitialize={true}
            noValidate
            autoComplete="off"
          >
            <Grid container align="center" >
                <Grid item xs={12} md={12} style={{width:"10%",marginTop:"2%"}}>
                    <SelectMultipleValues 
                        label="Add Users"
                        name="selectUser"
                        className={classes.selectMultipleValues}
                        dataList={disEnrolledUserList}
                        onSelectData={(selectUser) =>
                            formik.setFieldValue("selectUser", selectUser)
                        }
                        errors={
                            formik.touched.selectUser && formik.errors.selectUser
                            ? formik.errors.selectUser
                            : null
                        }
                    />
                </Grid>
                <Grid item xs={12} md={12} style={{width:"10%",marginTop:"2%"}}>
                    <SelectSingleValues
                        label="Select Batch"
                        name="selectBatch"
                        className={classes.selectMultipleValues}
                        values={batchListByProgramId}
                        style={{ marginTop: "5%", paddingTop: "5%" }}
                        onSelect={(selectBatch) =>
                        formik.setFieldValue("selectBatch", selectBatch)
                        }
                        selected={formik.values.selectBatch}
                        errors={
                        formik.touched.selectBatch &&
                        formik.errors.selectBatch
                            ? formik.errors.selectBatch
                            : null
                        }
                    />
                </Grid>
                <Grid item xs={12} md={12} style={{marginTop:"2%"}}>
                  <Button
                    buttonStyle="submit"
                    type="submit"
                    name="submit"
                    color="primary"
                    className={classes.inputButtonFields}
                    variant="contained"
                    // onClick={submitForm}
                  >
                    Submit
                  </Button>
                </Grid>
            </Grid>
          </form>
        </>
     );
}
AddingUserToProgram.propTypes = {
  auth: PropTypes.object.isRequired,
  getProgramDisEnrolledUserList:  PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  programs: state.programs,
  programBatch: state.programBatch,
});

const mapDispatchToProps = {
  getProgramDisEnrolledUserList,
  getBatchByProgramId,
  assignMultipleUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddingUserToProgram);